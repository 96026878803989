/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { JobResultType } from './JobResultType';
import { JobResultTypeFromJSON, JobResultTypeFromJSONTyped, JobResultTypeToJSON, } from './JobResultType';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface JobStatusDataResult
 *
 */
// create custom validators for the regexes
export interface JobStatusDataResult {
    /**
     *
     * #type JobResultType
     * #memberof JobStatusDataResult
        
        
         */
    type: JobResultType;
    /**
     * Depending on the job type, this can be anything
     * #type any
     * #memberof JobStatusDataResult
        
        
         */
    data?: any | null;
}
/**
 * Check if a given object implements the JobStatusDataResult interface.
 */
export function instanceOfJobStatusDataResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { JobResultTypeValidator, JobResultTypeStringifyShape, JobResultTypeStringifyShapeProperties, } from './JobResultType';
export const JobStatusDataResultTypiaAssertEquals = (input: any) => {
    return ((input: any): JobStatusDataResult => {
        const __is = (input: any, _exceptionable: boolean = true): input is JobStatusDataResult => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => ("DATASET_PROCESSING" === input.type || "IMAGEMETA" === input.type || "EMBEDDING" === input.type || "EMBEDDINGS2D" === input.type || "SAMPLING" === input.type) && true && (1 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["type", "data"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is JobStatusDataResult => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("DATASET_PROCESSING" === input.type || "IMAGEMETA" === input.type || "EMBEDDING" === input.type || "EMBEDDINGS2D" === input.type || "SAMPLING" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "(\"DATASET_PROCESSING\" | \"EMBEDDING\" | \"EMBEDDINGS2D\" | \"IMAGEMETA\" | \"SAMPLING\")",
                    value: input.type
                })) && true && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["type", "data"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "JobStatusDataResult",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "JobStatusDataResult",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const JobStatusDataResultTypiaAssertStringify = (input: JobStatusDataResult): string => {
    return ((input: any): string => { const assert = (input: any): JobStatusDataResult => {
        const __is = (input: any): input is JobStatusDataResult => {
            const $io0 = (input: any): boolean => ("DATASET_PROCESSING" === input.type || "IMAGEMETA" === input.type || "EMBEDDING" === input.type || "EMBEDDINGS2D" === input.type || "SAMPLING" === input.type) && true;
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is JobStatusDataResult => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("DATASET_PROCESSING" === input.type || "IMAGEMETA" === input.type || "EMBEDDING" === input.type || "EMBEDDINGS2D" === input.type || "SAMPLING" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "(\"DATASET_PROCESSING\" | \"EMBEDDING\" | \"EMBEDDINGS2D\" | \"IMAGEMETA\" | \"SAMPLING\")",
                    value: input.type
                })) && true;
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "JobStatusDataResult",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "JobStatusDataResult",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: JobStatusDataResult): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $so0 = (input: any): any => `{${undefined === input.data || "function" === typeof input.data ? "" : `"data":${undefined !== input.data ? JSON.stringify(input.data) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "(\"DATASET_PROCESSING\" | \"EMBEDDING\" | \"EMBEDDINGS2D\" | \"IMAGEMETA\" | \"SAMPLING\")",
                value: input.type
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const JobStatusDataResultValidatorShape = {
    'type': JobResultTypeValidator.defined(),
    'data': yup.mixed().nullable(),
};
export const JobStatusDataResultValidator = yup.object().shape(JobStatusDataResultValidatorShape);
export const JobStatusDataResultStringifyShapeProperties: ObjectSchema['properties'] = {
    'type': JobResultTypeStringifyShape,
    'data': {},
};
export const JobStatusDataResultStringifyShape: ObjectSchema = {
    title: 'JobStatusDataResult Stringifier',
    type: 'object',
    properties: {
        ...JobStatusDataResultStringifyShapeProperties
    },
    required: [
        'type',
    ],
};
export const JobStatusDataResultStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(JobStatusDataResultStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const JobStatusDataResultStringify: (data: JobStatusDataResult) => string = JobStatusDataResultStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function JobStatusDataResultFromJSON(json: any): JobStatusDataResult {
    return JobStatusDataResultFromJSONTyped(json, false);
}
export function JobStatusDataResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobStatusDataResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'type': JobResultTypeFromJSON(json['type']),
        'data': !exists(json, 'data') ? undefined : json['data'],
    };
}
export function JobStatusDataResultToJSON(value?: JobStatusDataResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': JobResultTypeToJSON(value.type),
        'data': value.data,
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { DockerTaskDescription } from './DockerTaskDescription';
import { DockerTaskDescriptionFromJSON, DockerTaskDescriptionFromJSONTyped, DockerTaskDescriptionToJSON, } from './DockerTaskDescription';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DockerAuthorizationRequest
 *
 */
// create custom validators for the regexes
export interface DockerAuthorizationRequest {
    /**
     * unix timestamp in milliseconds
     * #type number
     * #memberof DockerAuthorizationRequest
        
    * @minimum 0
            
         */
    timestamp: number;
    /**
     *
     * #type DockerTaskDescription
     * #memberof DockerAuthorizationRequest
        
        
         */
    taskDescription: DockerTaskDescription;
}
/**
 * Check if a given object implements the DockerAuthorizationRequest interface.
 */
export function instanceOfDockerAuthorizationRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "timestamp" in value;
    isInstance = isInstance && "taskDescription" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { DockerTaskDescriptionValidator, DockerTaskDescriptionStringifyShape, DockerTaskDescriptionStringifyShapeProperties, } from './DockerTaskDescription';
export const DockerAuthorizationRequestTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerAuthorizationRequest => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerAuthorizationRequest => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.timestamp && 0 <= input.timestamp && ("object" === typeof input.taskDescription && null !== input.taskDescription && $io1(input.taskDescription, true && _exceptionable)) && (2 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["timestamp", "taskDescription"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.embeddingsFilename && "string" === typeof input.embeddingsHash && ("ACTIVE_LEARNING" === input.method || "CORAL" === input.method || "CORESET" === input.method || "RANDOM" === input.method) && "string" === typeof input.existingSelectionColumnName && "string" === typeof input.activeLearningScoresColumnName && "string" === typeof input.maskedOutColumnName && ("object" === typeof input.samplingConfig && null !== input.samplingConfig && false === Array.isArray(input.samplingConfig) && $io2(input.samplingConfig, true && _exceptionable)) && ("number" === typeof input.nData && 0 <= input.nData) && (8 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["embeddingsFilename", "embeddingsHash", "method", "existingSelectionColumnName", "activeLearningScoresColumnName", "maskedOutColumnName", "samplingConfig", "nData"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.stoppingCondition || "object" === typeof input.stoppingCondition && null !== input.stoppingCondition && false === Array.isArray(input.stoppingCondition) && $io3(input.stoppingCondition, true && _exceptionable)) && (0 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["stoppingCondition"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io3 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.nSamples || "number" === typeof input.nSamples) && (undefined === input.minDistance || "number" === typeof input.minDistance) && (0 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["nSamples", "minDistance"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerAuthorizationRequest => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.timestamp && (0 <= input.timestamp || $guard(_exceptionable, {
                    path: _path + ".timestamp",
                    expected: "number (@minimum 0)",
                    value: input.timestamp
                })) || $guard(_exceptionable, {
                    path: _path + ".timestamp",
                    expected: "number",
                    value: input.timestamp
                })) && (("object" === typeof input.taskDescription && null !== input.taskDescription || $guard(_exceptionable, {
                    path: _path + ".taskDescription",
                    expected: "DockerTaskDescription",
                    value: input.taskDescription
                })) && $ao1(input.taskDescription, _path + ".taskDescription", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".taskDescription",
                    expected: "DockerTaskDescription",
                    value: input.taskDescription
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["timestamp", "taskDescription"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.embeddingsFilename || $guard(_exceptionable, {
                    path: _path + ".embeddingsFilename",
                    expected: "string",
                    value: input.embeddingsFilename
                })) && ("string" === typeof input.embeddingsHash || $guard(_exceptionable, {
                    path: _path + ".embeddingsHash",
                    expected: "string",
                    value: input.embeddingsHash
                })) && ("ACTIVE_LEARNING" === input.method || "CORAL" === input.method || "CORESET" === input.method || "RANDOM" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"ACTIVE_LEARNING\" | \"CORAL\" | \"CORESET\" | \"RANDOM\")",
                    value: input.method
                })) && ("string" === typeof input.existingSelectionColumnName || $guard(_exceptionable, {
                    path: _path + ".existingSelectionColumnName",
                    expected: "string",
                    value: input.existingSelectionColumnName
                })) && ("string" === typeof input.activeLearningScoresColumnName || $guard(_exceptionable, {
                    path: _path + ".activeLearningScoresColumnName",
                    expected: "string",
                    value: input.activeLearningScoresColumnName
                })) && ("string" === typeof input.maskedOutColumnName || $guard(_exceptionable, {
                    path: _path + ".maskedOutColumnName",
                    expected: "string",
                    value: input.maskedOutColumnName
                })) && (("object" === typeof input.samplingConfig && null !== input.samplingConfig && false === Array.isArray(input.samplingConfig) || $guard(_exceptionable, {
                    path: _path + ".samplingConfig",
                    expected: "SamplingConfig",
                    value: input.samplingConfig
                })) && $ao2(input.samplingConfig, _path + ".samplingConfig", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".samplingConfig",
                    expected: "SamplingConfig",
                    value: input.samplingConfig
                })) && ("number" === typeof input.nData && (0 <= input.nData || $guard(_exceptionable, {
                    path: _path + ".nData",
                    expected: "number (@minimum 0)",
                    value: input.nData
                })) || $guard(_exceptionable, {
                    path: _path + ".nData",
                    expected: "number",
                    value: input.nData
                })) && (8 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["embeddingsFilename", "embeddingsHash", "method", "existingSelectionColumnName", "activeLearningScoresColumnName", "maskedOutColumnName", "samplingConfig", "nData"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.stoppingCondition || ("object" === typeof input.stoppingCondition && null !== input.stoppingCondition && false === Array.isArray(input.stoppingCondition) || $guard(_exceptionable, {
                    path: _path + ".stoppingCondition",
                    expected: "(SamplingConfigStoppingCondition | undefined)",
                    value: input.stoppingCondition
                })) && $ao3(input.stoppingCondition, _path + ".stoppingCondition", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".stoppingCondition",
                    expected: "(SamplingConfigStoppingCondition | undefined)",
                    value: input.stoppingCondition
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["stoppingCondition"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.nSamples || "number" === typeof input.nSamples || $guard(_exceptionable, {
                    path: _path + ".nSamples",
                    expected: "(number | undefined)",
                    value: input.nSamples
                })) && (undefined === input.minDistance || "number" === typeof input.minDistance || $guard(_exceptionable, {
                    path: _path + ".minDistance",
                    expected: "(number | undefined)",
                    value: input.minDistance
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["nSamples", "minDistance"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerAuthorizationRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerAuthorizationRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerAuthorizationRequestTypiaAssertStringify = (input: DockerAuthorizationRequest): string => {
    return ((input: any): string => { const assert = (input: any): DockerAuthorizationRequest => {
        const __is = (input: any): input is DockerAuthorizationRequest => {
            const $io0 = (input: any): boolean => "number" === typeof input.timestamp && !Number.isNaN(input.timestamp) && 0 <= input.timestamp && ("object" === typeof input.taskDescription && null !== input.taskDescription && $io1(input.taskDescription));
            const $io1 = (input: any): boolean => "string" === typeof input.embeddingsFilename && "string" === typeof input.embeddingsHash && ("ACTIVE_LEARNING" === input.method || "CORAL" === input.method || "CORESET" === input.method || "RANDOM" === input.method) && "string" === typeof input.existingSelectionColumnName && "string" === typeof input.activeLearningScoresColumnName && "string" === typeof input.maskedOutColumnName && ("object" === typeof input.samplingConfig && null !== input.samplingConfig && false === Array.isArray(input.samplingConfig) && $io2(input.samplingConfig)) && ("number" === typeof input.nData && !Number.isNaN(input.nData) && 0 <= input.nData);
            const $io2 = (input: any): boolean => undefined === input.stoppingCondition || "object" === typeof input.stoppingCondition && null !== input.stoppingCondition && false === Array.isArray(input.stoppingCondition) && $io3(input.stoppingCondition);
            const $io3 = (input: any): boolean => (undefined === input.nSamples || "number" === typeof input.nSamples && !Number.isNaN(input.nSamples)) && (undefined === input.minDistance || "number" === typeof input.minDistance && !Number.isNaN(input.minDistance));
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerAuthorizationRequest => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.timestamp && !Number.isNaN(input.timestamp) && (0 <= input.timestamp || $guard(_exceptionable, {
                    path: _path + ".timestamp",
                    expected: "number (@minimum 0)",
                    value: input.timestamp
                })) || $guard(_exceptionable, {
                    path: _path + ".timestamp",
                    expected: "number",
                    value: input.timestamp
                })) && (("object" === typeof input.taskDescription && null !== input.taskDescription || $guard(_exceptionable, {
                    path: _path + ".taskDescription",
                    expected: "DockerTaskDescription",
                    value: input.taskDescription
                })) && $ao1(input.taskDescription, _path + ".taskDescription", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".taskDescription",
                    expected: "DockerTaskDescription",
                    value: input.taskDescription
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.embeddingsFilename || $guard(_exceptionable, {
                    path: _path + ".embeddingsFilename",
                    expected: "string",
                    value: input.embeddingsFilename
                })) && ("string" === typeof input.embeddingsHash || $guard(_exceptionable, {
                    path: _path + ".embeddingsHash",
                    expected: "string",
                    value: input.embeddingsHash
                })) && ("ACTIVE_LEARNING" === input.method || "CORAL" === input.method || "CORESET" === input.method || "RANDOM" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"ACTIVE_LEARNING\" | \"CORAL\" | \"CORESET\" | \"RANDOM\")",
                    value: input.method
                })) && ("string" === typeof input.existingSelectionColumnName || $guard(_exceptionable, {
                    path: _path + ".existingSelectionColumnName",
                    expected: "string",
                    value: input.existingSelectionColumnName
                })) && ("string" === typeof input.activeLearningScoresColumnName || $guard(_exceptionable, {
                    path: _path + ".activeLearningScoresColumnName",
                    expected: "string",
                    value: input.activeLearningScoresColumnName
                })) && ("string" === typeof input.maskedOutColumnName || $guard(_exceptionable, {
                    path: _path + ".maskedOutColumnName",
                    expected: "string",
                    value: input.maskedOutColumnName
                })) && (("object" === typeof input.samplingConfig && null !== input.samplingConfig && false === Array.isArray(input.samplingConfig) || $guard(_exceptionable, {
                    path: _path + ".samplingConfig",
                    expected: "SamplingConfig",
                    value: input.samplingConfig
                })) && $ao2(input.samplingConfig, _path + ".samplingConfig", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".samplingConfig",
                    expected: "SamplingConfig",
                    value: input.samplingConfig
                })) && ("number" === typeof input.nData && !Number.isNaN(input.nData) && (0 <= input.nData || $guard(_exceptionable, {
                    path: _path + ".nData",
                    expected: "number (@minimum 0)",
                    value: input.nData
                })) || $guard(_exceptionable, {
                    path: _path + ".nData",
                    expected: "number",
                    value: input.nData
                }));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => undefined === input.stoppingCondition || ("object" === typeof input.stoppingCondition && null !== input.stoppingCondition && false === Array.isArray(input.stoppingCondition) || $guard(_exceptionable, {
                    path: _path + ".stoppingCondition",
                    expected: "(SamplingConfigStoppingCondition | undefined)",
                    value: input.stoppingCondition
                })) && $ao3(input.stoppingCondition, _path + ".stoppingCondition", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".stoppingCondition",
                    expected: "(SamplingConfigStoppingCondition | undefined)",
                    value: input.stoppingCondition
                });
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.nSamples || "number" === typeof input.nSamples && !Number.isNaN(input.nSamples) || $guard(_exceptionable, {
                    path: _path + ".nSamples",
                    expected: "(number | undefined)",
                    value: input.nSamples
                })) && (undefined === input.minDistance || "number" === typeof input.minDistance && !Number.isNaN(input.minDistance) || $guard(_exceptionable, {
                    path: _path + ".minDistance",
                    expected: "(number | undefined)",
                    value: input.minDistance
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerAuthorizationRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerAuthorizationRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerAuthorizationRequest): string => {
        const $io1 = (input: any): boolean => "string" === typeof input.embeddingsFilename && "string" === typeof input.embeddingsHash && ("ACTIVE_LEARNING" === input.method || "CORAL" === input.method || "CORESET" === input.method || "RANDOM" === input.method) && "string" === typeof input.existingSelectionColumnName && "string" === typeof input.activeLearningScoresColumnName && "string" === typeof input.maskedOutColumnName && ("object" === typeof input.samplingConfig && null !== input.samplingConfig && false === Array.isArray(input.samplingConfig) && $io2(input.samplingConfig)) && ("number" === typeof input.nData && 0 <= input.nData);
        const $io2 = (input: any): boolean => undefined === input.stoppingCondition || "object" === typeof input.stoppingCondition && null !== input.stoppingCondition && false === Array.isArray(input.stoppingCondition) && $io3(input.stoppingCondition);
        const $io3 = (input: any): boolean => (undefined === input.nSamples || "number" === typeof input.nSamples) && (undefined === input.minDistance || "number" === typeof input.minDistance);
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $tail = (typia.createAssertStringify as any).tail;
        const $so0 = (input: any): any => `{"timestamp":${input.timestamp},"taskDescription":${$so1(input.taskDescription)}}`;
        const $so1 = (input: any): any => `{"embeddingsFilename":${$string(input.embeddingsFilename)},"embeddingsHash":${$string(input.embeddingsHash)},"method":${(() => {
            if ("string" === typeof input.method)
                return $string(input.method);
            if ("string" === typeof input.method)
                return "\"" + input.method + "\"";
            $throws({
                expected: "(\"ACTIVE_LEARNING\" | \"CORAL\" | \"CORESET\" | \"RANDOM\")",
                value: input.method
            });
        })()},"existingSelectionColumnName":${$string(input.existingSelectionColumnName)},"activeLearningScoresColumnName":${$string(input.activeLearningScoresColumnName)},"maskedOutColumnName":${$string(input.maskedOutColumnName)},"samplingConfig":${$so2(input.samplingConfig)},"nData":${input.nData}}`;
        const $so2 = (input: any): any => `{${$tail(`${undefined === input.stoppingCondition ? "" : `"stoppingCondition":${undefined !== input.stoppingCondition ? $so3(input.stoppingCondition) : undefined}`}`)}}`;
        const $so3 = (input: any): any => `{${$tail(`${undefined === input.nSamples ? "" : `"nSamples":${undefined !== input.nSamples ? input.nSamples : undefined},`}${undefined === input.minDistance ? "" : `"minDistance":${undefined !== input.minDistance ? input.minDistance : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DockerAuthorizationRequestValidatorShape = {
    'timestamp': yup.number().min(0).defined(),
    'taskDescription': DockerTaskDescriptionValidator.defined(),
};
export const DockerAuthorizationRequestValidator = yup.object().shape(DockerAuthorizationRequestValidatorShape);
export const DockerAuthorizationRequestStringifyShapeProperties: ObjectSchema['properties'] = {
    'timestamp': {
        'type': 'number',
        'minimum': 0,
    },
    'taskDescription': DockerTaskDescriptionStringifyShape,
};
export const DockerAuthorizationRequestStringifyShape: ObjectSchema = {
    title: 'DockerAuthorizationRequest Stringifier',
    type: 'object',
    properties: {
        ...DockerAuthorizationRequestStringifyShapeProperties
    },
    required: [
        'timestamp',
        'taskDescription',
    ],
};
export const DockerAuthorizationRequestStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerAuthorizationRequestStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerAuthorizationRequestStringify: (data: DockerAuthorizationRequest) => string = DockerAuthorizationRequestStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerAuthorizationRequestFromJSON(json: any): DockerAuthorizationRequest {
    return DockerAuthorizationRequestFromJSONTyped(json, false);
}
export function DockerAuthorizationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerAuthorizationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'timestamp': json['timestamp'],
        'taskDescription': DockerTaskDescriptionFromJSON(json['taskDescription']),
    };
}
export function DockerAuthorizationRequestToJSON(value?: DockerAuthorizationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'timestamp': value.timestamp,
        'taskDescription': DockerTaskDescriptionToJSON(value.taskDescription),
    };
}

// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import type { ApiErrorResponse, } from '../models';
import { ApiErrorResponseFromJSON, ApiErrorResponseToJSON, ApiErrorResponseValidator, } from '../models';
import * as models from '../models';
/* LIGHTLY CUSTOM START -> -> moved from bottom to top:  apiMustache */
/**
  * @export
  * @enum {string}
  */
export enum GetSampleMappingsByDatasetIdFieldEnum {
    id = '_id',
    fileName = 'fileName'
}
/* LIGHTLY CUSTOM START ->  apiMustache */
export const GetSampleMappingsByDatasetIdFieldEnumValidatorShape = [
    '_id',
    'fileName'
];
export const GetSampleMappingsByDatasetIdFieldEnumValidator = yup.mixed().oneOf(GetSampleMappingsByDatasetIdFieldEnumValidatorShape);
/* LIGHTLY CUSTOM END ->  apiMustache */
/* LIGHTLY CUSTOM END -> moved from bottom to top: apiMustache */
export interface GetSampleMappingsByDatasetIdRequest {
    datasetId: string;
    field: GetSampleMappingsByDatasetIdFieldEnum;
    pageSize?: number;
    pageOffset?: number;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetSampleMappingsByDatasetIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'field': yup.string().oneOf(GetSampleMappingsByDatasetIdFieldEnumValidatorShape).defined(),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
};
export const GetSampleMappingsByDatasetIdRequestValidator = yup.object().shape(GetSampleMappingsByDatasetIdRequestValidatorShape);
export interface GetSampleMappingsByDatasetIdRequestParams {
    datasetId: string;
}
export function GetSampleMappingsByDatasetIdRequestParamsSanitizer(json: any): GetSampleMappingsByDatasetIdRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const GetSampleMappingsByDatasetIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetSampleMappingsByDatasetIdRequestParamsValidator = yup.object().shape(GetSampleMappingsByDatasetIdRequestParamsValidatorShape);
export interface GetSampleMappingsByDatasetIdRequestQuery {
    field: GetSampleMappingsByDatasetIdFieldEnum;
    pageSize?: number;
    pageOffset?: number;
}
export function GetSampleMappingsByDatasetIdRequestQuerySanitizer(json: any): GetSampleMappingsByDatasetIdRequestQuery {
    return {
        'field': json['field'],
        'pageSize': json['pageSize'],
        'pageOffset': json['pageOffset'],
    };
}
export const GetSampleMappingsByDatasetIdRequestQueryValidatorShape = {
    'field': yup.string().oneOf(GetSampleMappingsByDatasetIdFieldEnumValidatorShape).defined(),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
};
export const GetSampleMappingsByDatasetIdRequestQueryValidator = yup.object().shape(GetSampleMappingsByDatasetIdRequestQueryValidatorShape);
/*
HANDLERS:



/v1/datasets/{datasetId}/mappings
const stringArrayStringify = fastJSONArrayify(stringStringifyShape);
export const getSampleMappingsByDatasetIdFN: RequestHandlerAsync<
    GetSampleMappingsByDatasetIdRequestParams,
    string[] | ApiErrorResponse,
    GetSampleMappingsByDatasetIdRequestBody,
    GetSampleMappingsByDatasetIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetSampleMappingsByDatasetIdRequestBodySanitizer, GetSampleMappingsByDatasetIdRequestBodyValidator);
    const {
        datasetId,
        
        
        
    } =  await validateAndSanitize(req.params, GetSampleMappingsByDatasetIdRequestParamsSanitizer, GetSampleMappingsByDatasetIdRequestParamsValidator);
    const {
        
        field,
        pageSize,
        pageOffset,
    } = await validateAndSanitize(req.query, GetSampleMappingsByDatasetIdRequestQuerySanitizer, GetSampleMappingsByDatasetIdRequestQueryValidator);



    
    const schemas = ....

    return fastJSONResponse(
        res,
        stringArrayStringify,
        schemas.map((schema) => {
            const { _id: id, ...rest } = schema;
            return validateAndRespond({ id, ...rest }, stringFromJSON, stringValidator);
        }),
    );
}


*/
/* LIGHTLY CUSTOM END -> api */
/**
 * MappingsApi - interface
 *
 * @export
 * @interface MappingsApiInterface
 */
export interface MappingsApiInterface {
    /**
     * Get all samples of a dataset as a list. List index is the index of the sample2bitmask mapping and the value is the \'field\' you wanted (e.g _id, fileName)
     * @param {string} datasetId ObjectId of the dataset
     * @param {'_id' | 'fileName'} field the field to return as the value
     * @param {number} [pageSize] pagination size/limit of the number of samples to return
     * @param {number} [pageOffset] pagination offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MappingsApiInterface
     */
    getSampleMappingsByDatasetIdRaw(requestParameters: GetSampleMappingsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>>;
    /**
     * Get all samples of a dataset as a list. List index is the index of the sample2bitmask mapping and the value is the \'field\' you wanted (e.g _id, fileName)
     */
    getSampleMappingsByDatasetId(requestParameters: GetSampleMappingsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>>;
}
/**
 *
 */
export class MappingsApi extends runtime.BaseAPI implements MappingsApiInterface {
    /**
     * Get all samples of a dataset as a list. List index is the index of the sample2bitmask mapping and the value is the \'field\' you wanted (e.g _id, fileName)
     */
    async getSampleMappingsByDatasetIdRaw(requestParameters: GetSampleMappingsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getSampleMappingsByDatasetId.');
        }
        if (requestParameters.field === null || requestParameters.field === undefined) {
            throw new runtime.RequiredError('field', 'Required parameter requestParameters.field was null or undefined when calling getSampleMappingsByDatasetId.');
        }
        const queryParameters: any = {};
        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }
        if (requestParameters.pageOffset !== undefined) {
            queryParameters['pageOffset'] = requestParameters.pageOffset;
        }
        if (requestParameters.field !== undefined) {
            queryParameters['field'] = requestParameters.field;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/mappings`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse<any>(response);
    }
    /**
     * Get all samples of a dataset as a list. List index is the index of the sample2bitmask mapping and the value is the \'field\' you wanted (e.g _id, fileName)
     */
    async getSampleMappingsByDatasetId(requestParameters: GetSampleMappingsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getSampleMappingsByDatasetIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
}

/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { DockerRunLogEntryData } from './DockerRunLogEntryData';
import { DockerRunLogEntryDataFromJSON, DockerRunLogEntryDataFromJSONTyped, DockerRunLogEntryDataToJSON, } from './DockerRunLogEntryData';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DockerRunLogData
 *
 */
// create custom validators for the regexes
export interface DockerRunLogData {
    /**
     * The cursor to use to fetch more logs.
     * #type number
     * #memberof DockerRunLogData
        * @type int
    * @minimum 0
            
         */
    cursor?: number;
    /**
     *
     * #type Array<DockerRunLogEntryData>
     * #memberof DockerRunLogData
        
        
         */
    logs: Array<DockerRunLogEntryData>;
}
/**
 * Check if a given object implements the DockerRunLogData interface.
 */
export function instanceOfDockerRunLogData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "logs" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { DockerRunLogEntryDataValidator, DockerRunLogEntryDataStringifyShape, DockerRunLogEntryDataStringifyShapeProperties, } from './DockerRunLogEntryData';
export const DockerRunLogDataTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerRunLogData => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerRunLogData => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.cursor || "number" === typeof input.cursor && Math.floor(input.cursor) === input.cursor && (-2147483648 <= input.cursor && input.cursor <= 2147483647) && 0 <= input.cursor) && (Array.isArray(input.logs) && input.logs.every((elem: any, _index1: number) => "object" === typeof elem && null !== elem && $io1(elem, true && _exceptionable))) && (1 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["cursor", "logs"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.ts && 0 <= input.ts && ("VERBOSE" === input.level || "NOTSET" === input.level || "DEBUG" === input.level || "INFO" === input.level || "WARNING" === input.level || "ERROR" === input.level || "CRITICAL" === input.level) && (undefined === input.group || "string" === typeof input.group) && (undefined === input.origin || "string" === typeof input.origin) && "string" === typeof input.msg && ("STARTED" === input.state || "INITIALIZING" === input.state || "LOADING_DATASET" === input.state || "LOADING_METADATA" === input.state || "LOADING_PREDICTION" === input.state || "CHECKING_CORRUPTNESS" === input.state || "INITIALIZING_OBJECT_CROPS" === input.state || "COMPUTING_METADATA" === input.state || "TRAINING" === input.state || "EMBEDDING" === input.state || "EMBEDDING_OBJECT_CROPS" === input.state || "PRETAGGING" === input.state || "COMPUTING_ACTIVE_LEARNING_SCORES" === input.state || "SAMPLING" === input.state || "EMBEDDING_FULL_IMAGES" === input.state || "SAVING_RESULTS" === input.state || "UPLOADING_DATASET" === input.state || "GENERATING_REPORT" === input.state || "UPLOADING_REPORT" === input.state || "UPLOADED_REPORT" === input.state || "UPLOADING_ARTIFACTS" === input.state || "UPLOADED_ARTIFACTS" === input.state || "COMPLETED" === input.state || "FAILED" === input.state || "CRASHED" === input.state || "ABORTED" === input.state) && (undefined === input.load || "object" === typeof input.load && null !== input.load && false === Array.isArray(input.load) && $io2(input.load, true && _exceptionable)) && (4 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["ts", "level", "group", "origin", "msg", "state", "load"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.cpu || "number" === typeof input.cpu && 0 <= input.cpu && 100 >= input.cpu) && (undefined === input.mem || "number" === typeof input.mem && 0 <= input.mem && 100 >= input.mem) && (0 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["cpu", "mem"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerRunLogData => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.cursor || "number" === typeof input.cursor && (Math.floor(input.cursor) === input.cursor || $guard(_exceptionable, {
                    path: _path + ".cursor",
                    expected: "number (@type int)",
                    value: input.cursor
                })) && (-2147483648 <= input.cursor && input.cursor <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".cursor",
                    expected: "number (@type int)",
                    value: input.cursor
                })) && (0 <= input.cursor || $guard(_exceptionable, {
                    path: _path + ".cursor",
                    expected: "number (@minimum 0)",
                    value: input.cursor
                })) || $guard(_exceptionable, {
                    path: _path + ".cursor",
                    expected: "(number | undefined)",
                    value: input.cursor
                })) && ((Array.isArray(input.logs) || $guard(_exceptionable, {
                    path: _path + ".logs",
                    expected: "Array<DockerRunLogEntryData>",
                    value: input.logs
                })) && input.logs.every((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".logs[" + _index1 + "]",
                    expected: "DockerRunLogEntryData",
                    value: elem
                })) && $ao1(elem, _path + ".logs[" + _index1 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".logs[" + _index1 + "]",
                    expected: "DockerRunLogEntryData",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".logs",
                    expected: "Array<DockerRunLogEntryData>",
                    value: input.logs
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["cursor", "logs"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.ts && (0 <= input.ts || $guard(_exceptionable, {
                    path: _path + ".ts",
                    expected: "number (@minimum 0)",
                    value: input.ts
                })) || $guard(_exceptionable, {
                    path: _path + ".ts",
                    expected: "number",
                    value: input.ts
                })) && ("VERBOSE" === input.level || "NOTSET" === input.level || "DEBUG" === input.level || "INFO" === input.level || "WARNING" === input.level || "ERROR" === input.level || "CRITICAL" === input.level || $guard(_exceptionable, {
                    path: _path + ".level",
                    expected: "(\"CRITICAL\" | \"DEBUG\" | \"ERROR\" | \"INFO\" | \"NOTSET\" | \"VERBOSE\" | \"WARNING\")",
                    value: input.level
                })) && (undefined === input.group || "string" === typeof input.group || $guard(_exceptionable, {
                    path: _path + ".group",
                    expected: "(string | undefined)",
                    value: input.group
                })) && (undefined === input.origin || "string" === typeof input.origin || $guard(_exceptionable, {
                    path: _path + ".origin",
                    expected: "(string | undefined)",
                    value: input.origin
                })) && ("string" === typeof input.msg || $guard(_exceptionable, {
                    path: _path + ".msg",
                    expected: "string",
                    value: input.msg
                })) && ("STARTED" === input.state || "INITIALIZING" === input.state || "LOADING_DATASET" === input.state || "LOADING_METADATA" === input.state || "LOADING_PREDICTION" === input.state || "CHECKING_CORRUPTNESS" === input.state || "INITIALIZING_OBJECT_CROPS" === input.state || "COMPUTING_METADATA" === input.state || "TRAINING" === input.state || "EMBEDDING" === input.state || "EMBEDDING_OBJECT_CROPS" === input.state || "PRETAGGING" === input.state || "COMPUTING_ACTIVE_LEARNING_SCORES" === input.state || "SAMPLING" === input.state || "EMBEDDING_FULL_IMAGES" === input.state || "SAVING_RESULTS" === input.state || "UPLOADING_DATASET" === input.state || "GENERATING_REPORT" === input.state || "UPLOADING_REPORT" === input.state || "UPLOADED_REPORT" === input.state || "UPLOADING_ARTIFACTS" === input.state || "UPLOADED_ARTIFACTS" === input.state || "COMPLETED" === input.state || "FAILED" === input.state || "CRASHED" === input.state || "ABORTED" === input.state || $guard(_exceptionable, {
                    path: _path + ".state",
                    expected: "(\"ABORTED\" | \"CHECKING_CORRUPTNESS\" | \"COMPLETED\" | \"COMPUTING_ACTIVE_LEARNING_SCORES\" | \"COMPUTING_METADATA\" | \"CRASHED\" | \"EMBEDDING\" | \"EMBEDDING_FULL_IMAGES\" | \"EMBEDDING_OBJECT_CROPS\" | \"FAILED\" | \"GENERATING_REPORT\" | \"INITIALIZING\" | \"INITIALIZING_OBJECT_CROPS\" | \"LOADING_DATASET\" | \"LOADING_METADATA\" | \"LOADING_PREDICTION\" | \"PRETAGGING\" | \"SAMPLING\" | \"SAVING_RESULTS\" | \"STARTED\" | \"TRAINING\" | \"UPLOADED_ARTIFACTS\" | \"UPLOADED_REPORT\" | \"UPLOADING_ARTIFACTS\" | \"UPLOADING_DATASET\" | \"UPLOADING_REPORT\")",
                    value: input.state
                })) && (undefined === input.load || ("object" === typeof input.load && null !== input.load && false === Array.isArray(input.load) || $guard(_exceptionable, {
                    path: _path + ".load",
                    expected: "(DockerRunLogDockerLoad | undefined)",
                    value: input.load
                })) && $ao2(input.load, _path + ".load", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".load",
                    expected: "(DockerRunLogDockerLoad | undefined)",
                    value: input.load
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["ts", "level", "group", "origin", "msg", "state", "load"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.cpu || "number" === typeof input.cpu && (0 <= input.cpu || $guard(_exceptionable, {
                    path: _path + ".cpu",
                    expected: "number (@minimum 0)",
                    value: input.cpu
                })) && (100 >= input.cpu || $guard(_exceptionable, {
                    path: _path + ".cpu",
                    expected: "number (@maximum 100)",
                    value: input.cpu
                })) || $guard(_exceptionable, {
                    path: _path + ".cpu",
                    expected: "(number | undefined)",
                    value: input.cpu
                })) && (undefined === input.mem || "number" === typeof input.mem && (0 <= input.mem || $guard(_exceptionable, {
                    path: _path + ".mem",
                    expected: "number (@minimum 0)",
                    value: input.mem
                })) && (100 >= input.mem || $guard(_exceptionable, {
                    path: _path + ".mem",
                    expected: "number (@maximum 100)",
                    value: input.mem
                })) || $guard(_exceptionable, {
                    path: _path + ".mem",
                    expected: "(number | undefined)",
                    value: input.mem
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["cpu", "mem"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerRunLogData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerRunLogData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerRunLogDataTypiaAssertStringify = (input: DockerRunLogData): string => {
    return ((input: any): string => { const assert = (input: any): DockerRunLogData => {
        const __is = (input: any): input is DockerRunLogData => {
            const $io0 = (input: any): boolean => (undefined === input.cursor || "number" === typeof input.cursor && !Number.isNaN(input.cursor) && Math.floor(input.cursor) === input.cursor && (-2147483648 <= input.cursor && input.cursor <= 2147483647) && 0 <= input.cursor) && (Array.isArray(input.logs) && input.logs.every((elem: any) => "object" === typeof elem && null !== elem && $io1(elem)));
            const $io1 = (input: any): boolean => "number" === typeof input.ts && !Number.isNaN(input.ts) && 0 <= input.ts && ("VERBOSE" === input.level || "NOTSET" === input.level || "DEBUG" === input.level || "INFO" === input.level || "WARNING" === input.level || "ERROR" === input.level || "CRITICAL" === input.level) && (undefined === input.group || "string" === typeof input.group) && (undefined === input.origin || "string" === typeof input.origin) && "string" === typeof input.msg && ("STARTED" === input.state || "INITIALIZING" === input.state || "LOADING_DATASET" === input.state || "LOADING_METADATA" === input.state || "LOADING_PREDICTION" === input.state || "CHECKING_CORRUPTNESS" === input.state || "INITIALIZING_OBJECT_CROPS" === input.state || "COMPUTING_METADATA" === input.state || "TRAINING" === input.state || "EMBEDDING" === input.state || "EMBEDDING_OBJECT_CROPS" === input.state || "PRETAGGING" === input.state || "COMPUTING_ACTIVE_LEARNING_SCORES" === input.state || "SAMPLING" === input.state || "EMBEDDING_FULL_IMAGES" === input.state || "SAVING_RESULTS" === input.state || "UPLOADING_DATASET" === input.state || "GENERATING_REPORT" === input.state || "UPLOADING_REPORT" === input.state || "UPLOADED_REPORT" === input.state || "UPLOADING_ARTIFACTS" === input.state || "UPLOADED_ARTIFACTS" === input.state || "COMPLETED" === input.state || "FAILED" === input.state || "CRASHED" === input.state || "ABORTED" === input.state) && (undefined === input.load || "object" === typeof input.load && null !== input.load && false === Array.isArray(input.load) && $io2(input.load));
            const $io2 = (input: any): boolean => (undefined === input.cpu || "number" === typeof input.cpu && 0 <= input.cpu && 100 >= input.cpu) && (undefined === input.mem || "number" === typeof input.mem && 0 <= input.mem && 100 >= input.mem);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerRunLogData => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.cursor || "number" === typeof input.cursor && !Number.isNaN(input.cursor) && (Math.floor(input.cursor) === input.cursor || $guard(_exceptionable, {
                    path: _path + ".cursor",
                    expected: "number (@type int)",
                    value: input.cursor
                })) && (-2147483648 <= input.cursor && input.cursor <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".cursor",
                    expected: "number (@type int)",
                    value: input.cursor
                })) && (0 <= input.cursor || $guard(_exceptionable, {
                    path: _path + ".cursor",
                    expected: "number (@minimum 0)",
                    value: input.cursor
                })) || $guard(_exceptionable, {
                    path: _path + ".cursor",
                    expected: "(number | undefined)",
                    value: input.cursor
                })) && ((Array.isArray(input.logs) || $guard(_exceptionable, {
                    path: _path + ".logs",
                    expected: "Array<DockerRunLogEntryData>",
                    value: input.logs
                })) && input.logs.every((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".logs[" + _index1 + "]",
                    expected: "DockerRunLogEntryData",
                    value: elem
                })) && $ao1(elem, _path + ".logs[" + _index1 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".logs[" + _index1 + "]",
                    expected: "DockerRunLogEntryData",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".logs",
                    expected: "Array<DockerRunLogEntryData>",
                    value: input.logs
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.ts && !Number.isNaN(input.ts) && (0 <= input.ts || $guard(_exceptionable, {
                    path: _path + ".ts",
                    expected: "number (@minimum 0)",
                    value: input.ts
                })) || $guard(_exceptionable, {
                    path: _path + ".ts",
                    expected: "number",
                    value: input.ts
                })) && ("VERBOSE" === input.level || "NOTSET" === input.level || "DEBUG" === input.level || "INFO" === input.level || "WARNING" === input.level || "ERROR" === input.level || "CRITICAL" === input.level || $guard(_exceptionable, {
                    path: _path + ".level",
                    expected: "(\"CRITICAL\" | \"DEBUG\" | \"ERROR\" | \"INFO\" | \"NOTSET\" | \"VERBOSE\" | \"WARNING\")",
                    value: input.level
                })) && (undefined === input.group || "string" === typeof input.group || $guard(_exceptionable, {
                    path: _path + ".group",
                    expected: "(string | undefined)",
                    value: input.group
                })) && (undefined === input.origin || "string" === typeof input.origin || $guard(_exceptionable, {
                    path: _path + ".origin",
                    expected: "(string | undefined)",
                    value: input.origin
                })) && ("string" === typeof input.msg || $guard(_exceptionable, {
                    path: _path + ".msg",
                    expected: "string",
                    value: input.msg
                })) && ("STARTED" === input.state || "INITIALIZING" === input.state || "LOADING_DATASET" === input.state || "LOADING_METADATA" === input.state || "LOADING_PREDICTION" === input.state || "CHECKING_CORRUPTNESS" === input.state || "INITIALIZING_OBJECT_CROPS" === input.state || "COMPUTING_METADATA" === input.state || "TRAINING" === input.state || "EMBEDDING" === input.state || "EMBEDDING_OBJECT_CROPS" === input.state || "PRETAGGING" === input.state || "COMPUTING_ACTIVE_LEARNING_SCORES" === input.state || "SAMPLING" === input.state || "EMBEDDING_FULL_IMAGES" === input.state || "SAVING_RESULTS" === input.state || "UPLOADING_DATASET" === input.state || "GENERATING_REPORT" === input.state || "UPLOADING_REPORT" === input.state || "UPLOADED_REPORT" === input.state || "UPLOADING_ARTIFACTS" === input.state || "UPLOADED_ARTIFACTS" === input.state || "COMPLETED" === input.state || "FAILED" === input.state || "CRASHED" === input.state || "ABORTED" === input.state || $guard(_exceptionable, {
                    path: _path + ".state",
                    expected: "(\"ABORTED\" | \"CHECKING_CORRUPTNESS\" | \"COMPLETED\" | \"COMPUTING_ACTIVE_LEARNING_SCORES\" | \"COMPUTING_METADATA\" | \"CRASHED\" | \"EMBEDDING\" | \"EMBEDDING_FULL_IMAGES\" | \"EMBEDDING_OBJECT_CROPS\" | \"FAILED\" | \"GENERATING_REPORT\" | \"INITIALIZING\" | \"INITIALIZING_OBJECT_CROPS\" | \"LOADING_DATASET\" | \"LOADING_METADATA\" | \"LOADING_PREDICTION\" | \"PRETAGGING\" | \"SAMPLING\" | \"SAVING_RESULTS\" | \"STARTED\" | \"TRAINING\" | \"UPLOADED_ARTIFACTS\" | \"UPLOADED_REPORT\" | \"UPLOADING_ARTIFACTS\" | \"UPLOADING_DATASET\" | \"UPLOADING_REPORT\")",
                    value: input.state
                })) && (undefined === input.load || ("object" === typeof input.load && null !== input.load && false === Array.isArray(input.load) || $guard(_exceptionable, {
                    path: _path + ".load",
                    expected: "(DockerRunLogDockerLoad | undefined)",
                    value: input.load
                })) && $ao2(input.load, _path + ".load", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".load",
                    expected: "(DockerRunLogDockerLoad | undefined)",
                    value: input.load
                }));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.cpu || "number" === typeof input.cpu && (0 <= input.cpu || $guard(_exceptionable, {
                    path: _path + ".cpu",
                    expected: "number (@minimum 0)",
                    value: input.cpu
                })) && (100 >= input.cpu || $guard(_exceptionable, {
                    path: _path + ".cpu",
                    expected: "number (@maximum 100)",
                    value: input.cpu
                })) || $guard(_exceptionable, {
                    path: _path + ".cpu",
                    expected: "(number | undefined)",
                    value: input.cpu
                })) && (undefined === input.mem || "number" === typeof input.mem && (0 <= input.mem || $guard(_exceptionable, {
                    path: _path + ".mem",
                    expected: "number (@minimum 0)",
                    value: input.mem
                })) && (100 >= input.mem || $guard(_exceptionable, {
                    path: _path + ".mem",
                    expected: "number (@maximum 100)",
                    value: input.mem
                })) || $guard(_exceptionable, {
                    path: _path + ".mem",
                    expected: "(number | undefined)",
                    value: input.mem
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerRunLogData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerRunLogData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerRunLogData): string => {
        const $io1 = (input: any): boolean => "number" === typeof input.ts && 0 <= input.ts && ("VERBOSE" === input.level || "NOTSET" === input.level || "DEBUG" === input.level || "INFO" === input.level || "WARNING" === input.level || "ERROR" === input.level || "CRITICAL" === input.level) && (undefined === input.group || "string" === typeof input.group) && (undefined === input.origin || "string" === typeof input.origin) && "string" === typeof input.msg && ("STARTED" === input.state || "INITIALIZING" === input.state || "LOADING_DATASET" === input.state || "LOADING_METADATA" === input.state || "LOADING_PREDICTION" === input.state || "CHECKING_CORRUPTNESS" === input.state || "INITIALIZING_OBJECT_CROPS" === input.state || "COMPUTING_METADATA" === input.state || "TRAINING" === input.state || "EMBEDDING" === input.state || "EMBEDDING_OBJECT_CROPS" === input.state || "PRETAGGING" === input.state || "COMPUTING_ACTIVE_LEARNING_SCORES" === input.state || "SAMPLING" === input.state || "EMBEDDING_FULL_IMAGES" === input.state || "SAVING_RESULTS" === input.state || "UPLOADING_DATASET" === input.state || "GENERATING_REPORT" === input.state || "UPLOADING_REPORT" === input.state || "UPLOADED_REPORT" === input.state || "UPLOADING_ARTIFACTS" === input.state || "UPLOADED_ARTIFACTS" === input.state || "COMPLETED" === input.state || "FAILED" === input.state || "CRASHED" === input.state || "ABORTED" === input.state) && (undefined === input.load || "object" === typeof input.load && null !== input.load && false === Array.isArray(input.load) && $io2(input.load));
        const $io2 = (input: any): boolean => (undefined === input.cpu || "number" === typeof input.cpu && 0 <= input.cpu && 100 >= input.cpu) && (undefined === input.mem || "number" === typeof input.mem && 0 <= input.mem && 100 >= input.mem);
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $tail = (typia.createAssertStringify as any).tail;
        const $so0 = (input: any): any => `{${undefined === input.cursor ? "" : `"cursor":${undefined !== input.cursor ? input.cursor : undefined},`}"logs":${`[${input.logs.map((elem: any) => $so1(elem)).join(",")}]`}}`;
        const $so1 = (input: any): any => `{${undefined === input.group ? "" : `"group":${undefined !== input.group ? $string(input.group) : undefined},`}${undefined === input.origin ? "" : `"origin":${undefined !== input.origin ? $string(input.origin) : undefined},`}${undefined === input.load ? "" : `"load":${undefined !== input.load ? $so2(input.load) : undefined},`}"ts":${input.ts},"level":${(() => {
            if ("string" === typeof input.level)
                return $string(input.level);
            if ("string" === typeof input.level)
                return "\"" + input.level + "\"";
            $throws({
                expected: "(\"CRITICAL\" | \"DEBUG\" | \"ERROR\" | \"INFO\" | \"NOTSET\" | \"VERBOSE\" | \"WARNING\")",
                value: input.level
            });
        })()},"msg":${$string(input.msg)},"state":${(() => {
            if ("string" === typeof input.state)
                return $string(input.state);
            if ("string" === typeof input.state)
                return "\"" + input.state + "\"";
            $throws({
                expected: "(\"ABORTED\" | \"CHECKING_CORRUPTNESS\" | \"COMPLETED\" | \"COMPUTING_ACTIVE_LEARNING_SCORES\" | \"COMPUTING_METADATA\" | \"CRASHED\" | \"EMBEDDING\" | \"EMBEDDING_FULL_IMAGES\" | \"EMBEDDING_OBJECT_CROPS\" | \"FAILED\" | \"GENERATING_REPORT\" | \"INITIALIZING\" | \"INITIALIZING_OBJECT_CROPS\" | \"LOADING_DATASET\" | \"LOADING_METADATA\" | \"LOADING_PREDICTION\" | \"PRETAGGING\" | \"SAMPLING\" | \"SAVING_RESULTS\" | \"STARTED\" | \"TRAINING\" | \"UPLOADED_ARTIFACTS\" | \"UPLOADED_REPORT\" | \"UPLOADING_ARTIFACTS\" | \"UPLOADING_DATASET\" | \"UPLOADING_REPORT\")",
                value: input.state
            });
        })()}}`;
        const $so2 = (input: any): any => `{${$tail(`${undefined === input.cpu ? "" : `"cpu":${undefined !== input.cpu ? input.cpu : undefined},`}${undefined === input.mem ? "" : `"mem":${undefined !== input.mem ? input.mem : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DockerRunLogDataValidatorShape = {
    'cursor': yup.number().integer().min(0),
    'logs': yup.array().of(DockerRunLogEntryDataValidator).defined(),
};
export const DockerRunLogDataValidator = yup.object().shape(DockerRunLogDataValidatorShape);
export const DockerRunLogDataStringifyShapeProperties: ObjectSchema['properties'] = {
    'cursor': {
        'type': 'integer',
        'minimum': 0,
    },
    'logs': {
        type: 'array',
        items: {
            DockerRunLogEntryDataStringifyShape
        }
    },
};
export const DockerRunLogDataStringifyShape: ObjectSchema = {
    title: 'DockerRunLogData Stringifier',
    type: 'object',
    properties: {
        ...DockerRunLogDataStringifyShapeProperties
    },
    required: [
        'logs',
    ],
};
export const DockerRunLogDataStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerRunLogDataStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerRunLogDataStringify: (data: DockerRunLogData) => string = DockerRunLogDataStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerRunLogDataFromJSON(json: any): DockerRunLogData {
    return DockerRunLogDataFromJSONTyped(json, false);
}
export function DockerRunLogDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerRunLogData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'cursor': !exists(json, 'cursor') ? undefined : json['cursor'],
        'logs': ((json['logs'] as Array<any> || []).map(DockerRunLogEntryDataFromJSON)),
    };
}
export function DockerRunLogDataToJSON(value?: DockerRunLogData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cursor': value.cursor,
        'logs': ((value.logs as Array<any> || []).map(DockerRunLogEntryDataToJSON)),
    };
}

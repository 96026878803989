/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { BoundingBox } from './BoundingBox';
import { BoundingBoxFromJSON, BoundingBoxFromJSONTyped, BoundingBoxToJSON, } from './BoundingBox';
import type { PredictionSingletonBase } from './PredictionSingletonBase';
import { PredictionSingletonBaseFromJSON, PredictionSingletonBaseFromJSONTyped, PredictionSingletonBaseToJSON, } from './PredictionSingletonBase';
import type { Probabilities } from './Probabilities';
import { ProbabilitiesFromJSON, ProbabilitiesFromJSONTyped, ProbabilitiesToJSON, } from './Probabilities';
/*
causes circular dependendies
// .concat(PredictionSingletonBaseValidator)

*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface PredictionSingletonObjectDetection
 *
 */
// create custom validators for the regexes
export interface PredictionSingletonObjectDetection extends PredictionSingletonBase {
    /**
     *
     * #type BoundingBox
     * #memberof PredictionSingletonObjectDetection
        
        
         */
    bbox: BoundingBox;
    /**
     *
     * #type Probabilities
     * #memberof PredictionSingletonObjectDetection
        
        
         */
    probabilities?: Probabilities;
}
/**
 * Check if a given object implements the PredictionSingletonObjectDetection interface.
 */
export function instanceOfPredictionSingletonObjectDetection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "bbox" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { BoundingBoxValidator, BoundingBoxStringifyShape, BoundingBoxStringifyShapeProperties, } from './BoundingBox';
import { PredictionSingletonBaseValidator, PredictionSingletonBaseStringifyShape, PredictionSingletonBaseStringifyShapeProperties, } from './PredictionSingletonBase';
import { ProbabilitiesValidator, ProbabilitiesStringifyShape, ProbabilitiesStringifyShapeProperties, } from './Probabilities';
export const PredictionSingletonObjectDetectionTypiaAssertEquals = (input: any) => {
    return ((input: any): PredictionSingletonObjectDetection => {
        const __is = (input: any, _exceptionable: boolean = true): input is PredictionSingletonObjectDetection => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "object" === typeof input.bbox && null !== input.bbox && $io1(input.bbox, true && _exceptionable) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities, true && _exceptionable)) && "string" === typeof input.type && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (5 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["bbox", "probabilities", "type", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value;
                return false;
            });
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value;
                return false;
            });
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is PredictionSingletonObjectDetection => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && $ao1(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "string",
                    value: input.type
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["bbox", "probabilities", "type", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "PredictionSingletonObjectDetection",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "PredictionSingletonObjectDetection",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const PredictionSingletonObjectDetectionTypiaAssertStringify = (input: PredictionSingletonObjectDetection): string => {
    return ((input: any): string => { const assert = (input: any): PredictionSingletonObjectDetection => {
        const __is = (input: any): input is PredictionSingletonObjectDetection => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => "object" === typeof input.bbox && null !== input.bbox && $io1(input.bbox) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && "string" === typeof input.type && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io1 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value && !Number.isNaN(value);
                return true;
            });
            const $io2 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value && !Number.isNaN(value);
                return true;
            });
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is PredictionSingletonObjectDetection => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && $ao1(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "string",
                    value: input.type
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value && !Number.isNaN(value) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return true;
                }));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value && !Number.isNaN(value) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return true;
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "PredictionSingletonObjectDetection",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "PredictionSingletonObjectDetection",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: PredictionSingletonObjectDetection): string => {
        const $io1 = (input: any): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
            if (["length"].some(prop => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                return "number" === typeof value;
            return true;
        });
        const $io2 = (input: any): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
            if (["length"].some(prop => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                return "number" === typeof value;
            return true;
        });
        const $string = (typia.createAssertStringify as any).string;
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so2(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"bbox":${$so1(input.bbox)},"type":${$string(input.type)},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so1 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some(regular => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${value}`; return ""; }).filter(str => "" !== str).join(",")}`)}}`;
        const $so2 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some(regular => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${value}`; return ""; }).filter(str => "" !== str).join(",")}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const PredictionSingletonObjectDetectionValidatorShape = {
    'bbox': BoundingBoxValidator.defined(),
    'probabilities': ProbabilitiesValidator.optional().default(undefined),
};
export const PredictionSingletonObjectDetectionValidator = yup.object().shape(PredictionSingletonObjectDetectionValidatorShape).concat(PredictionSingletonBaseValidator);
export const PredictionSingletonObjectDetectionStringifyShapeProperties: ObjectSchema['properties'] = {
    'bbox': BoundingBoxStringifyShape,
    'probabilities': ProbabilitiesStringifyShape,
};
export const PredictionSingletonObjectDetectionStringifyShape: ObjectSchema = {
    title: 'PredictionSingletonObjectDetection Stringifier',
    type: 'object',
    properties: {
        ...PredictionSingletonBaseStringifyShapeProperties,
        ...PredictionSingletonObjectDetectionStringifyShapeProperties
    },
    required: [
        ...(PredictionSingletonBaseStringifyShape.required ? PredictionSingletonBaseStringifyShape.required : []),
        'bbox',
    ],
};
export const PredictionSingletonObjectDetectionStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(PredictionSingletonObjectDetectionStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const PredictionSingletonObjectDetectionStringify: (data: PredictionSingletonObjectDetection) => string = PredictionSingletonObjectDetectionStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function PredictionSingletonObjectDetectionFromJSON(json: any): PredictionSingletonObjectDetection {
    return PredictionSingletonObjectDetectionFromJSONTyped(json, false);
}
export function PredictionSingletonObjectDetectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PredictionSingletonObjectDetection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
    if (!ignoreDiscriminator) {
    }
*/
    return {
        ...PredictionSingletonBaseFromJSONTyped(json, ignoreDiscriminator),
        'bbox': BoundingBoxFromJSON(json['bbox']),
        'probabilities': !exists(json, 'probabilities') ? undefined : ProbabilitiesFromJSON(json['probabilities']),
    };
}
export function PredictionSingletonObjectDetectionToJSON(value?: PredictionSingletonObjectDetection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PredictionSingletonBaseToJSON(value),
        'bbox': BoundingBoxToJSON(value.bbox),
        'probabilities': ProbabilitiesToJSON(value.probabilities),
    };
}

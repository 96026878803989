/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { Creator } from './Creator';
import { CreatorFromJSON, CreatorFromJSONTyped, CreatorToJSON, } from './Creator';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DockerRunCreateRequest
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_datasetId")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_scheduledId")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_configId")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
export interface DockerRunCreateRequest {
    /**
     *
     * #type string
     * #memberof DockerRunCreateRequest
        
        
         */
    dockerVersion: string;
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof DockerRunCreateRequest
        
        
    * @pattern_datasetId
         */
    datasetId?: string;
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof DockerRunCreateRequest
        
        
    * @pattern_scheduledId
         */
    scheduledId?: string;
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof DockerRunCreateRequest
        
        
    * @pattern_configId
         */
    configId?: string;
    /**
     *
     * #type string
     * #memberof DockerRunCreateRequest
        
        
         */
    message?: string;
    /**
     *
     * #type Creator
     * #memberof DockerRunCreateRequest
        
        
         */
    creator?: Creator;
}
/**
 * Check if a given object implements the DockerRunCreateRequest interface.
 */
export function instanceOfDockerRunCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dockerVersion" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { CreatorValidator, CreatorStringifyShape, CreatorStringifyShapeProperties, } from './Creator';
export const DockerRunCreateRequestTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerRunCreateRequest => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerRunCreateRequest => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.dockerVersion && (undefined === input.datasetId || "string" === typeof input.datasetId && $is_custom("pattern_datasetId", "string", "", input.datasetId)) && (undefined === input.scheduledId || "string" === typeof input.scheduledId && $is_custom("pattern_scheduledId", "string", "", input.scheduledId)) && (undefined === input.configId || "string" === typeof input.configId && $is_custom("pattern_configId", "string", "", input.configId)) && (undefined === input.message || "string" === typeof input.message) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator) && (1 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["dockerVersion", "datasetId", "scheduledId", "configId", "message", "creator"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerRunCreateRequest => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.dockerVersion || $guard(_exceptionable, {
                    path: _path + ".dockerVersion",
                    expected: "string",
                    value: input.dockerVersion
                })) && (undefined === input.datasetId || "string" === typeof input.datasetId && ($is_custom("pattern_datasetId", "string", "", input.datasetId) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "string (@pattern_datasetId)",
                    value: input.datasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "(string | undefined)",
                    value: input.datasetId
                })) && (undefined === input.scheduledId || "string" === typeof input.scheduledId && ($is_custom("pattern_scheduledId", "string", "", input.scheduledId) || $guard(_exceptionable, {
                    path: _path + ".scheduledId",
                    expected: "string (@pattern_scheduledId)",
                    value: input.scheduledId
                })) || $guard(_exceptionable, {
                    path: _path + ".scheduledId",
                    expected: "(string | undefined)",
                    value: input.scheduledId
                })) && (undefined === input.configId || "string" === typeof input.configId && ($is_custom("pattern_configId", "string", "", input.configId) || $guard(_exceptionable, {
                    path: _path + ".configId",
                    expected: "string (@pattern_configId)",
                    value: input.configId
                })) || $guard(_exceptionable, {
                    path: _path + ".configId",
                    expected: "(string | undefined)",
                    value: input.configId
                })) && (undefined === input.message || "string" === typeof input.message || $guard(_exceptionable, {
                    path: _path + ".message",
                    expected: "(string | undefined)",
                    value: input.message
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["dockerVersion", "datasetId", "scheduledId", "configId", "message", "creator"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerRunCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerRunCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerRunCreateRequestTypiaAssertStringify = (input: DockerRunCreateRequest): string => {
    return ((input: any): string => { const assert = (input: any): DockerRunCreateRequest => {
        const __is = (input: any): input is DockerRunCreateRequest => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "string" === typeof input.dockerVersion && (undefined === input.datasetId || "string" === typeof input.datasetId && $is_custom("pattern_datasetId", "string", "", input.datasetId)) && (undefined === input.scheduledId || "string" === typeof input.scheduledId && $is_custom("pattern_scheduledId", "string", "", input.scheduledId)) && (undefined === input.configId || "string" === typeof input.configId && $is_custom("pattern_configId", "string", "", input.configId)) && (undefined === input.message || "string" === typeof input.message) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerRunCreateRequest => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.dockerVersion || $guard(_exceptionable, {
                    path: _path + ".dockerVersion",
                    expected: "string",
                    value: input.dockerVersion
                })) && (undefined === input.datasetId || "string" === typeof input.datasetId && ($is_custom("pattern_datasetId", "string", "", input.datasetId) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "string (@pattern_datasetId)",
                    value: input.datasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "(string | undefined)",
                    value: input.datasetId
                })) && (undefined === input.scheduledId || "string" === typeof input.scheduledId && ($is_custom("pattern_scheduledId", "string", "", input.scheduledId) || $guard(_exceptionable, {
                    path: _path + ".scheduledId",
                    expected: "string (@pattern_scheduledId)",
                    value: input.scheduledId
                })) || $guard(_exceptionable, {
                    path: _path + ".scheduledId",
                    expected: "(string | undefined)",
                    value: input.scheduledId
                })) && (undefined === input.configId || "string" === typeof input.configId && ($is_custom("pattern_configId", "string", "", input.configId) || $guard(_exceptionable, {
                    path: _path + ".configId",
                    expected: "string (@pattern_configId)",
                    value: input.configId
                })) || $guard(_exceptionable, {
                    path: _path + ".configId",
                    expected: "(string | undefined)",
                    value: input.configId
                })) && (undefined === input.message || "string" === typeof input.message || $guard(_exceptionable, {
                    path: _path + ".message",
                    expected: "(string | undefined)",
                    value: input.message
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerRunCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerRunCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerRunCreateRequest): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.datasetId ? "" : `"datasetId":${undefined !== input.datasetId ? $string(input.datasetId) : undefined},`}${undefined === input.scheduledId ? "" : `"scheduledId":${undefined !== input.scheduledId ? $string(input.scheduledId) : undefined},`}${undefined === input.configId ? "" : `"configId":${undefined !== input.configId ? $string(input.configId) : undefined},`}${undefined === input.message ? "" : `"message":${undefined !== input.message ? $string(input.message) : undefined},`}${undefined === input.creator ? "" : `"creator":${undefined !== input.creator ? (() => {
            if ("string" === typeof input.creator)
                return $string(input.creator);
            if ("string" === typeof input.creator)
                return "\"" + input.creator + "\"";
            $throws({
                expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                value: input.creator
            });
        })() : undefined},`}"dockerVersion":${$string(input.dockerVersion)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DockerRunCreateRequestValidatorShape = {
    'dockerVersion': yup.string().defined(),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/),
    'scheduledId': yup.string().matches(/^[a-f0-9]{24}$/),
    'configId': yup.string().matches(/^[a-f0-9]{24}$/),
    'message': yup.string(),
    'creator': CreatorValidator.optional().default(undefined),
};
export const DockerRunCreateRequestValidator = yup.object().shape(DockerRunCreateRequestValidatorShape);
export const DockerRunCreateRequestStringifyShapeProperties: ObjectSchema['properties'] = {
    'dockerVersion': {
        'type': 'string',
    },
    'datasetId': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    },
    'scheduledId': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    },
    'configId': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    },
    'message': {
        'type': 'string',
    },
    'creator': CreatorStringifyShape,
};
export const DockerRunCreateRequestStringifyShape: ObjectSchema = {
    title: 'DockerRunCreateRequest Stringifier',
    type: 'object',
    properties: {
        ...DockerRunCreateRequestStringifyShapeProperties
    },
    required: [
        'dockerVersion',
    ],
};
export const DockerRunCreateRequestStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerRunCreateRequestStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerRunCreateRequestStringify: (data: DockerRunCreateRequest) => string = DockerRunCreateRequestStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerRunCreateRequestFromJSON(json: any): DockerRunCreateRequest {
    return DockerRunCreateRequestFromJSONTyped(json, false);
}
export function DockerRunCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerRunCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'dockerVersion': json['dockerVersion'],
        'datasetId': !exists(json, 'datasetId') ? undefined : json['datasetId'],
        'scheduledId': !exists(json, 'scheduledId') ? undefined : json['scheduledId'],
        'configId': !exists(json, 'configId') ? undefined : json['configId'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'creator': !exists(json, 'creator') ? undefined : CreatorFromJSON(json['creator']),
    };
}
export function DockerRunCreateRequestToJSON(value?: DockerRunCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dockerVersion': value.dockerVersion,
        'datasetId': value.datasetId,
        'scheduledId': value.scheduledId,
        'configId': value.configId,
        'message': value.message,
        'creator': CreatorToJSON(value.creator),
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { DockerWorkerLabels } from './DockerWorkerLabels';
import { DockerWorkerLabelsFromJSON, DockerWorkerLabelsFromJSONTyped, DockerWorkerLabelsToJSON, } from './DockerWorkerLabels';
import type { DockerWorkerState } from './DockerWorkerState';
import { DockerWorkerStateFromJSON, DockerWorkerStateFromJSONTyped, DockerWorkerStateToJSON, } from './DockerWorkerState';
import type { DockerWorkerType } from './DockerWorkerType';
import { DockerWorkerTypeFromJSON, DockerWorkerTypeFromJSONTyped, DockerWorkerTypeToJSON, } from './DockerWorkerType';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DockerWorkerRegistryEntryData
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_id")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_name")("string")(() => {
    const regex = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9 _-]+$/);
    return (value: string) => !!regex.exec(value);
});
export interface DockerWorkerRegistryEntryData {
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof DockerWorkerRegistryEntryData
        
        
    * @pattern_id
         */
    id: string;
    /**
     *
     * #type string
     * #memberof DockerWorkerRegistryEntryData
        
        
         */
    userId: string;
    /**
     *
     * #type string
     * #memberof DockerWorkerRegistryEntryData
        
        
    * @pattern_name
    * @minLength 3
         */
    name: string;
    /**
     *
     * #type DockerWorkerType
     * #memberof DockerWorkerRegistryEntryData
        
        
         */
    workerType: DockerWorkerType;
    /**
     *
     * #type DockerWorkerState
     * #memberof DockerWorkerRegistryEntryData
        
        
         */
    state: DockerWorkerState;
    /**
     * unix timestamp in milliseconds
     * #type number
     * #memberof DockerWorkerRegistryEntryData
        
    * @minimum 0
            
         */
    createdAt: number;
    /**
     * unix timestamp in milliseconds
     * #type number
     * #memberof DockerWorkerRegistryEntryData
        
    * @minimum 0
            
         */
    lastModifiedAt: number;
    /**
     *
     * #type DockerWorkerLabels
     * #memberof DockerWorkerRegistryEntryData
        
        
         */
    labels: DockerWorkerLabels;
    /**
     * If true, this worker was created by the API/System and not by a user.
     * #type boolean
     * #memberof DockerWorkerRegistryEntryData
         * @deprecated
    
        
         */
    isDefault?: boolean;
    /**
     *
     * #type string
     * #memberof DockerWorkerRegistryEntryData
        
        
         */
    dockerVersion?: string;
}
/**
 * Check if a given object implements the DockerWorkerRegistryEntryData interface.
 */
export function instanceOfDockerWorkerRegistryEntryData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "workerType" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "lastModifiedAt" in value;
    isInstance = isInstance && "labels" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { DockerWorkerLabelsValidator, DockerWorkerLabelsStringifyShape, DockerWorkerLabelsStringifyShapeProperties, } from './DockerWorkerLabels';
import { DockerWorkerStateValidator, DockerWorkerStateStringifyShape, DockerWorkerStateStringifyShapeProperties, } from './DockerWorkerState';
import { DockerWorkerTypeValidator, DockerWorkerTypeStringifyShape, DockerWorkerTypeStringifyShapeProperties, } from './DockerWorkerType';
export const DockerWorkerRegistryEntryDataTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerWorkerRegistryEntryData => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerWorkerRegistryEntryData => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.id && $is_custom("pattern_id", "string", "", input.id) && "string" === typeof input.userId && ("string" === typeof input.name && 3 <= input.name.length && $is_custom("pattern_name", "string", "", input.name)) && "FULL" === input.workerType && ("OFFLINE" === input.state || "CRASHED" === input.state || "IDLE" === input.state || "BUSY" === input.state) && ("number" === typeof input.createdAt && 0 <= input.createdAt) && ("number" === typeof input.lastModifiedAt && 0 <= input.lastModifiedAt) && ("object" === typeof input.labels && null !== input.labels && $io1(input.labels, true && _exceptionable)) && (undefined === input.isDefault || "boolean" === typeof input.isDefault) && (undefined === input.dockerVersion || "string" === typeof input.dockerVersion) && (8 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["id", "userId", "name", "workerType", "state", "createdAt", "lastModifiedAt", "labels", "isDefault", "dockerVersion"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "string" === typeof value;
                return false;
            });
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerRegistryEntryData => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id && ($is_custom("pattern_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && ("string" === typeof input.userId || $guard(_exceptionable, {
                    path: _path + ".userId",
                    expected: "string",
                    value: input.userId
                })) && ("string" === typeof input.name && (3 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 3)",
                    value: input.name
                })) && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("FULL" === input.workerType || $guard(_exceptionable, {
                    path: _path + ".workerType",
                    expected: "\"FULL\"",
                    value: input.workerType
                })) && ("OFFLINE" === input.state || "CRASHED" === input.state || "IDLE" === input.state || "BUSY" === input.state || $guard(_exceptionable, {
                    path: _path + ".state",
                    expected: "(\"BUSY\" | \"CRASHED\" | \"IDLE\" | \"OFFLINE\")",
                    value: input.state
                })) && ("number" === typeof input.createdAt && (0 <= input.createdAt || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number (@minimum 0)",
                    value: input.createdAt
                })) || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number",
                    value: input.createdAt
                })) && ("number" === typeof input.lastModifiedAt && (0 <= input.lastModifiedAt || $guard(_exceptionable, {
                    path: _path + ".lastModifiedAt",
                    expected: "number (@minimum 0)",
                    value: input.lastModifiedAt
                })) || $guard(_exceptionable, {
                    path: _path + ".lastModifiedAt",
                    expected: "number",
                    value: input.lastModifiedAt
                })) && (("object" === typeof input.labels && null !== input.labels || $guard(_exceptionable, {
                    path: _path + ".labels",
                    expected: "DockerWorkerLabels",
                    value: input.labels
                })) && $ao1(input.labels, _path + ".labels", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".labels",
                    expected: "DockerWorkerLabels",
                    value: input.labels
                })) && (undefined === input.isDefault || "boolean" === typeof input.isDefault || $guard(_exceptionable, {
                    path: _path + ".isDefault",
                    expected: "(boolean | undefined)",
                    value: input.isDefault
                })) && (undefined === input.dockerVersion || "string" === typeof input.dockerVersion || $guard(_exceptionable, {
                    path: _path + ".dockerVersion",
                    expected: "(string | undefined)",
                    value: input.dockerVersion
                })) && (8 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["id", "userId", "name", "workerType", "state", "createdAt", "lastModifiedAt", "labels", "isDefault", "dockerVersion"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "string" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "string",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerRegistryEntryData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerRegistryEntryData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerWorkerRegistryEntryDataTypiaAssertStringify = (input: DockerWorkerRegistryEntryData): string => {
    return ((input: any): string => { const assert = (input: any): DockerWorkerRegistryEntryData => {
        const __is = (input: any): input is DockerWorkerRegistryEntryData => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => "string" === typeof input.id && $is_custom("pattern_id", "string", "", input.id) && "string" === typeof input.userId && ("string" === typeof input.name && 3 <= input.name.length && $is_custom("pattern_name", "string", "", input.name)) && "FULL" === input.workerType && ("OFFLINE" === input.state || "CRASHED" === input.state || "IDLE" === input.state || "BUSY" === input.state) && ("number" === typeof input.createdAt && !Number.isNaN(input.createdAt) && 0 <= input.createdAt) && ("number" === typeof input.lastModifiedAt && !Number.isNaN(input.lastModifiedAt) && 0 <= input.lastModifiedAt) && ("object" === typeof input.labels && null !== input.labels && $io1(input.labels)) && (undefined === input.isDefault || "boolean" === typeof input.isDefault) && (undefined === input.dockerVersion || "string" === typeof input.dockerVersion);
            const $io1 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "string" === typeof value;
                return true;
            });
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerRegistryEntryData => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id && ($is_custom("pattern_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && ("string" === typeof input.userId || $guard(_exceptionable, {
                    path: _path + ".userId",
                    expected: "string",
                    value: input.userId
                })) && ("string" === typeof input.name && (3 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 3)",
                    value: input.name
                })) && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("FULL" === input.workerType || $guard(_exceptionable, {
                    path: _path + ".workerType",
                    expected: "\"FULL\"",
                    value: input.workerType
                })) && ("OFFLINE" === input.state || "CRASHED" === input.state || "IDLE" === input.state || "BUSY" === input.state || $guard(_exceptionable, {
                    path: _path + ".state",
                    expected: "(\"BUSY\" | \"CRASHED\" | \"IDLE\" | \"OFFLINE\")",
                    value: input.state
                })) && ("number" === typeof input.createdAt && !Number.isNaN(input.createdAt) && (0 <= input.createdAt || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number (@minimum 0)",
                    value: input.createdAt
                })) || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number",
                    value: input.createdAt
                })) && ("number" === typeof input.lastModifiedAt && !Number.isNaN(input.lastModifiedAt) && (0 <= input.lastModifiedAt || $guard(_exceptionable, {
                    path: _path + ".lastModifiedAt",
                    expected: "number (@minimum 0)",
                    value: input.lastModifiedAt
                })) || $guard(_exceptionable, {
                    path: _path + ".lastModifiedAt",
                    expected: "number",
                    value: input.lastModifiedAt
                })) && (("object" === typeof input.labels && null !== input.labels || $guard(_exceptionable, {
                    path: _path + ".labels",
                    expected: "DockerWorkerLabels",
                    value: input.labels
                })) && $ao1(input.labels, _path + ".labels", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".labels",
                    expected: "DockerWorkerLabels",
                    value: input.labels
                })) && (undefined === input.isDefault || "boolean" === typeof input.isDefault || $guard(_exceptionable, {
                    path: _path + ".isDefault",
                    expected: "(boolean | undefined)",
                    value: input.isDefault
                })) && (undefined === input.dockerVersion || "string" === typeof input.dockerVersion || $guard(_exceptionable, {
                    path: _path + ".dockerVersion",
                    expected: "(string | undefined)",
                    value: input.dockerVersion
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "string" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "string",
                            value: value
                        });
                    return true;
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerRegistryEntryData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerRegistryEntryData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerWorkerRegistryEntryData): string => {
        const $io1 = (input: any): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
            if (["length"].some(prop => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                return "string" === typeof value;
            return true;
        });
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.isDefault ? "" : `"isDefault":${undefined !== input.isDefault ? input.isDefault : undefined},`}${undefined === input.dockerVersion ? "" : `"dockerVersion":${undefined !== input.dockerVersion ? $string(input.dockerVersion) : undefined},`}"id":${$string(input.id)},"userId":${$string(input.userId)},"name":${$string(input.name)},"workerType":${(() => {
            if ("string" === typeof input.workerType)
                return $string(input.workerType);
            if ("string" === typeof input.workerType)
                return "\"" + input.workerType + "\"";
            $throws({
                expected: "\"FULL\"",
                value: input.workerType
            });
        })()},"state":${(() => {
            if ("string" === typeof input.state)
                return $string(input.state);
            if ("string" === typeof input.state)
                return "\"" + input.state + "\"";
            $throws({
                expected: "(\"BUSY\" | \"CRASHED\" | \"IDLE\" | \"OFFLINE\")",
                value: input.state
            });
        })()},"createdAt":${input.createdAt},"lastModifiedAt":${input.lastModifiedAt},"labels":${$so1(input.labels)}}`;
        const $so1 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some(regular => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${$string(value)}`; return ""; }).filter(str => "" !== str).join(",")}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DockerWorkerRegistryEntryDataValidatorShape = {
    'id': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'userId': yup.string().defined(),
    'name': yup.string().matches(/^[a-zA-Z0-9][a-zA-Z0-9 _-]+$/).min(3).defined(),
    'workerType': DockerWorkerTypeValidator.defined(),
    'state': DockerWorkerStateValidator.defined(),
    'createdAt': yup.number().min(0).defined(),
    'lastModifiedAt': yup.number().min(0).defined(),
    'labels': DockerWorkerLabelsValidator.defined(),
    'isDefault': yup.boolean(),
    'dockerVersion': yup.string(),
};
export const DockerWorkerRegistryEntryDataValidator = yup.object().shape(DockerWorkerRegistryEntryDataValidatorShape);
export const DockerWorkerRegistryEntryDataStringifyShapeProperties: ObjectSchema['properties'] = {
    'id': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    },
    'userId': {
        'type': 'string',
    },
    'name': {
        'type': 'string',
        'pattern': "/^[a-zA-Z0-9][a-zA-Z0-9 _-]+$/".slice(1, -1),
        'minLength': 3,
    },
    'workerType': DockerWorkerTypeStringifyShape,
    'state': DockerWorkerStateStringifyShape,
    'createdAt': {
        'type': 'number',
        'minimum': 0,
    },
    'lastModifiedAt': {
        'type': 'number',
        'minimum': 0,
    },
    'labels': DockerWorkerLabelsStringifyShape,
    'isDefault': {
        'type': 'boolean',
    },
    'dockerVersion': {
        'type': 'string',
    },
};
export const DockerWorkerRegistryEntryDataStringifyShape: ObjectSchema = {
    title: 'DockerWorkerRegistryEntryData Stringifier',
    type: 'object',
    properties: {
        ...DockerWorkerRegistryEntryDataStringifyShapeProperties
    },
    required: [
        'id',
        'userId',
        'name',
        'workerType',
        'state',
        'createdAt',
        'lastModifiedAt',
        'labels',
    ],
};
export const DockerWorkerRegistryEntryDataStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerWorkerRegistryEntryDataStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerWorkerRegistryEntryDataStringify: (data: DockerWorkerRegistryEntryData) => string = DockerWorkerRegistryEntryDataStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerWorkerRegistryEntryDataFromJSON(json: any): DockerWorkerRegistryEntryData {
    return DockerWorkerRegistryEntryDataFromJSONTyped(json, false);
}
export function DockerWorkerRegistryEntryDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerWorkerRegistryEntryData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'id': json['id'],
        'userId': json['userId'],
        'name': json['name'],
        'workerType': DockerWorkerTypeFromJSON(json['workerType']),
        'state': DockerWorkerStateFromJSON(json['state']),
        'createdAt': json['createdAt'],
        'lastModifiedAt': json['lastModifiedAt'],
        'labels': DockerWorkerLabelsFromJSON(json['labels']),
        'isDefault': !exists(json, 'isDefault') ? undefined : json['isDefault'],
        'dockerVersion': !exists(json, 'dockerVersion') ? undefined : json['dockerVersion'],
    };
}
export function DockerWorkerRegistryEntryDataToJSON(value?: DockerWorkerRegistryEntryData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'userId': value.userId,
        'name': value.name,
        'workerType': DockerWorkerTypeToJSON(value.workerType),
        'state': DockerWorkerStateToJSON(value.state),
        'createdAt': value.createdAt,
        'lastModifiedAt': value.lastModifiedAt,
        'labels': DockerWorkerLabelsToJSON(value.labels),
        'isDefault': value.isDefault,
        'dockerVersion': value.dockerVersion,
    };
}

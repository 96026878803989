/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { SelectionConfigV3EntryStrategyAllOfTargetRange } from './SelectionConfigV3EntryStrategyAllOfTargetRange';
import { SelectionConfigV3EntryStrategyAllOfTargetRangeFromJSON, SelectionConfigV3EntryStrategyAllOfTargetRangeFromJSONTyped, SelectionConfigV3EntryStrategyAllOfTargetRangeToJSON, } from './SelectionConfigV3EntryStrategyAllOfTargetRange';
import type { SelectionStrategyThresholdOperation } from './SelectionStrategyThresholdOperation';
import { SelectionStrategyThresholdOperationFromJSON, SelectionStrategyThresholdOperationFromJSONTyped, SelectionStrategyThresholdOperationToJSON, } from './SelectionStrategyThresholdOperation';
import type { SelectionStrategyTypeV3 } from './SelectionStrategyTypeV3';
import { SelectionStrategyTypeV3FromJSON, SelectionStrategyTypeV3FromJSONTyped, SelectionStrategyTypeV3ToJSON, } from './SelectionStrategyTypeV3';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface SelectionConfigV3EntryStrategy
 *
 */
// create custom validators for the regexes
export interface SelectionConfigV3EntryStrategy {
    /**
     *
     * #type SelectionStrategyTypeV3
     * #memberof SelectionConfigV3EntryStrategy
        
        
         */
    type: SelectionStrategyTypeV3;
    /**
     *
     * #type number
     * #memberof SelectionConfigV3EntryStrategy
        
        
         */
    stopping_condition_minimum_distance?: number;
    /**
     *
     * #type number
     * #memberof SelectionConfigV3EntryStrategy
        
        
         */
    threshold?: number;
    /**
     *
     * #type SelectionStrategyThresholdOperation
     * #memberof SelectionConfigV3EntryStrategy
        
        
         */
    operation?: SelectionStrategyThresholdOperation;
    /**
     *
     * #type object
     * #memberof SelectionConfigV3EntryStrategy
        
        
         */
    target?: object;
    /**
     * It is the number of nearest datapoints used to compute the typicality of each sample.
     * #type number
     * #memberof SelectionConfigV3EntryStrategy
        * @type int
    * @minimum 2
            
         */
    numNearestNeighbors?: number;
    /**
     * It is the minimal allowed typicality of the selected samples. When the typicality of the selected samples reaches this, the selection stops. It should be  a number between 0 and 1.
     * #type number
     * #memberof SelectionConfigV3EntryStrategy
        
     * @exclusiveMinimum 0
        
         */
    stoppingConditionMinimumTypicality?: number;
    /**
     * The relative strength of this strategy compared to other strategies. The default value is 1.0, which is set in the worker for backwards compatibility. The minimum and maximum values of +-10^9 are used to prevent numerical issues.
     * #type number
     * #memberof SelectionConfigV3EntryStrategy
        
    * @minimum -1000000000
        * @maximum 1000000000
        
         */
    strength?: number;
    /**
     * When the sum of inputs reaches this, the selection stops. Only compatible with the WEIGHTS strategy. Similar to the stopping_condition_minimum_distance for the DIVERSITY strategy.
     * #type number
     * #memberof SelectionConfigV3EntryStrategy
        
    * @minimum 0.0
            
         */
    stoppingConditionMaxSum?: number;
    /**
     *
     * #type SelectionConfigV3EntryStrategyAllOfTargetRange
     * #memberof SelectionConfigV3EntryStrategy
        
        
         */
    targetRange?: SelectionConfigV3EntryStrategyAllOfTargetRange;
}
/**
 * Check if a given object implements the SelectionConfigV3EntryStrategy interface.
 */
export function instanceOfSelectionConfigV3EntryStrategy(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { SelectionConfigV3EntryStrategyAllOfTargetRangeValidator, SelectionConfigV3EntryStrategyAllOfTargetRangeStringifyShape, SelectionConfigV3EntryStrategyAllOfTargetRangeStringifyShapeProperties, } from './SelectionConfigV3EntryStrategyAllOfTargetRange';
import { SelectionStrategyThresholdOperationValidator, SelectionStrategyThresholdOperationStringifyShape, SelectionStrategyThresholdOperationStringifyShapeProperties, } from './SelectionStrategyThresholdOperation';
import { SelectionStrategyTypeV3Validator, SelectionStrategyTypeV3StringifyShape, SelectionStrategyTypeV3StringifyShapeProperties, } from './SelectionStrategyTypeV3';
export const SelectionConfigV3EntryStrategyTypiaAssertEquals = (input: any) => {
    return ((input: any): SelectionConfigV3EntryStrategy => {
        const __is = (input: any, _exceptionable: boolean = true): input is SelectionConfigV3EntryStrategy => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => ("DIVERSITY" === input.type || "WEIGHTS" === input.type || "THRESHOLD" === input.type || "BALANCE" === input.type || "SIMILARITY" === input.type || "TYPICALITY" === input.type) && (undefined === input.stopping_condition_minimum_distance || "number" === typeof input.stopping_condition_minimum_distance) && (undefined === input.threshold || "number" === typeof input.threshold) && (undefined === input.operation || "SMALLER" === input.operation || "SMALLER_EQUAL" === input.operation || "BIGGER" === input.operation || "BIGGER_EQUAL" === input.operation) && (undefined === input.target || "object" === typeof input.target && null !== input.target && false === Array.isArray(input.target) && $io1(input.target, true && _exceptionable)) && (undefined === input.numNearestNeighbors || "number" === typeof input.numNearestNeighbors && Math.floor(input.numNearestNeighbors) === input.numNearestNeighbors && (-2147483648 <= input.numNearestNeighbors && input.numNearestNeighbors <= 2147483647) && 2 <= input.numNearestNeighbors) && (undefined === input.stoppingConditionMinimumTypicality || "number" === typeof input.stoppingConditionMinimumTypicality && 0 < input.stoppingConditionMinimumTypicality) && (undefined === input.strength || "number" === typeof input.strength && -1000000000 <= input.strength && 1000000000 >= input.strength) && (undefined === input.stoppingConditionMaxSum || "number" === typeof input.stoppingConditionMaxSum && 0 <= input.stoppingConditionMaxSum) && (undefined === input.targetRange || "object" === typeof input.targetRange && null !== input.targetRange && false === Array.isArray(input.targetRange) && $io2(input.targetRange, true && _exceptionable)) && (1 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["type", "stopping_condition_minimum_distance", "threshold", "operation", "target", "numNearestNeighbors", "stoppingConditionMinimumTypicality", "strength", "stoppingConditionMaxSum", "targetRange"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => 0 === Object.keys(input).length || Object.keys(input).every(key => {
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            });
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.minSum || "number" === typeof input.minSum && 0 <= input.minSum) && (undefined === input.maxSum || "number" === typeof input.maxSum && 0 <= input.maxSum) && (0 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["minSum", "maxSum"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is SelectionConfigV3EntryStrategy => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("DIVERSITY" === input.type || "WEIGHTS" === input.type || "THRESHOLD" === input.type || "BALANCE" === input.type || "SIMILARITY" === input.type || "TYPICALITY" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "(\"BALANCE\" | \"DIVERSITY\" | \"SIMILARITY\" | \"THRESHOLD\" | \"TYPICALITY\" | \"WEIGHTS\")",
                    value: input.type
                })) && (undefined === input.stopping_condition_minimum_distance || "number" === typeof input.stopping_condition_minimum_distance || $guard(_exceptionable, {
                    path: _path + ".stopping_condition_minimum_distance",
                    expected: "(number | undefined)",
                    value: input.stopping_condition_minimum_distance
                })) && (undefined === input.threshold || "number" === typeof input.threshold || $guard(_exceptionable, {
                    path: _path + ".threshold",
                    expected: "(number | undefined)",
                    value: input.threshold
                })) && (undefined === input.operation || "SMALLER" === input.operation || "SMALLER_EQUAL" === input.operation || "BIGGER" === input.operation || "BIGGER_EQUAL" === input.operation || $guard(_exceptionable, {
                    path: _path + ".operation",
                    expected: "(\"BIGGER\" | \"BIGGER_EQUAL\" | \"SMALLER\" | \"SMALLER_EQUAL\" | undefined)",
                    value: input.operation
                })) && (undefined === input.target || ("object" === typeof input.target && null !== input.target && false === Array.isArray(input.target) || $guard(_exceptionable, {
                    path: _path + ".target",
                    expected: "(object | undefined)",
                    value: input.target
                })) && $ao1(input.target, _path + ".target", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".target",
                    expected: "(object | undefined)",
                    value: input.target
                })) && (undefined === input.numNearestNeighbors || "number" === typeof input.numNearestNeighbors && (Math.floor(input.numNearestNeighbors) === input.numNearestNeighbors || $guard(_exceptionable, {
                    path: _path + ".numNearestNeighbors",
                    expected: "number (@type int)",
                    value: input.numNearestNeighbors
                })) && (-2147483648 <= input.numNearestNeighbors && input.numNearestNeighbors <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".numNearestNeighbors",
                    expected: "number (@type int)",
                    value: input.numNearestNeighbors
                })) && (2 <= input.numNearestNeighbors || $guard(_exceptionable, {
                    path: _path + ".numNearestNeighbors",
                    expected: "number (@minimum 2)",
                    value: input.numNearestNeighbors
                })) || $guard(_exceptionable, {
                    path: _path + ".numNearestNeighbors",
                    expected: "(number | undefined)",
                    value: input.numNearestNeighbors
                })) && (undefined === input.stoppingConditionMinimumTypicality || "number" === typeof input.stoppingConditionMinimumTypicality && (0 < input.stoppingConditionMinimumTypicality || $guard(_exceptionable, {
                    path: _path + ".stoppingConditionMinimumTypicality",
                    expected: "number (@exclusiveMinimum 0)",
                    value: input.stoppingConditionMinimumTypicality
                })) || $guard(_exceptionable, {
                    path: _path + ".stoppingConditionMinimumTypicality",
                    expected: "(number | undefined)",
                    value: input.stoppingConditionMinimumTypicality
                })) && (undefined === input.strength || "number" === typeof input.strength && (-1000000000 <= input.strength || $guard(_exceptionable, {
                    path: _path + ".strength",
                    expected: "number (@minimum -1000000000)",
                    value: input.strength
                })) && (1000000000 >= input.strength || $guard(_exceptionable, {
                    path: _path + ".strength",
                    expected: "number (@maximum 1000000000)",
                    value: input.strength
                })) || $guard(_exceptionable, {
                    path: _path + ".strength",
                    expected: "(number | undefined)",
                    value: input.strength
                })) && (undefined === input.stoppingConditionMaxSum || "number" === typeof input.stoppingConditionMaxSum && (0 <= input.stoppingConditionMaxSum || $guard(_exceptionable, {
                    path: _path + ".stoppingConditionMaxSum",
                    expected: "number (@minimum 0)",
                    value: input.stoppingConditionMaxSum
                })) || $guard(_exceptionable, {
                    path: _path + ".stoppingConditionMaxSum",
                    expected: "(number | undefined)",
                    value: input.stoppingConditionMaxSum
                })) && (undefined === input.targetRange || ("object" === typeof input.targetRange && null !== input.targetRange && false === Array.isArray(input.targetRange) || $guard(_exceptionable, {
                    path: _path + ".targetRange",
                    expected: "(SelectionConfigV3EntryStrategyAllOfTargetRange | undefined)",
                    value: input.targetRange
                })) && $ao2(input.targetRange, _path + ".targetRange", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".targetRange",
                    expected: "(SelectionConfigV3EntryStrategyAllOfTargetRange | undefined)",
                    value: input.targetRange
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["type", "stopping_condition_minimum_distance", "threshold", "operation", "target", "numNearestNeighbors", "stoppingConditionMinimumTypicality", "strength", "stoppingConditionMaxSum", "targetRange"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => 0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.minSum || "number" === typeof input.minSum && (0 <= input.minSum || $guard(_exceptionable, {
                    path: _path + ".minSum",
                    expected: "number (@minimum 0)",
                    value: input.minSum
                })) || $guard(_exceptionable, {
                    path: _path + ".minSum",
                    expected: "(number | undefined)",
                    value: input.minSum
                })) && (undefined === input.maxSum || "number" === typeof input.maxSum && (0 <= input.maxSum || $guard(_exceptionable, {
                    path: _path + ".maxSum",
                    expected: "number (@minimum 0)",
                    value: input.maxSum
                })) || $guard(_exceptionable, {
                    path: _path + ".maxSum",
                    expected: "(number | undefined)",
                    value: input.maxSum
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["minSum", "maxSum"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SelectionConfigV3EntryStrategy",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SelectionConfigV3EntryStrategy",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const SelectionConfigV3EntryStrategyTypiaAssertStringify = (input: SelectionConfigV3EntryStrategy): string => {
    return ((input: any): string => { const assert = (input: any): SelectionConfigV3EntryStrategy => {
        const __is = (input: any): input is SelectionConfigV3EntryStrategy => {
            const $io0 = (input: any): boolean => ("DIVERSITY" === input.type || "WEIGHTS" === input.type || "THRESHOLD" === input.type || "BALANCE" === input.type || "SIMILARITY" === input.type || "TYPICALITY" === input.type) && (undefined === input.stopping_condition_minimum_distance || "number" === typeof input.stopping_condition_minimum_distance && !Number.isNaN(input.stopping_condition_minimum_distance)) && (undefined === input.threshold || "number" === typeof input.threshold && !Number.isNaN(input.threshold)) && (undefined === input.operation || "SMALLER" === input.operation || "SMALLER_EQUAL" === input.operation || "BIGGER" === input.operation || "BIGGER_EQUAL" === input.operation) && (undefined === input.target || "object" === typeof input.target && null !== input.target && false === Array.isArray(input.target) && $io1(input.target)) && (undefined === input.numNearestNeighbors || "number" === typeof input.numNearestNeighbors && !Number.isNaN(input.numNearestNeighbors) && Math.floor(input.numNearestNeighbors) === input.numNearestNeighbors && (-2147483648 <= input.numNearestNeighbors && input.numNearestNeighbors <= 2147483647) && 2 <= input.numNearestNeighbors) && (undefined === input.stoppingConditionMinimumTypicality || "number" === typeof input.stoppingConditionMinimumTypicality && !Number.isNaN(input.stoppingConditionMinimumTypicality) && 0 < input.stoppingConditionMinimumTypicality) && (undefined === input.strength || "number" === typeof input.strength && -1000000000 <= input.strength && 1000000000 >= input.strength) && (undefined === input.stoppingConditionMaxSum || "number" === typeof input.stoppingConditionMaxSum && !Number.isNaN(input.stoppingConditionMaxSum) && 0 <= input.stoppingConditionMaxSum) && (undefined === input.targetRange || "object" === typeof input.targetRange && null !== input.targetRange && false === Array.isArray(input.targetRange) && $io2(input.targetRange));
            const $io1 = (input: any): boolean => true;
            const $io2 = (input: any): boolean => (undefined === input.minSum || "number" === typeof input.minSum && !Number.isNaN(input.minSum) && 0 <= input.minSum) && (undefined === input.maxSum || "number" === typeof input.maxSum && !Number.isNaN(input.maxSum) && 0 <= input.maxSum);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is SelectionConfigV3EntryStrategy => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("DIVERSITY" === input.type || "WEIGHTS" === input.type || "THRESHOLD" === input.type || "BALANCE" === input.type || "SIMILARITY" === input.type || "TYPICALITY" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "(\"BALANCE\" | \"DIVERSITY\" | \"SIMILARITY\" | \"THRESHOLD\" | \"TYPICALITY\" | \"WEIGHTS\")",
                    value: input.type
                })) && (undefined === input.stopping_condition_minimum_distance || "number" === typeof input.stopping_condition_minimum_distance && !Number.isNaN(input.stopping_condition_minimum_distance) || $guard(_exceptionable, {
                    path: _path + ".stopping_condition_minimum_distance",
                    expected: "(number | undefined)",
                    value: input.stopping_condition_minimum_distance
                })) && (undefined === input.threshold || "number" === typeof input.threshold && !Number.isNaN(input.threshold) || $guard(_exceptionable, {
                    path: _path + ".threshold",
                    expected: "(number | undefined)",
                    value: input.threshold
                })) && (undefined === input.operation || "SMALLER" === input.operation || "SMALLER_EQUAL" === input.operation || "BIGGER" === input.operation || "BIGGER_EQUAL" === input.operation || $guard(_exceptionable, {
                    path: _path + ".operation",
                    expected: "(\"BIGGER\" | \"BIGGER_EQUAL\" | \"SMALLER\" | \"SMALLER_EQUAL\" | undefined)",
                    value: input.operation
                })) && (undefined === input.target || ("object" === typeof input.target && null !== input.target && false === Array.isArray(input.target) || $guard(_exceptionable, {
                    path: _path + ".target",
                    expected: "(object | undefined)",
                    value: input.target
                })) && $ao1(input.target, _path + ".target", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".target",
                    expected: "(object | undefined)",
                    value: input.target
                })) && (undefined === input.numNearestNeighbors || "number" === typeof input.numNearestNeighbors && !Number.isNaN(input.numNearestNeighbors) && (Math.floor(input.numNearestNeighbors) === input.numNearestNeighbors || $guard(_exceptionable, {
                    path: _path + ".numNearestNeighbors",
                    expected: "number (@type int)",
                    value: input.numNearestNeighbors
                })) && (-2147483648 <= input.numNearestNeighbors && input.numNearestNeighbors <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".numNearestNeighbors",
                    expected: "number (@type int)",
                    value: input.numNearestNeighbors
                })) && (2 <= input.numNearestNeighbors || $guard(_exceptionable, {
                    path: _path + ".numNearestNeighbors",
                    expected: "number (@minimum 2)",
                    value: input.numNearestNeighbors
                })) || $guard(_exceptionable, {
                    path: _path + ".numNearestNeighbors",
                    expected: "(number | undefined)",
                    value: input.numNearestNeighbors
                })) && (undefined === input.stoppingConditionMinimumTypicality || "number" === typeof input.stoppingConditionMinimumTypicality && !Number.isNaN(input.stoppingConditionMinimumTypicality) && (0 < input.stoppingConditionMinimumTypicality || $guard(_exceptionable, {
                    path: _path + ".stoppingConditionMinimumTypicality",
                    expected: "number (@exclusiveMinimum 0)",
                    value: input.stoppingConditionMinimumTypicality
                })) || $guard(_exceptionable, {
                    path: _path + ".stoppingConditionMinimumTypicality",
                    expected: "(number | undefined)",
                    value: input.stoppingConditionMinimumTypicality
                })) && (undefined === input.strength || "number" === typeof input.strength && (-1000000000 <= input.strength || $guard(_exceptionable, {
                    path: _path + ".strength",
                    expected: "number (@minimum -1000000000)",
                    value: input.strength
                })) && (1000000000 >= input.strength || $guard(_exceptionable, {
                    path: _path + ".strength",
                    expected: "number (@maximum 1000000000)",
                    value: input.strength
                })) || $guard(_exceptionable, {
                    path: _path + ".strength",
                    expected: "(number | undefined)",
                    value: input.strength
                })) && (undefined === input.stoppingConditionMaxSum || "number" === typeof input.stoppingConditionMaxSum && !Number.isNaN(input.stoppingConditionMaxSum) && (0 <= input.stoppingConditionMaxSum || $guard(_exceptionable, {
                    path: _path + ".stoppingConditionMaxSum",
                    expected: "number (@minimum 0)",
                    value: input.stoppingConditionMaxSum
                })) || $guard(_exceptionable, {
                    path: _path + ".stoppingConditionMaxSum",
                    expected: "(number | undefined)",
                    value: input.stoppingConditionMaxSum
                })) && (undefined === input.targetRange || ("object" === typeof input.targetRange && null !== input.targetRange && false === Array.isArray(input.targetRange) || $guard(_exceptionable, {
                    path: _path + ".targetRange",
                    expected: "(SelectionConfigV3EntryStrategyAllOfTargetRange | undefined)",
                    value: input.targetRange
                })) && $ao2(input.targetRange, _path + ".targetRange", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".targetRange",
                    expected: "(SelectionConfigV3EntryStrategyAllOfTargetRange | undefined)",
                    value: input.targetRange
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => true;
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.minSum || "number" === typeof input.minSum && !Number.isNaN(input.minSum) && (0 <= input.minSum || $guard(_exceptionable, {
                    path: _path + ".minSum",
                    expected: "number (@minimum 0)",
                    value: input.minSum
                })) || $guard(_exceptionable, {
                    path: _path + ".minSum",
                    expected: "(number | undefined)",
                    value: input.minSum
                })) && (undefined === input.maxSum || "number" === typeof input.maxSum && !Number.isNaN(input.maxSum) && (0 <= input.maxSum || $guard(_exceptionable, {
                    path: _path + ".maxSum",
                    expected: "number (@minimum 0)",
                    value: input.maxSum
                })) || $guard(_exceptionable, {
                    path: _path + ".maxSum",
                    expected: "(number | undefined)",
                    value: input.maxSum
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SelectionConfigV3EntryStrategy",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SelectionConfigV3EntryStrategy",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: SelectionConfigV3EntryStrategy): string => {
        const $io1 = (input: any): boolean => true;
        const $io2 = (input: any): boolean => (undefined === input.minSum || "number" === typeof input.minSum && 0 <= input.minSum) && (undefined === input.maxSum || "number" === typeof input.maxSum && 0 <= input.maxSum);
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $tail = (typia.createAssertStringify as any).tail;
        const $so0 = (input: any): any => `{${undefined === input.stopping_condition_minimum_distance ? "" : `"stopping_condition_minimum_distance":${undefined !== input.stopping_condition_minimum_distance ? input.stopping_condition_minimum_distance : undefined},`}${undefined === input.threshold ? "" : `"threshold":${undefined !== input.threshold ? input.threshold : undefined},`}${undefined === input.operation ? "" : `"operation":${undefined !== input.operation ? (() => {
            if ("string" === typeof input.operation)
                return $string(input.operation);
            if ("string" === typeof input.operation)
                return "\"" + input.operation + "\"";
            $throws({
                expected: "(\"BIGGER\" | \"BIGGER_EQUAL\" | \"SMALLER\" | \"SMALLER_EQUAL\" | undefined)",
                value: input.operation
            });
        })() : undefined},`}${undefined === input.target ? "" : `"target":${undefined !== input.target ? "{}" : undefined},`}${undefined === input.numNearestNeighbors ? "" : `"numNearestNeighbors":${undefined !== input.numNearestNeighbors ? input.numNearestNeighbors : undefined},`}${undefined === input.stoppingConditionMinimumTypicality ? "" : `"stoppingConditionMinimumTypicality":${undefined !== input.stoppingConditionMinimumTypicality ? input.stoppingConditionMinimumTypicality : undefined},`}${undefined === input.strength ? "" : `"strength":${undefined !== input.strength ? input.strength : undefined},`}${undefined === input.stoppingConditionMaxSum ? "" : `"stoppingConditionMaxSum":${undefined !== input.stoppingConditionMaxSum ? input.stoppingConditionMaxSum : undefined},`}${undefined === input.targetRange ? "" : `"targetRange":${undefined !== input.targetRange ? $so2(input.targetRange) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "(\"BALANCE\" | \"DIVERSITY\" | \"SIMILARITY\" | \"THRESHOLD\" | \"TYPICALITY\" | \"WEIGHTS\")",
                value: input.type
            });
        })()}}`;
        const $so2 = (input: any): any => `{${$tail(`${undefined === input.minSum ? "" : `"minSum":${undefined !== input.minSum ? input.minSum : undefined},`}${undefined === input.maxSum ? "" : `"maxSum":${undefined !== input.maxSum ? input.maxSum : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const SelectionConfigV3EntryStrategyValidatorShape = {
    'type': SelectionStrategyTypeV3Validator.defined(),
    'stopping_condition_minimum_distance': yup.number(),
    'threshold': yup.number(),
    'operation': SelectionStrategyThresholdOperationValidator.optional().default(undefined),
    'target': yup.mixed().transform((val: any) => { return val instanceof Object && !Array.isArray(val) ? val : {}; }),
    'numNearestNeighbors': yup.number().integer().min(2),
    'stoppingConditionMinimumTypicality': yup.number().min(0),
    'strength': yup.number().min(-1000000000).max(1000000000),
    'stoppingConditionMaxSum': yup.number().min(0.0),
    'targetRange': SelectionConfigV3EntryStrategyAllOfTargetRangeValidator.optional().default(undefined),
};
export const SelectionConfigV3EntryStrategyValidator = yup.object().shape(SelectionConfigV3EntryStrategyValidatorShape);
export const SelectionConfigV3EntryStrategyStringifyShapeProperties: ObjectSchema['properties'] = {
    'type': SelectionStrategyTypeV3StringifyShape,
    'stopping_condition_minimum_distance': {
        'type': 'number',
    },
    'threshold': {
        'type': 'number',
    },
    'operation': SelectionStrategyThresholdOperationStringifyShape,
    'target': {
        type: 'object',
        additionalProperties: true
    },
    'numNearestNeighbors': {
        'type': 'integer',
        'minimum': 2,
    },
    'stoppingConditionMinimumTypicality': {
        'type': 'number',
        'exclusiveMinimum': 0,
    },
    'strength': {
        'type': 'number',
        'minimum': -1000000000,
        'maximum': 1000000000,
    },
    'stoppingConditionMaxSum': {
        'type': 'number',
        'minimum': 0.0,
    },
    'targetRange': SelectionConfigV3EntryStrategyAllOfTargetRangeStringifyShape,
};
export const SelectionConfigV3EntryStrategyStringifyShape: ObjectSchema = {
    title: 'SelectionConfigV3EntryStrategy Stringifier',
    type: 'object',
    properties: {
        ...SelectionConfigV3EntryStrategyStringifyShapeProperties
    },
    required: [
        'type',
    ],
};
export const SelectionConfigV3EntryStrategyStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(SelectionConfigV3EntryStrategyStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const SelectionConfigV3EntryStrategyStringify: (data: SelectionConfigV3EntryStrategy) => string = SelectionConfigV3EntryStrategyStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function SelectionConfigV3EntryStrategyFromJSON(json: any): SelectionConfigV3EntryStrategy {
    return SelectionConfigV3EntryStrategyFromJSONTyped(json, false);
}
export function SelectionConfigV3EntryStrategyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectionConfigV3EntryStrategy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'type': SelectionStrategyTypeV3FromJSON(json['type']),
        'stopping_condition_minimum_distance': !exists(json, 'stopping_condition_minimum_distance') ? undefined : json['stopping_condition_minimum_distance'],
        'threshold': !exists(json, 'threshold') ? undefined : json['threshold'],
        'operation': !exists(json, 'operation') ? undefined : SelectionStrategyThresholdOperationFromJSON(json['operation']),
        'target': !exists(json, 'target') ? undefined : json['target'],
        'numNearestNeighbors': !exists(json, 'numNearestNeighbors') ? undefined : json['numNearestNeighbors'],
        'stoppingConditionMinimumTypicality': !exists(json, 'stoppingConditionMinimumTypicality') ? undefined : json['stoppingConditionMinimumTypicality'],
        'strength': !exists(json, 'strength') ? undefined : json['strength'],
        'stoppingConditionMaxSum': !exists(json, 'stoppingConditionMaxSum') ? undefined : json['stoppingConditionMaxSum'],
        'targetRange': !exists(json, 'targetRange') ? undefined : SelectionConfigV3EntryStrategyAllOfTargetRangeFromJSON(json['targetRange']),
    };
}
export function SelectionConfigV3EntryStrategyToJSON(value?: SelectionConfigV3EntryStrategy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': SelectionStrategyTypeV3ToJSON(value.type),
        'stopping_condition_minimum_distance': value.stopping_condition_minimum_distance,
        'threshold': value.threshold,
        'operation': SelectionStrategyThresholdOperationToJSON(value.operation),
        'target': value.target,
        'numNearestNeighbors': value.numNearestNeighbors,
        'stoppingConditionMinimumTypicality': value.stoppingConditionMinimumTypicality,
        'strength': value.strength,
        'stoppingConditionMaxSum': value.stoppingConditionMaxSum,
        'targetRange': SelectionConfigV3EntryStrategyAllOfTargetRangeToJSON(value.targetRange),
    };
}

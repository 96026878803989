/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelOneOf.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options } from 'fast-json-stringify';
import { PredictionSingletonClassification, instanceOfPredictionSingletonClassification, PredictionSingletonClassificationFromJSON, PredictionSingletonClassificationFromJSONTyped, PredictionSingletonClassificationToJSON, } from './PredictionSingletonClassification';
import { PredictionSingletonInstanceSegmentation, instanceOfPredictionSingletonInstanceSegmentation, PredictionSingletonInstanceSegmentationFromJSON, PredictionSingletonInstanceSegmentationFromJSONTyped, PredictionSingletonInstanceSegmentationToJSON, } from './PredictionSingletonInstanceSegmentation';
import { PredictionSingletonKeypointDetection, instanceOfPredictionSingletonKeypointDetection, PredictionSingletonKeypointDetectionFromJSON, PredictionSingletonKeypointDetectionFromJSONTyped, PredictionSingletonKeypointDetectionToJSON, } from './PredictionSingletonKeypointDetection';
import { PredictionSingletonObjectDetection, instanceOfPredictionSingletonObjectDetection, PredictionSingletonObjectDetectionFromJSON, PredictionSingletonObjectDetectionFromJSONTyped, PredictionSingletonObjectDetectionToJSON, } from './PredictionSingletonObjectDetection';
import { PredictionSingletonSemanticSegmentation, instanceOfPredictionSingletonSemanticSegmentation, PredictionSingletonSemanticSegmentationFromJSON, PredictionSingletonSemanticSegmentationFromJSONTyped, PredictionSingletonSemanticSegmentationToJSON, } from './PredictionSingletonSemanticSegmentation';
/**
 * @type PredictionSingleton
 *
 * @export
 */
export type PredictionSingleton = {
    type: 'CLASSIFICATION';
} & PredictionSingletonClassification | {
    type: 'INSTANCE_SEGMENTATION';
} & PredictionSingletonInstanceSegmentation | {
    type: 'KEYPOINT_DETECTION';
} & PredictionSingletonKeypointDetection | {
    type: 'OBJECT_DETECTION';
} & PredictionSingletonObjectDetection | {
    type: 'PredictionSingletonClassification';
} & PredictionSingletonClassification | {
    type: 'PredictionSingletonInstanceSegmentation';
} & PredictionSingletonInstanceSegmentation | {
    type: 'PredictionSingletonKeypointDetection';
} & PredictionSingletonKeypointDetection | {
    type: 'PredictionSingletonObjectDetection';
} & PredictionSingletonObjectDetection | {
    type: 'PredictionSingletonSemanticSegmentation';
} & PredictionSingletonSemanticSegmentation | {
    type: 'SEMANTIC_SEGMENTATION';
} & PredictionSingletonSemanticSegmentation;
/* LIGHTLY CUSTOM START -> modelOneOf */
import { PredictionSingletonClassificationStringifyShape, PredictionSingletonClassificationStringifyShapeProperties, PredictionSingletonClassificationValidator } from './PredictionSingletonClassification';
import { PredictionSingletonInstanceSegmentationStringifyShape, PredictionSingletonInstanceSegmentationStringifyShapeProperties, PredictionSingletonInstanceSegmentationValidator } from './PredictionSingletonInstanceSegmentation';
import { PredictionSingletonKeypointDetectionStringifyShape, PredictionSingletonKeypointDetectionStringifyShapeProperties, PredictionSingletonKeypointDetectionValidator } from './PredictionSingletonKeypointDetection';
import { PredictionSingletonObjectDetectionStringifyShape, PredictionSingletonObjectDetectionStringifyShapeProperties, PredictionSingletonObjectDetectionValidator } from './PredictionSingletonObjectDetection';
import { PredictionSingletonSemanticSegmentationStringifyShape, PredictionSingletonSemanticSegmentationStringifyShapeProperties, PredictionSingletonSemanticSegmentationValidator } from './PredictionSingletonSemanticSegmentation';
export const PredictionSingletonTypiaAssertEquals = (input: any) => {
    return ((input: any): PredictionSingleton => {
        const __is = (input: any, _exceptionable: boolean = true): input is PredictionSingleton => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "CLASSIFICATION" === input.type && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (4 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["type", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value;
                return false;
            });
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => "INSTANCE_SEGMENTATION" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io3(input.segmentation, true && _exceptionable)) && ("object" === typeof input.bbox && null !== input.bbox && $io4(input.bbox, true && _exceptionable)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (6 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["type", "segmentation", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io3 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value;
                return false;
            });
            const $io4 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value;
                return false;
            });
            const $io5 = (input: any, _exceptionable: boolean = true): boolean => "KEYPOINT_DETECTION" === input.type && (Array.isArray(input.keypoints) && input.keypoints.every((elem: any, _index1: number) => "number" === typeof elem)) && (undefined === input.bbox || "object" === typeof input.bbox && null !== input.bbox && $io4(input.bbox, true && _exceptionable)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (5 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["type", "keypoints", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io6 = (input: any, _exceptionable: boolean = true): boolean => "OBJECT_DETECTION" === input.type && ("object" === typeof input.bbox && null !== input.bbox && $io4(input.bbox, true && _exceptionable)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (5 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["type", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io7 = (input: any, _exceptionable: boolean = true): boolean => "PredictionSingletonClassification" === input.type && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (4 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["type", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io8 = (input: any, _exceptionable: boolean = true): boolean => "PredictionSingletonInstanceSegmentation" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io3(input.segmentation, true && _exceptionable)) && ("object" === typeof input.bbox && null !== input.bbox && $io4(input.bbox, true && _exceptionable)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (6 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["type", "segmentation", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io9 = (input: any, _exceptionable: boolean = true): boolean => "PredictionSingletonKeypointDetection" === input.type && (Array.isArray(input.keypoints) && input.keypoints.every((elem: any, _index2: number) => "number" === typeof elem)) && (undefined === input.bbox || "object" === typeof input.bbox && null !== input.bbox && $io4(input.bbox, true && _exceptionable)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (5 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["type", "keypoints", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io10 = (input: any, _exceptionable: boolean = true): boolean => "PredictionSingletonObjectDetection" === input.type && ("object" === typeof input.bbox && null !== input.bbox && $io4(input.bbox, true && _exceptionable)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (5 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["type", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io11 = (input: any, _exceptionable: boolean = true): boolean => "PredictionSingletonSemanticSegmentation" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io3(input.segmentation, true && _exceptionable)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (5 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["type", "segmentation", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io12 = (input: any, _exceptionable: boolean = true): boolean => "SEMANTIC_SEGMENTATION" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io3(input.segmentation, true && _exceptionable)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (5 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["type", "segmentation", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $iu0 = (input: any, _exceptionable: boolean = true): any => (() => {
                if ("CLASSIFICATION" === input.type)
                    return $io0(input, true && _exceptionable);
                if ("INSTANCE_SEGMENTATION" === input.type)
                    return $io2(input, true && _exceptionable);
                if ("KEYPOINT_DETECTION" === input.type)
                    return $io5(input, true && _exceptionable);
                if ("OBJECT_DETECTION" === input.type)
                    return $io6(input, true && _exceptionable);
                if ("PredictionSingletonClassification" === input.type)
                    return $io7(input, true && _exceptionable);
                if ("PredictionSingletonInstanceSegmentation" === input.type)
                    return $io8(input, true && _exceptionable);
                if ("PredictionSingletonKeypointDetection" === input.type)
                    return $io9(input, true && _exceptionable);
                if ("PredictionSingletonObjectDetection" === input.type)
                    return $io10(input, true && _exceptionable);
                if ("SEMANTIC_SEGMENTATION" === input.type)
                    return $io12(input, true && _exceptionable);
                if ("PredictionSingletonSemanticSegmentation" === input.type)
                    return $io11(input, true && _exceptionable);
                return false;
            })();
            return "object" === typeof input && null !== input && $iu0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is PredictionSingleton => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("CLASSIFICATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"CLASSIFICATION\"",
                    value: input.type
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao1(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["type", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("INSTANCE_SEGMENTATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"INSTANCE_SEGMENTATION\"",
                    value: input.type
                })) && (("object" === typeof input.segmentation && null !== input.segmentation || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && $ao3(input.segmentation, _path + ".segmentation", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && (("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && $ao4(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao1(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (6 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["type", "segmentation", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("KEYPOINT_DETECTION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"KEYPOINT_DETECTION\"",
                    value: input.type
                })) && ((Array.isArray(input.keypoints) || $guard(_exceptionable, {
                    path: _path + ".keypoints",
                    expected: "Array<number>",
                    value: input.keypoints
                })) && input.keypoints.every((elem: any, _index1: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".keypoints[" + _index1 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".keypoints",
                    expected: "Array<number>",
                    value: input.keypoints
                })) && (undefined === input.bbox || ("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "(BoundingBox | undefined)",
                    value: input.bbox
                })) && $ao4(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "(BoundingBox | undefined)",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao1(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["type", "keypoints", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("OBJECT_DETECTION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"OBJECT_DETECTION\"",
                    value: input.type
                })) && (("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && $ao4(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao1(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["type", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao7 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonClassification" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonClassification\"",
                    value: input.type
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao1(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["type", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao8 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonInstanceSegmentation" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonInstanceSegmentation\"",
                    value: input.type
                })) && (("object" === typeof input.segmentation && null !== input.segmentation || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && $ao3(input.segmentation, _path + ".segmentation", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && (("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && $ao4(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao1(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (6 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["type", "segmentation", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao9 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonKeypointDetection" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonKeypointDetection\"",
                    value: input.type
                })) && ((Array.isArray(input.keypoints) || $guard(_exceptionable, {
                    path: _path + ".keypoints",
                    expected: "Array<number>",
                    value: input.keypoints
                })) && input.keypoints.every((elem: any, _index2: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".keypoints[" + _index2 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".keypoints",
                    expected: "Array<number>",
                    value: input.keypoints
                })) && (undefined === input.bbox || ("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "(BoundingBox | undefined)",
                    value: input.bbox
                })) && $ao4(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "(BoundingBox | undefined)",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao1(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["type", "keypoints", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao10 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonObjectDetection" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonObjectDetection\"",
                    value: input.type
                })) && (("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && $ao4(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao1(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["type", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao11 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonSemanticSegmentation" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonSemanticSegmentation\"",
                    value: input.type
                })) && (("object" === typeof input.segmentation && null !== input.segmentation || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && $ao3(input.segmentation, _path + ".segmentation", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao1(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["type", "segmentation", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao12 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("SEMANTIC_SEGMENTATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"SEMANTIC_SEGMENTATION\"",
                    value: input.type
                })) && (("object" === typeof input.segmentation && null !== input.segmentation || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && $ao3(input.segmentation, _path + ".segmentation", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao1(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["type", "segmentation", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $au0 = (input: any, _path: string, _exceptionable: boolean = true): any => (() => {
                    if ("CLASSIFICATION" === input.type)
                        return $ao0(input, _path, true && _exceptionable);
                    if ("INSTANCE_SEGMENTATION" === input.type)
                        return $ao2(input, _path, true && _exceptionable);
                    if ("KEYPOINT_DETECTION" === input.type)
                        return $ao5(input, _path, true && _exceptionable);
                    if ("OBJECT_DETECTION" === input.type)
                        return $ao6(input, _path, true && _exceptionable);
                    if ("PredictionSingletonClassification" === input.type)
                        return $ao7(input, _path, true && _exceptionable);
                    if ("PredictionSingletonInstanceSegmentation" === input.type)
                        return $ao8(input, _path, true && _exceptionable);
                    if ("PredictionSingletonKeypointDetection" === input.type)
                        return $ao9(input, _path, true && _exceptionable);
                    if ("PredictionSingletonObjectDetection" === input.type)
                        return $ao10(input, _path, true && _exceptionable);
                    if ("SEMANTIC_SEGMENTATION" === input.type)
                        return $ao12(input, _path, true && _exceptionable);
                    if ("PredictionSingletonSemanticSegmentation" === input.type)
                        return $ao11(input, _path, true && _exceptionable);
                    return $guard(_exceptionable, {
                        path: _path,
                        expected: "({ type: \"CLASSIFICATION\"; } & PredictionSingletonClassification | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionSingletonInstanceSegmentation | { type: \"KEYPOINT_DETECTION\"; } & PredictionSingletonKeypointDetection | { type: \"OBJECT_DETECTION\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonClassification\"; } & PredictionSingletonClassification | { type: \"PredictionSingletonInstanceSegmentation\"; } & PredictionSingletonInstanceSegmentation | { type: \"PredictionSingletonKeypointDetection\"; } & PredictionSingletonKeypointDetection | { type: \"PredictionSingletonObjectDetection\"; } & PredictionSingletonObjectDetection | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionSingletonSemanticSegmentation | { type: \"PredictionSingletonSemanticSegmentation\"; } & PredictionSingletonSemanticSegmentation)",
                        value: input
                    });
                })();
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "({ type: \"CLASSIFICATION\"; } & PredictionSingletonClassification | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionSingletonInstanceSegmentation | { type: \"KEYPOINT_DETECTION\"; } & PredictionSingletonKeypointDetection | { type: \"OBJECT_DETECTION\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonClassification\"; } & PredictionSingletonClassification | { type: \"PredictionSingletonInstanceSegmentation\"; } & PredictionSingletonInstanceSegmentation | { type: \"PredictionSingletonKeypointDetection\"; } & PredictionSingletonKeypointDetection | { type: \"PredictionSingletonObjectDetection\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonSemanticSegmentation\"; } & PredictionSingletonSemanticSegmentation | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionSingletonSemanticSegmentation)",
                    value: input
                })) && $au0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "({ type: \"CLASSIFICATION\"; } & PredictionSingletonClassification | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionSingletonInstanceSegmentation | { type: \"KEYPOINT_DETECTION\"; } & PredictionSingletonKeypointDetection | { type: \"OBJECT_DETECTION\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonClassification\"; } & PredictionSingletonClassification | { type: \"PredictionSingletonInstanceSegmentation\"; } & PredictionSingletonInstanceSegmentation | { type: \"PredictionSingletonKeypointDetection\"; } & PredictionSingletonKeypointDetection | { type: \"PredictionSingletonObjectDetection\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonSemanticSegmentation\"; } & PredictionSingletonSemanticSegmentation | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionSingletonSemanticSegmentation)",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const PredictionSingletonTypiaAssertStringify = (input: PredictionSingleton): string => {
    return ((input: any): string => { const assert = (input: any): PredictionSingleton => {
        const __is = (input: any): input is PredictionSingleton => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => "CLASSIFICATION" === input.type && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io1 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value && !Number.isNaN(value);
                return true;
            });
            const $io2 = (input: any): boolean => "INSTANCE_SEGMENTATION" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io3(input.segmentation)) && ("object" === typeof input.bbox && null !== input.bbox && $io4(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io3 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value && !Number.isNaN(value);
                return true;
            });
            const $io4 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value && !Number.isNaN(value);
                return true;
            });
            const $io5 = (input: any): boolean => "KEYPOINT_DETECTION" === input.type && (Array.isArray(input.keypoints) && input.keypoints.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.bbox || "object" === typeof input.bbox && null !== input.bbox && $io4(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io6 = (input: any): boolean => "OBJECT_DETECTION" === input.type && ("object" === typeof input.bbox && null !== input.bbox && $io4(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io7 = (input: any): boolean => "PredictionSingletonClassification" === input.type && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io8 = (input: any): boolean => "PredictionSingletonInstanceSegmentation" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io3(input.segmentation)) && ("object" === typeof input.bbox && null !== input.bbox && $io4(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io9 = (input: any): boolean => "PredictionSingletonKeypointDetection" === input.type && (Array.isArray(input.keypoints) && input.keypoints.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.bbox || "object" === typeof input.bbox && null !== input.bbox && $io4(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io10 = (input: any): boolean => "PredictionSingletonObjectDetection" === input.type && ("object" === typeof input.bbox && null !== input.bbox && $io4(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io11 = (input: any): boolean => "PredictionSingletonSemanticSegmentation" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io3(input.segmentation)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io12 = (input: any): boolean => "SEMANTIC_SEGMENTATION" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io3(input.segmentation)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $iu0 = (input: any): any => (() => {
                if ("CLASSIFICATION" === input.type)
                    return $io0(input);
                if ("INSTANCE_SEGMENTATION" === input.type)
                    return $io2(input);
                if ("KEYPOINT_DETECTION" === input.type)
                    return $io5(input);
                if ("OBJECT_DETECTION" === input.type)
                    return $io6(input);
                if ("PredictionSingletonClassification" === input.type)
                    return $io7(input);
                if ("PredictionSingletonInstanceSegmentation" === input.type)
                    return $io8(input);
                if ("PredictionSingletonKeypointDetection" === input.type)
                    return $io9(input);
                if ("PredictionSingletonObjectDetection" === input.type)
                    return $io10(input);
                if ("SEMANTIC_SEGMENTATION" === input.type)
                    return $io12(input);
                if ("PredictionSingletonSemanticSegmentation" === input.type)
                    return $io11(input);
                return false;
            })();
            return "object" === typeof input && null !== input && $iu0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is PredictionSingleton => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("CLASSIFICATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"CLASSIFICATION\"",
                    value: input.type
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao1(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value && !Number.isNaN(value) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return true;
                }));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("INSTANCE_SEGMENTATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"INSTANCE_SEGMENTATION\"",
                    value: input.type
                })) && (("object" === typeof input.segmentation && null !== input.segmentation || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && $ao3(input.segmentation, _path + ".segmentation", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && (("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && $ao4(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao1(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value && !Number.isNaN(value) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return true;
                }));
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value && !Number.isNaN(value) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return true;
                }));
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("KEYPOINT_DETECTION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"KEYPOINT_DETECTION\"",
                    value: input.type
                })) && ((Array.isArray(input.keypoints) || $guard(_exceptionable, {
                    path: _path + ".keypoints",
                    expected: "Array<number>",
                    value: input.keypoints
                })) && input.keypoints.every((elem: any, _index1: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".keypoints[" + _index1 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".keypoints",
                    expected: "Array<number>",
                    value: input.keypoints
                })) && (undefined === input.bbox || ("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "(BoundingBox | undefined)",
                    value: input.bbox
                })) && $ao4(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "(BoundingBox | undefined)",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao1(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("OBJECT_DETECTION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"OBJECT_DETECTION\"",
                    value: input.type
                })) && (("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && $ao4(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao1(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao7 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonClassification" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonClassification\"",
                    value: input.type
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao1(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao8 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonInstanceSegmentation" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonInstanceSegmentation\"",
                    value: input.type
                })) && (("object" === typeof input.segmentation && null !== input.segmentation || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && $ao3(input.segmentation, _path + ".segmentation", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && (("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && $ao4(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao1(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao9 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonKeypointDetection" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonKeypointDetection\"",
                    value: input.type
                })) && ((Array.isArray(input.keypoints) || $guard(_exceptionable, {
                    path: _path + ".keypoints",
                    expected: "Array<number>",
                    value: input.keypoints
                })) && input.keypoints.every((elem: any, _index2: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".keypoints[" + _index2 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".keypoints",
                    expected: "Array<number>",
                    value: input.keypoints
                })) && (undefined === input.bbox || ("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "(BoundingBox | undefined)",
                    value: input.bbox
                })) && $ao4(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "(BoundingBox | undefined)",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao1(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao10 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonObjectDetection" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonObjectDetection\"",
                    value: input.type
                })) && (("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && $ao4(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao1(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao11 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonSemanticSegmentation" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonSemanticSegmentation\"",
                    value: input.type
                })) && (("object" === typeof input.segmentation && null !== input.segmentation || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && $ao3(input.segmentation, _path + ".segmentation", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao1(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao12 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("SEMANTIC_SEGMENTATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"SEMANTIC_SEGMENTATION\"",
                    value: input.type
                })) && (("object" === typeof input.segmentation && null !== input.segmentation || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && $ao3(input.segmentation, _path + ".segmentation", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao1(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $au0 = (input: any, _path: string, _exceptionable: boolean = true): any => (() => {
                    if ("CLASSIFICATION" === input.type)
                        return $ao0(input, _path, true && _exceptionable);
                    if ("INSTANCE_SEGMENTATION" === input.type)
                        return $ao2(input, _path, true && _exceptionable);
                    if ("KEYPOINT_DETECTION" === input.type)
                        return $ao5(input, _path, true && _exceptionable);
                    if ("OBJECT_DETECTION" === input.type)
                        return $ao6(input, _path, true && _exceptionable);
                    if ("PredictionSingletonClassification" === input.type)
                        return $ao7(input, _path, true && _exceptionable);
                    if ("PredictionSingletonInstanceSegmentation" === input.type)
                        return $ao8(input, _path, true && _exceptionable);
                    if ("PredictionSingletonKeypointDetection" === input.type)
                        return $ao9(input, _path, true && _exceptionable);
                    if ("PredictionSingletonObjectDetection" === input.type)
                        return $ao10(input, _path, true && _exceptionable);
                    if ("SEMANTIC_SEGMENTATION" === input.type)
                        return $ao12(input, _path, true && _exceptionable);
                    if ("PredictionSingletonSemanticSegmentation" === input.type)
                        return $ao11(input, _path, true && _exceptionable);
                    return $guard(_exceptionable, {
                        path: _path,
                        expected: "({ type: \"CLASSIFICATION\"; } & PredictionSingletonClassification | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionSingletonInstanceSegmentation | { type: \"KEYPOINT_DETECTION\"; } & PredictionSingletonKeypointDetection | { type: \"OBJECT_DETECTION\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonClassification\"; } & PredictionSingletonClassification | { type: \"PredictionSingletonInstanceSegmentation\"; } & PredictionSingletonInstanceSegmentation | { type: \"PredictionSingletonKeypointDetection\"; } & PredictionSingletonKeypointDetection | { type: \"PredictionSingletonObjectDetection\"; } & PredictionSingletonObjectDetection | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionSingletonSemanticSegmentation | { type: \"PredictionSingletonSemanticSegmentation\"; } & PredictionSingletonSemanticSegmentation)",
                        value: input
                    });
                })();
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "({ type: \"CLASSIFICATION\"; } & PredictionSingletonClassification | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionSingletonInstanceSegmentation | { type: \"KEYPOINT_DETECTION\"; } & PredictionSingletonKeypointDetection | { type: \"OBJECT_DETECTION\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonClassification\"; } & PredictionSingletonClassification | { type: \"PredictionSingletonInstanceSegmentation\"; } & PredictionSingletonInstanceSegmentation | { type: \"PredictionSingletonKeypointDetection\"; } & PredictionSingletonKeypointDetection | { type: \"PredictionSingletonObjectDetection\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonSemanticSegmentation\"; } & PredictionSingletonSemanticSegmentation | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionSingletonSemanticSegmentation)",
                    value: input
                })) && $au0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "({ type: \"CLASSIFICATION\"; } & PredictionSingletonClassification | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionSingletonInstanceSegmentation | { type: \"KEYPOINT_DETECTION\"; } & PredictionSingletonKeypointDetection | { type: \"OBJECT_DETECTION\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonClassification\"; } & PredictionSingletonClassification | { type: \"PredictionSingletonInstanceSegmentation\"; } & PredictionSingletonInstanceSegmentation | { type: \"PredictionSingletonKeypointDetection\"; } & PredictionSingletonKeypointDetection | { type: \"PredictionSingletonObjectDetection\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonSemanticSegmentation\"; } & PredictionSingletonSemanticSegmentation | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionSingletonSemanticSegmentation)",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: PredictionSingleton): string => {
        const $io0 = (input: any): boolean => "CLASSIFICATION" === input.type && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io1 = (input: any): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
            if (["length"].some(prop => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                return "number" === typeof value;
            return true;
        });
        const $io2 = (input: any): boolean => "INSTANCE_SEGMENTATION" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io3(input.segmentation)) && ("object" === typeof input.bbox && null !== input.bbox && $io4(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io3 = (input: any): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
            if (["length"].some(prop => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                return "number" === typeof value;
            return true;
        });
        const $io4 = (input: any): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
            if (["length"].some(prop => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                return "number" === typeof value;
            return true;
        });
        const $io5 = (input: any): boolean => "KEYPOINT_DETECTION" === input.type && (Array.isArray(input.keypoints) && input.keypoints.every((elem: any) => "number" === typeof elem)) && (undefined === input.bbox || "object" === typeof input.bbox && null !== input.bbox && $io4(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io6 = (input: any): boolean => "OBJECT_DETECTION" === input.type && ("object" === typeof input.bbox && null !== input.bbox && $io4(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io7 = (input: any): boolean => "PredictionSingletonClassification" === input.type && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io8 = (input: any): boolean => "PredictionSingletonInstanceSegmentation" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io3(input.segmentation)) && ("object" === typeof input.bbox && null !== input.bbox && $io4(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io9 = (input: any): boolean => "PredictionSingletonKeypointDetection" === input.type && (Array.isArray(input.keypoints) && input.keypoints.every((elem: any) => "number" === typeof elem)) && (undefined === input.bbox || "object" === typeof input.bbox && null !== input.bbox && $io4(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io10 = (input: any): boolean => "PredictionSingletonObjectDetection" === input.type && ("object" === typeof input.bbox && null !== input.bbox && $io4(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io11 = (input: any): boolean => "PredictionSingletonSemanticSegmentation" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io3(input.segmentation)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io12 = (input: any): boolean => "SEMANTIC_SEGMENTATION" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io3(input.segmentation)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io1(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so1(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"CLASSIFICATION\"",
                value: input.type
            });
        })()},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so1 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some(regular => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${value}`; return ""; }).filter(str => "" !== str).join(",")}`)}}`;
        const $so2 = (input: any): any => `{${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so1(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"INSTANCE_SEGMENTATION\"",
                value: input.type
            });
        })()},"segmentation":${$so3(input.segmentation)},"bbox":${$so4(input.bbox)},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so3 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some(regular => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${value}`; return ""; }).filter(str => "" !== str).join(",")}`)}}`;
        const $so4 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some(regular => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${value}`; return ""; }).filter(str => "" !== str).join(",")}`)}}`;
        const $so5 = (input: any): any => `{${undefined === input.bbox ? "" : `"bbox":${undefined !== input.bbox ? $so4(input.bbox) : undefined},`}${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so1(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"KEYPOINT_DETECTION\"",
                value: input.type
            });
        })()},"keypoints":${`[${input.keypoints.map((elem: any) => elem).join(",")}]`},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so6 = (input: any): any => `{${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so1(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"OBJECT_DETECTION\"",
                value: input.type
            });
        })()},"bbox":${$so4(input.bbox)},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so7 = (input: any): any => `{${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so1(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"PredictionSingletonClassification\"",
                value: input.type
            });
        })()},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so8 = (input: any): any => `{${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so1(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"PredictionSingletonInstanceSegmentation\"",
                value: input.type
            });
        })()},"segmentation":${$so3(input.segmentation)},"bbox":${$so4(input.bbox)},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so9 = (input: any): any => `{${undefined === input.bbox ? "" : `"bbox":${undefined !== input.bbox ? $so4(input.bbox) : undefined},`}${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so1(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"PredictionSingletonKeypointDetection\"",
                value: input.type
            });
        })()},"keypoints":${`[${input.keypoints.map((elem: any) => elem).join(",")}]`},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so10 = (input: any): any => `{${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so1(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"PredictionSingletonObjectDetection\"",
                value: input.type
            });
        })()},"bbox":${$so4(input.bbox)},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so11 = (input: any): any => `{${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so1(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"PredictionSingletonSemanticSegmentation\"",
                value: input.type
            });
        })()},"segmentation":${$so3(input.segmentation)},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so12 = (input: any): any => `{${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so1(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"SEMANTIC_SEGMENTATION\"",
                value: input.type
            });
        })()},"segmentation":${$so3(input.segmentation)},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $su0 = (input: any): any => (() => {
            if ("CLASSIFICATION" === input.type)
                return $so0(input);
            if ("INSTANCE_SEGMENTATION" === input.type)
                return $so2(input);
            if ("KEYPOINT_DETECTION" === input.type)
                return $so5(input);
            if ("OBJECT_DETECTION" === input.type)
                return $so6(input);
            if ("PredictionSingletonClassification" === input.type)
                return $so7(input);
            if ("PredictionSingletonInstanceSegmentation" === input.type)
                return $so8(input);
            if ("PredictionSingletonKeypointDetection" === input.type)
                return $so9(input);
            if ("PredictionSingletonObjectDetection" === input.type)
                return $so10(input);
            if ("SEMANTIC_SEGMENTATION" === input.type)
                return $so12(input);
            if ("PredictionSingletonSemanticSegmentation" === input.type)
                return $so11(input);
            $throws({
                expected: "({ type: \"CLASSIFICATION\"; } & PredictionSingletonClassification | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionSingletonInstanceSegmentation | { type: \"KEYPOINT_DETECTION\"; } & PredictionSingletonKeypointDetection | { type: \"OBJECT_DETECTION\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonClassification\"; } & PredictionSingletonClassification | { type: \"PredictionSingletonInstanceSegmentation\"; } & PredictionSingletonInstanceSegmentation | { type: \"PredictionSingletonKeypointDetection\"; } & PredictionSingletonKeypointDetection | { type: \"PredictionSingletonObjectDetection\"; } & PredictionSingletonObjectDetection | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionSingletonSemanticSegmentation | { type: \"PredictionSingletonSemanticSegmentation\"; } & PredictionSingletonSemanticSegmentation)",
                value: input
            });
        })();
        return $su0(input);
    }; return stringify(assert(input)); })(input);
};
export enum PredictionSingletonTypeDiscriminator {
    CLASSIFICATION = 'CLASSIFICATION',
    INSTANCE_SEGMENTATION = 'INSTANCE_SEGMENTATION',
    KEYPOINT_DETECTION = 'KEYPOINT_DETECTION',
    OBJECT_DETECTION = 'OBJECT_DETECTION',
    PredictionSingletonClassification = 'PredictionSingletonClassification',
    PredictionSingletonInstanceSegmentation = 'PredictionSingletonInstanceSegmentation',
    PredictionSingletonKeypointDetection = 'PredictionSingletonKeypointDetection',
    PredictionSingletonObjectDetection = 'PredictionSingletonObjectDetection',
    PredictionSingletonSemanticSegmentation = 'PredictionSingletonSemanticSegmentation',
    SEMANTIC_SEGMENTATION = 'SEMANTIC_SEGMENTATION'
}
export const PredictionSingletonValidatorShape = yup.lazy((json) => {
    switch (json['type']) {
        case 'CLASSIFICATION':
            return PredictionSingletonClassificationValidator.defined();
        case 'INSTANCE_SEGMENTATION':
            return PredictionSingletonInstanceSegmentationValidator.defined();
        case 'KEYPOINT_DETECTION':
            return PredictionSingletonKeypointDetectionValidator.defined();
        case 'OBJECT_DETECTION':
            return PredictionSingletonObjectDetectionValidator.defined();
        case 'PredictionSingletonClassification':
            return PredictionSingletonClassificationValidator.defined();
        case 'PredictionSingletonInstanceSegmentation':
            return PredictionSingletonInstanceSegmentationValidator.defined();
        case 'PredictionSingletonKeypointDetection':
            return PredictionSingletonKeypointDetectionValidator.defined();
        case 'PredictionSingletonObjectDetection':
            return PredictionSingletonObjectDetectionValidator.defined();
        case 'PredictionSingletonSemanticSegmentation':
            return PredictionSingletonSemanticSegmentationValidator.defined();
        case 'SEMANTIC_SEGMENTATION':
            return PredictionSingletonSemanticSegmentationValidator.defined();
        default:
            throw new Error(`No variant of PredictionSingleton exists with 'type=${json['type']}'`);
    }
});
export const PredictionSingletonValidator = PredictionSingletonValidatorShape as any;
export const PredictionSingletonStringifyShapeProperties = {};
export const PredictionSingletonStringifyShape: any = {
    title: 'PredictionSingleton OneOf Stringifier',
    type: 'object',
    'if': {
        '$id': 'PredictionSingleton-CLASSIFICATION-ifThenElse',
        type: 'object',
        'properties': {
            'type': { 'type': 'string', 'enum': ['CLASSIFICATION'] }
        }
    },
    'then': {
        'properties': PredictionSingletonClassificationStringifyShape.properties || {}
    },
    'else': {
        'if': {
            '$id': 'PredictionSingleton-INSTANCE_SEGMENTATION-ifThenElse',
            type: 'object',
            'properties': {
                'type': { 'type': 'string', 'enum': ['INSTANCE_SEGMENTATION'] }
            }
        },
        'then': {
            'properties': PredictionSingletonInstanceSegmentationStringifyShape.properties || {}
        },
        'else': {
            'if': {
                '$id': 'PredictionSingleton-KEYPOINT_DETECTION-ifThenElse',
                type: 'object',
                'properties': {
                    'type': { 'type': 'string', 'enum': ['KEYPOINT_DETECTION'] }
                }
            },
            'then': {
                'properties': PredictionSingletonKeypointDetectionStringifyShape.properties || {}
            },
            'else': {
                'if': {
                    '$id': 'PredictionSingleton-OBJECT_DETECTION-ifThenElse',
                    type: 'object',
                    'properties': {
                        'type': { 'type': 'string', 'enum': ['OBJECT_DETECTION'] }
                    }
                },
                'then': {
                    'properties': PredictionSingletonObjectDetectionStringifyShape.properties || {}
                },
                'else': {
                    'if': {
                        '$id': 'PredictionSingleton-PredictionSingletonClassification-ifThenElse',
                        type: 'object',
                        'properties': {
                            'type': { 'type': 'string', 'enum': ['PredictionSingletonClassification'] }
                        }
                    },
                    'then': {
                        'properties': PredictionSingletonClassificationStringifyShape.properties || {}
                    },
                    'else': {
                        'if': {
                            '$id': 'PredictionSingleton-PredictionSingletonInstanceSegmentation-ifThenElse',
                            type: 'object',
                            'properties': {
                                'type': { 'type': 'string', 'enum': ['PredictionSingletonInstanceSegmentation'] }
                            }
                        },
                        'then': {
                            'properties': PredictionSingletonInstanceSegmentationStringifyShape.properties || {}
                        },
                        'else': {
                            'if': {
                                '$id': 'PredictionSingleton-PredictionSingletonKeypointDetection-ifThenElse',
                                type: 'object',
                                'properties': {
                                    'type': { 'type': 'string', 'enum': ['PredictionSingletonKeypointDetection'] }
                                }
                            },
                            'then': {
                                'properties': PredictionSingletonKeypointDetectionStringifyShape.properties || {}
                            },
                            'else': {
                                'if': {
                                    '$id': 'PredictionSingleton-PredictionSingletonObjectDetection-ifThenElse',
                                    type: 'object',
                                    'properties': {
                                        'type': { 'type': 'string', 'enum': ['PredictionSingletonObjectDetection'] }
                                    }
                                },
                                'then': {
                                    'properties': PredictionSingletonObjectDetectionStringifyShape.properties || {}
                                },
                                'else': {
                                    'if': {
                                        '$id': 'PredictionSingleton-PredictionSingletonSemanticSegmentation-ifThenElse',
                                        type: 'object',
                                        'properties': {
                                            'type': { 'type': 'string', 'enum': ['PredictionSingletonSemanticSegmentation'] }
                                        }
                                    },
                                    'then': {
                                        'properties': PredictionSingletonSemanticSegmentationStringifyShape.properties || {}
                                    },
                                    'else': {
                                        'if': {
                                            '$id': 'PredictionSingleton-SEMANTIC_SEGMENTATION-ifThenElse',
                                            type: 'object',
                                            'properties': {
                                                'type': { 'type': 'string', 'enum': ['SEMANTIC_SEGMENTATION'] }
                                            }
                                        },
                                        'then': {
                                            'properties': PredictionSingletonSemanticSegmentationStringifyShape.properties || {}
                                        }
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    },
};
export const PredictionSingletonStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(PredictionSingletonStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const PredictionSingletonStringify: (data: PredictionSingleton) => string = PredictionSingletonStringifyCompiled;
/* LIGHTLY CUSTOM END -> oneOf */
export function PredictionSingletonFromJSON(json: any): PredictionSingleton {
    return PredictionSingletonFromJSONTyped(json, false);
}
export function PredictionSingletonFromJSONTyped(json: any, ignoreDiscriminator: boolean): PredictionSingleton {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'CLASSIFICATION':
            return { ...PredictionSingletonClassificationFromJSONTyped(json, true), type: 'CLASSIFICATION' };
        case 'INSTANCE_SEGMENTATION':
            return { ...PredictionSingletonInstanceSegmentationFromJSONTyped(json, true), type: 'INSTANCE_SEGMENTATION' };
        case 'KEYPOINT_DETECTION':
            return { ...PredictionSingletonKeypointDetectionFromJSONTyped(json, true), type: 'KEYPOINT_DETECTION' };
        case 'OBJECT_DETECTION':
            return { ...PredictionSingletonObjectDetectionFromJSONTyped(json, true), type: 'OBJECT_DETECTION' };
        case 'PredictionSingletonClassification':
            return { ...PredictionSingletonClassificationFromJSONTyped(json, true), type: 'PredictionSingletonClassification' };
        case 'PredictionSingletonInstanceSegmentation':
            return { ...PredictionSingletonInstanceSegmentationFromJSONTyped(json, true), type: 'PredictionSingletonInstanceSegmentation' };
        case 'PredictionSingletonKeypointDetection':
            return { ...PredictionSingletonKeypointDetectionFromJSONTyped(json, true), type: 'PredictionSingletonKeypointDetection' };
        case 'PredictionSingletonObjectDetection':
            return { ...PredictionSingletonObjectDetectionFromJSONTyped(json, true), type: 'PredictionSingletonObjectDetection' };
        case 'PredictionSingletonSemanticSegmentation':
            return { ...PredictionSingletonSemanticSegmentationFromJSONTyped(json, true), type: 'PredictionSingletonSemanticSegmentation' };
        case 'SEMANTIC_SEGMENTATION':
            return { ...PredictionSingletonSemanticSegmentationFromJSONTyped(json, true), type: 'SEMANTIC_SEGMENTATION' };
        default:
            throw new Error(`No variant of PredictionSingleton exists with 'type=${json['type']}'`);
    }
}
export function PredictionSingletonToJSON(value?: PredictionSingleton | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'CLASSIFICATION':
            return PredictionSingletonClassificationToJSON(value);
        case 'INSTANCE_SEGMENTATION':
            return PredictionSingletonInstanceSegmentationToJSON(value);
        case 'KEYPOINT_DETECTION':
            return PredictionSingletonKeypointDetectionToJSON(value);
        case 'OBJECT_DETECTION':
            return PredictionSingletonObjectDetectionToJSON(value);
        case 'PredictionSingletonClassification':
            return PredictionSingletonClassificationToJSON(value);
        case 'PredictionSingletonInstanceSegmentation':
            return PredictionSingletonInstanceSegmentationToJSON(value);
        case 'PredictionSingletonKeypointDetection':
            return PredictionSingletonKeypointDetectionToJSON(value);
        case 'PredictionSingletonObjectDetection':
            return PredictionSingletonObjectDetectionToJSON(value);
        case 'PredictionSingletonSemanticSegmentation':
            return PredictionSingletonSemanticSegmentationToJSON(value);
        case 'SEMANTIC_SEGMENTATION':
            return PredictionSingletonSemanticSegmentationToJSON(value);
        default:
            throw new Error(`No variant of PredictionSingleton exists with 'type=${value['type']}'`);
    }
}

/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { DatasourceConfigBase } from './DatasourceConfigBase';
import { DatasourceConfigBaseFromJSON, DatasourceConfigBaseFromJSONTyped, DatasourceConfigBaseToJSON, } from './DatasourceConfigBase';
import type { DatasourcePurpose } from './DatasourcePurpose';
import { DatasourcePurposeFromJSON, DatasourcePurposeFromJSONTyped, DatasourcePurposeToJSON, } from './DatasourcePurpose';
/*
causes circular dependendies
// .concat(DatasourceConfigBaseValidator)

*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DatasourceConfigAzure
 *
 */
// create custom validators for the regexes
export interface DatasourceConfigAzure extends DatasourceConfigBase {
    /**
     * path includes the bucket name and the path within the bucket where you have stored your information
     * #type string
     * #memberof DatasourceConfigAzure
        
        
         */
    fullPath: string;
    /**
     * name of the Azure Storage Account
     * #type string
     * #memberof DatasourceConfigAzure
        
        
    * @minLength 1
         */
    accountName: string;
    /**
     * key of the Azure Storage Account
     * #type string
     * #memberof DatasourceConfigAzure
        
        
    * @minLength 1
         */
    accountKey: string;
}
/**
 * Check if a given object implements the DatasourceConfigAzure interface.
 */
export function instanceOfDatasourceConfigAzure(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fullPath" in value;
    isInstance = isInstance && "accountName" in value;
    isInstance = isInstance && "accountKey" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { DatasourceConfigBaseValidator, DatasourceConfigBaseStringifyShape, DatasourceConfigBaseStringifyShapeProperties, } from './DatasourceConfigBase';
import { DatasourcePurposeValidator, DatasourcePurposeStringifyShape, DatasourcePurposeStringifyShapeProperties, } from './DatasourcePurpose';
export const DatasourceConfigAzureTypiaAssertEquals = (input: any) => {
    return ((input: any): DatasourceConfigAzure => {
        const __is = (input: any, _exceptionable: boolean = true): input is DatasourceConfigAzure => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.fullPath && ("string" === typeof input.accountName && 1 <= input.accountName.length) && ("string" === typeof input.accountKey && 1 <= input.accountKey.length) && (undefined === input.id || "string" === typeof input.id && $is_custom("pattern_id", "string", "", input.id)) && ("INPUT_OUTPUT" === input.purpose || "INPUT" === input.purpose || "LIGHTLY" === input.purpose) && "string" === typeof input.type && (undefined === input.thumbSuffix || "string" === typeof input.thumbSuffix) && (5 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["fullPath", "accountName", "accountKey", "id", "purpose", "type", "thumbSuffix"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DatasourceConfigAzure => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.fullPath || $guard(_exceptionable, {
                    path: _path + ".fullPath",
                    expected: "string",
                    value: input.fullPath
                })) && ("string" === typeof input.accountName && (1 <= input.accountName.length || $guard(_exceptionable, {
                    path: _path + ".accountName",
                    expected: "string (@minLength 1)",
                    value: input.accountName
                })) || $guard(_exceptionable, {
                    path: _path + ".accountName",
                    expected: "string",
                    value: input.accountName
                })) && ("string" === typeof input.accountKey && (1 <= input.accountKey.length || $guard(_exceptionable, {
                    path: _path + ".accountKey",
                    expected: "string (@minLength 1)",
                    value: input.accountKey
                })) || $guard(_exceptionable, {
                    path: _path + ".accountKey",
                    expected: "string",
                    value: input.accountKey
                })) && (undefined === input.id || "string" === typeof input.id && ($is_custom("pattern_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "(string | undefined)",
                    value: input.id
                })) && ("INPUT_OUTPUT" === input.purpose || "INPUT" === input.purpose || "LIGHTLY" === input.purpose || $guard(_exceptionable, {
                    path: _path + ".purpose",
                    expected: "(\"INPUT\" | \"INPUT_OUTPUT\" | \"LIGHTLY\")",
                    value: input.purpose
                })) && ("string" === typeof input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "string",
                    value: input.type
                })) && (undefined === input.thumbSuffix || "string" === typeof input.thumbSuffix || $guard(_exceptionable, {
                    path: _path + ".thumbSuffix",
                    expected: "(string | undefined)",
                    value: input.thumbSuffix
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["fullPath", "accountName", "accountKey", "id", "purpose", "type", "thumbSuffix"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceConfigAzure",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceConfigAzure",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DatasourceConfigAzureTypiaAssertStringify = (input: DatasourceConfigAzure): string => {
    return ((input: any): string => { const assert = (input: any): DatasourceConfigAzure => {
        const __is = (input: any): input is DatasourceConfigAzure => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "string" === typeof input.fullPath && ("string" === typeof input.accountName && 1 <= input.accountName.length) && ("string" === typeof input.accountKey && 1 <= input.accountKey.length) && (undefined === input.id || "string" === typeof input.id && $is_custom("pattern_id", "string", "", input.id)) && ("INPUT_OUTPUT" === input.purpose || "INPUT" === input.purpose || "LIGHTLY" === input.purpose) && "string" === typeof input.type && (undefined === input.thumbSuffix || "string" === typeof input.thumbSuffix);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DatasourceConfigAzure => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.fullPath || $guard(_exceptionable, {
                    path: _path + ".fullPath",
                    expected: "string",
                    value: input.fullPath
                })) && ("string" === typeof input.accountName && (1 <= input.accountName.length || $guard(_exceptionable, {
                    path: _path + ".accountName",
                    expected: "string (@minLength 1)",
                    value: input.accountName
                })) || $guard(_exceptionable, {
                    path: _path + ".accountName",
                    expected: "string",
                    value: input.accountName
                })) && ("string" === typeof input.accountKey && (1 <= input.accountKey.length || $guard(_exceptionable, {
                    path: _path + ".accountKey",
                    expected: "string (@minLength 1)",
                    value: input.accountKey
                })) || $guard(_exceptionable, {
                    path: _path + ".accountKey",
                    expected: "string",
                    value: input.accountKey
                })) && (undefined === input.id || "string" === typeof input.id && ($is_custom("pattern_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "(string | undefined)",
                    value: input.id
                })) && ("INPUT_OUTPUT" === input.purpose || "INPUT" === input.purpose || "LIGHTLY" === input.purpose || $guard(_exceptionable, {
                    path: _path + ".purpose",
                    expected: "(\"INPUT\" | \"INPUT_OUTPUT\" | \"LIGHTLY\")",
                    value: input.purpose
                })) && ("string" === typeof input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "string",
                    value: input.type
                })) && (undefined === input.thumbSuffix || "string" === typeof input.thumbSuffix || $guard(_exceptionable, {
                    path: _path + ".thumbSuffix",
                    expected: "(string | undefined)",
                    value: input.thumbSuffix
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceConfigAzure",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceConfigAzure",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DatasourceConfigAzure): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.id ? "" : `"id":${undefined !== input.id ? $string(input.id) : undefined},`}${undefined === input.thumbSuffix ? "" : `"thumbSuffix":${undefined !== input.thumbSuffix ? $string(input.thumbSuffix) : undefined},`}"fullPath":${$string(input.fullPath)},"accountName":${$string(input.accountName)},"accountKey":${$string(input.accountKey)},"purpose":${(() => {
            if ("string" === typeof input.purpose)
                return $string(input.purpose);
            if ("string" === typeof input.purpose)
                return "\"" + input.purpose + "\"";
            $throws({
                expected: "(\"INPUT\" | \"INPUT_OUTPUT\" | \"LIGHTLY\")",
                value: input.purpose
            });
        })()},"type":${$string(input.type)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DatasourceConfigAzureValidatorShape = {
    'fullPath': yup.string().defined().test('is-valid', 'the path needs to be set', (value) => {
        if (!value) {
            return false;
        }
        return true;
    })
        .test('is-valid', "It's not allowed to use '.lightly' in your path as its a reserved word used internally", (value) => {
        if (!value) {
            return true;
        }
        if (value.startsWith('.lightly/') ||
            value.endsWith('/.lightly') ||
            value.indexOf('/.lightly/') !== -1) {
            return false;
        }
        return true;
    })
        .test('is-valid', 'The path should not directly point to the location of the .lightly folder itself but to the .lightly folders parent', (value) => {
        if (!value) {
            return true;
        }
        if (value.endsWith('/.lightly/') || value.endsWith('/.lightly')) {
            return false;
        }
        return true;
    }),
    'accountName': yup.string().min(1).defined(),
    'accountKey': yup.string().min(1).defined(),
};
export const DatasourceConfigAzureValidator = yup.object().shape(DatasourceConfigAzureValidatorShape).concat(DatasourceConfigBaseValidator);
export const DatasourceConfigAzureStringifyShapeProperties: ObjectSchema['properties'] = {
    'fullPath': {
        'type': 'string',
    },
    'accountName': {
        'type': 'string',
        'minLength': 1,
    },
    'accountKey': {
        'type': 'string',
        'minLength': 1,
    },
};
export const DatasourceConfigAzureStringifyShape: ObjectSchema = {
    title: 'DatasourceConfigAzure Stringifier',
    type: 'object',
    properties: {
        ...DatasourceConfigBaseStringifyShapeProperties,
        ...DatasourceConfigAzureStringifyShapeProperties
    },
    required: [
        ...(DatasourceConfigBaseStringifyShape.required ? DatasourceConfigBaseStringifyShape.required : []),
        'fullPath',
        'accountName',
        'accountKey',
    ],
};
export const DatasourceConfigAzureStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DatasourceConfigAzureStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DatasourceConfigAzureStringify: (data: DatasourceConfigAzure) => string = DatasourceConfigAzureStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DatasourceConfigAzureFromJSON(json: any): DatasourceConfigAzure {
    return DatasourceConfigAzureFromJSONTyped(json, false);
}
export function DatasourceConfigAzureFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatasourceConfigAzure {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
    if (!ignoreDiscriminator) {
    }
*/
    return {
        ...DatasourceConfigBaseFromJSONTyped(json, ignoreDiscriminator),
        'fullPath': json['fullPath'],
        'accountName': json['accountName'],
        'accountKey': json['accountKey'],
    };
}
export function DatasourceConfigAzureToJSON(value?: DatasourceConfigAzure | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...DatasourceConfigBaseToJSON(value),
        'fullPath': value.fullPath,
        'accountName': value.accountName,
        'accountKey': value.accountKey,
    };
}

// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import type { ApiErrorResponse, JobStatusData, JobsData, } from '../models';
import { ApiErrorResponseFromJSON, ApiErrorResponseToJSON, ApiErrorResponseValidator, JobStatusDataFromJSON, JobStatusDataToJSON, JobStatusDataValidator, JobsDataFromJSON, JobsDataToJSON, JobsDataValidator, } from '../models';
import * as models from '../models';
/* LIGHTLY CUSTOM START -> -> moved from bottom to top:  apiMustache */
/* LIGHTLY CUSTOM END -> moved from bottom to top: apiMustache */
export interface GetJobStatusByIdRequest {
    jobId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetJobStatusByIdRequestValidatorShape = {
    'jobId': yup.string().defined(),
};
export const GetJobStatusByIdRequestValidator = yup.object().shape(GetJobStatusByIdRequestValidatorShape);
export interface GetJobStatusByIdRequestParams {
    jobId: string;
}
export function GetJobStatusByIdRequestParamsSanitizer(json: any): GetJobStatusByIdRequestParams {
    return {
        'jobId': json['jobId'],
    };
}
export const GetJobStatusByIdRequestParamsValidatorShape = {
    'jobId': yup.string().defined(),
};
export const GetJobStatusByIdRequestParamsValidator = yup.object().shape(GetJobStatusByIdRequestParamsValidatorShape);
export interface GetJobsRequestParams {
}
export function GetJobsRequestParamsSanitizer(json: any): GetJobsRequestParams {
    return {};
}
export const GetJobsRequestParamsValidatorShape = {};
export const GetJobsRequestParamsValidator = yup.object().shape(GetJobsRequestParamsValidatorShape);
export interface GetJobStatusByIdRequestQuery {
}
export function GetJobStatusByIdRequestQuerySanitizer(json: any): GetJobStatusByIdRequestQuery {
    return {};
}
export const GetJobStatusByIdRequestQueryValidatorShape = {};
export const GetJobStatusByIdRequestQueryValidator = yup.object().shape(GetJobStatusByIdRequestQueryValidatorShape);
export interface GetJobsRequestQuery {
}
export function GetJobsRequestQuerySanitizer(json: any): GetJobsRequestQuery {
    return {};
}
export const GetJobsRequestQueryValidatorShape = {};
export const GetJobsRequestQueryValidator = yup.object().shape(GetJobsRequestQueryValidatorShape);
/*
HANDLERS:



/v1/jobs/{jobId}

export const getJobStatusByIdFN: RequestHandlerAsync<
    GetJobStatusByIdRequestParams,
    JobStatusData | ApiErrorResponse,
    GetJobStatusByIdRequestBody,
    GetJobStatusByIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetJobStatusByIdRequestBodySanitizer, GetJobStatusByIdRequestBodyValidator);
    const {
        jobId,
    } =  await validateAndSanitize(req.params, GetJobStatusByIdRequestParamsSanitizer, GetJobStatusByIdRequestParamsValidator);
    const {
        
    } = await validateAndSanitize(req.query, GetJobStatusByIdRequestQuerySanitizer, GetJobStatusByIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        JobStatusDataStringify,
        validateAndRespond({ id, ...rest }, JobStatusDataFromJSON, JobStatusDataValidator),
    );
}



/v1/jobs
const JobsDataArrayStringify = fastJSONArrayify(JobsDataStringifyShape);
export const getJobsFN: RequestHandlerAsync<
    GetJobsRequestParams,
    JobsData[] | ApiErrorResponse,
    GetJobsRequestBody,
    GetJobsRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetJobsRequestBodySanitizer, GetJobsRequestBodyValidator);
    const {
    } =  await validateAndSanitize(req.params, GetJobsRequestParamsSanitizer, GetJobsRequestParamsValidator);
    const {
    } = await validateAndSanitize(req.query, GetJobsRequestQuerySanitizer, GetJobsRequestQueryValidator);



    
    const schemas = ....

    return fastJSONResponse(
        res,
        JobsDataArrayStringify,
        schemas.map((schema) => {
            const { _id: id, ...rest } = schema;
            return validateAndRespond({ id, ...rest }, JobsDataFromJSON, JobsDataValidator);
        }),
    );
}


*/
/* LIGHTLY CUSTOM END -> api */
/**
 * JobsApi - interface
 *
 * @export
 * @interface JobsApiInterface
 */
export interface JobsApiInterface {
    /**
     * Get status of a specific job
     * @param {string} jobId id of the job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApiInterface
     */
    getJobStatusByIdRaw(requestParameters: GetJobStatusByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JobStatusData>>;
    /**
     * Get status of a specific job
     */
    getJobStatusById(requestParameters: GetJobStatusByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JobStatusData>;
    /**
     * Get all jobs you have created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApiInterface
     */
    getJobsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<JobsData>>>;
    /**
     * Get all jobs you have created
     */
    getJobs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<JobsData>>;
}
/**
 *
 */
export class JobsApi extends runtime.BaseAPI implements JobsApiInterface {
    /**
     * Get status of a specific job
     */
    async getJobStatusByIdRaw(requestParameters: GetJobStatusByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JobStatusData>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId', 'Required parameter requestParameters.jobId was null or undefined when calling getJobStatusById.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/jobs/{jobId}`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => JobStatusDataFromJSON(jsonValue));
    }
    /**
     * Get status of a specific job
     */
    async getJobStatusById(requestParameters: GetJobStatusByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JobStatusData> {
        const response = await this.getJobStatusByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get all jobs you have created
     */
    async getJobsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<JobsData>>> {
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/jobs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(JobsDataFromJSON));
    }
    /**
     * Get all jobs you have created
     */
    async getJobs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<JobsData>> {
        const response = await this.getJobsRaw(initOverrides);
        return await response.value();
    }
}

/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DockerWorkerConfigV2DockerStoppingCondition
 *
 */
// create custom validators for the regexes
export interface DockerWorkerConfigV2DockerStoppingCondition {
    /**
     *
     * #type number
     * #memberof DockerWorkerConfigV2DockerStoppingCondition
        
        
         */
    minDistance?: number;
    /**
     *
     * #type number
     * #memberof DockerWorkerConfigV2DockerStoppingCondition
        
        
         */
    nSamples?: number;
}
/**
 * Check if a given object implements the DockerWorkerConfigV2DockerStoppingCondition interface.
 */
export function instanceOfDockerWorkerConfigV2DockerStoppingCondition(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
export const DockerWorkerConfigV2DockerStoppingConditionTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerWorkerConfigV2DockerStoppingCondition => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerWorkerConfigV2DockerStoppingCondition => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.minDistance || "number" === typeof input.minDistance) && (undefined === input.nSamples || "number" === typeof input.nSamples) && (0 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["minDistance", "nSamples"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigV2DockerStoppingCondition => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.minDistance || "number" === typeof input.minDistance || $guard(_exceptionable, {
                    path: _path + ".minDistance",
                    expected: "(number | undefined)",
                    value: input.minDistance
                })) && (undefined === input.nSamples || "number" === typeof input.nSamples || $guard(_exceptionable, {
                    path: _path + ".nSamples",
                    expected: "(number | undefined)",
                    value: input.nSamples
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["minDistance", "nSamples"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV2DockerStoppingCondition",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV2DockerStoppingCondition",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerWorkerConfigV2DockerStoppingConditionTypiaAssertStringify = (input: DockerWorkerConfigV2DockerStoppingCondition): string => {
    return ((input: any): string => { const assert = (input: any): DockerWorkerConfigV2DockerStoppingCondition => {
        const __is = (input: any): input is DockerWorkerConfigV2DockerStoppingCondition => {
            const $io0 = (input: any): boolean => (undefined === input.minDistance || "number" === typeof input.minDistance && !Number.isNaN(input.minDistance)) && (undefined === input.nSamples || "number" === typeof input.nSamples && !Number.isNaN(input.nSamples));
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigV2DockerStoppingCondition => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.minDistance || "number" === typeof input.minDistance && !Number.isNaN(input.minDistance) || $guard(_exceptionable, {
                    path: _path + ".minDistance",
                    expected: "(number | undefined)",
                    value: input.minDistance
                })) && (undefined === input.nSamples || "number" === typeof input.nSamples && !Number.isNaN(input.nSamples) || $guard(_exceptionable, {
                    path: _path + ".nSamples",
                    expected: "(number | undefined)",
                    value: input.nSamples
                }));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV2DockerStoppingCondition",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV2DockerStoppingCondition",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerWorkerConfigV2DockerStoppingCondition): string => {
        const $tail = (typia.createAssertStringify as any).tail;
        const $so0 = (input: any): any => `{${$tail(`${undefined === input.minDistance ? "" : `"minDistance":${undefined !== input.minDistance ? input.minDistance : undefined},`}${undefined === input.nSamples ? "" : `"nSamples":${undefined !== input.nSamples ? input.nSamples : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DockerWorkerConfigV2DockerStoppingConditionValidatorShape = {
    'minDistance': yup.number(),
    'nSamples': yup.number(),
};
export const DockerWorkerConfigV2DockerStoppingConditionValidator = yup.object().shape(DockerWorkerConfigV2DockerStoppingConditionValidatorShape);
export const DockerWorkerConfigV2DockerStoppingConditionStringifyShapeProperties: ObjectSchema['properties'] = {
    'minDistance': {
        'type': 'number',
    },
    'nSamples': {
        'type': 'number',
    },
};
export const DockerWorkerConfigV2DockerStoppingConditionStringifyShape: ObjectSchema = {
    title: 'DockerWorkerConfigV2DockerStoppingCondition Stringifier',
    type: 'object',
    properties: {
        ...DockerWorkerConfigV2DockerStoppingConditionStringifyShapeProperties
    },
    required: [],
};
export const DockerWorkerConfigV2DockerStoppingConditionStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerWorkerConfigV2DockerStoppingConditionStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerWorkerConfigV2DockerStoppingConditionStringify: (data: DockerWorkerConfigV2DockerStoppingCondition) => string = DockerWorkerConfigV2DockerStoppingConditionStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerWorkerConfigV2DockerStoppingConditionFromJSON(json: any): DockerWorkerConfigV2DockerStoppingCondition {
    return DockerWorkerConfigV2DockerStoppingConditionFromJSONTyped(json, false);
}
export function DockerWorkerConfigV2DockerStoppingConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerWorkerConfigV2DockerStoppingCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'minDistance': !exists(json, 'minDistance') ? undefined : json['minDistance'],
        'nSamples': !exists(json, 'nSamples') ? undefined : json['nSamples'],
    };
}
export function DockerWorkerConfigV2DockerStoppingConditionToJSON(value?: DockerWorkerConfigV2DockerStoppingCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'minDistance': value.minDistance,
        'nSamples': value.nSamples,
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DockerWorkerConfigV2LightlyCollate
 *
 */
// create custom validators for the regexes
export interface DockerWorkerConfigV2LightlyCollate {
    /**
     *
     * #type number
     * #memberof DockerWorkerConfigV2LightlyCollate
        * @type int
    * @minimum 1
            
         */
    inputSize?: number;
    /**
     *
     * #type number
     * #memberof DockerWorkerConfigV2LightlyCollate
        
    * @minimum 0.0
        * @maximum 1.0
        
         */
    cjProb?: number;
    /**
     *
     * #type number
     * #memberof DockerWorkerConfigV2LightlyCollate
        
    * @minimum 0.0
        * @maximum 1.0
        
         */
    cjBright?: number;
    /**
     *
     * #type number
     * #memberof DockerWorkerConfigV2LightlyCollate
        
    * @minimum 0.0
        * @maximum 1.0
        
         */
    cjContrast?: number;
    /**
     *
     * #type number
     * #memberof DockerWorkerConfigV2LightlyCollate
        
    * @minimum 0.0
        * @maximum 1.0
        
         */
    cjSat?: number;
    /**
     *
     * #type number
     * #memberof DockerWorkerConfigV2LightlyCollate
        
    * @minimum 0.0
        * @maximum 1.0
        
         */
    cjHue?: number;
    /**
     *
     * #type number
     * #memberof DockerWorkerConfigV2LightlyCollate
        
    * @minimum 0.0
        * @maximum 1.0
        
         */
    minScale?: number;
    /**
     *
     * #type number
     * #memberof DockerWorkerConfigV2LightlyCollate
        
    * @minimum 0.0
        * @maximum 1.0
        
         */
    randomGrayScale?: number;
    /**
     *
     * #type number
     * #memberof DockerWorkerConfigV2LightlyCollate
        
    * @minimum 0.0
        * @maximum 1.0
        
         */
    gaussianBlur?: number;
    /**
     *
     * #type number
     * #memberof DockerWorkerConfigV2LightlyCollate
        
    * @minimum 0.0
            
         */
    kernelSize?: number;
    /**
     *
     * #type Array<number>
     * #memberof DockerWorkerConfigV2LightlyCollate
        
        
         */
    sigmas?: Array<number>;
    /**
     *
     * #type number
     * #memberof DockerWorkerConfigV2LightlyCollate
        
    * @minimum 0.0
        * @maximum 1.0
        
         */
    vfProb?: number;
    /**
     *
     * #type number
     * #memberof DockerWorkerConfigV2LightlyCollate
        
    * @minimum 0.0
        * @maximum 1.0
        
         */
    hfProb?: number;
    /**
     *
     * #type number
     * #memberof DockerWorkerConfigV2LightlyCollate
        
    * @minimum 0.0
        * @maximum 1.0
        
         */
    rrProb?: number;
}
/**
 * Check if a given object implements the DockerWorkerConfigV2LightlyCollate interface.
 */
export function instanceOfDockerWorkerConfigV2LightlyCollate(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
export const DockerWorkerConfigV2LightlyCollateTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerWorkerConfigV2LightlyCollate => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerWorkerConfigV2LightlyCollate => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.inputSize || "number" === typeof input.inputSize && Math.floor(input.inputSize) === input.inputSize && (-2147483648 <= input.inputSize && input.inputSize <= 2147483647) && 1 <= input.inputSize) && (undefined === input.cjProb || "number" === typeof input.cjProb && 0 <= input.cjProb && 1 >= input.cjProb) && (undefined === input.cjBright || "number" === typeof input.cjBright && 0 <= input.cjBright && 1 >= input.cjBright) && (undefined === input.cjContrast || "number" === typeof input.cjContrast && 0 <= input.cjContrast && 1 >= input.cjContrast) && (undefined === input.cjSat || "number" === typeof input.cjSat && 0 <= input.cjSat && 1 >= input.cjSat) && (undefined === input.cjHue || "number" === typeof input.cjHue && 0 <= input.cjHue && 1 >= input.cjHue) && (undefined === input.minScale || "number" === typeof input.minScale && 0 <= input.minScale && 1 >= input.minScale) && (undefined === input.randomGrayScale || "number" === typeof input.randomGrayScale && 0 <= input.randomGrayScale && 1 >= input.randomGrayScale) && (undefined === input.gaussianBlur || "number" === typeof input.gaussianBlur && 0 <= input.gaussianBlur && 1 >= input.gaussianBlur) && (undefined === input.kernelSize || "number" === typeof input.kernelSize && 0 <= input.kernelSize) && (undefined === input.sigmas || Array.isArray(input.sigmas) && input.sigmas.every((elem: any, _index1: number) => "number" === typeof elem)) && (undefined === input.vfProb || "number" === typeof input.vfProb && 0 <= input.vfProb && 1 >= input.vfProb) && (undefined === input.hfProb || "number" === typeof input.hfProb && 0 <= input.hfProb && 1 >= input.hfProb) && (undefined === input.rrProb || "number" === typeof input.rrProb && 0 <= input.rrProb && 1 >= input.rrProb) && (0 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["inputSize", "cjProb", "cjBright", "cjContrast", "cjSat", "cjHue", "minScale", "randomGrayScale", "gaussianBlur", "kernelSize", "sigmas", "vfProb", "hfProb", "rrProb"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigV2LightlyCollate => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.inputSize || "number" === typeof input.inputSize && (Math.floor(input.inputSize) === input.inputSize || $guard(_exceptionable, {
                    path: _path + ".inputSize",
                    expected: "number (@type int)",
                    value: input.inputSize
                })) && (-2147483648 <= input.inputSize && input.inputSize <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".inputSize",
                    expected: "number (@type int)",
                    value: input.inputSize
                })) && (1 <= input.inputSize || $guard(_exceptionable, {
                    path: _path + ".inputSize",
                    expected: "number (@minimum 1)",
                    value: input.inputSize
                })) || $guard(_exceptionable, {
                    path: _path + ".inputSize",
                    expected: "(number | undefined)",
                    value: input.inputSize
                })) && (undefined === input.cjProb || "number" === typeof input.cjProb && (0 <= input.cjProb || $guard(_exceptionable, {
                    path: _path + ".cjProb",
                    expected: "number (@minimum 0)",
                    value: input.cjProb
                })) && (1 >= input.cjProb || $guard(_exceptionable, {
                    path: _path + ".cjProb",
                    expected: "number (@maximum 1)",
                    value: input.cjProb
                })) || $guard(_exceptionable, {
                    path: _path + ".cjProb",
                    expected: "(number | undefined)",
                    value: input.cjProb
                })) && (undefined === input.cjBright || "number" === typeof input.cjBright && (0 <= input.cjBright || $guard(_exceptionable, {
                    path: _path + ".cjBright",
                    expected: "number (@minimum 0)",
                    value: input.cjBright
                })) && (1 >= input.cjBright || $guard(_exceptionable, {
                    path: _path + ".cjBright",
                    expected: "number (@maximum 1)",
                    value: input.cjBright
                })) || $guard(_exceptionable, {
                    path: _path + ".cjBright",
                    expected: "(number | undefined)",
                    value: input.cjBright
                })) && (undefined === input.cjContrast || "number" === typeof input.cjContrast && (0 <= input.cjContrast || $guard(_exceptionable, {
                    path: _path + ".cjContrast",
                    expected: "number (@minimum 0)",
                    value: input.cjContrast
                })) && (1 >= input.cjContrast || $guard(_exceptionable, {
                    path: _path + ".cjContrast",
                    expected: "number (@maximum 1)",
                    value: input.cjContrast
                })) || $guard(_exceptionable, {
                    path: _path + ".cjContrast",
                    expected: "(number | undefined)",
                    value: input.cjContrast
                })) && (undefined === input.cjSat || "number" === typeof input.cjSat && (0 <= input.cjSat || $guard(_exceptionable, {
                    path: _path + ".cjSat",
                    expected: "number (@minimum 0)",
                    value: input.cjSat
                })) && (1 >= input.cjSat || $guard(_exceptionable, {
                    path: _path + ".cjSat",
                    expected: "number (@maximum 1)",
                    value: input.cjSat
                })) || $guard(_exceptionable, {
                    path: _path + ".cjSat",
                    expected: "(number | undefined)",
                    value: input.cjSat
                })) && (undefined === input.cjHue || "number" === typeof input.cjHue && (0 <= input.cjHue || $guard(_exceptionable, {
                    path: _path + ".cjHue",
                    expected: "number (@minimum 0)",
                    value: input.cjHue
                })) && (1 >= input.cjHue || $guard(_exceptionable, {
                    path: _path + ".cjHue",
                    expected: "number (@maximum 1)",
                    value: input.cjHue
                })) || $guard(_exceptionable, {
                    path: _path + ".cjHue",
                    expected: "(number | undefined)",
                    value: input.cjHue
                })) && (undefined === input.minScale || "number" === typeof input.minScale && (0 <= input.minScale || $guard(_exceptionable, {
                    path: _path + ".minScale",
                    expected: "number (@minimum 0)",
                    value: input.minScale
                })) && (1 >= input.minScale || $guard(_exceptionable, {
                    path: _path + ".minScale",
                    expected: "number (@maximum 1)",
                    value: input.minScale
                })) || $guard(_exceptionable, {
                    path: _path + ".minScale",
                    expected: "(number | undefined)",
                    value: input.minScale
                })) && (undefined === input.randomGrayScale || "number" === typeof input.randomGrayScale && (0 <= input.randomGrayScale || $guard(_exceptionable, {
                    path: _path + ".randomGrayScale",
                    expected: "number (@minimum 0)",
                    value: input.randomGrayScale
                })) && (1 >= input.randomGrayScale || $guard(_exceptionable, {
                    path: _path + ".randomGrayScale",
                    expected: "number (@maximum 1)",
                    value: input.randomGrayScale
                })) || $guard(_exceptionable, {
                    path: _path + ".randomGrayScale",
                    expected: "(number | undefined)",
                    value: input.randomGrayScale
                })) && (undefined === input.gaussianBlur || "number" === typeof input.gaussianBlur && (0 <= input.gaussianBlur || $guard(_exceptionable, {
                    path: _path + ".gaussianBlur",
                    expected: "number (@minimum 0)",
                    value: input.gaussianBlur
                })) && (1 >= input.gaussianBlur || $guard(_exceptionable, {
                    path: _path + ".gaussianBlur",
                    expected: "number (@maximum 1)",
                    value: input.gaussianBlur
                })) || $guard(_exceptionable, {
                    path: _path + ".gaussianBlur",
                    expected: "(number | undefined)",
                    value: input.gaussianBlur
                })) && (undefined === input.kernelSize || "number" === typeof input.kernelSize && (0 <= input.kernelSize || $guard(_exceptionable, {
                    path: _path + ".kernelSize",
                    expected: "number (@minimum 0)",
                    value: input.kernelSize
                })) || $guard(_exceptionable, {
                    path: _path + ".kernelSize",
                    expected: "(number | undefined)",
                    value: input.kernelSize
                })) && (undefined === input.sigmas || (Array.isArray(input.sigmas) || $guard(_exceptionable, {
                    path: _path + ".sigmas",
                    expected: "(Array<number> | undefined)",
                    value: input.sigmas
                })) && input.sigmas.every((elem: any, _index1: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".sigmas[" + _index1 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".sigmas",
                    expected: "(Array<number> | undefined)",
                    value: input.sigmas
                })) && (undefined === input.vfProb || "number" === typeof input.vfProb && (0 <= input.vfProb || $guard(_exceptionable, {
                    path: _path + ".vfProb",
                    expected: "number (@minimum 0)",
                    value: input.vfProb
                })) && (1 >= input.vfProb || $guard(_exceptionable, {
                    path: _path + ".vfProb",
                    expected: "number (@maximum 1)",
                    value: input.vfProb
                })) || $guard(_exceptionable, {
                    path: _path + ".vfProb",
                    expected: "(number | undefined)",
                    value: input.vfProb
                })) && (undefined === input.hfProb || "number" === typeof input.hfProb && (0 <= input.hfProb || $guard(_exceptionable, {
                    path: _path + ".hfProb",
                    expected: "number (@minimum 0)",
                    value: input.hfProb
                })) && (1 >= input.hfProb || $guard(_exceptionable, {
                    path: _path + ".hfProb",
                    expected: "number (@maximum 1)",
                    value: input.hfProb
                })) || $guard(_exceptionable, {
                    path: _path + ".hfProb",
                    expected: "(number | undefined)",
                    value: input.hfProb
                })) && (undefined === input.rrProb || "number" === typeof input.rrProb && (0 <= input.rrProb || $guard(_exceptionable, {
                    path: _path + ".rrProb",
                    expected: "number (@minimum 0)",
                    value: input.rrProb
                })) && (1 >= input.rrProb || $guard(_exceptionable, {
                    path: _path + ".rrProb",
                    expected: "number (@maximum 1)",
                    value: input.rrProb
                })) || $guard(_exceptionable, {
                    path: _path + ".rrProb",
                    expected: "(number | undefined)",
                    value: input.rrProb
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["inputSize", "cjProb", "cjBright", "cjContrast", "cjSat", "cjHue", "minScale", "randomGrayScale", "gaussianBlur", "kernelSize", "sigmas", "vfProb", "hfProb", "rrProb"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV2LightlyCollate",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV2LightlyCollate",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerWorkerConfigV2LightlyCollateTypiaAssertStringify = (input: DockerWorkerConfigV2LightlyCollate): string => {
    return ((input: any): string => { const assert = (input: any): DockerWorkerConfigV2LightlyCollate => {
        const __is = (input: any): input is DockerWorkerConfigV2LightlyCollate => {
            const $io0 = (input: any): boolean => (undefined === input.inputSize || "number" === typeof input.inputSize && !Number.isNaN(input.inputSize) && Math.floor(input.inputSize) === input.inputSize && (-2147483648 <= input.inputSize && input.inputSize <= 2147483647) && 1 <= input.inputSize) && (undefined === input.cjProb || "number" === typeof input.cjProb && 0 <= input.cjProb && 1 >= input.cjProb) && (undefined === input.cjBright || "number" === typeof input.cjBright && 0 <= input.cjBright && 1 >= input.cjBright) && (undefined === input.cjContrast || "number" === typeof input.cjContrast && 0 <= input.cjContrast && 1 >= input.cjContrast) && (undefined === input.cjSat || "number" === typeof input.cjSat && 0 <= input.cjSat && 1 >= input.cjSat) && (undefined === input.cjHue || "number" === typeof input.cjHue && 0 <= input.cjHue && 1 >= input.cjHue) && (undefined === input.minScale || "number" === typeof input.minScale && 0 <= input.minScale && 1 >= input.minScale) && (undefined === input.randomGrayScale || "number" === typeof input.randomGrayScale && 0 <= input.randomGrayScale && 1 >= input.randomGrayScale) && (undefined === input.gaussianBlur || "number" === typeof input.gaussianBlur && 0 <= input.gaussianBlur && 1 >= input.gaussianBlur) && (undefined === input.kernelSize || "number" === typeof input.kernelSize && !Number.isNaN(input.kernelSize) && 0 <= input.kernelSize) && (undefined === input.sigmas || Array.isArray(input.sigmas) && input.sigmas.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.vfProb || "number" === typeof input.vfProb && 0 <= input.vfProb && 1 >= input.vfProb) && (undefined === input.hfProb || "number" === typeof input.hfProb && 0 <= input.hfProb && 1 >= input.hfProb) && (undefined === input.rrProb || "number" === typeof input.rrProb && 0 <= input.rrProb && 1 >= input.rrProb);
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigV2LightlyCollate => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.inputSize || "number" === typeof input.inputSize && !Number.isNaN(input.inputSize) && (Math.floor(input.inputSize) === input.inputSize || $guard(_exceptionable, {
                    path: _path + ".inputSize",
                    expected: "number (@type int)",
                    value: input.inputSize
                })) && (-2147483648 <= input.inputSize && input.inputSize <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".inputSize",
                    expected: "number (@type int)",
                    value: input.inputSize
                })) && (1 <= input.inputSize || $guard(_exceptionable, {
                    path: _path + ".inputSize",
                    expected: "number (@minimum 1)",
                    value: input.inputSize
                })) || $guard(_exceptionable, {
                    path: _path + ".inputSize",
                    expected: "(number | undefined)",
                    value: input.inputSize
                })) && (undefined === input.cjProb || "number" === typeof input.cjProb && (0 <= input.cjProb || $guard(_exceptionable, {
                    path: _path + ".cjProb",
                    expected: "number (@minimum 0)",
                    value: input.cjProb
                })) && (1 >= input.cjProb || $guard(_exceptionable, {
                    path: _path + ".cjProb",
                    expected: "number (@maximum 1)",
                    value: input.cjProb
                })) || $guard(_exceptionable, {
                    path: _path + ".cjProb",
                    expected: "(number | undefined)",
                    value: input.cjProb
                })) && (undefined === input.cjBright || "number" === typeof input.cjBright && (0 <= input.cjBright || $guard(_exceptionable, {
                    path: _path + ".cjBright",
                    expected: "number (@minimum 0)",
                    value: input.cjBright
                })) && (1 >= input.cjBright || $guard(_exceptionable, {
                    path: _path + ".cjBright",
                    expected: "number (@maximum 1)",
                    value: input.cjBright
                })) || $guard(_exceptionable, {
                    path: _path + ".cjBright",
                    expected: "(number | undefined)",
                    value: input.cjBright
                })) && (undefined === input.cjContrast || "number" === typeof input.cjContrast && (0 <= input.cjContrast || $guard(_exceptionable, {
                    path: _path + ".cjContrast",
                    expected: "number (@minimum 0)",
                    value: input.cjContrast
                })) && (1 >= input.cjContrast || $guard(_exceptionable, {
                    path: _path + ".cjContrast",
                    expected: "number (@maximum 1)",
                    value: input.cjContrast
                })) || $guard(_exceptionable, {
                    path: _path + ".cjContrast",
                    expected: "(number | undefined)",
                    value: input.cjContrast
                })) && (undefined === input.cjSat || "number" === typeof input.cjSat && (0 <= input.cjSat || $guard(_exceptionable, {
                    path: _path + ".cjSat",
                    expected: "number (@minimum 0)",
                    value: input.cjSat
                })) && (1 >= input.cjSat || $guard(_exceptionable, {
                    path: _path + ".cjSat",
                    expected: "number (@maximum 1)",
                    value: input.cjSat
                })) || $guard(_exceptionable, {
                    path: _path + ".cjSat",
                    expected: "(number | undefined)",
                    value: input.cjSat
                })) && (undefined === input.cjHue || "number" === typeof input.cjHue && (0 <= input.cjHue || $guard(_exceptionable, {
                    path: _path + ".cjHue",
                    expected: "number (@minimum 0)",
                    value: input.cjHue
                })) && (1 >= input.cjHue || $guard(_exceptionable, {
                    path: _path + ".cjHue",
                    expected: "number (@maximum 1)",
                    value: input.cjHue
                })) || $guard(_exceptionable, {
                    path: _path + ".cjHue",
                    expected: "(number | undefined)",
                    value: input.cjHue
                })) && (undefined === input.minScale || "number" === typeof input.minScale && (0 <= input.minScale || $guard(_exceptionable, {
                    path: _path + ".minScale",
                    expected: "number (@minimum 0)",
                    value: input.minScale
                })) && (1 >= input.minScale || $guard(_exceptionable, {
                    path: _path + ".minScale",
                    expected: "number (@maximum 1)",
                    value: input.minScale
                })) || $guard(_exceptionable, {
                    path: _path + ".minScale",
                    expected: "(number | undefined)",
                    value: input.minScale
                })) && (undefined === input.randomGrayScale || "number" === typeof input.randomGrayScale && (0 <= input.randomGrayScale || $guard(_exceptionable, {
                    path: _path + ".randomGrayScale",
                    expected: "number (@minimum 0)",
                    value: input.randomGrayScale
                })) && (1 >= input.randomGrayScale || $guard(_exceptionable, {
                    path: _path + ".randomGrayScale",
                    expected: "number (@maximum 1)",
                    value: input.randomGrayScale
                })) || $guard(_exceptionable, {
                    path: _path + ".randomGrayScale",
                    expected: "(number | undefined)",
                    value: input.randomGrayScale
                })) && (undefined === input.gaussianBlur || "number" === typeof input.gaussianBlur && (0 <= input.gaussianBlur || $guard(_exceptionable, {
                    path: _path + ".gaussianBlur",
                    expected: "number (@minimum 0)",
                    value: input.gaussianBlur
                })) && (1 >= input.gaussianBlur || $guard(_exceptionable, {
                    path: _path + ".gaussianBlur",
                    expected: "number (@maximum 1)",
                    value: input.gaussianBlur
                })) || $guard(_exceptionable, {
                    path: _path + ".gaussianBlur",
                    expected: "(number | undefined)",
                    value: input.gaussianBlur
                })) && (undefined === input.kernelSize || "number" === typeof input.kernelSize && !Number.isNaN(input.kernelSize) && (0 <= input.kernelSize || $guard(_exceptionable, {
                    path: _path + ".kernelSize",
                    expected: "number (@minimum 0)",
                    value: input.kernelSize
                })) || $guard(_exceptionable, {
                    path: _path + ".kernelSize",
                    expected: "(number | undefined)",
                    value: input.kernelSize
                })) && (undefined === input.sigmas || (Array.isArray(input.sigmas) || $guard(_exceptionable, {
                    path: _path + ".sigmas",
                    expected: "(Array<number> | undefined)",
                    value: input.sigmas
                })) && input.sigmas.every((elem: any, _index1: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".sigmas[" + _index1 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".sigmas",
                    expected: "(Array<number> | undefined)",
                    value: input.sigmas
                })) && (undefined === input.vfProb || "number" === typeof input.vfProb && (0 <= input.vfProb || $guard(_exceptionable, {
                    path: _path + ".vfProb",
                    expected: "number (@minimum 0)",
                    value: input.vfProb
                })) && (1 >= input.vfProb || $guard(_exceptionable, {
                    path: _path + ".vfProb",
                    expected: "number (@maximum 1)",
                    value: input.vfProb
                })) || $guard(_exceptionable, {
                    path: _path + ".vfProb",
                    expected: "(number | undefined)",
                    value: input.vfProb
                })) && (undefined === input.hfProb || "number" === typeof input.hfProb && (0 <= input.hfProb || $guard(_exceptionable, {
                    path: _path + ".hfProb",
                    expected: "number (@minimum 0)",
                    value: input.hfProb
                })) && (1 >= input.hfProb || $guard(_exceptionable, {
                    path: _path + ".hfProb",
                    expected: "number (@maximum 1)",
                    value: input.hfProb
                })) || $guard(_exceptionable, {
                    path: _path + ".hfProb",
                    expected: "(number | undefined)",
                    value: input.hfProb
                })) && (undefined === input.rrProb || "number" === typeof input.rrProb && (0 <= input.rrProb || $guard(_exceptionable, {
                    path: _path + ".rrProb",
                    expected: "number (@minimum 0)",
                    value: input.rrProb
                })) && (1 >= input.rrProb || $guard(_exceptionable, {
                    path: _path + ".rrProb",
                    expected: "number (@maximum 1)",
                    value: input.rrProb
                })) || $guard(_exceptionable, {
                    path: _path + ".rrProb",
                    expected: "(number | undefined)",
                    value: input.rrProb
                }));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV2LightlyCollate",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV2LightlyCollate",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerWorkerConfigV2LightlyCollate): string => {
        const $tail = (typia.createAssertStringify as any).tail;
        const $so0 = (input: any): any => `{${$tail(`${undefined === input.inputSize ? "" : `"inputSize":${undefined !== input.inputSize ? input.inputSize : undefined},`}${undefined === input.cjProb ? "" : `"cjProb":${undefined !== input.cjProb ? input.cjProb : undefined},`}${undefined === input.cjBright ? "" : `"cjBright":${undefined !== input.cjBright ? input.cjBright : undefined},`}${undefined === input.cjContrast ? "" : `"cjContrast":${undefined !== input.cjContrast ? input.cjContrast : undefined},`}${undefined === input.cjSat ? "" : `"cjSat":${undefined !== input.cjSat ? input.cjSat : undefined},`}${undefined === input.cjHue ? "" : `"cjHue":${undefined !== input.cjHue ? input.cjHue : undefined},`}${undefined === input.minScale ? "" : `"minScale":${undefined !== input.minScale ? input.minScale : undefined},`}${undefined === input.randomGrayScale ? "" : `"randomGrayScale":${undefined !== input.randomGrayScale ? input.randomGrayScale : undefined},`}${undefined === input.gaussianBlur ? "" : `"gaussianBlur":${undefined !== input.gaussianBlur ? input.gaussianBlur : undefined},`}${undefined === input.kernelSize ? "" : `"kernelSize":${undefined !== input.kernelSize ? input.kernelSize : undefined},`}${undefined === input.sigmas ? "" : `"sigmas":${undefined !== input.sigmas ? `[${input.sigmas.map((elem: any) => elem).join(",")}]` : undefined},`}${undefined === input.vfProb ? "" : `"vfProb":${undefined !== input.vfProb ? input.vfProb : undefined},`}${undefined === input.hfProb ? "" : `"hfProb":${undefined !== input.hfProb ? input.hfProb : undefined},`}${undefined === input.rrProb ? "" : `"rrProb":${undefined !== input.rrProb ? input.rrProb : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DockerWorkerConfigV2LightlyCollateValidatorShape = {
    'inputSize': yup.number().integer().min(1),
    'cjProb': yup.number().min(0.0).max(1.0),
    'cjBright': yup.number().min(0.0).max(1.0),
    'cjContrast': yup.number().min(0.0).max(1.0),
    'cjSat': yup.number().min(0.0).max(1.0),
    'cjHue': yup.number().min(0.0).max(1.0),
    'minScale': yup.number().min(0.0).max(1.0),
    'randomGrayScale': yup.number().min(0.0).max(1.0),
    'gaussianBlur': yup.number().min(0.0).max(1.0),
    'kernelSize': yup.number().min(0.0),
    'sigmas': yup.array().of(yup.number().min(0)).min(2).max(2),
    'vfProb': yup.number().min(0.0).max(1.0),
    'hfProb': yup.number().min(0.0).max(1.0),
    'rrProb': yup.number().min(0.0).max(1.0),
};
export const DockerWorkerConfigV2LightlyCollateValidator = yup.object().shape(DockerWorkerConfigV2LightlyCollateValidatorShape);
export const DockerWorkerConfigV2LightlyCollateStringifyShapeProperties: ObjectSchema['properties'] = {
    'inputSize': {
        'type': 'integer',
        'minimum': 1,
    },
    'cjProb': {
        'type': 'number',
        'minimum': 0.0,
        'maximum': 1.0,
    },
    'cjBright': {
        'type': 'number',
        'minimum': 0.0,
        'maximum': 1.0,
    },
    'cjContrast': {
        'type': 'number',
        'minimum': 0.0,
        'maximum': 1.0,
    },
    'cjSat': {
        'type': 'number',
        'minimum': 0.0,
        'maximum': 1.0,
    },
    'cjHue': {
        'type': 'number',
        'minimum': 0.0,
        'maximum': 1.0,
    },
    'minScale': {
        'type': 'number',
        'minimum': 0.0,
        'maximum': 1.0,
    },
    'randomGrayScale': {
        'type': 'number',
        'minimum': 0.0,
        'maximum': 1.0,
    },
    'gaussianBlur': {
        'type': 'number',
        'minimum': 0.0,
        'maximum': 1.0,
    },
    'kernelSize': {
        'type': 'number',
        'minimum': 0.0,
    },
    'sigmas': {
        type: 'array',
        'maxItems': 2,
        'minItems': 2,
        items: {
            type: 'number'
        }
    },
    'vfProb': {
        'type': 'number',
        'minimum': 0.0,
        'maximum': 1.0,
    },
    'hfProb': {
        'type': 'number',
        'minimum': 0.0,
        'maximum': 1.0,
    },
    'rrProb': {
        'type': 'number',
        'minimum': 0.0,
        'maximum': 1.0,
    },
};
export const DockerWorkerConfigV2LightlyCollateStringifyShape: ObjectSchema = {
    title: 'DockerWorkerConfigV2LightlyCollate Stringifier',
    type: 'object',
    properties: {
        ...DockerWorkerConfigV2LightlyCollateStringifyShapeProperties
    },
    required: [],
};
export const DockerWorkerConfigV2LightlyCollateStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerWorkerConfigV2LightlyCollateStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerWorkerConfigV2LightlyCollateStringify: (data: DockerWorkerConfigV2LightlyCollate) => string = DockerWorkerConfigV2LightlyCollateStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerWorkerConfigV2LightlyCollateFromJSON(json: any): DockerWorkerConfigV2LightlyCollate {
    return DockerWorkerConfigV2LightlyCollateFromJSONTyped(json, false);
}
export function DockerWorkerConfigV2LightlyCollateFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerWorkerConfigV2LightlyCollate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'inputSize': !exists(json, 'inputSize') ? undefined : json['inputSize'],
        'cjProb': !exists(json, 'cjProb') ? undefined : json['cjProb'],
        'cjBright': !exists(json, 'cjBright') ? undefined : json['cjBright'],
        'cjContrast': !exists(json, 'cjContrast') ? undefined : json['cjContrast'],
        'cjSat': !exists(json, 'cjSat') ? undefined : json['cjSat'],
        'cjHue': !exists(json, 'cjHue') ? undefined : json['cjHue'],
        'minScale': !exists(json, 'minScale') ? undefined : json['minScale'],
        'randomGrayScale': !exists(json, 'randomGrayScale') ? undefined : json['randomGrayScale'],
        'gaussianBlur': !exists(json, 'gaussianBlur') ? undefined : json['gaussianBlur'],
        'kernelSize': !exists(json, 'kernelSize') ? undefined : json['kernelSize'],
        'sigmas': !exists(json, 'sigmas') ? undefined : json['sigmas'],
        'vfProb': !exists(json, 'vfProb') ? undefined : json['vfProb'],
        'hfProb': !exists(json, 'hfProb') ? undefined : json['hfProb'],
        'rrProb': !exists(json, 'rrProb') ? undefined : json['rrProb'],
    };
}
export function DockerWorkerConfigV2LightlyCollateToJSON(value?: DockerWorkerConfigV2LightlyCollate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'inputSize': value.inputSize,
        'cjProb': value.cjProb,
        'cjBright': value.cjBright,
        'cjContrast': value.cjContrast,
        'cjSat': value.cjSat,
        'cjHue': value.cjHue,
        'minScale': value.minScale,
        'randomGrayScale': value.randomGrayScale,
        'gaussianBlur': value.gaussianBlur,
        'kernelSize': value.kernelSize,
        'sigmas': value.sigmas,
        'vfProb': value.vfProb,
        'hfProb': value.hfProb,
        'rrProb': value.rrProb,
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { SelectionConfigV3EntryInput } from './SelectionConfigV3EntryInput';
import { SelectionConfigV3EntryInputFromJSON, SelectionConfigV3EntryInputFromJSONTyped, SelectionConfigV3EntryInputToJSON, } from './SelectionConfigV3EntryInput';
import type { SelectionConfigV3EntryStrategy } from './SelectionConfigV3EntryStrategy';
import { SelectionConfigV3EntryStrategyFromJSON, SelectionConfigV3EntryStrategyFromJSONTyped, SelectionConfigV3EntryStrategyToJSON, } from './SelectionConfigV3EntryStrategy';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface SelectionConfigV3Entry
 *
 */
// create custom validators for the regexes
export interface SelectionConfigV3Entry {
    /**
     *
     * #type SelectionConfigV3EntryInput
     * #memberof SelectionConfigV3Entry
        
        
         */
    input: SelectionConfigV3EntryInput;
    /**
     *
     * #type SelectionConfigV3EntryStrategy
     * #memberof SelectionConfigV3Entry
        
        
         */
    strategy: SelectionConfigV3EntryStrategy;
}
/**
 * Check if a given object implements the SelectionConfigV3Entry interface.
 */
export function instanceOfSelectionConfigV3Entry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "input" in value;
    isInstance = isInstance && "strategy" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { SelectionConfigV3EntryInputValidator, SelectionConfigV3EntryInputStringifyShape, SelectionConfigV3EntryInputStringifyShapeProperties, } from './SelectionConfigV3EntryInput';
import { SelectionConfigV3EntryStrategyValidator, SelectionConfigV3EntryStrategyStringifyShape, SelectionConfigV3EntryStrategyStringifyShapeProperties, } from './SelectionConfigV3EntryStrategy';
export const SelectionConfigV3EntryTypiaAssertEquals = (input: any) => {
    return ((input: any): SelectionConfigV3Entry => {
        const __is = (input: any, _exceptionable: boolean = true): input is SelectionConfigV3Entry => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "object" === typeof input.input && null !== input.input && $io1(input.input, true && _exceptionable) && ("object" === typeof input.strategy && null !== input.strategy && $io2(input.strategy, true && _exceptionable)) && (2 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["input", "strategy"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => ("EMBEDDINGS" === input.type || "SCORES" === input.type || "METADATA" === input.type || "PREDICTIONS" === input.type || "RANDOM" === input.type) && (undefined === input.task || "string" === typeof input.task && $is_custom("pattern_task", "string", "", input.task)) && (undefined === input.score || "string" === typeof input.score && 1 <= input.score.length && $is_custom("pattern_score", "string", "", input.score)) && (undefined === input.key || "string" === typeof input.key && 1 <= input.key.length) && (undefined === input.name || "CLASS_DISTRIBUTION" === input.name || "CATEGORY_COUNT" === input.name) && (undefined === input.datasetId || "string" === typeof input.datasetId && $is_custom("pattern_datasetId", "string", "", input.datasetId)) && (undefined === input.tagName || "string" === typeof input.tagName && 3 <= input.tagName.length && $is_custom("pattern_tagName", "string", "", input.tagName)) && (undefined === input.randomSeed || "number" === typeof input.randomSeed && Math.floor(input.randomSeed) === input.randomSeed && (-2147483648 <= input.randomSeed && input.randomSeed <= 2147483647)) && (undefined === input.categories || input.categories instanceof Set && (() => [...input.categories].every((elem: any, _index1: number) => "string" === typeof elem))()) && (1 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["type", "task", "score", "key", "name", "datasetId", "tagName", "randomSeed", "categories"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => ("DIVERSITY" === input.type || "WEIGHTS" === input.type || "THRESHOLD" === input.type || "BALANCE" === input.type || "SIMILARITY" === input.type || "TYPICALITY" === input.type) && (undefined === input.stopping_condition_minimum_distance || "number" === typeof input.stopping_condition_minimum_distance) && (undefined === input.threshold || "number" === typeof input.threshold) && (undefined === input.operation || "SMALLER" === input.operation || "SMALLER_EQUAL" === input.operation || "BIGGER" === input.operation || "BIGGER_EQUAL" === input.operation) && (undefined === input.target || "object" === typeof input.target && null !== input.target && false === Array.isArray(input.target) && $io3(input.target, true && _exceptionable)) && (undefined === input.numNearestNeighbors || "number" === typeof input.numNearestNeighbors && Math.floor(input.numNearestNeighbors) === input.numNearestNeighbors && (-2147483648 <= input.numNearestNeighbors && input.numNearestNeighbors <= 2147483647) && 2 <= input.numNearestNeighbors) && (undefined === input.stoppingConditionMinimumTypicality || "number" === typeof input.stoppingConditionMinimumTypicality && 0 < input.stoppingConditionMinimumTypicality) && (undefined === input.strength || "number" === typeof input.strength && -1000000000 <= input.strength && 1000000000 >= input.strength) && (undefined === input.stoppingConditionMaxSum || "number" === typeof input.stoppingConditionMaxSum && 0 <= input.stoppingConditionMaxSum) && (undefined === input.targetRange || "object" === typeof input.targetRange && null !== input.targetRange && false === Array.isArray(input.targetRange) && $io4(input.targetRange, true && _exceptionable)) && (1 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["type", "stopping_condition_minimum_distance", "threshold", "operation", "target", "numNearestNeighbors", "stoppingConditionMinimumTypicality", "strength", "stoppingConditionMaxSum", "targetRange"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io3 = (input: any, _exceptionable: boolean = true): boolean => 0 === Object.keys(input).length || Object.keys(input).every(key => {
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            });
            const $io4 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.minSum || "number" === typeof input.minSum && 0 <= input.minSum) && (undefined === input.maxSum || "number" === typeof input.maxSum && 0 <= input.maxSum) && (0 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["minSum", "maxSum"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is SelectionConfigV3Entry => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (("object" === typeof input.input && null !== input.input || $guard(_exceptionable, {
                    path: _path + ".input",
                    expected: "SelectionConfigV3EntryInput",
                    value: input.input
                })) && $ao1(input.input, _path + ".input", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".input",
                    expected: "SelectionConfigV3EntryInput",
                    value: input.input
                })) && (("object" === typeof input.strategy && null !== input.strategy || $guard(_exceptionable, {
                    path: _path + ".strategy",
                    expected: "SelectionConfigV3EntryStrategy",
                    value: input.strategy
                })) && $ao2(input.strategy, _path + ".strategy", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".strategy",
                    expected: "SelectionConfigV3EntryStrategy",
                    value: input.strategy
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["input", "strategy"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("EMBEDDINGS" === input.type || "SCORES" === input.type || "METADATA" === input.type || "PREDICTIONS" === input.type || "RANDOM" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "(\"EMBEDDINGS\" | \"METADATA\" | \"PREDICTIONS\" | \"RANDOM\" | \"SCORES\")",
                    value: input.type
                })) && (undefined === input.task || "string" === typeof input.task && ($is_custom("pattern_task", "string", "", input.task) || $guard(_exceptionable, {
                    path: _path + ".task",
                    expected: "string (@pattern_task)",
                    value: input.task
                })) || $guard(_exceptionable, {
                    path: _path + ".task",
                    expected: "(string | undefined)",
                    value: input.task
                })) && (undefined === input.score || "string" === typeof input.score && (1 <= input.score.length || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "string (@minLength 1)",
                    value: input.score
                })) && ($is_custom("pattern_score", "string", "", input.score) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "string (@pattern_score)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "(string | undefined)",
                    value: input.score
                })) && (undefined === input.key || "string" === typeof input.key && (1 <= input.key.length || $guard(_exceptionable, {
                    path: _path + ".key",
                    expected: "string (@minLength 1)",
                    value: input.key
                })) || $guard(_exceptionable, {
                    path: _path + ".key",
                    expected: "(string | undefined)",
                    value: input.key
                })) && (undefined === input.name || "CLASS_DISTRIBUTION" === input.name || "CATEGORY_COUNT" === input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "(\"CATEGORY_COUNT\" | \"CLASS_DISTRIBUTION\" | undefined)",
                    value: input.name
                })) && (undefined === input.datasetId || "string" === typeof input.datasetId && ($is_custom("pattern_datasetId", "string", "", input.datasetId) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "string (@pattern_datasetId)",
                    value: input.datasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "(string | undefined)",
                    value: input.datasetId
                })) && (undefined === input.tagName || "string" === typeof input.tagName && (3 <= input.tagName.length || $guard(_exceptionable, {
                    path: _path + ".tagName",
                    expected: "string (@minLength 3)",
                    value: input.tagName
                })) && ($is_custom("pattern_tagName", "string", "", input.tagName) || $guard(_exceptionable, {
                    path: _path + ".tagName",
                    expected: "string (@pattern_tagName)",
                    value: input.tagName
                })) || $guard(_exceptionable, {
                    path: _path + ".tagName",
                    expected: "(string | undefined)",
                    value: input.tagName
                })) && (undefined === input.randomSeed || "number" === typeof input.randomSeed && (Math.floor(input.randomSeed) === input.randomSeed || $guard(_exceptionable, {
                    path: _path + ".randomSeed",
                    expected: "number (@type int)",
                    value: input.randomSeed
                })) && (-2147483648 <= input.randomSeed && input.randomSeed <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".randomSeed",
                    expected: "number (@type int)",
                    value: input.randomSeed
                })) || $guard(_exceptionable, {
                    path: _path + ".randomSeed",
                    expected: "(number | undefined)",
                    value: input.randomSeed
                })) && (undefined === input.categories || (input.categories instanceof Set || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "(Set<string> | undefined)",
                    value: input.categories
                })) && (() => [...input.categories].every((elem: any, _index1: number) => "string" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index1 + "]",
                    expected: "string",
                    value: elem
                })))() || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "(Set<string> | undefined)",
                    value: input.categories
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["type", "task", "score", "key", "name", "datasetId", "tagName", "randomSeed", "categories"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("DIVERSITY" === input.type || "WEIGHTS" === input.type || "THRESHOLD" === input.type || "BALANCE" === input.type || "SIMILARITY" === input.type || "TYPICALITY" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "(\"BALANCE\" | \"DIVERSITY\" | \"SIMILARITY\" | \"THRESHOLD\" | \"TYPICALITY\" | \"WEIGHTS\")",
                    value: input.type
                })) && (undefined === input.stopping_condition_minimum_distance || "number" === typeof input.stopping_condition_minimum_distance || $guard(_exceptionable, {
                    path: _path + ".stopping_condition_minimum_distance",
                    expected: "(number | undefined)",
                    value: input.stopping_condition_minimum_distance
                })) && (undefined === input.threshold || "number" === typeof input.threshold || $guard(_exceptionable, {
                    path: _path + ".threshold",
                    expected: "(number | undefined)",
                    value: input.threshold
                })) && (undefined === input.operation || "SMALLER" === input.operation || "SMALLER_EQUAL" === input.operation || "BIGGER" === input.operation || "BIGGER_EQUAL" === input.operation || $guard(_exceptionable, {
                    path: _path + ".operation",
                    expected: "(\"BIGGER\" | \"BIGGER_EQUAL\" | \"SMALLER\" | \"SMALLER_EQUAL\" | undefined)",
                    value: input.operation
                })) && (undefined === input.target || ("object" === typeof input.target && null !== input.target && false === Array.isArray(input.target) || $guard(_exceptionable, {
                    path: _path + ".target",
                    expected: "(object | undefined)",
                    value: input.target
                })) && $ao3(input.target, _path + ".target", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".target",
                    expected: "(object | undefined)",
                    value: input.target
                })) && (undefined === input.numNearestNeighbors || "number" === typeof input.numNearestNeighbors && (Math.floor(input.numNearestNeighbors) === input.numNearestNeighbors || $guard(_exceptionable, {
                    path: _path + ".numNearestNeighbors",
                    expected: "number (@type int)",
                    value: input.numNearestNeighbors
                })) && (-2147483648 <= input.numNearestNeighbors && input.numNearestNeighbors <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".numNearestNeighbors",
                    expected: "number (@type int)",
                    value: input.numNearestNeighbors
                })) && (2 <= input.numNearestNeighbors || $guard(_exceptionable, {
                    path: _path + ".numNearestNeighbors",
                    expected: "number (@minimum 2)",
                    value: input.numNearestNeighbors
                })) || $guard(_exceptionable, {
                    path: _path + ".numNearestNeighbors",
                    expected: "(number | undefined)",
                    value: input.numNearestNeighbors
                })) && (undefined === input.stoppingConditionMinimumTypicality || "number" === typeof input.stoppingConditionMinimumTypicality && (0 < input.stoppingConditionMinimumTypicality || $guard(_exceptionable, {
                    path: _path + ".stoppingConditionMinimumTypicality",
                    expected: "number (@exclusiveMinimum 0)",
                    value: input.stoppingConditionMinimumTypicality
                })) || $guard(_exceptionable, {
                    path: _path + ".stoppingConditionMinimumTypicality",
                    expected: "(number | undefined)",
                    value: input.stoppingConditionMinimumTypicality
                })) && (undefined === input.strength || "number" === typeof input.strength && (-1000000000 <= input.strength || $guard(_exceptionable, {
                    path: _path + ".strength",
                    expected: "number (@minimum -1000000000)",
                    value: input.strength
                })) && (1000000000 >= input.strength || $guard(_exceptionable, {
                    path: _path + ".strength",
                    expected: "number (@maximum 1000000000)",
                    value: input.strength
                })) || $guard(_exceptionable, {
                    path: _path + ".strength",
                    expected: "(number | undefined)",
                    value: input.strength
                })) && (undefined === input.stoppingConditionMaxSum || "number" === typeof input.stoppingConditionMaxSum && (0 <= input.stoppingConditionMaxSum || $guard(_exceptionable, {
                    path: _path + ".stoppingConditionMaxSum",
                    expected: "number (@minimum 0)",
                    value: input.stoppingConditionMaxSum
                })) || $guard(_exceptionable, {
                    path: _path + ".stoppingConditionMaxSum",
                    expected: "(number | undefined)",
                    value: input.stoppingConditionMaxSum
                })) && (undefined === input.targetRange || ("object" === typeof input.targetRange && null !== input.targetRange && false === Array.isArray(input.targetRange) || $guard(_exceptionable, {
                    path: _path + ".targetRange",
                    expected: "(SelectionConfigV3EntryStrategyAllOfTargetRange | undefined)",
                    value: input.targetRange
                })) && $ao4(input.targetRange, _path + ".targetRange", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".targetRange",
                    expected: "(SelectionConfigV3EntryStrategyAllOfTargetRange | undefined)",
                    value: input.targetRange
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["type", "stopping_condition_minimum_distance", "threshold", "operation", "target", "numNearestNeighbors", "stoppingConditionMinimumTypicality", "strength", "stoppingConditionMaxSum", "targetRange"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => 0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.minSum || "number" === typeof input.minSum && (0 <= input.minSum || $guard(_exceptionable, {
                    path: _path + ".minSum",
                    expected: "number (@minimum 0)",
                    value: input.minSum
                })) || $guard(_exceptionable, {
                    path: _path + ".minSum",
                    expected: "(number | undefined)",
                    value: input.minSum
                })) && (undefined === input.maxSum || "number" === typeof input.maxSum && (0 <= input.maxSum || $guard(_exceptionable, {
                    path: _path + ".maxSum",
                    expected: "number (@minimum 0)",
                    value: input.maxSum
                })) || $guard(_exceptionable, {
                    path: _path + ".maxSum",
                    expected: "(number | undefined)",
                    value: input.maxSum
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["minSum", "maxSum"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SelectionConfigV3Entry",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SelectionConfigV3Entry",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const SelectionConfigV3EntryTypiaAssertStringify = (input: SelectionConfigV3Entry): string => {
    return ((input: any): string => { const assert = (input: any): SelectionConfigV3Entry => {
        const __is = (input: any): input is SelectionConfigV3Entry => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "object" === typeof input.input && null !== input.input && $io1(input.input) && ("object" === typeof input.strategy && null !== input.strategy && $io2(input.strategy));
            const $io1 = (input: any): boolean => ("EMBEDDINGS" === input.type || "SCORES" === input.type || "METADATA" === input.type || "PREDICTIONS" === input.type || "RANDOM" === input.type) && (undefined === input.task || "string" === typeof input.task && $is_custom("pattern_task", "string", "", input.task)) && (undefined === input.score || "string" === typeof input.score && 1 <= input.score.length && $is_custom("pattern_score", "string", "", input.score)) && (undefined === input.key || "string" === typeof input.key && 1 <= input.key.length) && (undefined === input.name || "CLASS_DISTRIBUTION" === input.name || "CATEGORY_COUNT" === input.name) && (undefined === input.datasetId || "string" === typeof input.datasetId && $is_custom("pattern_datasetId", "string", "", input.datasetId)) && (undefined === input.tagName || "string" === typeof input.tagName && 3 <= input.tagName.length && $is_custom("pattern_tagName", "string", "", input.tagName)) && (undefined === input.randomSeed || "number" === typeof input.randomSeed && !Number.isNaN(input.randomSeed) && Math.floor(input.randomSeed) === input.randomSeed && (-2147483648 <= input.randomSeed && input.randomSeed <= 2147483647)) && (undefined === input.categories || input.categories instanceof Set && (() => [...input.categories].every((elem: any) => "string" === typeof elem))());
            const $io2 = (input: any): boolean => ("DIVERSITY" === input.type || "WEIGHTS" === input.type || "THRESHOLD" === input.type || "BALANCE" === input.type || "SIMILARITY" === input.type || "TYPICALITY" === input.type) && (undefined === input.stopping_condition_minimum_distance || "number" === typeof input.stopping_condition_minimum_distance && !Number.isNaN(input.stopping_condition_minimum_distance)) && (undefined === input.threshold || "number" === typeof input.threshold && !Number.isNaN(input.threshold)) && (undefined === input.operation || "SMALLER" === input.operation || "SMALLER_EQUAL" === input.operation || "BIGGER" === input.operation || "BIGGER_EQUAL" === input.operation) && (undefined === input.target || "object" === typeof input.target && null !== input.target && false === Array.isArray(input.target) && $io3(input.target)) && (undefined === input.numNearestNeighbors || "number" === typeof input.numNearestNeighbors && !Number.isNaN(input.numNearestNeighbors) && Math.floor(input.numNearestNeighbors) === input.numNearestNeighbors && (-2147483648 <= input.numNearestNeighbors && input.numNearestNeighbors <= 2147483647) && 2 <= input.numNearestNeighbors) && (undefined === input.stoppingConditionMinimumTypicality || "number" === typeof input.stoppingConditionMinimumTypicality && !Number.isNaN(input.stoppingConditionMinimumTypicality) && 0 < input.stoppingConditionMinimumTypicality) && (undefined === input.strength || "number" === typeof input.strength && -1000000000 <= input.strength && 1000000000 >= input.strength) && (undefined === input.stoppingConditionMaxSum || "number" === typeof input.stoppingConditionMaxSum && !Number.isNaN(input.stoppingConditionMaxSum) && 0 <= input.stoppingConditionMaxSum) && (undefined === input.targetRange || "object" === typeof input.targetRange && null !== input.targetRange && false === Array.isArray(input.targetRange) && $io4(input.targetRange));
            const $io3 = (input: any): boolean => true;
            const $io4 = (input: any): boolean => (undefined === input.minSum || "number" === typeof input.minSum && !Number.isNaN(input.minSum) && 0 <= input.minSum) && (undefined === input.maxSum || "number" === typeof input.maxSum && !Number.isNaN(input.maxSum) && 0 <= input.maxSum);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is SelectionConfigV3Entry => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (("object" === typeof input.input && null !== input.input || $guard(_exceptionable, {
                    path: _path + ".input",
                    expected: "SelectionConfigV3EntryInput",
                    value: input.input
                })) && $ao1(input.input, _path + ".input", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".input",
                    expected: "SelectionConfigV3EntryInput",
                    value: input.input
                })) && (("object" === typeof input.strategy && null !== input.strategy || $guard(_exceptionable, {
                    path: _path + ".strategy",
                    expected: "SelectionConfigV3EntryStrategy",
                    value: input.strategy
                })) && $ao2(input.strategy, _path + ".strategy", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".strategy",
                    expected: "SelectionConfigV3EntryStrategy",
                    value: input.strategy
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("EMBEDDINGS" === input.type || "SCORES" === input.type || "METADATA" === input.type || "PREDICTIONS" === input.type || "RANDOM" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "(\"EMBEDDINGS\" | \"METADATA\" | \"PREDICTIONS\" | \"RANDOM\" | \"SCORES\")",
                    value: input.type
                })) && (undefined === input.task || "string" === typeof input.task && ($is_custom("pattern_task", "string", "", input.task) || $guard(_exceptionable, {
                    path: _path + ".task",
                    expected: "string (@pattern_task)",
                    value: input.task
                })) || $guard(_exceptionable, {
                    path: _path + ".task",
                    expected: "(string | undefined)",
                    value: input.task
                })) && (undefined === input.score || "string" === typeof input.score && (1 <= input.score.length || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "string (@minLength 1)",
                    value: input.score
                })) && ($is_custom("pattern_score", "string", "", input.score) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "string (@pattern_score)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "(string | undefined)",
                    value: input.score
                })) && (undefined === input.key || "string" === typeof input.key && (1 <= input.key.length || $guard(_exceptionable, {
                    path: _path + ".key",
                    expected: "string (@minLength 1)",
                    value: input.key
                })) || $guard(_exceptionable, {
                    path: _path + ".key",
                    expected: "(string | undefined)",
                    value: input.key
                })) && (undefined === input.name || "CLASS_DISTRIBUTION" === input.name || "CATEGORY_COUNT" === input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "(\"CATEGORY_COUNT\" | \"CLASS_DISTRIBUTION\" | undefined)",
                    value: input.name
                })) && (undefined === input.datasetId || "string" === typeof input.datasetId && ($is_custom("pattern_datasetId", "string", "", input.datasetId) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "string (@pattern_datasetId)",
                    value: input.datasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "(string | undefined)",
                    value: input.datasetId
                })) && (undefined === input.tagName || "string" === typeof input.tagName && (3 <= input.tagName.length || $guard(_exceptionable, {
                    path: _path + ".tagName",
                    expected: "string (@minLength 3)",
                    value: input.tagName
                })) && ($is_custom("pattern_tagName", "string", "", input.tagName) || $guard(_exceptionable, {
                    path: _path + ".tagName",
                    expected: "string (@pattern_tagName)",
                    value: input.tagName
                })) || $guard(_exceptionable, {
                    path: _path + ".tagName",
                    expected: "(string | undefined)",
                    value: input.tagName
                })) && (undefined === input.randomSeed || "number" === typeof input.randomSeed && !Number.isNaN(input.randomSeed) && (Math.floor(input.randomSeed) === input.randomSeed || $guard(_exceptionable, {
                    path: _path + ".randomSeed",
                    expected: "number (@type int)",
                    value: input.randomSeed
                })) && (-2147483648 <= input.randomSeed && input.randomSeed <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".randomSeed",
                    expected: "number (@type int)",
                    value: input.randomSeed
                })) || $guard(_exceptionable, {
                    path: _path + ".randomSeed",
                    expected: "(number | undefined)",
                    value: input.randomSeed
                })) && (undefined === input.categories || (input.categories instanceof Set || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "(Set<string> | undefined)",
                    value: input.categories
                })) && (() => [...input.categories].every((elem: any, _index1: number) => "string" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index1 + "]",
                    expected: "string",
                    value: elem
                })))() || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "(Set<string> | undefined)",
                    value: input.categories
                }));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("DIVERSITY" === input.type || "WEIGHTS" === input.type || "THRESHOLD" === input.type || "BALANCE" === input.type || "SIMILARITY" === input.type || "TYPICALITY" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "(\"BALANCE\" | \"DIVERSITY\" | \"SIMILARITY\" | \"THRESHOLD\" | \"TYPICALITY\" | \"WEIGHTS\")",
                    value: input.type
                })) && (undefined === input.stopping_condition_minimum_distance || "number" === typeof input.stopping_condition_minimum_distance && !Number.isNaN(input.stopping_condition_minimum_distance) || $guard(_exceptionable, {
                    path: _path + ".stopping_condition_minimum_distance",
                    expected: "(number | undefined)",
                    value: input.stopping_condition_minimum_distance
                })) && (undefined === input.threshold || "number" === typeof input.threshold && !Number.isNaN(input.threshold) || $guard(_exceptionable, {
                    path: _path + ".threshold",
                    expected: "(number | undefined)",
                    value: input.threshold
                })) && (undefined === input.operation || "SMALLER" === input.operation || "SMALLER_EQUAL" === input.operation || "BIGGER" === input.operation || "BIGGER_EQUAL" === input.operation || $guard(_exceptionable, {
                    path: _path + ".operation",
                    expected: "(\"BIGGER\" | \"BIGGER_EQUAL\" | \"SMALLER\" | \"SMALLER_EQUAL\" | undefined)",
                    value: input.operation
                })) && (undefined === input.target || ("object" === typeof input.target && null !== input.target && false === Array.isArray(input.target) || $guard(_exceptionable, {
                    path: _path + ".target",
                    expected: "(object | undefined)",
                    value: input.target
                })) && $ao3(input.target, _path + ".target", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".target",
                    expected: "(object | undefined)",
                    value: input.target
                })) && (undefined === input.numNearestNeighbors || "number" === typeof input.numNearestNeighbors && !Number.isNaN(input.numNearestNeighbors) && (Math.floor(input.numNearestNeighbors) === input.numNearestNeighbors || $guard(_exceptionable, {
                    path: _path + ".numNearestNeighbors",
                    expected: "number (@type int)",
                    value: input.numNearestNeighbors
                })) && (-2147483648 <= input.numNearestNeighbors && input.numNearestNeighbors <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".numNearestNeighbors",
                    expected: "number (@type int)",
                    value: input.numNearestNeighbors
                })) && (2 <= input.numNearestNeighbors || $guard(_exceptionable, {
                    path: _path + ".numNearestNeighbors",
                    expected: "number (@minimum 2)",
                    value: input.numNearestNeighbors
                })) || $guard(_exceptionable, {
                    path: _path + ".numNearestNeighbors",
                    expected: "(number | undefined)",
                    value: input.numNearestNeighbors
                })) && (undefined === input.stoppingConditionMinimumTypicality || "number" === typeof input.stoppingConditionMinimumTypicality && !Number.isNaN(input.stoppingConditionMinimumTypicality) && (0 < input.stoppingConditionMinimumTypicality || $guard(_exceptionable, {
                    path: _path + ".stoppingConditionMinimumTypicality",
                    expected: "number (@exclusiveMinimum 0)",
                    value: input.stoppingConditionMinimumTypicality
                })) || $guard(_exceptionable, {
                    path: _path + ".stoppingConditionMinimumTypicality",
                    expected: "(number | undefined)",
                    value: input.stoppingConditionMinimumTypicality
                })) && (undefined === input.strength || "number" === typeof input.strength && (-1000000000 <= input.strength || $guard(_exceptionable, {
                    path: _path + ".strength",
                    expected: "number (@minimum -1000000000)",
                    value: input.strength
                })) && (1000000000 >= input.strength || $guard(_exceptionable, {
                    path: _path + ".strength",
                    expected: "number (@maximum 1000000000)",
                    value: input.strength
                })) || $guard(_exceptionable, {
                    path: _path + ".strength",
                    expected: "(number | undefined)",
                    value: input.strength
                })) && (undefined === input.stoppingConditionMaxSum || "number" === typeof input.stoppingConditionMaxSum && !Number.isNaN(input.stoppingConditionMaxSum) && (0 <= input.stoppingConditionMaxSum || $guard(_exceptionable, {
                    path: _path + ".stoppingConditionMaxSum",
                    expected: "number (@minimum 0)",
                    value: input.stoppingConditionMaxSum
                })) || $guard(_exceptionable, {
                    path: _path + ".stoppingConditionMaxSum",
                    expected: "(number | undefined)",
                    value: input.stoppingConditionMaxSum
                })) && (undefined === input.targetRange || ("object" === typeof input.targetRange && null !== input.targetRange && false === Array.isArray(input.targetRange) || $guard(_exceptionable, {
                    path: _path + ".targetRange",
                    expected: "(SelectionConfigV3EntryStrategyAllOfTargetRange | undefined)",
                    value: input.targetRange
                })) && $ao4(input.targetRange, _path + ".targetRange", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".targetRange",
                    expected: "(SelectionConfigV3EntryStrategyAllOfTargetRange | undefined)",
                    value: input.targetRange
                }));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => true;
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.minSum || "number" === typeof input.minSum && !Number.isNaN(input.minSum) && (0 <= input.minSum || $guard(_exceptionable, {
                    path: _path + ".minSum",
                    expected: "number (@minimum 0)",
                    value: input.minSum
                })) || $guard(_exceptionable, {
                    path: _path + ".minSum",
                    expected: "(number | undefined)",
                    value: input.minSum
                })) && (undefined === input.maxSum || "number" === typeof input.maxSum && !Number.isNaN(input.maxSum) && (0 <= input.maxSum || $guard(_exceptionable, {
                    path: _path + ".maxSum",
                    expected: "number (@minimum 0)",
                    value: input.maxSum
                })) || $guard(_exceptionable, {
                    path: _path + ".maxSum",
                    expected: "(number | undefined)",
                    value: input.maxSum
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SelectionConfigV3Entry",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SelectionConfigV3Entry",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: SelectionConfigV3Entry): string => {
        const $io1 = (input: any): boolean => ("EMBEDDINGS" === input.type || "SCORES" === input.type || "METADATA" === input.type || "PREDICTIONS" === input.type || "RANDOM" === input.type) && (undefined === input.task || "string" === typeof input.task && $is_custom("pattern_task", "string", "", input.task)) && (undefined === input.score || "string" === typeof input.score && 1 <= input.score.length && $is_custom("pattern_score", "string", "", input.score)) && (undefined === input.key || "string" === typeof input.key && 1 <= input.key.length) && (undefined === input.name || "CLASS_DISTRIBUTION" === input.name || "CATEGORY_COUNT" === input.name) && (undefined === input.datasetId || "string" === typeof input.datasetId && $is_custom("pattern_datasetId", "string", "", input.datasetId)) && (undefined === input.tagName || "string" === typeof input.tagName && 3 <= input.tagName.length && $is_custom("pattern_tagName", "string", "", input.tagName)) && (undefined === input.randomSeed || "number" === typeof input.randomSeed && Math.floor(input.randomSeed) === input.randomSeed && (-2147483648 <= input.randomSeed && input.randomSeed <= 2147483647)) && (undefined === input.categories || input.categories instanceof Set && (() => [...input.categories].every((elem: any) => "string" === typeof elem))());
        const $io2 = (input: any): boolean => ("DIVERSITY" === input.type || "WEIGHTS" === input.type || "THRESHOLD" === input.type || "BALANCE" === input.type || "SIMILARITY" === input.type || "TYPICALITY" === input.type) && (undefined === input.stopping_condition_minimum_distance || "number" === typeof input.stopping_condition_minimum_distance) && (undefined === input.threshold || "number" === typeof input.threshold) && (undefined === input.operation || "SMALLER" === input.operation || "SMALLER_EQUAL" === input.operation || "BIGGER" === input.operation || "BIGGER_EQUAL" === input.operation) && (undefined === input.target || "object" === typeof input.target && null !== input.target && false === Array.isArray(input.target) && $io3(input.target)) && (undefined === input.numNearestNeighbors || "number" === typeof input.numNearestNeighbors && Math.floor(input.numNearestNeighbors) === input.numNearestNeighbors && (-2147483648 <= input.numNearestNeighbors && input.numNearestNeighbors <= 2147483647) && 2 <= input.numNearestNeighbors) && (undefined === input.stoppingConditionMinimumTypicality || "number" === typeof input.stoppingConditionMinimumTypicality && 0 < input.stoppingConditionMinimumTypicality) && (undefined === input.strength || "number" === typeof input.strength && -1000000000 <= input.strength && 1000000000 >= input.strength) && (undefined === input.stoppingConditionMaxSum || "number" === typeof input.stoppingConditionMaxSum && 0 <= input.stoppingConditionMaxSum) && (undefined === input.targetRange || "object" === typeof input.targetRange && null !== input.targetRange && false === Array.isArray(input.targetRange) && $io4(input.targetRange));
        const $io3 = (input: any): boolean => true;
        const $io4 = (input: any): boolean => (undefined === input.minSum || "number" === typeof input.minSum && 0 <= input.minSum) && (undefined === input.maxSum || "number" === typeof input.maxSum && 0 <= input.maxSum);
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $tail = (typia.createAssertStringify as any).tail;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{"input":${$so1(input.input)},"strategy":${$so2(input.strategy)}}`;
        const $so1 = (input: any): any => `{${undefined === input.task ? "" : `"task":${undefined !== input.task ? $string(input.task) : undefined},`}${undefined === input.score ? "" : `"score":${undefined !== input.score ? $string(input.score) : undefined},`}${undefined === input.key ? "" : `"key":${undefined !== input.key ? $string(input.key) : undefined},`}${undefined === input.name ? "" : `"name":${undefined !== input.name ? (() => {
            if ("string" === typeof input.name)
                return $string(input.name);
            if ("string" === typeof input.name)
                return "\"" + input.name + "\"";
            $throws({
                expected: "(\"CATEGORY_COUNT\" | \"CLASS_DISTRIBUTION\" | undefined)",
                value: input.name
            });
        })() : undefined},`}${undefined === input.datasetId ? "" : `"datasetId":${undefined !== input.datasetId ? $string(input.datasetId) : undefined},`}${undefined === input.tagName ? "" : `"tagName":${undefined !== input.tagName ? $string(input.tagName) : undefined},`}${undefined === input.randomSeed ? "" : `"randomSeed":${undefined !== input.randomSeed ? input.randomSeed : undefined},`}${undefined === input.categories ? "" : `"categories":${undefined !== input.categories ? "{}" : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "(\"EMBEDDINGS\" | \"METADATA\" | \"PREDICTIONS\" | \"RANDOM\" | \"SCORES\")",
                value: input.type
            });
        })()}}`;
        const $so2 = (input: any): any => `{${undefined === input.stopping_condition_minimum_distance ? "" : `"stopping_condition_minimum_distance":${undefined !== input.stopping_condition_minimum_distance ? input.stopping_condition_minimum_distance : undefined},`}${undefined === input.threshold ? "" : `"threshold":${undefined !== input.threshold ? input.threshold : undefined},`}${undefined === input.operation ? "" : `"operation":${undefined !== input.operation ? (() => {
            if ("string" === typeof input.operation)
                return $string(input.operation);
            if ("string" === typeof input.operation)
                return "\"" + input.operation + "\"";
            $throws({
                expected: "(\"BIGGER\" | \"BIGGER_EQUAL\" | \"SMALLER\" | \"SMALLER_EQUAL\" | undefined)",
                value: input.operation
            });
        })() : undefined},`}${undefined === input.target ? "" : `"target":${undefined !== input.target ? "{}" : undefined},`}${undefined === input.numNearestNeighbors ? "" : `"numNearestNeighbors":${undefined !== input.numNearestNeighbors ? input.numNearestNeighbors : undefined},`}${undefined === input.stoppingConditionMinimumTypicality ? "" : `"stoppingConditionMinimumTypicality":${undefined !== input.stoppingConditionMinimumTypicality ? input.stoppingConditionMinimumTypicality : undefined},`}${undefined === input.strength ? "" : `"strength":${undefined !== input.strength ? input.strength : undefined},`}${undefined === input.stoppingConditionMaxSum ? "" : `"stoppingConditionMaxSum":${undefined !== input.stoppingConditionMaxSum ? input.stoppingConditionMaxSum : undefined},`}${undefined === input.targetRange ? "" : `"targetRange":${undefined !== input.targetRange ? $so4(input.targetRange) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "(\"BALANCE\" | \"DIVERSITY\" | \"SIMILARITY\" | \"THRESHOLD\" | \"TYPICALITY\" | \"WEIGHTS\")",
                value: input.type
            });
        })()}}`;
        const $so4 = (input: any): any => `{${$tail(`${undefined === input.minSum ? "" : `"minSum":${undefined !== input.minSum ? input.minSum : undefined},`}${undefined === input.maxSum ? "" : `"maxSum":${undefined !== input.maxSum ? input.maxSum : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const SelectionConfigV3EntryValidatorShape = {
    'input': SelectionConfigV3EntryInputValidator.defined(),
    'strategy': SelectionConfigV3EntryStrategyValidator.defined(),
};
export const SelectionConfigV3EntryValidator = yup.object().shape(SelectionConfigV3EntryValidatorShape);
export const SelectionConfigV3EntryStringifyShapeProperties: ObjectSchema['properties'] = {
    'input': SelectionConfigV3EntryInputStringifyShape,
    'strategy': SelectionConfigV3EntryStrategyStringifyShape,
};
export const SelectionConfigV3EntryStringifyShape: ObjectSchema = {
    title: 'SelectionConfigV3Entry Stringifier',
    type: 'object',
    properties: {
        ...SelectionConfigV3EntryStringifyShapeProperties
    },
    required: [
        'input',
        'strategy',
    ],
};
export const SelectionConfigV3EntryStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(SelectionConfigV3EntryStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const SelectionConfigV3EntryStringify: (data: SelectionConfigV3Entry) => string = SelectionConfigV3EntryStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function SelectionConfigV3EntryFromJSON(json: any): SelectionConfigV3Entry {
    return SelectionConfigV3EntryFromJSONTyped(json, false);
}
export function SelectionConfigV3EntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectionConfigV3Entry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'input': SelectionConfigV3EntryInputFromJSON(json['input']),
        'strategy': SelectionConfigV3EntryStrategyFromJSON(json['strategy']),
    };
}
export function SelectionConfigV3EntryToJSON(value?: SelectionConfigV3Entry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'input': SelectionConfigV3EntryInputToJSON(value.input),
        'strategy': SelectionConfigV3EntryStrategyToJSON(value.strategy),
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { PredictionTaskSchemaBase } from './PredictionTaskSchemaBase';
import { PredictionTaskSchemaBaseFromJSON, PredictionTaskSchemaBaseFromJSONTyped, PredictionTaskSchemaBaseToJSON, } from './PredictionTaskSchemaBase';
import type { PredictionTaskSchemaCategory } from './PredictionTaskSchemaCategory';
import { PredictionTaskSchemaCategoryFromJSON, PredictionTaskSchemaCategoryFromJSONTyped, PredictionTaskSchemaCategoryToJSON, } from './PredictionTaskSchemaCategory';
/*
causes circular dependendies
// .concat(PredictionTaskSchemaBaseValidator)

*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface PredictionTaskSchemaSimple
 *
 */
// create custom validators for the regexes
export interface PredictionTaskSchemaSimple extends PredictionTaskSchemaBase {
    /**
     * An array of the categories that exist for this prediction task. The id needs to be unique
     * #type Array<PredictionTaskSchemaCategory>
     * #memberof PredictionTaskSchemaSimple
        
        
         */
    categories: Array<PredictionTaskSchemaCategory>;
}
/**
 * Check if a given object implements the PredictionTaskSchemaSimple interface.
 */
export function instanceOfPredictionTaskSchemaSimple(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "categories" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { PredictionTaskSchemaBaseValidator, PredictionTaskSchemaBaseStringifyShape, PredictionTaskSchemaBaseStringifyShapeProperties, } from './PredictionTaskSchemaBase';
import { PredictionTaskSchemaCategoryValidator, PredictionTaskSchemaCategoryStringifyShape, PredictionTaskSchemaCategoryStringifyShapeProperties, } from './PredictionTaskSchemaCategory';
export const PredictionTaskSchemaSimpleTypiaAssertEquals = (input: any) => {
    return ((input: any): PredictionTaskSchemaSimple => {
        const __is = (input: any, _exceptionable: boolean = true): input is PredictionTaskSchemaSimple => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => Array.isArray(input.categories) && input.categories.every((elem: any, _index1: number) => "object" === typeof elem && null !== elem && $io1(elem, true && _exceptionable)) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name)) && "string" === typeof input.type && (3 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["categories", "name", "type"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.id && 0 <= input.id && ("string" === typeof input.name && 1 <= input.name.length) && (2 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["id", "name"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is PredictionTaskSchemaSimple => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index1 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) && $ao1(elem, _path + ".categories[" + _index1 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index1 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("string" === typeof input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "string",
                    value: input.type
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["categories", "name", "type"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.id && (0 <= input.id || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number (@minimum 0)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number",
                    value: input.id
                })) && ("string" === typeof input.name && (1 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 1)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["id", "name"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "PredictionTaskSchemaSimple",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "PredictionTaskSchemaSimple",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const PredictionTaskSchemaSimpleTypiaAssertStringify = (input: PredictionTaskSchemaSimple): string => {
    return ((input: any): string => { const assert = (input: any): PredictionTaskSchemaSimple => {
        const __is = (input: any): input is PredictionTaskSchemaSimple => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io1(elem)) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name)) && "string" === typeof input.type;
            const $io1 = (input: any): boolean => "number" === typeof input.id && !Number.isNaN(input.id) && 0 <= input.id && ("string" === typeof input.name && 1 <= input.name.length);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is PredictionTaskSchemaSimple => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index1 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) && $ao1(elem, _path + ".categories[" + _index1 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index1 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("string" === typeof input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "string",
                    value: input.type
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.id && !Number.isNaN(input.id) && (0 <= input.id || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number (@minimum 0)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number",
                    value: input.id
                })) && ("string" === typeof input.name && (1 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 1)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "PredictionTaskSchemaSimple",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "PredictionTaskSchemaSimple",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: PredictionTaskSchemaSimple): string => {
        const $io1 = (input: any): boolean => "number" === typeof input.id && 0 <= input.id && ("string" === typeof input.name && 1 <= input.name.length);
        const $string = (typia.createAssertStringify as any).string;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{"categories":${`[${input.categories.map((elem: any) => `{"id":${(elem as any).id},"name":${$string((elem as any).name)}}`).join(",")}]`},"name":${$string(input.name)},"type":${$string(input.type)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const PredictionTaskSchemaSimpleValidatorShape = {
    'categories': yup.array().of(PredictionTaskSchemaCategoryValidator).defined(),
};
export const PredictionTaskSchemaSimpleValidator = yup.object().shape(PredictionTaskSchemaSimpleValidatorShape).concat(PredictionTaskSchemaBaseValidator);
export const PredictionTaskSchemaSimpleStringifyShapeProperties: ObjectSchema['properties'] = {
    'categories': {
        type: 'array',
        items: {
            PredictionTaskSchemaCategoryStringifyShape
        }
    },
};
export const PredictionTaskSchemaSimpleStringifyShape: ObjectSchema = {
    title: 'PredictionTaskSchemaSimple Stringifier',
    type: 'object',
    properties: {
        ...PredictionTaskSchemaBaseStringifyShapeProperties,
        ...PredictionTaskSchemaSimpleStringifyShapeProperties
    },
    required: [
        ...(PredictionTaskSchemaBaseStringifyShape.required ? PredictionTaskSchemaBaseStringifyShape.required : []),
        'categories',
    ],
};
export const PredictionTaskSchemaSimpleStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(PredictionTaskSchemaSimpleStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const PredictionTaskSchemaSimpleStringify: (data: PredictionTaskSchemaSimple) => string = PredictionTaskSchemaSimpleStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function PredictionTaskSchemaSimpleFromJSON(json: any): PredictionTaskSchemaSimple {
    return PredictionTaskSchemaSimpleFromJSONTyped(json, false);
}
export function PredictionTaskSchemaSimpleFromJSONTyped(json: any, ignoreDiscriminator: boolean): PredictionTaskSchemaSimple {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
    if (!ignoreDiscriminator) {
    }
*/
    return {
        ...PredictionTaskSchemaBaseFromJSONTyped(json, ignoreDiscriminator),
        'categories': ((json['categories'] as Array<any> || []).map(PredictionTaskSchemaCategoryFromJSON)),
    };
}
export function PredictionTaskSchemaSimpleToJSON(value?: PredictionTaskSchemaSimple | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PredictionTaskSchemaBaseToJSON(value),
        'categories': ((value.categories as Array<any> || []).map(PredictionTaskSchemaCategoryToJSON)),
    };
}

// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import type { ApiErrorResponse, CreateEntityResponse, DatasetCreateRequest, DatasetData, DatasetDataEnriched, DatasetUpdateRequest, JobStatusMeta, } from '../models';
import { ApiErrorResponseFromJSON, ApiErrorResponseToJSON, ApiErrorResponseValidator, CreateEntityResponseFromJSON, CreateEntityResponseToJSON, CreateEntityResponseValidator, DatasetCreateRequestFromJSON, DatasetCreateRequestToJSON, DatasetCreateRequestValidator, DatasetDataFromJSON, DatasetDataToJSON, DatasetDataValidator, DatasetDataEnrichedFromJSON, DatasetDataEnrichedToJSON, DatasetDataEnrichedValidator, DatasetUpdateRequestFromJSON, DatasetUpdateRequestToJSON, DatasetUpdateRequestValidator, JobStatusMetaFromJSON, JobStatusMetaToJSON, JobStatusMetaValidator, } from '../models';
import * as models from '../models';
/* LIGHTLY CUSTOM START -> -> moved from bottom to top:  apiMustache */
/* LIGHTLY CUSTOM END -> moved from bottom to top: apiMustache */
export interface CreateDatasetRequest {
    datasetCreateRequest: models.DatasetCreateRequest;
}
/* LIGHTLY CUSTOM START -> apis */
export const CreateDatasetRequestValidatorShape = {
    'datasetCreateRequest': models.DatasetCreateRequestValidator.defined(),
};
export const CreateDatasetRequestValidator = yup.object().shape(CreateDatasetRequestValidatorShape);
export interface DeleteDatasetByIdRequest {
    datasetId: string;
    force?: boolean;
}
/* LIGHTLY CUSTOM START -> apis */
export const DeleteDatasetByIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'force': yup.boolean(),
};
export const DeleteDatasetByIdRequestValidator = yup.object().shape(DeleteDatasetByIdRequestValidatorShape);
export interface GetChildrenOfDatasetIdRequest {
    datasetId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetChildrenOfDatasetIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetChildrenOfDatasetIdRequestValidator = yup.object().shape(GetChildrenOfDatasetIdRequestValidatorShape);
export interface GetDatasetByIdRequest {
    datasetId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetDatasetByIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetDatasetByIdRequestValidator = yup.object().shape(GetDatasetByIdRequestValidatorShape);
export interface GetDatasetsRequest {
    shared?: boolean;
    excludePlayground?: boolean;
    getAssetsOfTeam?: boolean;
    getAssetsOfTeamInclusiveSelf?: boolean;
    pageSize?: number;
    pageOffset?: number;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetDatasetsRequestValidatorShape = {
    'shared': yup.boolean(),
    'excludePlayground': yup.boolean(),
    'getAssetsOfTeam': yup.boolean(),
    'getAssetsOfTeamInclusiveSelf': yup.boolean(),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
};
export const GetDatasetsRequestValidator = yup.object().shape(GetDatasetsRequestValidatorShape);
export interface GetDatasetsEnrichedRequest {
    shared?: boolean;
    limit?: number;
    excludePlayground?: boolean;
    getAssetsOfTeam?: boolean;
    getAssetsOfTeamInclusiveSelf?: boolean;
    pageSize?: number;
    pageOffset?: number;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetDatasetsEnrichedRequestValidatorShape = {
    'shared': yup.boolean(),
    'limit': yup.number().integer(),
    'excludePlayground': yup.boolean(),
    'getAssetsOfTeam': yup.boolean(),
    'getAssetsOfTeamInclusiveSelf': yup.boolean(),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
};
export const GetDatasetsEnrichedRequestValidator = yup.object().shape(GetDatasetsEnrichedRequestValidatorShape);
export interface GetDatasetsEnrichedQueryByIdsRequest {
    datasetIds: models.MongoObjectIDs;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetDatasetsEnrichedQueryByIdsRequestValidatorShape = {
    'datasetIds': models.MongoObjectIDsValidator.defined(),
};
export const GetDatasetsEnrichedQueryByIdsRequestValidator = yup.object().shape(GetDatasetsEnrichedQueryByIdsRequestValidatorShape);
export interface GetDatasetsEnrichedQueryByNameRequest {
    datasetName: string;
    shared?: boolean;
    exact?: boolean;
    excludePlayground?: boolean;
    getAssetsOfTeam?: boolean;
    getAssetsOfTeamInclusiveSelf?: boolean;
    pageSize?: number;
    pageOffset?: number;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetDatasetsEnrichedQueryByNameRequestValidatorShape = {
    'datasetName': yup.string().matches(/^[a-zA-Z0-9 _-]+$/).min(1).defined(),
    'shared': yup.boolean(),
    'exact': yup.boolean(),
    'excludePlayground': yup.boolean(),
    'getAssetsOfTeam': yup.boolean(),
    'getAssetsOfTeamInclusiveSelf': yup.boolean(),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
};
export const GetDatasetsEnrichedQueryByNameRequestValidator = yup.object().shape(GetDatasetsEnrichedQueryByNameRequestValidatorShape);
export interface GetDatasetsQueryByNameRequest {
    datasetName: string;
    shared?: boolean;
    exact?: boolean;
    excludePlayground?: boolean;
    getAssetsOfTeam?: boolean;
    getAssetsOfTeamInclusiveSelf?: boolean;
    pageSize?: number;
    pageOffset?: number;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetDatasetsQueryByNameRequestValidatorShape = {
    'datasetName': yup.string().matches(/^[a-zA-Z0-9 _-]+$/).min(1).defined(),
    'shared': yup.boolean(),
    'exact': yup.boolean(),
    'excludePlayground': yup.boolean(),
    'getAssetsOfTeam': yup.boolean(),
    'getAssetsOfTeamInclusiveSelf': yup.boolean(),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
};
export const GetDatasetsQueryByNameRequestValidator = yup.object().shape(GetDatasetsQueryByNameRequestValidatorShape);
export interface RegisterDatasetUploadByIdRequest {
    jobStatusMeta: models.JobStatusMeta;
    datasetId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const RegisterDatasetUploadByIdRequestValidatorShape = {
    'jobStatusMeta': models.JobStatusMetaValidator.defined(),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const RegisterDatasetUploadByIdRequestValidator = yup.object().shape(RegisterDatasetUploadByIdRequestValidatorShape);
export interface UpdateDatasetByIdRequest {
    datasetUpdateRequest: models.DatasetUpdateRequest;
    datasetId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const UpdateDatasetByIdRequestValidatorShape = {
    'datasetUpdateRequest': models.DatasetUpdateRequestValidator.defined(),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const UpdateDatasetByIdRequestValidator = yup.object().shape(UpdateDatasetByIdRequestValidatorShape);
export interface CreateDatasetRequestParams {
}
export function CreateDatasetRequestParamsSanitizer(json: any): CreateDatasetRequestParams {
    return {};
}
export const CreateDatasetRequestParamsValidatorShape = {};
export const CreateDatasetRequestParamsValidator = yup.object().shape(CreateDatasetRequestParamsValidatorShape);
export interface DeleteDatasetByIdRequestParams {
    datasetId: string;
}
export function DeleteDatasetByIdRequestParamsSanitizer(json: any): DeleteDatasetByIdRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const DeleteDatasetByIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const DeleteDatasetByIdRequestParamsValidator = yup.object().shape(DeleteDatasetByIdRequestParamsValidatorShape);
export interface GetChildrenOfDatasetIdRequestParams {
    datasetId: string;
}
export function GetChildrenOfDatasetIdRequestParamsSanitizer(json: any): GetChildrenOfDatasetIdRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const GetChildrenOfDatasetIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetChildrenOfDatasetIdRequestParamsValidator = yup.object().shape(GetChildrenOfDatasetIdRequestParamsValidatorShape);
export interface GetDatasetByIdRequestParams {
    datasetId: string;
}
export function GetDatasetByIdRequestParamsSanitizer(json: any): GetDatasetByIdRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const GetDatasetByIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetDatasetByIdRequestParamsValidator = yup.object().shape(GetDatasetByIdRequestParamsValidatorShape);
export interface GetDatasetsRequestParams {
}
export function GetDatasetsRequestParamsSanitizer(json: any): GetDatasetsRequestParams {
    return {};
}
export const GetDatasetsRequestParamsValidatorShape = {};
export const GetDatasetsRequestParamsValidator = yup.object().shape(GetDatasetsRequestParamsValidatorShape);
export interface GetDatasetsEnrichedRequestParams {
}
export function GetDatasetsEnrichedRequestParamsSanitizer(json: any): GetDatasetsEnrichedRequestParams {
    return {};
}
export const GetDatasetsEnrichedRequestParamsValidatorShape = {};
export const GetDatasetsEnrichedRequestParamsValidator = yup.object().shape(GetDatasetsEnrichedRequestParamsValidatorShape);
export interface GetDatasetsEnrichedQueryByIdsRequestParams {
}
export function GetDatasetsEnrichedQueryByIdsRequestParamsSanitizer(json: any): GetDatasetsEnrichedQueryByIdsRequestParams {
    return {};
}
export const GetDatasetsEnrichedQueryByIdsRequestParamsValidatorShape = {};
export const GetDatasetsEnrichedQueryByIdsRequestParamsValidator = yup.object().shape(GetDatasetsEnrichedQueryByIdsRequestParamsValidatorShape);
export interface GetDatasetsEnrichedQueryByNameRequestParams {
    datasetName: string;
}
export function GetDatasetsEnrichedQueryByNameRequestParamsSanitizer(json: any): GetDatasetsEnrichedQueryByNameRequestParams {
    return {
        'datasetName': json['datasetName'],
    };
}
export const GetDatasetsEnrichedQueryByNameRequestParamsValidatorShape = {
    'datasetName': yup.string().matches(/^[a-zA-Z0-9 _-]+$/).min(1).defined(),
};
export const GetDatasetsEnrichedQueryByNameRequestParamsValidator = yup.object().shape(GetDatasetsEnrichedQueryByNameRequestParamsValidatorShape);
export interface GetDatasetsQueryByNameRequestParams {
    datasetName: string;
}
export function GetDatasetsQueryByNameRequestParamsSanitizer(json: any): GetDatasetsQueryByNameRequestParams {
    return {
        'datasetName': json['datasetName'],
    };
}
export const GetDatasetsQueryByNameRequestParamsValidatorShape = {
    'datasetName': yup.string().matches(/^[a-zA-Z0-9 _-]+$/).min(1).defined(),
};
export const GetDatasetsQueryByNameRequestParamsValidator = yup.object().shape(GetDatasetsQueryByNameRequestParamsValidatorShape);
export interface RegisterDatasetUploadByIdRequestParams {
    datasetId: string;
}
export function RegisterDatasetUploadByIdRequestParamsSanitizer(json: any): RegisterDatasetUploadByIdRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const RegisterDatasetUploadByIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const RegisterDatasetUploadByIdRequestParamsValidator = yup.object().shape(RegisterDatasetUploadByIdRequestParamsValidatorShape);
export interface UpdateDatasetByIdRequestParams {
    datasetId: string;
}
export function UpdateDatasetByIdRequestParamsSanitizer(json: any): UpdateDatasetByIdRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const UpdateDatasetByIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const UpdateDatasetByIdRequestParamsValidator = yup.object().shape(UpdateDatasetByIdRequestParamsValidatorShape);
export interface CreateDatasetRequestQuery {
}
export function CreateDatasetRequestQuerySanitizer(json: any): CreateDatasetRequestQuery {
    return {};
}
export const CreateDatasetRequestQueryValidatorShape = {};
export const CreateDatasetRequestQueryValidator = yup.object().shape(CreateDatasetRequestQueryValidatorShape);
export interface DeleteDatasetByIdRequestQuery {
    force?: boolean;
}
export function DeleteDatasetByIdRequestQuerySanitizer(json: any): DeleteDatasetByIdRequestQuery {
    return {
        'force': json['force'],
    };
}
export const DeleteDatasetByIdRequestQueryValidatorShape = {
    'force': yup.boolean(),
};
export const DeleteDatasetByIdRequestQueryValidator = yup.object().shape(DeleteDatasetByIdRequestQueryValidatorShape);
export interface GetChildrenOfDatasetIdRequestQuery {
}
export function GetChildrenOfDatasetIdRequestQuerySanitizer(json: any): GetChildrenOfDatasetIdRequestQuery {
    return {};
}
export const GetChildrenOfDatasetIdRequestQueryValidatorShape = {};
export const GetChildrenOfDatasetIdRequestQueryValidator = yup.object().shape(GetChildrenOfDatasetIdRequestQueryValidatorShape);
export interface GetDatasetByIdRequestQuery {
}
export function GetDatasetByIdRequestQuerySanitizer(json: any): GetDatasetByIdRequestQuery {
    return {};
}
export const GetDatasetByIdRequestQueryValidatorShape = {};
export const GetDatasetByIdRequestQueryValidator = yup.object().shape(GetDatasetByIdRequestQueryValidatorShape);
export interface GetDatasetsRequestQuery {
    shared?: boolean;
    excludePlayground?: boolean;
    getAssetsOfTeam?: boolean;
    getAssetsOfTeamInclusiveSelf?: boolean;
    pageSize?: number;
    pageOffset?: number;
}
export function GetDatasetsRequestQuerySanitizer(json: any): GetDatasetsRequestQuery {
    return {
        'shared': json['shared'],
        'excludePlayground': json['excludePlayground'],
        'getAssetsOfTeam': json['getAssetsOfTeam'],
        'getAssetsOfTeamInclusiveSelf': json['getAssetsOfTeamInclusiveSelf'],
        'pageSize': json['pageSize'],
        'pageOffset': json['pageOffset'],
    };
}
export const GetDatasetsRequestQueryValidatorShape = {
    'shared': yup.boolean(),
    'excludePlayground': yup.boolean(),
    'getAssetsOfTeam': yup.boolean(),
    'getAssetsOfTeamInclusiveSelf': yup.boolean(),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
};
export const GetDatasetsRequestQueryValidator = yup.object().shape(GetDatasetsRequestQueryValidatorShape);
export interface GetDatasetsEnrichedRequestQuery {
    shared?: boolean;
    limit?: number;
    excludePlayground?: boolean;
    getAssetsOfTeam?: boolean;
    getAssetsOfTeamInclusiveSelf?: boolean;
    pageSize?: number;
    pageOffset?: number;
}
export function GetDatasetsEnrichedRequestQuerySanitizer(json: any): GetDatasetsEnrichedRequestQuery {
    return {
        'shared': json['shared'],
        'limit': json['limit'],
        'excludePlayground': json['excludePlayground'],
        'getAssetsOfTeam': json['getAssetsOfTeam'],
        'getAssetsOfTeamInclusiveSelf': json['getAssetsOfTeamInclusiveSelf'],
        'pageSize': json['pageSize'],
        'pageOffset': json['pageOffset'],
    };
}
export const GetDatasetsEnrichedRequestQueryValidatorShape = {
    'shared': yup.boolean(),
    'limit': yup.number().integer(),
    'excludePlayground': yup.boolean(),
    'getAssetsOfTeam': yup.boolean(),
    'getAssetsOfTeamInclusiveSelf': yup.boolean(),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
};
export const GetDatasetsEnrichedRequestQueryValidator = yup.object().shape(GetDatasetsEnrichedRequestQueryValidatorShape);
export interface GetDatasetsEnrichedQueryByIdsRequestQuery {
    datasetIds: models.MongoObjectIDs;
}
export function GetDatasetsEnrichedQueryByIdsRequestQuerySanitizer(json: any): GetDatasetsEnrichedQueryByIdsRequestQuery {
    return {
        'datasetIds': json['datasetIds'],
    };
}
export const GetDatasetsEnrichedQueryByIdsRequestQueryValidatorShape = {
    'datasetIds': models.MongoObjectIDsValidator.defined(),
};
export const GetDatasetsEnrichedQueryByIdsRequestQueryValidator = yup.object().shape(GetDatasetsEnrichedQueryByIdsRequestQueryValidatorShape);
export interface GetDatasetsEnrichedQueryByNameRequestQuery {
    shared?: boolean;
    exact?: boolean;
    excludePlayground?: boolean;
    getAssetsOfTeam?: boolean;
    getAssetsOfTeamInclusiveSelf?: boolean;
    pageSize?: number;
    pageOffset?: number;
}
export function GetDatasetsEnrichedQueryByNameRequestQuerySanitizer(json: any): GetDatasetsEnrichedQueryByNameRequestQuery {
    return {
        'shared': json['shared'],
        'exact': json['exact'],
        'excludePlayground': json['excludePlayground'],
        'getAssetsOfTeam': json['getAssetsOfTeam'],
        'getAssetsOfTeamInclusiveSelf': json['getAssetsOfTeamInclusiveSelf'],
        'pageSize': json['pageSize'],
        'pageOffset': json['pageOffset'],
    };
}
export const GetDatasetsEnrichedQueryByNameRequestQueryValidatorShape = {
    'shared': yup.boolean(),
    'exact': yup.boolean(),
    'excludePlayground': yup.boolean(),
    'getAssetsOfTeam': yup.boolean(),
    'getAssetsOfTeamInclusiveSelf': yup.boolean(),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
};
export const GetDatasetsEnrichedQueryByNameRequestQueryValidator = yup.object().shape(GetDatasetsEnrichedQueryByNameRequestQueryValidatorShape);
export interface GetDatasetsQueryByNameRequestQuery {
    shared?: boolean;
    exact?: boolean;
    excludePlayground?: boolean;
    getAssetsOfTeam?: boolean;
    getAssetsOfTeamInclusiveSelf?: boolean;
    pageSize?: number;
    pageOffset?: number;
}
export function GetDatasetsQueryByNameRequestQuerySanitizer(json: any): GetDatasetsQueryByNameRequestQuery {
    return {
        'shared': json['shared'],
        'exact': json['exact'],
        'excludePlayground': json['excludePlayground'],
        'getAssetsOfTeam': json['getAssetsOfTeam'],
        'getAssetsOfTeamInclusiveSelf': json['getAssetsOfTeamInclusiveSelf'],
        'pageSize': json['pageSize'],
        'pageOffset': json['pageOffset'],
    };
}
export const GetDatasetsQueryByNameRequestQueryValidatorShape = {
    'shared': yup.boolean(),
    'exact': yup.boolean(),
    'excludePlayground': yup.boolean(),
    'getAssetsOfTeam': yup.boolean(),
    'getAssetsOfTeamInclusiveSelf': yup.boolean(),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
};
export const GetDatasetsQueryByNameRequestQueryValidator = yup.object().shape(GetDatasetsQueryByNameRequestQueryValidatorShape);
export interface RegisterDatasetUploadByIdRequestQuery {
}
export function RegisterDatasetUploadByIdRequestQuerySanitizer(json: any): RegisterDatasetUploadByIdRequestQuery {
    return {};
}
export const RegisterDatasetUploadByIdRequestQueryValidatorShape = {};
export const RegisterDatasetUploadByIdRequestQueryValidator = yup.object().shape(RegisterDatasetUploadByIdRequestQueryValidatorShape);
export interface UpdateDatasetByIdRequestQuery {
}
export function UpdateDatasetByIdRequestQuerySanitizer(json: any): UpdateDatasetByIdRequestQuery {
    return {};
}
export const UpdateDatasetByIdRequestQueryValidatorShape = {};
export const UpdateDatasetByIdRequestQueryValidator = yup.object().shape(UpdateDatasetByIdRequestQueryValidatorShape);
export type CreateDatasetRequestBody = DatasetCreateRequest;
export const CreateDatasetRequestBodySanitizer = DatasetCreateRequestFromJSON;
export const CreateDatasetRequestBodyValidator = DatasetCreateRequestValidator;
export const CreateDatasetTypiaAssertEquals = (input: any) => {
    return ((input: any): CreateDatasetRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is CreateDatasetRequestBody => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.name && 3 <= input.name.length && $is_custom("pattern_name", "string", "", input.name) && (undefined === input.type || "Crops" === input.type || "Images" === input.type || "Videos" === input.type) && (undefined === input.imgType || "full" === input.imgType || "thumbnail" === input.imgType || "meta" === input.imgType) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator) && (undefined === input.parentDatasetId || "string" === typeof input.parentDatasetId && $is_custom("pattern_parentDatasetId", "string", "", input.parentDatasetId)) && (1 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["name", "type", "imgType", "creator", "parentDatasetId"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateDatasetRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name && (3 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 3)",
                    value: input.name
                })) && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (undefined === input.type || "Crops" === input.type || "Images" === input.type || "Videos" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "(\"Crops\" | \"Images\" | \"Videos\" | undefined)",
                    value: input.type
                })) && (undefined === input.imgType || "full" === input.imgType || "thumbnail" === input.imgType || "meta" === input.imgType || $guard(_exceptionable, {
                    path: _path + ".imgType",
                    expected: "(\"full\" | \"meta\" | \"thumbnail\" | undefined)",
                    value: input.imgType
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                })) && (undefined === input.parentDatasetId || "string" === typeof input.parentDatasetId && ($is_custom("pattern_parentDatasetId", "string", "", input.parentDatasetId) || $guard(_exceptionable, {
                    path: _path + ".parentDatasetId",
                    expected: "string (@pattern_parentDatasetId)",
                    value: input.parentDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".parentDatasetId",
                    expected: "(string | undefined)",
                    value: input.parentDatasetId
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["name", "type", "imgType", "creator", "parentDatasetId"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DatasetCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DatasetCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const CreateDatasetTypiaAssertStringify = (input: CreateDatasetRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): CreateDatasetRequestBody => {
        const __is = (input: any): input is CreateDatasetRequestBody => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "string" === typeof input.name && 3 <= input.name.length && $is_custom("pattern_name", "string", "", input.name) && (undefined === input.type || "Crops" === input.type || "Images" === input.type || "Videos" === input.type) && (undefined === input.imgType || "full" === input.imgType || "thumbnail" === input.imgType || "meta" === input.imgType) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator) && (undefined === input.parentDatasetId || "string" === typeof input.parentDatasetId && $is_custom("pattern_parentDatasetId", "string", "", input.parentDatasetId));
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateDatasetRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name && (3 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 3)",
                    value: input.name
                })) && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (undefined === input.type || "Crops" === input.type || "Images" === input.type || "Videos" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "(\"Crops\" | \"Images\" | \"Videos\" | undefined)",
                    value: input.type
                })) && (undefined === input.imgType || "full" === input.imgType || "thumbnail" === input.imgType || "meta" === input.imgType || $guard(_exceptionable, {
                    path: _path + ".imgType",
                    expected: "(\"full\" | \"meta\" | \"thumbnail\" | undefined)",
                    value: input.imgType
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                })) && (undefined === input.parentDatasetId || "string" === typeof input.parentDatasetId && ($is_custom("pattern_parentDatasetId", "string", "", input.parentDatasetId) || $guard(_exceptionable, {
                    path: _path + ".parentDatasetId",
                    expected: "string (@pattern_parentDatasetId)",
                    value: input.parentDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".parentDatasetId",
                    expected: "(string | undefined)",
                    value: input.parentDatasetId
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DatasetCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DatasetCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: CreateDatasetRequestBody): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.type ? "" : `"type":${undefined !== input.type ? (() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "(\"Crops\" | \"Images\" | \"Videos\" | undefined)",
                value: input.type
            });
        })() : undefined},`}${undefined === input.imgType ? "" : `"imgType":${undefined !== input.imgType ? (() => {
            if ("string" === typeof input.imgType)
                return $string(input.imgType);
            if ("string" === typeof input.imgType)
                return "\"" + input.imgType + "\"";
            $throws({
                expected: "(\"full\" | \"meta\" | \"thumbnail\" | undefined)",
                value: input.imgType
            });
        })() : undefined},`}${undefined === input.creator ? "" : `"creator":${undefined !== input.creator ? (() => {
            if ("string" === typeof input.creator)
                return $string(input.creator);
            if ("string" === typeof input.creator)
                return "\"" + input.creator + "\"";
            $throws({
                expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                value: input.creator
            });
        })() : undefined},`}${undefined === input.parentDatasetId ? "" : `"parentDatasetId":${undefined !== input.parentDatasetId ? $string(input.parentDatasetId) : undefined},`}"name":${$string(input.name)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export type RegisterDatasetUploadByIdRequestBody = JobStatusMeta;
export const RegisterDatasetUploadByIdRequestBodySanitizer = JobStatusMetaFromJSON;
export const RegisterDatasetUploadByIdRequestBodyValidator = JobStatusMetaValidator;
export const RegisterDatasetUploadByIdTypiaAssertEquals = (input: any) => {
    return ((input: any): RegisterDatasetUploadByIdRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is RegisterDatasetUploadByIdRequestBody => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.total && Math.floor(input.total) === input.total && (-2147483648 <= input.total && input.total <= 2147483647) && ("number" === typeof input.processed && Math.floor(input.processed) === input.processed && (-2147483648 <= input.processed && input.processed <= 2147483647)) && (undefined === input.uploadMethod || "USER_WEBAPP" === input.uploadMethod || "USER_PIP" === input.uploadMethod || "INTERNAL" === input.uploadMethod) && (undefined === input.isRegistered || "boolean" === typeof input.isRegistered) && (2 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["total", "processed", "uploadMethod", "isRegistered"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is RegisterDatasetUploadByIdRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.total && (Math.floor(input.total) === input.total || $guard(_exceptionable, {
                    path: _path + ".total",
                    expected: "number (@type int)",
                    value: input.total
                })) && (-2147483648 <= input.total && input.total <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".total",
                    expected: "number (@type int)",
                    value: input.total
                })) || $guard(_exceptionable, {
                    path: _path + ".total",
                    expected: "number",
                    value: input.total
                })) && ("number" === typeof input.processed && (Math.floor(input.processed) === input.processed || $guard(_exceptionable, {
                    path: _path + ".processed",
                    expected: "number (@type int)",
                    value: input.processed
                })) && (-2147483648 <= input.processed && input.processed <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".processed",
                    expected: "number (@type int)",
                    value: input.processed
                })) || $guard(_exceptionable, {
                    path: _path + ".processed",
                    expected: "number",
                    value: input.processed
                })) && (undefined === input.uploadMethod || "USER_WEBAPP" === input.uploadMethod || "USER_PIP" === input.uploadMethod || "INTERNAL" === input.uploadMethod || $guard(_exceptionable, {
                    path: _path + ".uploadMethod",
                    expected: "(\"INTERNAL\" | \"USER_PIP\" | \"USER_WEBAPP\" | undefined)",
                    value: input.uploadMethod
                })) && (undefined === input.isRegistered || "boolean" === typeof input.isRegistered || $guard(_exceptionable, {
                    path: _path + ".isRegistered",
                    expected: "(boolean | undefined)",
                    value: input.isRegistered
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["total", "processed", "uploadMethod", "isRegistered"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "JobStatusMeta",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "JobStatusMeta",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const RegisterDatasetUploadByIdTypiaAssertStringify = (input: RegisterDatasetUploadByIdRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): RegisterDatasetUploadByIdRequestBody => {
        const __is = (input: any): input is RegisterDatasetUploadByIdRequestBody => {
            const $io0 = (input: any): boolean => "number" === typeof input.total && !Number.isNaN(input.total) && Math.floor(input.total) === input.total && (-2147483648 <= input.total && input.total <= 2147483647) && ("number" === typeof input.processed && !Number.isNaN(input.processed) && Math.floor(input.processed) === input.processed && (-2147483648 <= input.processed && input.processed <= 2147483647)) && (undefined === input.uploadMethod || "USER_WEBAPP" === input.uploadMethod || "USER_PIP" === input.uploadMethod || "INTERNAL" === input.uploadMethod) && (undefined === input.isRegistered || "boolean" === typeof input.isRegistered);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is RegisterDatasetUploadByIdRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.total && !Number.isNaN(input.total) && (Math.floor(input.total) === input.total || $guard(_exceptionable, {
                    path: _path + ".total",
                    expected: "number (@type int)",
                    value: input.total
                })) && (-2147483648 <= input.total && input.total <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".total",
                    expected: "number (@type int)",
                    value: input.total
                })) || $guard(_exceptionable, {
                    path: _path + ".total",
                    expected: "number",
                    value: input.total
                })) && ("number" === typeof input.processed && !Number.isNaN(input.processed) && (Math.floor(input.processed) === input.processed || $guard(_exceptionable, {
                    path: _path + ".processed",
                    expected: "number (@type int)",
                    value: input.processed
                })) && (-2147483648 <= input.processed && input.processed <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".processed",
                    expected: "number (@type int)",
                    value: input.processed
                })) || $guard(_exceptionable, {
                    path: _path + ".processed",
                    expected: "number",
                    value: input.processed
                })) && (undefined === input.uploadMethod || "USER_WEBAPP" === input.uploadMethod || "USER_PIP" === input.uploadMethod || "INTERNAL" === input.uploadMethod || $guard(_exceptionable, {
                    path: _path + ".uploadMethod",
                    expected: "(\"INTERNAL\" | \"USER_PIP\" | \"USER_WEBAPP\" | undefined)",
                    value: input.uploadMethod
                })) && (undefined === input.isRegistered || "boolean" === typeof input.isRegistered || $guard(_exceptionable, {
                    path: _path + ".isRegistered",
                    expected: "(boolean | undefined)",
                    value: input.isRegistered
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "JobStatusMeta",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "JobStatusMeta",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: RegisterDatasetUploadByIdRequestBody): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $so0 = (input: any): any => `{${undefined === input.uploadMethod ? "" : `"uploadMethod":${undefined !== input.uploadMethod ? (() => {
            if ("string" === typeof input.uploadMethod)
                return $string(input.uploadMethod);
            if ("string" === typeof input.uploadMethod)
                return "\"" + input.uploadMethod + "\"";
            $throws({
                expected: "(\"INTERNAL\" | \"USER_PIP\" | \"USER_WEBAPP\" | undefined)",
                value: input.uploadMethod
            });
        })() : undefined},`}${undefined === input.isRegistered ? "" : `"isRegistered":${undefined !== input.isRegistered ? input.isRegistered : undefined},`}"total":${input.total},"processed":${input.processed}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export type UpdateDatasetByIdRequestBody = DatasetUpdateRequest;
export const UpdateDatasetByIdRequestBodySanitizer = DatasetUpdateRequestFromJSON;
export const UpdateDatasetByIdRequestBodyValidator = DatasetUpdateRequestValidator;
export const UpdateDatasetByIdTypiaAssertEquals = (input: any) => {
    return ((input: any): UpdateDatasetByIdRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is UpdateDatasetByIdRequestBody => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.name && 3 <= input.name.length && $is_custom("pattern_name", "string", "", input.name) && (1 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["name"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is UpdateDatasetByIdRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name && (3 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 3)",
                    value: input.name
                })) && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["name"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DatasetUpdateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DatasetUpdateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const UpdateDatasetByIdTypiaAssertStringify = (input: UpdateDatasetByIdRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): UpdateDatasetByIdRequestBody => {
        const __is = (input: any): input is UpdateDatasetByIdRequestBody => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            return "object" === typeof input && null !== input && ("string" === typeof (input as any).name && 3 <= (input as any).name.length && $is_custom("pattern_name", "string", "", (input as any).name));
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is UpdateDatasetByIdRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => "string" === typeof input.name && (3 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 3)",
                    value: input.name
                })) && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                });
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DatasetUpdateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DatasetUpdateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: UpdateDatasetByIdRequestBody): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        return `{"name":${$string((input as any).name)}}`;
    }; return stringify(assert(input)); })(input);
};
/*
HANDLERS:



/v1/datasets

export const createDatasetFN: RequestHandlerAsync<
    CreateDatasetRequestParams,
    CreateEntityResponse | ApiErrorResponse,
    CreateDatasetRequestBody,
    CreateDatasetRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, CreateDatasetRequestBodySanitizer, CreateDatasetRequestBodyValidator);
    const {
        
    } =  await validateAndSanitize(req.params, CreateDatasetRequestParamsSanitizer, CreateDatasetRequestParamsValidator);
    const {
        
    } = await validateAndSanitize(req.query, CreateDatasetRequestQuerySanitizer, CreateDatasetRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        CreateEntityResponseStringify,
        validateAndRespond({ id, ...rest }, CreateEntityResponseFromJSON, CreateEntityResponseValidator),
    );
}



/v1/datasets/{datasetId}

export const deleteDatasetByIdFN: RequestHandlerAsync<
    DeleteDatasetByIdRequestParams,
     | ApiErrorResponse,
    DeleteDatasetByIdRequestBody,
    DeleteDatasetByIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, DeleteDatasetByIdRequestBodySanitizer, DeleteDatasetByIdRequestBodyValidator);
    const {
        datasetId,
        
    } =  await validateAndSanitize(req.params, DeleteDatasetByIdRequestParamsSanitizer, DeleteDatasetByIdRequestParamsValidator);
    const {
        
        force,
    } = await validateAndSanitize(req.query, DeleteDatasetByIdRequestQuerySanitizer, DeleteDatasetByIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        Stringify,
        validateAndRespond({ id, ...rest }, FromJSON, Validator),
    );
}



/v1/datasets/{datasetId}/children
const DatasetDataArrayStringify = fastJSONArrayify(DatasetDataStringifyShape);
export const getChildrenOfDatasetIdFN: RequestHandlerAsync<
    GetChildrenOfDatasetIdRequestParams,
    DatasetData[] | ApiErrorResponse,
    GetChildrenOfDatasetIdRequestBody,
    GetChildrenOfDatasetIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetChildrenOfDatasetIdRequestBodySanitizer, GetChildrenOfDatasetIdRequestBodyValidator);
    const {
        datasetId,
    } =  await validateAndSanitize(req.params, GetChildrenOfDatasetIdRequestParamsSanitizer, GetChildrenOfDatasetIdRequestParamsValidator);
    const {
        
    } = await validateAndSanitize(req.query, GetChildrenOfDatasetIdRequestQuerySanitizer, GetChildrenOfDatasetIdRequestQueryValidator);



    
    const schemas = ....

    return fastJSONResponse(
        res,
        DatasetDataArrayStringify,
        schemas.map((schema) => {
            const { _id: id, ...rest } = schema;
            return validateAndRespond({ id, ...rest }, DatasetDataFromJSON, DatasetDataValidator);
        }),
    );
}



/v1/datasets/{datasetId}

export const getDatasetByIdFN: RequestHandlerAsync<
    GetDatasetByIdRequestParams,
    DatasetData | ApiErrorResponse,
    GetDatasetByIdRequestBody,
    GetDatasetByIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetDatasetByIdRequestBodySanitizer, GetDatasetByIdRequestBodyValidator);
    const {
        datasetId,
    } =  await validateAndSanitize(req.params, GetDatasetByIdRequestParamsSanitizer, GetDatasetByIdRequestParamsValidator);
    const {
        
    } = await validateAndSanitize(req.query, GetDatasetByIdRequestQuerySanitizer, GetDatasetByIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        DatasetDataStringify,
        validateAndRespond({ id, ...rest }, DatasetDataFromJSON, DatasetDataValidator),
    );
}



/v1/datasets
const DatasetDataArrayStringify = fastJSONArrayify(DatasetDataStringifyShape);
export const getDatasetsFN: RequestHandlerAsync<
    GetDatasetsRequestParams,
    DatasetData[] | ApiErrorResponse,
    GetDatasetsRequestBody,
    GetDatasetsRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetDatasetsRequestBodySanitizer, GetDatasetsRequestBodyValidator);
    const {
        
        
        
        
        
        
    } =  await validateAndSanitize(req.params, GetDatasetsRequestParamsSanitizer, GetDatasetsRequestParamsValidator);
    const {
        shared,
        excludePlayground,
        getAssetsOfTeam,
        getAssetsOfTeamInclusiveSelf,
        pageSize,
        pageOffset,
    } = await validateAndSanitize(req.query, GetDatasetsRequestQuerySanitizer, GetDatasetsRequestQueryValidator);



    
    const schemas = ....

    return fastJSONResponse(
        res,
        DatasetDataArrayStringify,
        schemas.map((schema) => {
            const { _id: id, ...rest } = schema;
            return validateAndRespond({ id, ...rest }, DatasetDataFromJSON, DatasetDataValidator);
        }),
    );
}



/v1/datasets/enriched
const DatasetDataEnrichedArrayStringify = fastJSONArrayify(DatasetDataEnrichedStringifyShape);
export const getDatasetsEnrichedFN: RequestHandlerAsync<
    GetDatasetsEnrichedRequestParams,
    DatasetDataEnriched[] | ApiErrorResponse,
    GetDatasetsEnrichedRequestBody,
    GetDatasetsEnrichedRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetDatasetsEnrichedRequestBodySanitizer, GetDatasetsEnrichedRequestBodyValidator);
    const {
        
        
        
        
        
        
        
    } =  await validateAndSanitize(req.params, GetDatasetsEnrichedRequestParamsSanitizer, GetDatasetsEnrichedRequestParamsValidator);
    const {
        shared,
        limit,
        excludePlayground,
        getAssetsOfTeam,
        getAssetsOfTeamInclusiveSelf,
        pageSize,
        pageOffset,
    } = await validateAndSanitize(req.query, GetDatasetsEnrichedRequestQuerySanitizer, GetDatasetsEnrichedRequestQueryValidator);



    
    const schemas = ....

    return fastJSONResponse(
        res,
        DatasetDataEnrichedArrayStringify,
        schemas.map((schema) => {
            const { _id: id, ...rest } = schema;
            return validateAndRespond({ id, ...rest }, DatasetDataEnrichedFromJSON, DatasetDataEnrichedValidator);
        }),
    );
}



/v1/datasets/enriched/query/ids
const DatasetDataEnrichedArrayStringify = fastJSONArrayify(DatasetDataEnrichedStringifyShape);
export const getDatasetsEnrichedQueryByIdsFN: RequestHandlerAsync<
    GetDatasetsEnrichedQueryByIdsRequestParams,
    DatasetDataEnriched[] | ApiErrorResponse,
    GetDatasetsEnrichedQueryByIdsRequestBody,
    GetDatasetsEnrichedQueryByIdsRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetDatasetsEnrichedQueryByIdsRequestBodySanitizer, GetDatasetsEnrichedQueryByIdsRequestBodyValidator);
    const {
        
    } =  await validateAndSanitize(req.params, GetDatasetsEnrichedQueryByIdsRequestParamsSanitizer, GetDatasetsEnrichedQueryByIdsRequestParamsValidator);
    const {
        datasetIds,
    } = await validateAndSanitize(req.query, GetDatasetsEnrichedQueryByIdsRequestQuerySanitizer, GetDatasetsEnrichedQueryByIdsRequestQueryValidator);



    
    const schemas = ....

    return fastJSONResponse(
        res,
        DatasetDataEnrichedArrayStringify,
        schemas.map((schema) => {
            const { _id: id, ...rest } = schema;
            return validateAndRespond({ id, ...rest }, DatasetDataEnrichedFromJSON, DatasetDataEnrichedValidator);
        }),
    );
}



/v1/datasets/enriched/query/name/{datasetName}
const DatasetDataEnrichedArrayStringify = fastJSONArrayify(DatasetDataEnrichedStringifyShape);
export const getDatasetsEnrichedQueryByNameFN: RequestHandlerAsync<
    GetDatasetsEnrichedQueryByNameRequestParams,
    DatasetDataEnriched[] | ApiErrorResponse,
    GetDatasetsEnrichedQueryByNameRequestBody,
    GetDatasetsEnrichedQueryByNameRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetDatasetsEnrichedQueryByNameRequestBodySanitizer, GetDatasetsEnrichedQueryByNameRequestBodyValidator);
    const {
        datasetName,
        
        
        
        
        
        
        
    } =  await validateAndSanitize(req.params, GetDatasetsEnrichedQueryByNameRequestParamsSanitizer, GetDatasetsEnrichedQueryByNameRequestParamsValidator);
    const {
        
        shared,
        exact,
        excludePlayground,
        getAssetsOfTeam,
        getAssetsOfTeamInclusiveSelf,
        pageSize,
        pageOffset,
    } = await validateAndSanitize(req.query, GetDatasetsEnrichedQueryByNameRequestQuerySanitizer, GetDatasetsEnrichedQueryByNameRequestQueryValidator);



    
    const schemas = ....

    return fastJSONResponse(
        res,
        DatasetDataEnrichedArrayStringify,
        schemas.map((schema) => {
            const { _id: id, ...rest } = schema;
            return validateAndRespond({ id, ...rest }, DatasetDataEnrichedFromJSON, DatasetDataEnrichedValidator);
        }),
    );
}



/v1/datasets/query/name/{datasetName}
const DatasetDataArrayStringify = fastJSONArrayify(DatasetDataStringifyShape);
export const getDatasetsQueryByNameFN: RequestHandlerAsync<
    GetDatasetsQueryByNameRequestParams,
    DatasetData[] | ApiErrorResponse,
    GetDatasetsQueryByNameRequestBody,
    GetDatasetsQueryByNameRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetDatasetsQueryByNameRequestBodySanitizer, GetDatasetsQueryByNameRequestBodyValidator);
    const {
        datasetName,
        
        
        
        
        
        
        
    } =  await validateAndSanitize(req.params, GetDatasetsQueryByNameRequestParamsSanitizer, GetDatasetsQueryByNameRequestParamsValidator);
    const {
        
        shared,
        exact,
        excludePlayground,
        getAssetsOfTeam,
        getAssetsOfTeamInclusiveSelf,
        pageSize,
        pageOffset,
    } = await validateAndSanitize(req.query, GetDatasetsQueryByNameRequestQuerySanitizer, GetDatasetsQueryByNameRequestQueryValidator);



    
    const schemas = ....

    return fastJSONResponse(
        res,
        DatasetDataArrayStringify,
        schemas.map((schema) => {
            const { _id: id, ...rest } = schema;
            return validateAndRespond({ id, ...rest }, DatasetDataFromJSON, DatasetDataValidator);
        }),
    );
}



/v1/datasets/{datasetId}/registerDatasetUpload

export const registerDatasetUploadByIdFN: RequestHandlerAsync<
    RegisterDatasetUploadByIdRequestParams,
     | ApiErrorResponse,
    RegisterDatasetUploadByIdRequestBody,
    RegisterDatasetUploadByIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, RegisterDatasetUploadByIdRequestBodySanitizer, RegisterDatasetUploadByIdRequestBodyValidator);
    const {
        
        datasetId,
    } =  await validateAndSanitize(req.params, RegisterDatasetUploadByIdRequestParamsSanitizer, RegisterDatasetUploadByIdRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, RegisterDatasetUploadByIdRequestQuerySanitizer, RegisterDatasetUploadByIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        Stringify,
        validateAndRespond({ id, ...rest }, FromJSON, Validator),
    );
}



/v1/datasets/{datasetId}

export const updateDatasetByIdFN: RequestHandlerAsync<
    UpdateDatasetByIdRequestParams,
     | ApiErrorResponse,
    UpdateDatasetByIdRequestBody,
    UpdateDatasetByIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, UpdateDatasetByIdRequestBodySanitizer, UpdateDatasetByIdRequestBodyValidator);
    const {
        
        datasetId,
    } =  await validateAndSanitize(req.params, UpdateDatasetByIdRequestParamsSanitizer, UpdateDatasetByIdRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, UpdateDatasetByIdRequestQuerySanitizer, UpdateDatasetByIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        Stringify,
        validateAndRespond({ id, ...rest }, FromJSON, Validator),
    );
}


*/
/* LIGHTLY CUSTOM END -> api */
/**
 * DatasetsApi - interface
 *
 * @export
 * @interface DatasetsApiInterface
 */
export interface DatasetsApiInterface {
    /**
     * Creates a new dataset for a user
     * @param {DatasetCreateRequest} datasetCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetsApiInterface
     */
    createDatasetRaw(requestParameters: CreateDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEntityResponse>>;
    /**
     * Creates a new dataset for a user
     */
    createDataset(requestParameters: CreateDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEntityResponse>;
    /**
     * Delete a specific dataset
     * @param {string} datasetId ObjectId of the dataset
     * @param {boolean} [force]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetsApiInterface
     */
    deleteDatasetByIdRaw(requestParameters: DeleteDatasetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;
    /**
     * Delete a specific dataset
     */
    deleteDatasetById(requestParameters: DeleteDatasetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;
    /**
     * Get all datasets which are the children of a specific dataset (e.g crop datasets)
     * @param {string} datasetId ObjectId of the dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetsApiInterface
     */
    getChildrenOfDatasetIdRaw(requestParameters: GetChildrenOfDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DatasetData>>>;
    /**
     * Get all datasets which are the children of a specific dataset (e.g crop datasets)
     */
    getChildrenOfDatasetId(requestParameters: GetChildrenOfDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DatasetData>>;
    /**
     * Get a specific dataset
     * @param {string} datasetId ObjectId of the dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetsApiInterface
     */
    getDatasetByIdRaw(requestParameters: GetDatasetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatasetData>>;
    /**
     * Get a specific dataset
     */
    getDatasetById(requestParameters: GetDatasetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatasetData>;
    /**
     * Get all datasets for a user
     * @param {boolean} [shared] if set, only returns the datasets which have been shared with the user
     * @param {boolean} [excludePlayground] if set, excludes playground datasets. Typically set to true when querying for team assets
     * @param {boolean} [getAssetsOfTeam] if this flag is true, we get the relevant asset of the team of the user rather than the assets of the user
     * @param {boolean} [getAssetsOfTeamInclusiveSelf] if this flag is true, we get the relevant asset of the team of the user including the assets of the user
     * @param {number} [pageSize] pagination size/limit of the number of samples to return
     * @param {number} [pageOffset] pagination offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetsApiInterface
     */
    getDatasetsRaw(requestParameters: GetDatasetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DatasetData>>>;
    /**
     * Get all datasets for a user
     */
    getDatasets(requestParameters: GetDatasetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DatasetData>>;
    /**
     * Get all datasets for a user but enriched with additional information as nTags, nEmbeddings, samples.
     * @param {boolean} [shared] if set, only returns the datasets which have been shared with the user
     * @param {number} [limit] DEPRECATED, use pageSize instead. if set, only returns the newest up until limit
     * @param {boolean} [excludePlayground] if set, excludes playground datasets. Typically set to true when querying for team assets
     * @param {boolean} [getAssetsOfTeam] if this flag is true, we get the relevant asset of the team of the user rather than the assets of the user
     * @param {boolean} [getAssetsOfTeamInclusiveSelf] if this flag is true, we get the relevant asset of the team of the user including the assets of the user
     * @param {number} [pageSize] pagination size/limit of the number of samples to return
     * @param {number} [pageOffset] pagination offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetsApiInterface
     */
    getDatasetsEnrichedRaw(requestParameters: GetDatasetsEnrichedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DatasetDataEnriched>>>;
    /**
     * Get all datasets for a user but enriched with additional information as nTags, nEmbeddings, samples.
     */
    getDatasetsEnriched(requestParameters: GetDatasetsEnrichedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DatasetDataEnriched>>;
    /**
     * Query for datasets enriched with additional information by their Ids. Includes team and shared datasets. Used for favorite resolving
     * @param {MongoObjectIDs} datasetIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetsApiInterface
     */
    getDatasetsEnrichedQueryByIdsRaw(requestParameters: GetDatasetsEnrichedQueryByIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DatasetDataEnriched>>>;
    /**
     * Query for datasets enriched with additional information by their Ids. Includes team and shared datasets. Used for favorite resolving
     */
    getDatasetsEnrichedQueryByIds(requestParameters: GetDatasetsEnrichedQueryByIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DatasetDataEnriched>>;
    /**
     * Query for datasets  enriched with additional information by their name prefix unless exact flag is set
     * @param {string} datasetName
     * @param {boolean} [shared] if set, only returns the datasets which have been shared with the user
     * @param {boolean} [exact] if set, only returns the datasets which match the name exactly (not just by prefix)
     * @param {boolean} [excludePlayground] if set, excludes playground datasets. Typically set to true when querying for team assets
     * @param {boolean} [getAssetsOfTeam] if this flag is true, we get the relevant asset of the team of the user rather than the assets of the user
     * @param {boolean} [getAssetsOfTeamInclusiveSelf] if this flag is true, we get the relevant asset of the team of the user including the assets of the user
     * @param {number} [pageSize] pagination size/limit of the number of samples to return
     * @param {number} [pageOffset] pagination offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetsApiInterface
     */
    getDatasetsEnrichedQueryByNameRaw(requestParameters: GetDatasetsEnrichedQueryByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DatasetDataEnriched>>>;
    /**
     * Query for datasets  enriched with additional information by their name prefix unless exact flag is set
     */
    getDatasetsEnrichedQueryByName(requestParameters: GetDatasetsEnrichedQueryByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DatasetDataEnriched>>;
    /**
     * Query for datasets by their name prefix unless exact flag is set
     * @param {string} datasetName
     * @param {boolean} [shared] if set, only returns the datasets which have been shared with the user
     * @param {boolean} [exact] if set, only returns the datasets which match the name exactly (not just by prefix)
     * @param {boolean} [excludePlayground] if set, excludes playground datasets. Typically set to true when querying for team assets
     * @param {boolean} [getAssetsOfTeam] if this flag is true, we get the relevant asset of the team of the user rather than the assets of the user
     * @param {boolean} [getAssetsOfTeamInclusiveSelf] if this flag is true, we get the relevant asset of the team of the user including the assets of the user
     * @param {number} [pageSize] pagination size/limit of the number of samples to return
     * @param {number} [pageOffset] pagination offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetsApiInterface
     */
    getDatasetsQueryByNameRaw(requestParameters: GetDatasetsQueryByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DatasetData>>>;
    /**
     * Query for datasets by their name prefix unless exact flag is set
     */
    getDatasetsQueryByName(requestParameters: GetDatasetsQueryByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DatasetData>>;
    /**
     * Registers a job to track the dataset upload
     * @param {JobStatusMeta} jobStatusMeta
     * @param {string} datasetId ObjectId of the dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetsApiInterface
     */
    registerDatasetUploadByIdRaw(requestParameters: RegisterDatasetUploadByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;
    /**
     * Registers a job to track the dataset upload
     */
    registerDatasetUploadById(requestParameters: RegisterDatasetUploadByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;
    /**
     * Update a specific dataset
     * @param {DatasetUpdateRequest} datasetUpdateRequest updated data for dataset
     * @param {string} datasetId ObjectId of the dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetsApiInterface
     */
    updateDatasetByIdRaw(requestParameters: UpdateDatasetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;
    /**
     * Update a specific dataset
     */
    updateDatasetById(requestParameters: UpdateDatasetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;
}
/**
 *
 */
export class DatasetsApi extends runtime.BaseAPI implements DatasetsApiInterface {
    /**
     * Creates a new dataset for a user
     */
    async createDatasetRaw(requestParameters: CreateDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEntityResponse>> {
        if (requestParameters.datasetCreateRequest === null || requestParameters.datasetCreateRequest === undefined) {
            throw new runtime.RequiredError('datasetCreateRequest', 'Required parameter requestParameters.datasetCreateRequest was null or undefined when calling createDataset.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DatasetCreateRequestToJSON(requestParameters.datasetCreateRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateEntityResponseFromJSON(jsonValue));
    }
    /**
     * Creates a new dataset for a user
     */
    async createDataset(requestParameters: CreateDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEntityResponse> {
        const response = await this.createDatasetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Delete a specific dataset
     */
    async deleteDatasetByIdRaw(requestParameters: DeleteDatasetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling deleteDatasetById.');
        }
        const queryParameters: any = {};
        if (requestParameters.force !== undefined) {
            queryParameters['force'] = requestParameters.force;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Delete a specific dataset
     */
    async deleteDatasetById(requestParameters: DeleteDatasetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDatasetByIdRaw(requestParameters, initOverrides);
    }
    /**
     * Get all datasets which are the children of a specific dataset (e.g crop datasets)
     */
    async getChildrenOfDatasetIdRaw(requestParameters: GetChildrenOfDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DatasetData>>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getChildrenOfDatasetId.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/children`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DatasetDataFromJSON));
    }
    /**
     * Get all datasets which are the children of a specific dataset (e.g crop datasets)
     */
    async getChildrenOfDatasetId(requestParameters: GetChildrenOfDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DatasetData>> {
        const response = await this.getChildrenOfDatasetIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get a specific dataset
     */
    async getDatasetByIdRaw(requestParameters: GetDatasetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DatasetData>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getDatasetById.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => DatasetDataFromJSON(jsonValue));
    }
    /**
     * Get a specific dataset
     */
    async getDatasetById(requestParameters: GetDatasetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DatasetData> {
        const response = await this.getDatasetByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get all datasets for a user
     */
    async getDatasetsRaw(requestParameters: GetDatasetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DatasetData>>> {
        const queryParameters: any = {};
        if (requestParameters.shared !== undefined) {
            queryParameters['shared'] = requestParameters.shared;
        }
        if (requestParameters.excludePlayground !== undefined) {
            queryParameters['excludePlayground'] = requestParameters.excludePlayground;
        }
        if (requestParameters.getAssetsOfTeam !== undefined) {
            queryParameters['getAssetsOfTeam'] = requestParameters.getAssetsOfTeam;
        }
        if (requestParameters.getAssetsOfTeamInclusiveSelf !== undefined) {
            queryParameters['getAssetsOfTeamInclusiveSelf'] = requestParameters.getAssetsOfTeamInclusiveSelf;
        }
        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }
        if (requestParameters.pageOffset !== undefined) {
            queryParameters['pageOffset'] = requestParameters.pageOffset;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DatasetDataFromJSON));
    }
    /**
     * Get all datasets for a user
     */
    async getDatasets(requestParameters: GetDatasetsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DatasetData>> {
        const response = await this.getDatasetsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get all datasets for a user but enriched with additional information as nTags, nEmbeddings, samples.
     */
    async getDatasetsEnrichedRaw(requestParameters: GetDatasetsEnrichedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DatasetDataEnriched>>> {
        const queryParameters: any = {};
        if (requestParameters.shared !== undefined) {
            queryParameters['shared'] = requestParameters.shared;
        }
        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }
        if (requestParameters.excludePlayground !== undefined) {
            queryParameters['excludePlayground'] = requestParameters.excludePlayground;
        }
        if (requestParameters.getAssetsOfTeam !== undefined) {
            queryParameters['getAssetsOfTeam'] = requestParameters.getAssetsOfTeam;
        }
        if (requestParameters.getAssetsOfTeamInclusiveSelf !== undefined) {
            queryParameters['getAssetsOfTeamInclusiveSelf'] = requestParameters.getAssetsOfTeamInclusiveSelf;
        }
        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }
        if (requestParameters.pageOffset !== undefined) {
            queryParameters['pageOffset'] = requestParameters.pageOffset;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/enriched`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DatasetDataEnrichedFromJSON));
    }
    /**
     * Get all datasets for a user but enriched with additional information as nTags, nEmbeddings, samples.
     */
    async getDatasetsEnriched(requestParameters: GetDatasetsEnrichedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DatasetDataEnriched>> {
        const response = await this.getDatasetsEnrichedRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Query for datasets enriched with additional information by their Ids. Includes team and shared datasets. Used for favorite resolving
     */
    async getDatasetsEnrichedQueryByIdsRaw(requestParameters: GetDatasetsEnrichedQueryByIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DatasetDataEnriched>>> {
        if (requestParameters.datasetIds === null || requestParameters.datasetIds === undefined) {
            throw new runtime.RequiredError('datasetIds', 'Required parameter requestParameters.datasetIds was null or undefined when calling getDatasetsEnrichedQueryByIds.');
        }
        const queryParameters: any = {};
        if (requestParameters.datasetIds) {
            queryParameters['datasetIds'] = requestParameters.datasetIds;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/enriched/query/ids`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DatasetDataEnrichedFromJSON));
    }
    /**
     * Query for datasets enriched with additional information by their Ids. Includes team and shared datasets. Used for favorite resolving
     */
    async getDatasetsEnrichedQueryByIds(requestParameters: GetDatasetsEnrichedQueryByIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DatasetDataEnriched>> {
        const response = await this.getDatasetsEnrichedQueryByIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Query for datasets  enriched with additional information by their name prefix unless exact flag is set
     */
    async getDatasetsEnrichedQueryByNameRaw(requestParameters: GetDatasetsEnrichedQueryByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DatasetDataEnriched>>> {
        if (requestParameters.datasetName === null || requestParameters.datasetName === undefined) {
            throw new runtime.RequiredError('datasetName', 'Required parameter requestParameters.datasetName was null or undefined when calling getDatasetsEnrichedQueryByName.');
        }
        const queryParameters: any = {};
        if (requestParameters.shared !== undefined) {
            queryParameters['shared'] = requestParameters.shared;
        }
        if (requestParameters.exact !== undefined) {
            queryParameters['exact'] = requestParameters.exact;
        }
        if (requestParameters.excludePlayground !== undefined) {
            queryParameters['excludePlayground'] = requestParameters.excludePlayground;
        }
        if (requestParameters.getAssetsOfTeam !== undefined) {
            queryParameters['getAssetsOfTeam'] = requestParameters.getAssetsOfTeam;
        }
        if (requestParameters.getAssetsOfTeamInclusiveSelf !== undefined) {
            queryParameters['getAssetsOfTeamInclusiveSelf'] = requestParameters.getAssetsOfTeamInclusiveSelf;
        }
        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }
        if (requestParameters.pageOffset !== undefined) {
            queryParameters['pageOffset'] = requestParameters.pageOffset;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/enriched/query/name/{datasetName}`.replace(`{${"datasetName"}}`, encodeURIComponent(String(requestParameters.datasetName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DatasetDataEnrichedFromJSON));
    }
    /**
     * Query for datasets  enriched with additional information by their name prefix unless exact flag is set
     */
    async getDatasetsEnrichedQueryByName(requestParameters: GetDatasetsEnrichedQueryByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DatasetDataEnriched>> {
        const response = await this.getDatasetsEnrichedQueryByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Query for datasets by their name prefix unless exact flag is set
     */
    async getDatasetsQueryByNameRaw(requestParameters: GetDatasetsQueryByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DatasetData>>> {
        if (requestParameters.datasetName === null || requestParameters.datasetName === undefined) {
            throw new runtime.RequiredError('datasetName', 'Required parameter requestParameters.datasetName was null or undefined when calling getDatasetsQueryByName.');
        }
        const queryParameters: any = {};
        if (requestParameters.shared !== undefined) {
            queryParameters['shared'] = requestParameters.shared;
        }
        if (requestParameters.exact !== undefined) {
            queryParameters['exact'] = requestParameters.exact;
        }
        if (requestParameters.excludePlayground !== undefined) {
            queryParameters['excludePlayground'] = requestParameters.excludePlayground;
        }
        if (requestParameters.getAssetsOfTeam !== undefined) {
            queryParameters['getAssetsOfTeam'] = requestParameters.getAssetsOfTeam;
        }
        if (requestParameters.getAssetsOfTeamInclusiveSelf !== undefined) {
            queryParameters['getAssetsOfTeamInclusiveSelf'] = requestParameters.getAssetsOfTeamInclusiveSelf;
        }
        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }
        if (requestParameters.pageOffset !== undefined) {
            queryParameters['pageOffset'] = requestParameters.pageOffset;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/query/name/{datasetName}`.replace(`{${"datasetName"}}`, encodeURIComponent(String(requestParameters.datasetName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DatasetDataFromJSON));
    }
    /**
     * Query for datasets by their name prefix unless exact flag is set
     */
    async getDatasetsQueryByName(requestParameters: GetDatasetsQueryByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DatasetData>> {
        const response = await this.getDatasetsQueryByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Registers a job to track the dataset upload
     */
    async registerDatasetUploadByIdRaw(requestParameters: RegisterDatasetUploadByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.jobStatusMeta === null || requestParameters.jobStatusMeta === undefined) {
            throw new runtime.RequiredError('jobStatusMeta', 'Required parameter requestParameters.jobStatusMeta was null or undefined when calling registerDatasetUploadById.');
        }
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling registerDatasetUploadById.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/registerDatasetUpload`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: JobStatusMetaToJSON(requestParameters.jobStatusMeta),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Registers a job to track the dataset upload
     */
    async registerDatasetUploadById(requestParameters: RegisterDatasetUploadByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.registerDatasetUploadByIdRaw(requestParameters, initOverrides);
    }
    /**
     * Update a specific dataset
     */
    async updateDatasetByIdRaw(requestParameters: UpdateDatasetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.datasetUpdateRequest === null || requestParameters.datasetUpdateRequest === undefined) {
            throw new runtime.RequiredError('datasetUpdateRequest', 'Required parameter requestParameters.datasetUpdateRequest was null or undefined when calling updateDatasetById.');
        }
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling updateDatasetById.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DatasetUpdateRequestToJSON(requestParameters.datasetUpdateRequest),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Update a specific dataset
     */
    async updateDatasetById(requestParameters: UpdateDatasetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateDatasetByIdRaw(requestParameters, initOverrides);
    }
}

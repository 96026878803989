/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { DatasourceConfigAzure } from './DatasourceConfigAzure';
import { DatasourceConfigAzureFromJSON, DatasourceConfigAzureFromJSONTyped, DatasourceConfigAzureToJSON, } from './DatasourceConfigAzure';
import type { DatasourceConfigGCS } from './DatasourceConfigGCS';
import { DatasourceConfigGCSFromJSON, DatasourceConfigGCSFromJSONTyped, DatasourceConfigGCSToJSON, } from './DatasourceConfigGCS';
import type { DatasourceConfigLIGHTLY } from './DatasourceConfigLIGHTLY';
import { DatasourceConfigLIGHTLYFromJSON, DatasourceConfigLIGHTLYFromJSONTyped, DatasourceConfigLIGHTLYToJSON, } from './DatasourceConfigLIGHTLY';
import type { DatasourceConfigLOCAL } from './DatasourceConfigLOCAL';
import { DatasourceConfigLOCALFromJSON, DatasourceConfigLOCALFromJSONTyped, DatasourceConfigLOCALToJSON, } from './DatasourceConfigLOCAL';
import type { DatasourceConfigOBS } from './DatasourceConfigOBS';
import { DatasourceConfigOBSFromJSON, DatasourceConfigOBSFromJSONTyped, DatasourceConfigOBSToJSON, } from './DatasourceConfigOBS';
import type { DatasourceConfigS3 } from './DatasourceConfigS3';
import { DatasourceConfigS3FromJSON, DatasourceConfigS3FromJSONTyped, DatasourceConfigS3ToJSON, } from './DatasourceConfigS3';
import type { DatasourceConfigS3DelegatedAccess } from './DatasourceConfigS3DelegatedAccess';
import { DatasourceConfigS3DelegatedAccessFromJSON, DatasourceConfigS3DelegatedAccessFromJSONTyped, DatasourceConfigS3DelegatedAccessToJSON, } from './DatasourceConfigS3DelegatedAccess';
import type { DatasourcePurpose } from './DatasourcePurpose';
import { DatasourcePurposeFromJSON, DatasourcePurposeFromJSONTyped, DatasourcePurposeToJSON, } from './DatasourcePurpose';
/*
causes circular dependendies
import {
     DatasourceConfigAzureFromJSONTyped,
} from './DatasourceConfigAzure';
import {
     DatasourceConfigGCSFromJSONTyped,
} from './DatasourceConfigGCS';
import {
     DatasourceConfigLIGHTLYFromJSONTyped,
} from './DatasourceConfigLIGHTLY';
import {
     DatasourceConfigLOCALFromJSONTyped,
} from './DatasourceConfigLOCAL';
import {
     DatasourceConfigOBSFromJSONTyped,
} from './DatasourceConfigOBS';
import {
     DatasourceConfigS3FromJSONTyped,
} from './DatasourceConfigS3';
import {
     DatasourceConfigS3DelegatedAccessFromJSONTyped
} from './DatasourceConfigS3DelegatedAccess';


*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DatasourceConfigBase
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_id")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
export interface DatasourceConfigBase {
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof DatasourceConfigBase
        
        
    * @pattern_id
         */
    id?: string;
    /**
     *
     * #type DatasourcePurpose
     * #memberof DatasourceConfigBase
        
        
         */
    purpose: DatasourcePurpose;
    /**
     *
     * #type string
     * #memberof DatasourceConfigBase
        
        
         */
    type: string;
    /**
     * the suffix of where to find the thumbnail image. If none is provided, the full image will be loaded where thumbnails would be loaded otherwise.
     * - [filename]: represents the filename without the extension
     * - [extension]: represents the files extension (e.g jpg, png, webp)
     * #type string
     * #memberof DatasourceConfigBase
        
        
         */
    thumbSuffix?: string;
}
/**
 * Check if a given object implements the DatasourceConfigBase interface.
 */
export function instanceOfDatasourceConfigBase(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "purpose" in value;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { DatasourceConfigAzureValidator, DatasourceConfigAzureStringifyShape, DatasourceConfigAzureStringifyShapeProperties, } from './DatasourceConfigAzure';
import { DatasourceConfigGCSValidator, DatasourceConfigGCSStringifyShape, DatasourceConfigGCSStringifyShapeProperties, } from './DatasourceConfigGCS';
import { DatasourceConfigLIGHTLYValidator, DatasourceConfigLIGHTLYStringifyShape, DatasourceConfigLIGHTLYStringifyShapeProperties, } from './DatasourceConfigLIGHTLY';
import { DatasourceConfigLOCALValidator, DatasourceConfigLOCALStringifyShape, DatasourceConfigLOCALStringifyShapeProperties, } from './DatasourceConfigLOCAL';
import { DatasourceConfigOBSValidator, DatasourceConfigOBSStringifyShape, DatasourceConfigOBSStringifyShapeProperties, } from './DatasourceConfigOBS';
import { DatasourceConfigS3Validator, DatasourceConfigS3StringifyShape, DatasourceConfigS3StringifyShapeProperties, } from './DatasourceConfigS3';
import { DatasourceConfigS3DelegatedAccessValidator, DatasourceConfigS3DelegatedAccessStringifyShape, DatasourceConfigS3DelegatedAccessStringifyShapeProperties, } from './DatasourceConfigS3DelegatedAccess';
import { DatasourcePurposeValidator, DatasourcePurposeStringifyShape, DatasourcePurposeStringifyShapeProperties, } from './DatasourcePurpose';
export const DatasourceConfigBaseTypiaAssertEquals = (input: any) => {
    return ((input: any): DatasourceConfigBase => {
        const __is = (input: any, _exceptionable: boolean = true): input is DatasourceConfigBase => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.id || "string" === typeof input.id && $is_custom("pattern_id", "string", "", input.id)) && ("INPUT_OUTPUT" === input.purpose || "INPUT" === input.purpose || "LIGHTLY" === input.purpose) && "string" === typeof input.type && (undefined === input.thumbSuffix || "string" === typeof input.thumbSuffix) && (2 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["id", "purpose", "type", "thumbSuffix"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DatasourceConfigBase => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.id || "string" === typeof input.id && ($is_custom("pattern_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "(string | undefined)",
                    value: input.id
                })) && ("INPUT_OUTPUT" === input.purpose || "INPUT" === input.purpose || "LIGHTLY" === input.purpose || $guard(_exceptionable, {
                    path: _path + ".purpose",
                    expected: "(\"INPUT\" | \"INPUT_OUTPUT\" | \"LIGHTLY\")",
                    value: input.purpose
                })) && ("string" === typeof input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "string",
                    value: input.type
                })) && (undefined === input.thumbSuffix || "string" === typeof input.thumbSuffix || $guard(_exceptionable, {
                    path: _path + ".thumbSuffix",
                    expected: "(string | undefined)",
                    value: input.thumbSuffix
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["id", "purpose", "type", "thumbSuffix"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceConfigBase",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceConfigBase",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DatasourceConfigBaseTypiaAssertStringify = (input: DatasourceConfigBase): string => {
    return ((input: any): string => { const assert = (input: any): DatasourceConfigBase => {
        const __is = (input: any): input is DatasourceConfigBase => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => (undefined === input.id || "string" === typeof input.id && $is_custom("pattern_id", "string", "", input.id)) && ("INPUT_OUTPUT" === input.purpose || "INPUT" === input.purpose || "LIGHTLY" === input.purpose) && "string" === typeof input.type && (undefined === input.thumbSuffix || "string" === typeof input.thumbSuffix);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DatasourceConfigBase => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.id || "string" === typeof input.id && ($is_custom("pattern_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "(string | undefined)",
                    value: input.id
                })) && ("INPUT_OUTPUT" === input.purpose || "INPUT" === input.purpose || "LIGHTLY" === input.purpose || $guard(_exceptionable, {
                    path: _path + ".purpose",
                    expected: "(\"INPUT\" | \"INPUT_OUTPUT\" | \"LIGHTLY\")",
                    value: input.purpose
                })) && ("string" === typeof input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "string",
                    value: input.type
                })) && (undefined === input.thumbSuffix || "string" === typeof input.thumbSuffix || $guard(_exceptionable, {
                    path: _path + ".thumbSuffix",
                    expected: "(string | undefined)",
                    value: input.thumbSuffix
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceConfigBase",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceConfigBase",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DatasourceConfigBase): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.id ? "" : `"id":${undefined !== input.id ? $string(input.id) : undefined},`}${undefined === input.thumbSuffix ? "" : `"thumbSuffix":${undefined !== input.thumbSuffix ? $string(input.thumbSuffix) : undefined},`}"purpose":${(() => {
            if ("string" === typeof input.purpose)
                return $string(input.purpose);
            if ("string" === typeof input.purpose)
                return "\"" + input.purpose + "\"";
            $throws({
                expected: "(\"INPUT\" | \"INPUT_OUTPUT\" | \"LIGHTLY\")",
                value: input.purpose
            });
        })()},"type":${$string(input.type)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DatasourceConfigBaseValidatorShape = {
    'id': yup.string().matches(/^[a-f0-9]{24}$/),
    'purpose': DatasourcePurposeValidator.defined(),
    'type': yup.string().defined(),
    'thumbSuffix': yup.string().test('is-valid', 'If defined, the thumbnail suffix must include [filename]. You can only use [filename] and [extension] as placeholders', (value) => {
        if (!value) {
            return true;
        }
        const parts = value.match(/\[.+?\]/gm);
        if (!parts || parts.length === 0) { // it needs to have some [placeholder]
            return false;
        }
        if (!parts.includes('[filename]')) { // it needs to have at least the [filename] placeholder
            return false;
        }
        if (parts.length > 1 && !parts.includes('[extension]')) { // if there are other placeholders, it needs to be [extension]
            return false;
        }
        return true;
    }),
};
export const DatasourceConfigBaseValidator = yup.object().shape(DatasourceConfigBaseValidatorShape);
export const DatasourceConfigBaseStringifyShapeProperties: ObjectSchema['properties'] = {
    'id': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    },
    'purpose': DatasourcePurposeStringifyShape,
    'type': {
        'type': 'string',
    },
    'thumbSuffix': {
        'type': 'string',
    },
};
export const DatasourceConfigBaseStringifyShape: ObjectSchema = {
    title: 'DatasourceConfigBase Stringifier',
    type: 'object',
    properties: {
        ...DatasourceConfigBaseStringifyShapeProperties
    },
    required: [
        'purpose',
        'type',
    ],
};
export const DatasourceConfigBaseStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DatasourceConfigBaseStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DatasourceConfigBaseStringify: (data: DatasourceConfigBase) => string = DatasourceConfigBaseStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DatasourceConfigBaseFromJSON(json: any): DatasourceConfigBase {
    return DatasourceConfigBaseFromJSONTyped(json, false);
}
export function DatasourceConfigBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatasourceConfigBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
    if (!ignoreDiscriminator) {
        if (json['type'] === 'DatasourceConfigAzure') {
            return DatasourceConfigAzureFromJSONTyped(json, true);
        }
        if (json['type'] === 'DatasourceConfigGCS') {
            return DatasourceConfigGCSFromJSONTyped(json, true);
        }
        if (json['type'] === 'DatasourceConfigLIGHTLY') {
            return DatasourceConfigLIGHTLYFromJSONTyped(json, true);
        }
        if (json['type'] === 'DatasourceConfigLOCAL') {
            return DatasourceConfigLOCALFromJSONTyped(json, true);
        }
        if (json['type'] === 'DatasourceConfigOBS') {
            return DatasourceConfigOBSFromJSONTyped(json, true);
        }
        if (json['type'] === 'DatasourceConfigS3') {
            return DatasourceConfigS3FromJSONTyped(json, true);
        }
        if (json['type'] === 'DatasourceConfigS3DelegatedAccess') {
            return DatasourceConfigS3DelegatedAccessFromJSONTyped(json, true);
        }
    }
*/
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'purpose': DatasourcePurposeFromJSON(json['purpose']),
        'type': json['type'],
        'thumbSuffix': !exists(json, 'thumbSuffix') ? undefined : json['thumbSuffix'],
    };
}
export function DatasourceConfigBaseToJSON(value?: DatasourceConfigBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'purpose': DatasourcePurposeToJSON(value.purpose),
        'type': value.type,
        'thumbSuffix': value.thumbSuffix,
    };
}

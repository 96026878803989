/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { ActiveLearningScores } from './ActiveLearningScores';
import { ActiveLearningScoresFromJSON, ActiveLearningScoresFromJSONTyped, ActiveLearningScoresToJSON, } from './ActiveLearningScores';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface ActiveLearningScoreData
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_id")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_tagId")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_scoreType")("string")(() => {
    const regex = new RegExp(/^[a-zA-Z0-9_+=,.@:\/-]*$/);
    return (value: string) => !!regex.exec(value);
});
export interface ActiveLearningScoreData {
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof ActiveLearningScoreData
        
        
    * @pattern_id
         */
    id: string;
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof ActiveLearningScoreData
        
        
    * @pattern_tagId
         */
    tagId: string;
    /**
     * Type of active learning score
     * #type string
     * #memberof ActiveLearningScoreData
        
        
    * @pattern_scoreType
    * @minLength 1
         */
    scoreType: string;
    /**
     *
     * #type ActiveLearningScores
     * #memberof ActiveLearningScoreData
        
        
         */
    scores: ActiveLearningScores;
    /**
     * unix timestamp in milliseconds
     * #type number
     * #memberof ActiveLearningScoreData
        
    * @minimum 0
            
         */
    createdAt: number;
}
/**
 * Check if a given object implements the ActiveLearningScoreData interface.
 */
export function instanceOfActiveLearningScoreData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "tagId" in value;
    isInstance = isInstance && "scoreType" in value;
    isInstance = isInstance && "scores" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { ActiveLearningScoresValidator, ActiveLearningScoresStringifyShape, ActiveLearningScoresStringifyShapeProperties, } from './ActiveLearningScores';
export const ActiveLearningScoreDataTypiaAssertEquals = (input: any) => {
    return ((input: any): ActiveLearningScoreData => {
        const __is = (input: any, _exceptionable: boolean = true): input is ActiveLearningScoreData => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.id && $is_custom("pattern_id", "string", "", input.id) && ("string" === typeof input.tagId && $is_custom("pattern_tagId", "string", "", input.tagId)) && ("string" === typeof input.scoreType && 1 <= input.scoreType.length && $is_custom("pattern_scoreType", "string", "", input.scoreType)) && ("object" === typeof input.scores && null !== input.scores && $io1(input.scores, true && _exceptionable)) && ("number" === typeof input.createdAt && 0 <= input.createdAt) && (5 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["id", "tagId", "scoreType", "scores", "createdAt"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value;
                return false;
            });
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is ActiveLearningScoreData => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id && ($is_custom("pattern_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && ("string" === typeof input.tagId && ($is_custom("pattern_tagId", "string", "", input.tagId) || $guard(_exceptionable, {
                    path: _path + ".tagId",
                    expected: "string (@pattern_tagId)",
                    value: input.tagId
                })) || $guard(_exceptionable, {
                    path: _path + ".tagId",
                    expected: "string",
                    value: input.tagId
                })) && ("string" === typeof input.scoreType && (1 <= input.scoreType.length || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string (@minLength 1)",
                    value: input.scoreType
                })) && ($is_custom("pattern_scoreType", "string", "", input.scoreType) || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string (@pattern_scoreType)",
                    value: input.scoreType
                })) || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string",
                    value: input.scoreType
                })) && (("object" === typeof input.scores && null !== input.scores || $guard(_exceptionable, {
                    path: _path + ".scores",
                    expected: "ActiveLearningScores",
                    value: input.scores
                })) && $ao1(input.scores, _path + ".scores", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".scores",
                    expected: "ActiveLearningScores",
                    value: input.scores
                })) && ("number" === typeof input.createdAt && (0 <= input.createdAt || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number (@minimum 0)",
                    value: input.createdAt
                })) || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number",
                    value: input.createdAt
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["id", "tagId", "scoreType", "scores", "createdAt"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "ActiveLearningScoreData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "ActiveLearningScoreData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const ActiveLearningScoreDataTypiaAssertStringify = (input: ActiveLearningScoreData): string => {
    return ((input: any): string => { const assert = (input: any): ActiveLearningScoreData => {
        const __is = (input: any): input is ActiveLearningScoreData => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => "string" === typeof input.id && $is_custom("pattern_id", "string", "", input.id) && ("string" === typeof input.tagId && $is_custom("pattern_tagId", "string", "", input.tagId)) && ("string" === typeof input.scoreType && 1 <= input.scoreType.length && $is_custom("pattern_scoreType", "string", "", input.scoreType)) && ("object" === typeof input.scores && null !== input.scores && $io1(input.scores)) && ("number" === typeof input.createdAt && !Number.isNaN(input.createdAt) && 0 <= input.createdAt);
            const $io1 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value && !Number.isNaN(value);
                return true;
            });
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is ActiveLearningScoreData => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id && ($is_custom("pattern_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && ("string" === typeof input.tagId && ($is_custom("pattern_tagId", "string", "", input.tagId) || $guard(_exceptionable, {
                    path: _path + ".tagId",
                    expected: "string (@pattern_tagId)",
                    value: input.tagId
                })) || $guard(_exceptionable, {
                    path: _path + ".tagId",
                    expected: "string",
                    value: input.tagId
                })) && ("string" === typeof input.scoreType && (1 <= input.scoreType.length || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string (@minLength 1)",
                    value: input.scoreType
                })) && ($is_custom("pattern_scoreType", "string", "", input.scoreType) || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string (@pattern_scoreType)",
                    value: input.scoreType
                })) || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string",
                    value: input.scoreType
                })) && (("object" === typeof input.scores && null !== input.scores || $guard(_exceptionable, {
                    path: _path + ".scores",
                    expected: "ActiveLearningScores",
                    value: input.scores
                })) && $ao1(input.scores, _path + ".scores", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".scores",
                    expected: "ActiveLearningScores",
                    value: input.scores
                })) && ("number" === typeof input.createdAt && !Number.isNaN(input.createdAt) && (0 <= input.createdAt || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number (@minimum 0)",
                    value: input.createdAt
                })) || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number",
                    value: input.createdAt
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value && !Number.isNaN(value) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return true;
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "ActiveLearningScoreData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "ActiveLearningScoreData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: ActiveLearningScoreData): string => {
        const $io1 = (input: any): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
            if (["length"].some(prop => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                return "number" === typeof value;
            return true;
        });
        const $string = (typia.createAssertStringify as any).string;
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{"id":${$string(input.id)},"tagId":${$string(input.tagId)},"scoreType":${$string(input.scoreType)},"scores":${$so1(input.scores)},"createdAt":${input.createdAt}}`;
        const $so1 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some(regular => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${value}`; return ""; }).filter(str => "" !== str).join(",")}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const ActiveLearningScoreDataValidatorShape = {
    'id': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'scoreType': yup.string().matches(/^[a-zA-Z0-9_+=,.@:\/-]*$/).min(1).defined(),
    'scores': ActiveLearningScoresValidator.defined(),
    'createdAt': yup.number().min(0).defined(),
};
export const ActiveLearningScoreDataValidator = yup.object().shape(ActiveLearningScoreDataValidatorShape);
export const ActiveLearningScoreDataStringifyShapeProperties: ObjectSchema['properties'] = {
    'id': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    },
    'tagId': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    },
    'scoreType': {
        'type': 'string',
        'pattern': "/^[a-zA-Z0-9_+&#x3D;,.@:\/-]*$/".slice(1, -1),
        'minLength': 1,
    },
    'scores': ActiveLearningScoresStringifyShape,
    'createdAt': {
        'type': 'number',
        'minimum': 0,
    },
};
export const ActiveLearningScoreDataStringifyShape: ObjectSchema = {
    title: 'ActiveLearningScoreData Stringifier',
    type: 'object',
    properties: {
        ...ActiveLearningScoreDataStringifyShapeProperties
    },
    required: [
        'id',
        'tagId',
        'scoreType',
        'scores',
        'createdAt',
    ],
};
export const ActiveLearningScoreDataStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(ActiveLearningScoreDataStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const ActiveLearningScoreDataStringify: (data: ActiveLearningScoreData) => string = ActiveLearningScoreDataStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function ActiveLearningScoreDataFromJSON(json: any): ActiveLearningScoreData {
    return ActiveLearningScoreDataFromJSONTyped(json, false);
}
export function ActiveLearningScoreDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActiveLearningScoreData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'id': json['id'],
        'tagId': json['tagId'],
        'scoreType': json['scoreType'],
        'scores': ActiveLearningScoresFromJSON(json['scores']),
        'createdAt': json['createdAt'],
    };
}
export function ActiveLearningScoreDataToJSON(value?: ActiveLearningScoreData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'tagId': value.tagId,
        'scoreType': value.scoreType,
        'scores': ActiveLearningScoresToJSON(value.scores),
        'createdAt': value.createdAt,
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { Creator } from './Creator';
import { CreatorFromJSON, CreatorFromJSONTyped, CreatorToJSON, } from './Creator';
import type { DockerWorkerLabels } from './DockerWorkerLabels';
import { DockerWorkerLabelsFromJSON, DockerWorkerLabelsFromJSONTyped, DockerWorkerLabelsToJSON, } from './DockerWorkerLabels';
import type { DockerWorkerType } from './DockerWorkerType';
import { DockerWorkerTypeFromJSON, DockerWorkerTypeFromJSONTyped, DockerWorkerTypeToJSON, } from './DockerWorkerType';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface CreateDockerWorkerRegistryEntryRequest
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_name")("string")(() => {
    const regex = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9 _-]+$/);
    return (value: string) => !!regex.exec(value);
});
export interface CreateDockerWorkerRegistryEntryRequest {
    /**
     *
     * #type string
     * #memberof CreateDockerWorkerRegistryEntryRequest
        
        
    * @pattern_name
    * @minLength 3
         */
    name: string;
    /**
     *
     * #type DockerWorkerType
     * #memberof CreateDockerWorkerRegistryEntryRequest
        
        
         */
    workerType: DockerWorkerType;
    /**
     *
     * #type DockerWorkerLabels
     * #memberof CreateDockerWorkerRegistryEntryRequest
        
        
         */
    labels?: DockerWorkerLabels;
    /**
     *
     * #type Creator
     * #memberof CreateDockerWorkerRegistryEntryRequest
        
        
         */
    creator?: Creator;
    /**
     *
     * #type string
     * #memberof CreateDockerWorkerRegistryEntryRequest
        
        
         */
    dockerVersion?: string;
}
/**
 * Check if a given object implements the CreateDockerWorkerRegistryEntryRequest interface.
 */
export function instanceOfCreateDockerWorkerRegistryEntryRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "workerType" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { CreatorValidator, CreatorStringifyShape, CreatorStringifyShapeProperties, } from './Creator';
import { DockerWorkerLabelsValidator, DockerWorkerLabelsStringifyShape, DockerWorkerLabelsStringifyShapeProperties, } from './DockerWorkerLabels';
import { DockerWorkerTypeValidator, DockerWorkerTypeStringifyShape, DockerWorkerTypeStringifyShapeProperties, } from './DockerWorkerType';
export const CreateDockerWorkerRegistryEntryRequestTypiaAssertEquals = (input: any) => {
    return ((input: any): CreateDockerWorkerRegistryEntryRequest => {
        const __is = (input: any, _exceptionable: boolean = true): input is CreateDockerWorkerRegistryEntryRequest => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.name && 3 <= input.name.length && $is_custom("pattern_name", "string", "", input.name) && "FULL" === input.workerType && (undefined === input.labels || "object" === typeof input.labels && null !== input.labels && $io1(input.labels, true && _exceptionable)) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator) && (undefined === input.dockerVersion || "string" === typeof input.dockerVersion) && (2 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["name", "workerType", "labels", "creator", "dockerVersion"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "string" === typeof value;
                return false;
            });
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateDockerWorkerRegistryEntryRequest => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name && (3 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 3)",
                    value: input.name
                })) && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("FULL" === input.workerType || $guard(_exceptionable, {
                    path: _path + ".workerType",
                    expected: "\"FULL\"",
                    value: input.workerType
                })) && (undefined === input.labels || ("object" === typeof input.labels && null !== input.labels || $guard(_exceptionable, {
                    path: _path + ".labels",
                    expected: "(DockerWorkerLabels | undefined)",
                    value: input.labels
                })) && $ao1(input.labels, _path + ".labels", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".labels",
                    expected: "(DockerWorkerLabels | undefined)",
                    value: input.labels
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                })) && (undefined === input.dockerVersion || "string" === typeof input.dockerVersion || $guard(_exceptionable, {
                    path: _path + ".dockerVersion",
                    expected: "(string | undefined)",
                    value: input.dockerVersion
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["name", "workerType", "labels", "creator", "dockerVersion"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "string" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "string",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "CreateDockerWorkerRegistryEntryRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "CreateDockerWorkerRegistryEntryRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const CreateDockerWorkerRegistryEntryRequestTypiaAssertStringify = (input: CreateDockerWorkerRegistryEntryRequest): string => {
    return ((input: any): string => { const assert = (input: any): CreateDockerWorkerRegistryEntryRequest => {
        const __is = (input: any): input is CreateDockerWorkerRegistryEntryRequest => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => "string" === typeof input.name && 3 <= input.name.length && $is_custom("pattern_name", "string", "", input.name) && "FULL" === input.workerType && (undefined === input.labels || "object" === typeof input.labels && null !== input.labels && $io1(input.labels)) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator) && (undefined === input.dockerVersion || "string" === typeof input.dockerVersion);
            const $io1 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "string" === typeof value;
                return true;
            });
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateDockerWorkerRegistryEntryRequest => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name && (3 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 3)",
                    value: input.name
                })) && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("FULL" === input.workerType || $guard(_exceptionable, {
                    path: _path + ".workerType",
                    expected: "\"FULL\"",
                    value: input.workerType
                })) && (undefined === input.labels || ("object" === typeof input.labels && null !== input.labels || $guard(_exceptionable, {
                    path: _path + ".labels",
                    expected: "(DockerWorkerLabels | undefined)",
                    value: input.labels
                })) && $ao1(input.labels, _path + ".labels", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".labels",
                    expected: "(DockerWorkerLabels | undefined)",
                    value: input.labels
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                })) && (undefined === input.dockerVersion || "string" === typeof input.dockerVersion || $guard(_exceptionable, {
                    path: _path + ".dockerVersion",
                    expected: "(string | undefined)",
                    value: input.dockerVersion
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "string" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "string",
                            value: value
                        });
                    return true;
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "CreateDockerWorkerRegistryEntryRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "CreateDockerWorkerRegistryEntryRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: CreateDockerWorkerRegistryEntryRequest): string => {
        const $io1 = (input: any): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
            if (["length"].some(prop => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                return "string" === typeof value;
            return true;
        });
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.labels ? "" : `"labels":${undefined !== input.labels ? $so1(input.labels) : undefined},`}${undefined === input.creator ? "" : `"creator":${undefined !== input.creator ? (() => {
            if ("string" === typeof input.creator)
                return $string(input.creator);
            if ("string" === typeof input.creator)
                return "\"" + input.creator + "\"";
            $throws({
                expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                value: input.creator
            });
        })() : undefined},`}${undefined === input.dockerVersion ? "" : `"dockerVersion":${undefined !== input.dockerVersion ? $string(input.dockerVersion) : undefined},`}"name":${$string(input.name)},"workerType":${(() => {
            if ("string" === typeof input.workerType)
                return $string(input.workerType);
            if ("string" === typeof input.workerType)
                return "\"" + input.workerType + "\"";
            $throws({
                expected: "\"FULL\"",
                value: input.workerType
            });
        })()}}`;
        const $so1 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some(regular => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${$string(value)}`; return ""; }).filter(str => "" !== str).join(",")}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const CreateDockerWorkerRegistryEntryRequestValidatorShape = {
    'name': yup.string().matches(/^[a-zA-Z0-9][a-zA-Z0-9 _-]+$/).min(3).defined(),
    'workerType': DockerWorkerTypeValidator.defined(),
    'labels': DockerWorkerLabelsValidator.optional().default(undefined),
    'creator': CreatorValidator.optional().default(undefined),
    'dockerVersion': yup.string(),
};
export const CreateDockerWorkerRegistryEntryRequestValidator = yup.object().shape(CreateDockerWorkerRegistryEntryRequestValidatorShape);
export const CreateDockerWorkerRegistryEntryRequestStringifyShapeProperties: ObjectSchema['properties'] = {
    'name': {
        'type': 'string',
        'pattern': "/^[a-zA-Z0-9][a-zA-Z0-9 _-]+$/".slice(1, -1),
        'minLength': 3,
    },
    'workerType': DockerWorkerTypeStringifyShape,
    'labels': DockerWorkerLabelsStringifyShape,
    'creator': CreatorStringifyShape,
    'dockerVersion': {
        'type': 'string',
    },
};
export const CreateDockerWorkerRegistryEntryRequestStringifyShape: ObjectSchema = {
    title: 'CreateDockerWorkerRegistryEntryRequest Stringifier',
    type: 'object',
    properties: {
        ...CreateDockerWorkerRegistryEntryRequestStringifyShapeProperties
    },
    required: [
        'name',
        'workerType',
    ],
};
export const CreateDockerWorkerRegistryEntryRequestStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(CreateDockerWorkerRegistryEntryRequestStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const CreateDockerWorkerRegistryEntryRequestStringify: (data: CreateDockerWorkerRegistryEntryRequest) => string = CreateDockerWorkerRegistryEntryRequestStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function CreateDockerWorkerRegistryEntryRequestFromJSON(json: any): CreateDockerWorkerRegistryEntryRequest {
    return CreateDockerWorkerRegistryEntryRequestFromJSONTyped(json, false);
}
export function CreateDockerWorkerRegistryEntryRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDockerWorkerRegistryEntryRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'name': json['name'],
        'workerType': DockerWorkerTypeFromJSON(json['workerType']),
        'labels': !exists(json, 'labels') ? undefined : DockerWorkerLabelsFromJSON(json['labels']),
        'creator': !exists(json, 'creator') ? undefined : CreatorFromJSON(json['creator']),
        'dockerVersion': !exists(json, 'dockerVersion') ? undefined : json['dockerVersion'],
    };
}
export function CreateDockerWorkerRegistryEntryRequestToJSON(value?: CreateDockerWorkerRegistryEntryRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'workerType': DockerWorkerTypeToJSON(value.workerType),
        'labels': DockerWorkerLabelsToJSON(value.labels),
        'creator': CreatorToJSON(value.creator),
        'dockerVersion': value.dockerVersion,
    };
}

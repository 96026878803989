
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import useSWR, { SWRConfiguration, useSWRConfig , KeyedMutator } from 'swr';
import { FullConfiguration, PublicConfiguration } from 'swr/_internal';
import useSWRImmutable from 'swr/immutable';
import { PromiseType } from 'utility-types';

import {
	Configuration,
	AuthApi as Ref_AuthAPI,
	DatasourcesApi as Ref_DatasourcesApi,
	ProfilesApi as Ref_ProfilesAPI,
	ScoresApi as Ref_ScoresAPI,
	SamplesApi as Ref_SamplesAPI,
	PredictionsApi as Ref_PreditionsAPI,
	DatasetsApi as Ref_DatasetsAPI,
	CollaborationApi as Ref_CollaborationAPI,
	TagsApi as Ref_TagsAPI,
	MappingsApi as Ref_MappingsAPI,
	EmbeddingsApi as Ref_EmbeddingsAPI,
	Embeddings2dApi as Ref_Embeddings2dAPI,
	SamplingsApi as Ref_SamplingsAPI,
	JobsApi as Ref_JobsAPI,
	DockerApi as Ref_DockerAPI,
	MetaDataConfigurationsApi as Ref_MetaDataConfigurationsAPI,
	TeamsApi as Ref_TeamsAPI,
	JobState,
	JobStatusData,
	Middleware,
	ApiResponse,
} from '@lightly/api-spec';

import { SERVER_LOCATION } from '../constants';

import { getAccessToken } from './api.accessToken';
import { handleFetchError, IGenericError } from './utils';




const shouldRetryOnError: PublicConfiguration['shouldRetryOnError'] = (error) => {

	// never retry aborted requests
	if (error instanceof DOMException && error.name === 'AbortError') {
		return false
	}

	// TypeError are pretty generic errors mostly happening when the API is overloaded or with connection issues
	// - failed to fetch
	// - CORS errors (which sometimes pop up when we hit the API limits most likely due to OPTIONS/preflight failing)
	if (error instanceof TypeError) {
		return true;
	}

	// get the error details which are within the genericError
	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
	const errorDetails = ((error)?.errorDetails || (error)?.response?.errorDetails) as IGenericError;

	// only retry the same error codes as the worker would
	// see: onprem-docker/lightly_worker/src/lightly_worker/retry_utils.py
	// 400,  # Bad Request. Jeremy: This error is typically caused by the client but could also be caused by the server.
	// 408,  # Timeout
	// 409,  # Conflict
	// 429,  # Too Many Requests
	// 500,  # Internal Server Error
	// 502,  # Bad Gateway
	// 503,  # Service Unavailable
	// 504,  # Gateway Timeout
	if (errorDetails && errorDetails.code && [400, 408, 409, 429, 500, 502, 503, 504].includes(errorDetails.code) ) {
		return true;
	}
	return false;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const wrapEndpoint = <
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ENDPOINT extends (...args: any[]) => Promise<any>,
	DATA extends Parameters<ENDPOINT>[0],
	ERR extends Error,
	RETURN_DATA extends PromiseType<ReturnType<ENDPOINT>>
>(
		cacheKey: string, // we could use the function name of endpoint but if we minify, the name disappears as its translated to a inlined anonymous function, so we need to provide the cacheKey explicitly
		endpoint: ENDPOINT,
		generalConfig: SWRConfiguration<RETURN_DATA, ERR> = {
			dedupingInterval: 30_000, // don't get this resource again
			refreshWhenHidden: false,
			refreshWhenOffline: false,
			revalidateOnFocus: false,
			shouldRetryOnError: shouldRetryOnError,
		},
	) => {

	const useWrapEndpoint = (param: null | DATA, config?: typeof generalConfig, actuallySendRequest = true) => {
		const [mounted, setMounted] = useState(false);
		const sendRequest = param === null ? false : actuallySendRequest; // undefined is ok, only NULL prevents a request (or when actuallySendRequest is false)
		const requestKey = `${cacheKey}:${endpoint.name}:${JSON.stringify(param)}`;
		// eslint-disable-next-line react-hooks/exhaustive-deps
		const controller = useMemo(() => sendRequest && mounted ? new AbortController() : null,[sendRequest, mounted, requestKey]);
		// if the controller changes (due to requestKey change), we cancel the request
		useEffect(() => {
			setMounted(true);
			return () => {
				controller?.abort();
				setMounted(false);
			}
		},[controller]);

		const { data, error } = useSWR<RETURN_DATA, ERR>(
			!sendRequest || !mounted ? null : requestKey,
			(_cacheKey: string) => {
				return endpoint(param, { signal: controller?.signal });
			},
			{
				...generalConfig,
				...config,
			},
		);

		// create a bound mutator function so that we can mutate the cache even when actuallySendRequest is false!
		const { mutate: mutateGlobal } = useSWRConfig();
		const mutateBound = useCallback<KeyedMutator<RETURN_DATA | undefined>>((...args) => {
			return mutateGlobal(requestKey, ...args);
		},[mutateGlobal, requestKey])


		// if the requests get aborted, don't consider it as an error to be shown to the user
		let errorFiltered = error;
		if (errorFiltered instanceof DOMException && errorFiltered.name === 'AbortError') {
			errorFiltered = undefined;
		}

		const isLoading = sendRequest && !errorFiltered && data === undefined;
		return {
			data,
			error: errorFiltered,
			isLoading,
			mutate: mutateBound,
		};
	};
	return useWrapEndpoint;
};


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const wrapEndpointImmutable = <
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ENDPOINT extends (...args: any[]) => Promise<any>,
	DATA extends Parameters<ENDPOINT>[0],
	ERR extends Error,
	RETURN_DATA extends PromiseType<ReturnType<ENDPOINT>>
>(
		cacheKey: string, // we could use the function name of endpoint but if we minify, the name disappears as its translated to a inlined anonymous function, so we need to provide the cacheKey explicitly
		endpoint: ENDPOINT,
		generalConfig: SWRConfiguration<RETURN_DATA, ERR> = {},
	) => {

	const useWrapEndpointImmutable = (param: null | DATA, config?: typeof generalConfig) => {
		const sendRequest = param === null ? false : true; // undefined is ok, only NULL prevents a request
		const requestKey = `${cacheKey}:${endpoint.name}:${JSON.stringify(param)}`;
		const { data, error, mutate } = useSWRImmutable<RETURN_DATA, ERR>(
			!sendRequest ? null : requestKey,
			(_cacheKey: string) => {
				return endpoint(param);
			},
			{
				...generalConfig,
				...config,
			},
		);

		const isLoading = sendRequest && !error && data === undefined;
		return {
			data,
			error,
			isLoading,
			mutate,
		};
	};
	return useWrapEndpointImmutable;
};




const wrapGET = wrapEndpoint;
const wrapGETImmutable = wrapEndpointImmutable;
export type useGetEndpoint = ReturnType<typeof wrapGET>;
export type useGetEndpointReturn = ReturnType<ReturnType<typeof wrapGET>>;

const wrapPUTImmutable = wrapEndpointImmutable;
const wrapPOSTImmutable = wrapEndpointImmutable;
const wrapDELETEImmutable: typeof wrapEndpoint = (cacheKey, endpoint, generalConfig: SWRConfiguration = {}) => {
	return wrapEndpointImmutable(
		cacheKey,
		endpoint,
		{
			shouldRetryOnError: false,
			refreshWhenHidden: false,
			refreshWhenOffline: false,
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
			...generalConfig,
		},
	);
};

// this middleware somehow makes tests hang forever. Its not even really called but generated code stops before calling this
const middleware: Middleware[] = process.env['NODE_ENV'] === 'test' ?
	[]
	:
	[
		{
			post: async (responseContext) => {
				if (!responseContext) {
					return responseContext;
				}
				const { response } = responseContext;
				if (!response.ok) {
					const errorDetails = await handleFetchError(response);
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
					(response as any).errorDetails = errorDetails;
				}
				return response;
			},
		},
	];


// We create multiple requests in to concurrent bucket so that we
// load each bucket concurrently but within each bucket sequentially to keep a steady stream of data without overloading the API
// where we fill consecutive pages into the same bucket until they overflow.
// This is needed so that when we flatten/concat the arrays, we get the correct order
// e.g 5 concurrent with 8 pages will make buckets [[0,1],[2,3],[4,5],[6],[7]]
// e.g 10 concurrent with 30 pages will make buckets buckets [[0,1,2],[3,4,5],[6,7,8],[9,10,11],[12,13,14],[15,16,17],[18,19,20],[21,22,23],[24,25,26],[27,28,29]]
const getConcurrentPageBuckets = (concurrency: number, currentSize: number) => {
	const concurrencyLocal = Math.min(concurrency, currentSize);
	const buckets = new Array<Array<number>>(concurrencyLocal)
	const perBucket = Math.floor(currentSize / (concurrencyLocal || 1));
	for (let page = 0; page < currentSize; page++) {
		const bucketIndex = Math.floor(page / perBucket);
		buckets[bucketIndex] = buckets[bucketIndex] || [];
		buckets[bucketIndex].push(page);
	}
	return buckets
}

export const paginateEndpointConcurrent = async <
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ENDPOINT extends (...args: any[]) => Promise<ApiResponse<any>>,
	DATA extends Parameters<ENDPOINT>[0],
	RETURN_DATA extends PromiseType<ReturnType<ENDPOINT>> & []
>(
	endpoint: ENDPOINT,
	data: DATA,
	totalSize: number,
	pageSize = 2_500,
	concurrency = 12, // 15 pushes app engine and some requests will fail and be retried. 10 seems like a good balance not to overload app engine
) => {
	const totalPages = Math.ceil(totalSize / pageSize);

	const proms: Promise<ApiResponse<RETURN_DATA>[]>[] = [];

	const buckets = getConcurrentPageBuckets(concurrency, totalPages)

	for (const pages of buckets) {
		proms.push(
			(async () => {
				const bucketProms: ApiResponse<RETURN_DATA>[] = [];
				for await (const page of pages) {
					const request = (async () => {

						let retries = 5;
						const callEndpoint = async (): Promise<ApiResponse<RETURN_DATA>> => {
							try {
								return await endpoint({
									...data,
									pageSize: pageSize,
									pageOffset: page * pageSize,
								})
							}
							catch (err) {
								// if there is an error and we still have some retries left, we try again
								if (retries && shouldRetryOnError(err)) {
									--retries;
									await new Promise((res) => setTimeout(res, 2_000));
									return await callEndpoint();
								}
								throw err
							}
						}
						return await callEndpoint();
					})();

					bucketProms.push(await request);
				}
				return bucketProms;
			})(),
		);
	}

	const requests = await Promise.all(proms).then((a) => {
		// flatten array twice -> faster than using array.flat(2)
		return [].concat(...[].concat(...a as never[][])) as ApiResponse<RETURN_DATA>[];
	});

	const lastRes = requests[0];
	const results = await Promise.all(requests.map(async (res) => {
		return await res.value();
	}));

	if (!results || !lastRes) {
		throw new Error('No response sent');
	}

	const contentType = lastRes.raw.headers.get('content-type') || 'text/plain';
	return {
		res: lastRes,
		blob: new Blob(
			[
				contentType === 'text/plain' ?
					results.join('\n')
					:
					JSON.stringify(results.flat()),
			],
			{ type: contentType },
		),
	};
};


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const wrapEndpointConcurrent = <
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ENDPOINT extends (...args: any[]) => Promise<any>,
	DATA extends Parameters<ENDPOINT>[0],
	ERR extends Error,
	RETURN_DATA extends PromiseType<ReturnType<ENDPOINT>>,
>(
		cacheKey: string, // we could use the function name of endpoint but if we minify, the name disappears as its translated to a inlined anonymous function, so we need to provide the cacheKey explicitly
		endpoint: ENDPOINT,
		pageSize = 2_500,
		concurrency = 12, // 15 pushes app engine and some requests will fail and be retried. 10 seems like a good balance not to overload app engine
		generalConfig: SWRConfiguration<RETURN_DATA, ERR> = {
			dedupingInterval: 30_000, // don't get this resource again for 30 seconds
			refreshWhenHidden: false,
			refreshWhenOffline: false,
			revalidateOnFocus: false,
		},
	) => {

	const useWrapEndpointConcurrent = (param: null | DATA, totalSize = 0, config?: typeof generalConfig) => {
		const [mounted, setMounted] = useState(false);
		const sendRequest = param === null ? false : true; // undefined is ok, only NULL prevents a request

		const sizeRef = useRef(Math.ceil(totalSize / pageSize));
		const totalSizeRef = useRef(totalSize);

		const [totalLoaded, setTotalLoaded] = useState<number>(0)

		const requestKey = `${cacheKey}:${endpoint.name}:${JSON.stringify(param)}`;

		// if the controller changes (due to requestKey change), we cancel the request
		// eslint-disable-next-line react-hooks/exhaustive-deps
		const controller = useMemo(() => sendRequest && mounted ? new AbortController() : null,[sendRequest, mounted, requestKey]);
		useEffect(() => {
			setMounted(true);
			return () => {
				controller?.abort();
				setMounted(false);
			}
		},[controller]);

		const { cache } = useSWRConfig() as FullConfiguration<RETURN_DATA>;
		const { data, error, mutate, isValidating } = useSWR<RETURN_DATA, ERR>(
			!sendRequest || !mounted || typeof sizeRef.current !== 'number' ? null : `${requestKey}:parallel`,
			() => {

				// we get the total parallized cache so that we can "load" chunks/pages from there
				const prev = cache.get(`${requestKey}:parallel`)?.data as RETURN_DATA;

				const proms: Promise<unknown[]>[] = [];
				const buckets = getConcurrentPageBuckets(concurrency, sizeRef.current)
				for (const pages of buckets) {
					proms.push(
						(async () => {
							const bucketProms: unknown[] = [];
							for await (const page of pages) {
								const request = (async () => {

									// if we already aborted, we don't need to fetch
									if (controller?.signal.aborted) {
										return;
									}

									let data: unknown
									// if we have a previous chunk cache and its complete, we take a cut from the parallel cache
									const hasCached = cache.get(`${requestKey}:${page}:count`)?.data as number | undefined;
									if (prev && hasCached && hasCached >= pageSize) {
										// console.log('Using cache of', page)
										data = (prev as unknown[]).slice(page * pageSize, (page + 1) * pageSize) as unknown;
									}
									else {
										// console.log('Refetching', page)
										// call endpoint and retry on error
										let retries = 5;
										const callEndpoint = async (): Promise<unknown> => {
											try {
												return await endpoint({ ...param, pageOffset: page * pageSize, pageSize: pageSize }, { signal: controller?.signal }) as unknown;
											}
											catch (err) {
												// if there is an error and we still have some retries left, we try again
												if (retries && shouldRetryOnError(err)) {
													--retries;
													await new Promise((res) => setTimeout(res, 2_000));
													return await callEndpoint();
												}
												throw err
											}
										}
										data = await callEndpoint();
									}

									// keep track of how many entries we have loaded so far
									if (data && Array.isArray(data) && data.length) {
										setTotalLoaded((prev) => {
											return prev + (Array.isArray(data) ? data.length : 0)
										});
										cache.set(`${requestKey}:${page}:count`, { ...cache.get(`${requestKey}:${page}:count`), data: data.length });
									}
									return data;
								})();

								bucketProms.push(await request);
							}
							return bucketProms;
						})(),
					);
				}
				return Promise.all(proms).then((a) => {
					// flatten array twice -> faster than using array.flat(2)
					return [].concat(...[].concat(...a as never[][])) as RETURN_DATA;
				});
			},
			{
				...generalConfig,
				...config,
			},
		);

		// if we got a hard error, we should cancel all other requests as there is no merit in keeping them alive
		useEffect(() => {
			if (error && controller) {
				controller.abort();
			}
		},[controller, error]);


		// the mutator can either be called with force
		// true: everything needs to get reevaluated, so we remove the parallel + all chunk count cache
		// false: only the chunk count caches need to get reevaluated but alter the parallel cache
		const mutateAll = useCallback((force?: boolean) => {
			const prev = cache.get(`${requestKey}:parallel`)?.data as RETURN_DATA;
			if (force) {
				// flush every chunk cache
				for (let page = 0; page < sizeRef.current; page++) {
					cache.delete(`${requestKey}:${page}:count`);
				}
			}
			else {
				// reset all chunk count caches that don't have at least pageSize elements in order to reload them
				// => used when dataset is being uploaded from DatasetHome.tsx to periodically reload the dataset/samples
				let goodUntil = 0;
				let oneIsIncomplete = false
				for (let page = 0; page < sizeRef.current; page++) {
					const cachedCount = cache.get(`${requestKey}:${page}:count`)?.data as number | undefined;
					// as soon as one chunk is incomplete, we need to remove all chunks after that
					if (oneIsIncomplete || (cachedCount !== undefined && cachedCount < pageSize) ) {
						// console.log('Flushing', page)
						cache.delete(`${requestKey}:${page}:count`);
						oneIsIncomplete = true;
					}
					else {
						// console.log('Keeping', page)
						goodUntil += pageSize
					}
				}
				const newCached = prev ? (prev as unknown[]).slice(0, goodUntil) as RETURN_DATA : prev;
				cache.set(`${requestKey}:parallel`, newCached)
			}

			// return old cached version and force a revalidation
			// important when we are uploading samples and are in the first chunk/page
			return mutate(force ? undefined : prev, { revalidate: true });
		}, [cache, mutate, requestKey]);


		// callback for changing the total number of elements to reevaluate the number of pages
		const setTotal = useCallback((newTotalSize: number) => {
			const newSize = Math.ceil(newTotalSize / pageSize);
			// console.log('newSize', newSize, 'current?', sizeRef.current)
			if (newSize !== sizeRef.current || newTotalSize !== totalSizeRef.current) {
				// console.log('Changing size to ', newSize);
				sizeRef.current = newSize;
				totalSizeRef.current = newTotalSize;
				void mutateAll(false);
			}
		}, [mutateAll]);

		// if the total number of elements has changed via props, we need to reevaluate the number of pages
		useEffect(() => {
			const newSize = Math.ceil(totalSize / pageSize);
			if (newSize !== sizeRef.current || totalSize !== totalSizeRef.current) {
				sizeRef.current = Math.ceil(totalSize / pageSize);
				totalSizeRef.current = totalSize;
				void mutateAll(false);
			}
		},[mutateAll, totalSize])



		let errorFiltered = error;
		// if the requests get aborted, don't consider it as an error to be shown to the user
		if (errorFiltered instanceof DOMException && errorFiltered.name === 'AbortError') {
			errorFiltered = undefined;
		}

		const isLoading = sendRequest && !error && (data === undefined || (isValidating && data && data.length === 0));

		return {
			data,
			error: errorFiltered,
			isLoading,
			mutate: mutateAll,
			setTotal,
			totalLoaded,
		};
	};
	return useWrapEndpointConcurrent;
};

export type useGetEndpointConcurrent = ReturnType<typeof wrapEndpointConcurrent>;
export type useGetEndpointConcurrentReturn = ReturnType<ReturnType<typeof wrapEndpointConcurrent>>;


export const AuthApi = new Ref_AuthAPI(new Configuration({ basePath: SERVER_LOCATION, accessToken: getAccessToken, middleware }));
export const useGetTokens = wrapGETImmutable('getTokens', AuthApi.getTokens.bind(AuthApi));

export const DatasetsApi = new Ref_DatasetsAPI(new Configuration({ basePath: SERVER_LOCATION, accessToken: getAccessToken, middleware }));
export const useCreateDataset = wrapPOSTImmutable('createDataset', DatasetsApi.createDataset.bind(DatasetsApi));
export const useGetDatasets = wrapGET('getDatasets', DatasetsApi.getDatasets.bind(DatasetsApi));
export const useGetChildrenOfDatasetId = wrapGET('getChildrenOfDatasetId', DatasetsApi.getChildrenOfDatasetId.bind(DatasetsApi));
export const useGetDatasetsQueryByName = wrapGET('getDatasetsQueryByName', DatasetsApi.getDatasetsQueryByName.bind(DatasetsApi));
export const useGetDatasetsEnriched = wrapGET('getDatasetsEnriched', DatasetsApi.getDatasetsEnriched.bind(DatasetsApi));
export const useGetDatasetsEnrichedQueryByName = wrapGET('getDatasetsEnrichedQueryByName', DatasetsApi.getDatasetsEnrichedQueryByName.bind(DatasetsApi));
export const useGetDatasetsEnrichedQueryByIds = wrapGET('getDatasetsEnrichedQueryByIds', DatasetsApi.getDatasetsEnrichedQueryByIds.bind(DatasetsApi));
export const useGetDatasetById = wrapGET('getDatasetById', DatasetsApi.getDatasetById.bind(DatasetsApi));
export const useDeleteDatasetById = wrapDELETEImmutable('deleteDatasetById', DatasetsApi.deleteDatasetById.bind(DatasetsApi));

export const CollaborationApi = new Ref_CollaborationAPI(new Configuration({ basePath: SERVER_LOCATION, accessToken: getAccessToken, middleware }));
export const useGetSharedAccessConfigsByDatasetId = wrapGET('getSharedAccessConfigsByDatasetId', CollaborationApi.getSharedAccessConfigsByDatasetId.bind(CollaborationApi));

export const DatasourcesApi = new Ref_DatasourcesApi(new Configuration({ basePath: SERVER_LOCATION, accessToken: getAccessToken, middleware }));
export const useGetDatasourceByDatasetId = wrapGET('getDatasourceByDatasetId', DatasourcesApi.getDatasourceByDatasetId.bind(DatasourcesApi));
export const useGetDatasourcesByDatasetId = wrapGET('getDatasourcesByDatasetId', DatasourcesApi.getDatasourcesByDatasetId.bind(DatasourcesApi));
export const useVerifyDatasourceByDatasetId = wrapGET('verifyDatasourceByDatasetId', DatasourcesApi.verifyDatasourceByDatasetId.bind(DatasourcesApi));
export const useUpdateDatasourceByDatasetId = wrapPUTImmutable('updateDatasourceByDatasetId', DatasourcesApi.updateDatasourceByDatasetId.bind(DatasourcesApi));
export const useUpdateDatasourceProcessedUntilTimestampByDatasetId = wrapPUTImmutable('updateDatasourceProcessedUntilTimestampByDatasetId', DatasourcesApi.updateDatasourceProcessedUntilTimestampByDatasetId.bind(DatasourcesApi));

export const TagsApi = new Ref_TagsAPI(new Configuration({ basePath: SERVER_LOCATION, accessToken: getAccessToken, middleware }));
export const useGetTagByTagId = wrapGET('getTagByTagId', TagsApi.getTagByTagId.bind(TagsApi));
export const useGetTagsByDatasetId = wrapGET('getTagsByDatasetId', TagsApi.getTagsByDatasetId.bind(TagsApi));
export const useGetFilenamesByTagId = wrapGET('getFilenamesByTagId', TagsApi.getFilenamesByTagId.bind(TagsApi));

export const MappingsApi = new Ref_MappingsAPI(new Configuration({ basePath: SERVER_LOCATION, accessToken: getAccessToken, middleware }));
export const useGetSampleMappingsByDatasetId = wrapEndpointConcurrent(
	'getSampleMappingsByDatasetId',
	MappingsApi.getSampleMappingsByDatasetId.bind(MappingsApi),
	50_000, // page size
	4, // concurrency
	{
		revalidateIfStale: false,
		refreshWhenHidden: false,
		refreshWhenOffline: false,
		revalidateOnFocus: false,
	},
);

export const EmbeddingsApi = new Ref_EmbeddingsAPI(new Configuration({ basePath: SERVER_LOCATION, accessToken: getAccessToken, middleware }));
export const useGetEmbeddingsByDatasetId = wrapGET('getEmbeddingsByDatasetId', EmbeddingsApi.getEmbeddingsByDatasetId.bind(EmbeddingsApi));
export const useGetEmbeddingsCSVReadUrlById = wrapGET('getEmbeddingsCSVReadUrlById', EmbeddingsApi.getEmbeddingsCSVReadUrlById.bind(EmbeddingsApi));
export const useDeleteEmbeddingById = wrapDELETEImmutable('deleteEmbeddingById', EmbeddingsApi.deleteEmbeddingById.bind(EmbeddingsApi));

export const Embeddings2dApi = new Ref_Embeddings2dAPI(new Configuration({ basePath: SERVER_LOCATION, accessToken: getAccessToken, middleware }));
export const useGetEmbeddings2dByEmbeddingId = wrapGET('getEmbeddings2dByEmbeddingId', Embeddings2dApi.getEmbeddings2dByEmbeddingId.bind(Embeddings2dApi));
export const useGetEmbedding2dById = wrapGET('getEmbedding2dById', Embeddings2dApi.getEmbedding2dById.bind(Embeddings2dApi));
export const useCreateEmbeddings2dByEmbeddingId = wrapPOSTImmutable('createEmbeddings2dByEmbeddingId', Embeddings2dApi.createEmbeddings2dByEmbeddingId.bind(Embeddings2dApi));

export const SamplingsApi = new Ref_SamplingsAPI(new Configuration({ basePath: SERVER_LOCATION, accessToken: getAccessToken, middleware }));
export const useTriggerSamplingById = wrapGET('triggerSamplingById', SamplingsApi.triggerSamplingById.bind(SamplingsApi));

export const SamplesApi = new Ref_SamplesAPI(new Configuration({ basePath: SERVER_LOCATION, accessToken: getAccessToken, middleware }));
export const useGetSampleById = wrapGET('getSampleById', SamplesApi.getSampleById.bind(SamplesApi));
export const useUpdateSampleById = wrapPUTImmutable('updateSampleById', SamplesApi.updateSampleById.bind(SamplesApi));
export const useGetSampleImageReadUrlById = wrapGETImmutable('getSampleImageReadUrlById', SamplesApi.getSampleImageReadUrlById.bind(SamplesApi));
export const useGetSampleImageWriteUrlById = wrapGET('getSampleImageWriteUrlById', SamplesApi.getSampleImageWriteUrlById.bind(SamplesApi));
export const useCreateSampleByDatasetId = wrapPOSTImmutable('createSampleByDatasetId', SamplesApi.createSampleByDatasetId.bind(SamplesApi));
export const useGetSamplesByDatasetId = wrapEndpointConcurrent(
	'getSamplesByDatasetId',
	SamplesApi.getSamplesByDatasetId.bind(SamplesApi),
	undefined,
	undefined,
	{
		dedupingInterval: 0, // don't get this resource again
		revalidateIfStale: false,
		refreshWhenHidden: false,
		refreshWhenOffline: false,
		revalidateOnFocus: false,
		// swr does a deep compare with a safehash function that overloads the heap
		// as the data can get huge when we get 400k+ of samples, we don't want to compare as it would be too expensive
		// v8/chrome/blink will hit its max heap size and crash with RANGE ERROR: invalid string size
		// https://github.com/nodejs/node/issues/35973
		// https://chromium-review.googlesource.com/c/v8/v8/+/2030916
		compare: (a, b) => {
			if (a === b) {
				return true;
			}
			if ( a && b && Array.isArray(a) && Array.isArray(b) && a.length && b.length && a.length === b.length ) {
				// check first and last entry if their ids and index are the same
				if (
					// if 401, there first entry can somehow be undefined. Does not make sense, but lets safeguard
					// -> https://lightly-qr.sentry.io/issues/4863050049/?alert_rule_id=3689862&alert_type=issue&environment=prod%40app&notification_uuid=163c585f-c155-4eaa-8d6c-b7a20427948a&project=5581468&referrer=slack
					a[0] &&
					b[0] &&
					a[a.length - 1] &&
					b[b.length - 1] &&

					// check if the id's and index are the same of the first and last entries
					a[0].id === b[0].id &&
					a[0].index === b[0].index &&
					a[a.length - 1].id === b[b.length - 1].id &&
					a[a.length - 1].index === b[b.length - 1].index
				) {
					return true;
				}
			}
			return false
		},
	},
);

export const PredictionsApi = new Ref_PreditionsAPI(new Configuration({ basePath: SERVER_LOCATION, accessToken: getAccessToken, middleware }));
export const useGetPredictionsBySampleId = wrapGET('getPredictionsBySampleId', PredictionsApi.getPredictionsBySampleId.bind(PredictionsApi));
export const useGetPredictionTaskSchemaByTaskName = wrapGET('getPredictionTaskSchemaByTaskName', PredictionsApi.getPredictionTaskSchemaByTaskName.bind(PredictionsApi));
export const useGetPredictionTaskSchemasByDatasetId = wrapGET('getPredictionTaskSchemasByDatasetId', PredictionsApi.getPredictionTaskSchemasByDatasetId.bind(PredictionsApi));
export const useGetPredictionsByDatasetId = wrapEndpointConcurrent(
	'getPredictionsByDatasetId',
	PredictionsApi.getPredictionsByDatasetId.bind(PredictionsApi),
	250, // as predictions can be huge and many per sample, we need to be defensive
	undefined,
	{
		dedupingInterval: 0, // don't get this resource again
		revalidateIfStale: false,
		refreshWhenHidden: false,
		refreshWhenOffline: false,
		revalidateOnFocus: false,
	},
)


export const ScoresApi = new Ref_ScoresAPI(new Configuration({ basePath: SERVER_LOCATION, accessToken: getAccessToken, middleware }));

export const useGetActiveLearningV2ScoreByDatasetAndScoreId = wrapGET('getActiveLearningV2ScoreByDatasetAndScoreId', ScoresApi.getActiveLearningV2ScoreByDatasetAndScoreId.bind(ScoresApi));
export const useGetActiveLearningV2ScoresByDatasetId = wrapGET('getActiveLearningV2ScoresByDatasetId', ScoresApi.getActiveLearningV2ScoresByDatasetId.bind(ScoresApi));

export const MetaDataConfigurationsApi = new Ref_MetaDataConfigurationsAPI(new Configuration({ basePath: SERVER_LOCATION, accessToken: getAccessToken, middleware }));
export const useCreateMetaDataConfiguration = wrapPOSTImmutable('createMetaDataConfiguration', MetaDataConfigurationsApi.createMetaDataConfiguration.bind(MetaDataConfigurationsApi));
export const useGetMetaDataConfigurationById = wrapGET('getMetaDataConfigurationById', MetaDataConfigurationsApi.getMetaDataConfigurationById.bind(MetaDataConfigurationsApi));
export const useUpdateMetaDataConfigurationById = wrapPUTImmutable('updateMetaDataConfigurationById', MetaDataConfigurationsApi.updateMetaDataConfigurationById.bind(MetaDataConfigurationsApi));
export const useGetMetaDataConfigurations = wrapGET('getMetaDataConfigurations', MetaDataConfigurationsApi.getMetaDataConfigurations.bind(MetaDataConfigurationsApi));

export const JobsApi = new Ref_JobsAPI(new Configuration({ basePath: SERVER_LOCATION, accessToken: getAccessToken, middleware }));
export const useGetJobStatusById = wrapGET('getJobStatusById', JobsApi.getJobStatusById.bind(JobsApi));
export const useGetJobs = wrapGET('getJobs', JobsApi.getJobs.bind(JobsApi));

export const DockerApi = new Ref_DockerAPI(new Configuration({ basePath: SERVER_LOCATION, accessToken: getAccessToken, middleware }));
export const useGetDockerRunById = wrapGET('getDockerRunById', DockerApi.getDockerRunById.bind(DockerApi));
export const useGetDockerRuns = wrapGET('getDockerRuns', DockerApi.getDockerRuns.bind(DockerApi));
export const useGetDockerRunsCount = wrapGET('getDockerRunsCount', DockerApi.getDockerRunsCount.bind(DockerApi));
export const useGetDockerRunByScheduledId = wrapGET('getDockerRunByScheduledId', DockerApi.getDockerRunByScheduledId.bind(DockerApi));
export const useGetDockerWorkerRegistryEntries = wrapGET('getDockerWorkerRegistryEntries', DockerApi.getDockerWorkerRegistryEntries.bind(DockerApi));
export const useGetDockerWorkerConfigById = wrapGET('getDockerWorkerConfigById', DockerApi.getDockerWorkerConfigById.bind(DockerApi));
export const useGetDockerWorkerConfigV2ById = wrapGET('getDockerWorkerConfigV2ById', DockerApi.getDockerWorkerConfigV2ById.bind(DockerApi));
export const useGetDockerWorkerConfigV3ById = wrapGET('getDockerWorkerConfigV3ById', DockerApi.getDockerWorkerConfigV3ById.bind(DockerApi));
export const useGetDockerWorkerConfigVXById = wrapGET('getDockerWorkerConfigVXById', DockerApi.getDockerWorkerConfigVXById.bind(DockerApi));
export const useDeleteDockerWorkerRegistryEntryById = wrapDELETEImmutable('deleteDockerWorkerRegistryEntryById', DockerApi.deleteDockerWorkerRegistryEntryById.bind(DockerApi));
export const useGetDockerRunsScheduledByStateAndLabels = wrapGET('getDockerRunsScheduledByStateAndLabels', DockerApi.getDockerRunsScheduledByStateAndLabels.bind(DockerApi), { refreshInterval: 10000 });
export const useGetDockerRunsScheduledByDatasetId = wrapGET('getDockerRunsScheduledByDatasetId', DockerApi.getDockerRunsScheduledByDatasetId.bind(DockerApi));
export const useCancelScheduledDockerRunStateById = wrapGET('cancelScheduledDockerRunStateById', DockerApi.cancelScheduledDockerRunStateById.bind(DockerApi));
export const useGetDockerRunArtifactReadUrlById = wrapGET('getDockerRunArtifactReadUrlById', DockerApi.getDockerRunArtifactReadUrlById.bind(DockerApi));
export const useGetDockerRunLogsById = wrapGET('getDockerRunLogsById', DockerApi.getDockerRunLogsById.bind(DockerApi));

export const ProfilesApi = new Ref_ProfilesAPI(new Configuration({ basePath: SERVER_LOCATION, accessToken: getAccessToken, middleware }));
export const useGetProfileOfLoggedInUser = wrapGETImmutable('getProfileOfLoggedInUser', ProfilesApi.getProfileOfLoggedInUser.bind(ProfilesApi));
export const useGetConsent = wrapGET('getConsent', ProfilesApi.getConsent.bind(ProfilesApi));
export const useGetProfileOfUserById = wrapGETImmutable('getProfileOfUserById', ProfilesApi.getProfileOfUserById.bind(ProfilesApi));
export const useUpdateQuestionnaire = wrapGETImmutable('updateQuestionnaire', ProfilesApi.updateQuestionnaire.bind(ProfilesApi));
export const useGetDelegatedAccessExternalIdsOfLoggedInUser = wrapGETImmutable('getDelegatedAccessExternalIdsOfLoggedInUser', ProfilesApi.getDelegatedAccessExternalIdsOfLoggedInUser.bind(ProfilesApi));

export const TeamsApi = new Ref_TeamsAPI(new Configuration({ basePath: SERVER_LOCATION, accessToken: getAccessToken, middleware }));
export const useGetServiceAccountsByTeamId = wrapGETImmutable('getServiceAccountsByTeamId', TeamsApi.getServiceAccountsByTeamId.bind(TeamsApi));
export const useGetTeamMembersById = wrapGET('getTeamMembersById', TeamsApi.getTeamMembersById.bind(TeamsApi));
export const useDeleteTeamMemberById = wrapGET('deleteTeamMemberById', TeamsApi.deleteTeamMemberById.bind(TeamsApi));

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAPICacheMutator = () => {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const { cache, mutate } = useSWRConfig(); // as  {cache: Map<string, unknown>, mutate: (...args:unknown[]) => Promise<unknown>};
	return (matcher: RegExp, ...args: unknown[]) => {
		const keys = [];
		const cacheKeys = (cache as Map<string, unknown>).keys();
		for (const key of cacheKeys) {
			matcher.lastIndex = -1;
			if (matcher.test(key)) {
				keys.push(key);
			}
		}
		const mutations = keys.map((key) => {
			return mutate(key, ...args);
		});
		return Promise.all(mutations);
	};
};




export interface IWaitForJobStatusReturnType {
	promise: Promise<PromiseType<ReturnType<typeof JobsApi.getJobStatusById>>>;
	cancel: () => void;
}
export interface IWaitForJobStatusConfig {
	maxRetries?: number;
	status?: JobState[];
	onStatusUpdate?: (jobStatus: JobStatusData) => void;
}
export const waitForJobStatus = (jobId: string, config: IWaitForJobStatusConfig = {}): IWaitForJobStatusReturnType => {
	const { maxRetries = -1, onStatusUpdate, status = [JobState.FINISHED] } = config;
	let attempts = 0;
	let cancel = false;
	const check = () => {
		attempts++;
		return {
			cancel: () => {
				cancel = true;
			},
			promise: new Promise<PromiseType<ReturnType<typeof JobsApi.getJobStatusById>>>((res, rej) => {
				JobsApi.getJobStatusById({ jobId })
					.then((jobStatus) => {
						if (cancel) {
							return;
						}
						if (onStatusUpdate) {
							onStatusUpdate(jobStatus);
						}
						if (!status.includes(jobStatus.status)) {
							return new Promise((res) => {
								setTimeout(() => {
									if (cancel) {
										return res(Promise.resolve());
									}
									res(check().promise);
								}, (jobStatus.waitTimeTillNextPoll || 1) * 1e3);
							});
						}
						res(jobStatus);
					})
					.catch((_err) => {
						if (cancel) {
							return;
						}
						if (attempts < maxRetries || maxRetries === -1) {
							return setTimeout(() => {
								res(check().promise);
							}, 10e3);
						}
						rej(new Error('cant get job status: max retries reached'));
					});
			}),
		};
	};
	return check();
};

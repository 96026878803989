/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { TagChangeDataArithmetics } from './TagChangeDataArithmetics';
import { TagChangeDataArithmeticsFromJSON, TagChangeDataArithmeticsFromJSONTyped, TagChangeDataArithmeticsToJSON, } from './TagChangeDataArithmetics';
import type { TagChangeDataInitial } from './TagChangeDataInitial';
import { TagChangeDataInitialFromJSON, TagChangeDataInitialFromJSONTyped, TagChangeDataInitialToJSON, } from './TagChangeDataInitial';
import type { TagChangeDataMetadata } from './TagChangeDataMetadata';
import { TagChangeDataMetadataFromJSON, TagChangeDataMetadataFromJSONTyped, TagChangeDataMetadataToJSON, } from './TagChangeDataMetadata';
import type { TagChangeDataRename } from './TagChangeDataRename';
import { TagChangeDataRenameFromJSON, TagChangeDataRenameFromJSONTyped, TagChangeDataRenameToJSON, } from './TagChangeDataRename';
import type { TagChangeDataSampler } from './TagChangeDataSampler';
import { TagChangeDataSamplerFromJSON, TagChangeDataSamplerFromJSONTyped, TagChangeDataSamplerToJSON, } from './TagChangeDataSampler';
import type { TagChangeDataSamples } from './TagChangeDataSamples';
import { TagChangeDataSamplesFromJSON, TagChangeDataSamplesFromJSONTyped, TagChangeDataSamplesToJSON, } from './TagChangeDataSamples';
import type { TagChangeDataScatterplot } from './TagChangeDataScatterplot';
import { TagChangeDataScatterplotFromJSON, TagChangeDataScatterplotFromJSONTyped, TagChangeDataScatterplotToJSON, } from './TagChangeDataScatterplot';
import type { TagChangeDataUpsize } from './TagChangeDataUpsize';
import { TagChangeDataUpsizeFromJSON, TagChangeDataUpsizeFromJSONTyped, TagChangeDataUpsizeToJSON, } from './TagChangeDataUpsize';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface TagChangeData
 *
 */
// create custom validators for the regexes
export interface TagChangeData {
    [key: string]: any | any;
    /**
     *
     * #type TagChangeDataInitial
     * #memberof TagChangeData
        
        
         */
    initial?: TagChangeDataInitial;
    /**
     *
     * #type TagChangeDataRename
     * #memberof TagChangeData
        
        
         */
    rename?: TagChangeDataRename;
    /**
     *
     * #type TagChangeDataUpsize
     * #memberof TagChangeData
        
        
         */
    upsize?: TagChangeDataUpsize;
    /**
     *
     * #type TagChangeDataArithmetics
     * #memberof TagChangeData
        
        
         */
    arithmetics?: TagChangeDataArithmetics;
    /**
     *
     * #type TagChangeDataMetadata
     * #memberof TagChangeData
        
        
         */
    metadata?: TagChangeDataMetadata;
    /**
     *
     * #type TagChangeDataSamples
     * #memberof TagChangeData
        
        
         */
    samples?: TagChangeDataSamples;
    /**
     *
     * #type TagChangeDataScatterplot
     * #memberof TagChangeData
        
        
         */
    scatterplot?: TagChangeDataScatterplot;
    /**
     *
     * #type TagChangeDataSampler
     * #memberof TagChangeData
        
        
         */
    sampler?: TagChangeDataSampler;
}
/**
 * Check if a given object implements the TagChangeData interface.
 */
export function instanceOfTagChangeData(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { TagChangeDataArithmeticsValidator, TagChangeDataArithmeticsStringifyShape, TagChangeDataArithmeticsStringifyShapeProperties, } from './TagChangeDataArithmetics';
import { TagChangeDataInitialValidator, TagChangeDataInitialStringifyShape, TagChangeDataInitialStringifyShapeProperties, } from './TagChangeDataInitial';
import { TagChangeDataMetadataValidator, TagChangeDataMetadataStringifyShape, TagChangeDataMetadataStringifyShapeProperties, } from './TagChangeDataMetadata';
import { TagChangeDataRenameValidator, TagChangeDataRenameStringifyShape, TagChangeDataRenameStringifyShapeProperties, } from './TagChangeDataRename';
import { TagChangeDataSamplerValidator, TagChangeDataSamplerStringifyShape, TagChangeDataSamplerStringifyShapeProperties, } from './TagChangeDataSampler';
import { TagChangeDataSamplesValidator, TagChangeDataSamplesStringifyShape, TagChangeDataSamplesStringifyShapeProperties, } from './TagChangeDataSamples';
import { TagChangeDataScatterplotValidator, TagChangeDataScatterplotStringifyShape, TagChangeDataScatterplotStringifyShapeProperties, } from './TagChangeDataScatterplot';
import { TagChangeDataUpsizeValidator, TagChangeDataUpsizeStringifyShape, TagChangeDataUpsizeStringifyShapeProperties, } from './TagChangeDataUpsize';
export const TagChangeDataTypiaAssertEquals = (input: any) => {
    return ((input: any): TagChangeData => {
        const __is = (input: any, _exceptionable: boolean = true): input is TagChangeData => {
            const $join = (typia.createAssertEquals as any).join;
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.initial || "object" === typeof input.initial && null !== input.initial && false === Array.isArray(input.initial) && $io1(input.initial, true && _exceptionable)) && (undefined === input.rename || "object" === typeof input.rename && null !== input.rename && $io2(input.rename, true && _exceptionable)) && (undefined === input.upsize || "object" === typeof input.upsize && null !== input.upsize && $io3(input.upsize, true && _exceptionable)) && (undefined === input.arithmetics || "object" === typeof input.arithmetics && null !== input.arithmetics && $io4(input.arithmetics, true && _exceptionable)) && (undefined === input.metadata || "object" === typeof input.metadata && null !== input.metadata && $io5(input.metadata, true && _exceptionable)) && (undefined === input.samples || "object" === typeof input.samples && null !== input.samples && $io7(input.samples, true && _exceptionable)) && (undefined === input.scatterplot || "object" === typeof input.scatterplot && null !== input.scatterplot && $io8(input.scatterplot, true && _exceptionable)) && (undefined === input.sampler || "object" === typeof input.sampler && null !== input.sampler && $io9(input.sampler, true && _exceptionable)) && Object.keys(input).every(key => {
                if (["initial", "rename", "upsize", "arithmetics", "metadata", "samples", "scatterplot", "sampler"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_runId", "string", "", input.runId)) && (0 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["runId"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.from && "string" === typeof input.to && (2 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["from", "to"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io3 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_runId", "string", "", input.runId)) && "number" === typeof input.from && "number" === typeof input.to && (2 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["runId", "from", "to"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io4 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.operation && "string" === typeof input.tag1 && "string" === typeof input.tag2 && (3 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["operation", "tag1", "tag2"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io5 = (input: any, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && ("number" === typeof input.count && 0 <= input.count) && ("number" === typeof input.added && 0 <= input.added) && ("number" === typeof input.removed && 0 <= input.removed) && ("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) && $io6(input.changes, true && _exceptionable)) && (5 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["method", "count", "added", "removed", "changes"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io6 = (input: any, _exceptionable: boolean = true): boolean => Object.keys(input).every(key => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            const $io7 = (input: any, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && ("number" === typeof input.count && 0 <= input.count) && ("number" === typeof input.added && 0 <= input.added) && ("number" === typeof input.removed && 0 <= input.removed) && (4 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["method", "count", "added", "removed"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io8 = (input: any, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && (undefined === input.view || "string" === typeof input.view) && ("number" === typeof input.count && 0 <= input.count) && ("number" === typeof input.added && 0 <= input.added) && ("number" === typeof input.removed && 0 <= input.removed) && (4 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["method", "view", "count", "added", "removed"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io9 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.method && (1 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["method"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is TagChangeData => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.initial || ("object" === typeof input.initial && null !== input.initial && false === Array.isArray(input.initial) || $guard(_exceptionable, {
                    path: _path + ".initial",
                    expected: "(TagChangeDataInitial | undefined)",
                    value: input.initial
                })) && $ao1(input.initial, _path + ".initial", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".initial",
                    expected: "(TagChangeDataInitial | undefined)",
                    value: input.initial
                })) && (undefined === input.rename || ("object" === typeof input.rename && null !== input.rename || $guard(_exceptionable, {
                    path: _path + ".rename",
                    expected: "(TagChangeDataRename | undefined)",
                    value: input.rename
                })) && $ao2(input.rename, _path + ".rename", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".rename",
                    expected: "(TagChangeDataRename | undefined)",
                    value: input.rename
                })) && (undefined === input.upsize || ("object" === typeof input.upsize && null !== input.upsize || $guard(_exceptionable, {
                    path: _path + ".upsize",
                    expected: "(TagChangeDataUpsize | undefined)",
                    value: input.upsize
                })) && $ao3(input.upsize, _path + ".upsize", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".upsize",
                    expected: "(TagChangeDataUpsize | undefined)",
                    value: input.upsize
                })) && (undefined === input.arithmetics || ("object" === typeof input.arithmetics && null !== input.arithmetics || $guard(_exceptionable, {
                    path: _path + ".arithmetics",
                    expected: "(TagChangeDataArithmetics | undefined)",
                    value: input.arithmetics
                })) && $ao4(input.arithmetics, _path + ".arithmetics", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".arithmetics",
                    expected: "(TagChangeDataArithmetics | undefined)",
                    value: input.arithmetics
                })) && (undefined === input.metadata || ("object" === typeof input.metadata && null !== input.metadata || $guard(_exceptionable, {
                    path: _path + ".metadata",
                    expected: "(TagChangeDataMetadata | undefined)",
                    value: input.metadata
                })) && $ao5(input.metadata, _path + ".metadata", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".metadata",
                    expected: "(TagChangeDataMetadata | undefined)",
                    value: input.metadata
                })) && (undefined === input.samples || ("object" === typeof input.samples && null !== input.samples || $guard(_exceptionable, {
                    path: _path + ".samples",
                    expected: "(TagChangeDataSamples | undefined)",
                    value: input.samples
                })) && $ao7(input.samples, _path + ".samples", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".samples",
                    expected: "(TagChangeDataSamples | undefined)",
                    value: input.samples
                })) && (undefined === input.scatterplot || ("object" === typeof input.scatterplot && null !== input.scatterplot || $guard(_exceptionable, {
                    path: _path + ".scatterplot",
                    expected: "(TagChangeDataScatterplot | undefined)",
                    value: input.scatterplot
                })) && $ao8(input.scatterplot, _path + ".scatterplot", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".scatterplot",
                    expected: "(TagChangeDataScatterplot | undefined)",
                    value: input.scatterplot
                })) && (undefined === input.sampler || ("object" === typeof input.sampler && null !== input.sampler || $guard(_exceptionable, {
                    path: _path + ".sampler",
                    expected: "(TagChangeDataSampler | undefined)",
                    value: input.sampler
                })) && $ao9(input.sampler, _path + ".sampler", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".sampler",
                    expected: "(TagChangeDataSampler | undefined)",
                    value: input.sampler
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["initial", "rename", "upsize", "arithmetics", "metadata", "samples", "scatterplot", "sampler"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["runId"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.from || $guard(_exceptionable, {
                    path: _path + ".from",
                    expected: "string",
                    value: input.from
                })) && ("string" === typeof input.to || $guard(_exceptionable, {
                    path: _path + ".to",
                    expected: "string",
                    value: input.to
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["from", "to"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                })) && ("number" === typeof input.from || $guard(_exceptionable, {
                    path: _path + ".from",
                    expected: "number",
                    value: input.from
                })) && ("number" === typeof input.to || $guard(_exceptionable, {
                    path: _path + ".to",
                    expected: "number",
                    value: input.to
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["runId", "from", "to"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.operation || $guard(_exceptionable, {
                    path: _path + ".operation",
                    expected: "string",
                    value: input.operation
                })) && ("string" === typeof input.tag1 || $guard(_exceptionable, {
                    path: _path + ".tag1",
                    expected: "string",
                    value: input.tag1
                })) && ("string" === typeof input.tag2 || $guard(_exceptionable, {
                    path: _path + ".tag2",
                    expected: "string",
                    value: input.tag2
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["operation", "tag1", "tag2"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"added\" | \"removed\" | \"selected\")",
                    value: input.method
                })) && ("number" === typeof input.count && (0 <= input.count || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number (@minimum 0)",
                    value: input.count
                })) || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number",
                    value: input.count
                })) && ("number" === typeof input.added && (0 <= input.added || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number (@minimum 0)",
                    value: input.added
                })) || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number",
                    value: input.added
                })) && ("number" === typeof input.removed && (0 <= input.removed || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number (@minimum 0)",
                    value: input.removed
                })) || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number",
                    value: input.removed
                })) && (("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "__type",
                    value: input.changes
                })) && $ao6(input.changes, _path + ".changes", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "__type",
                    value: input.changes
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["method", "count", "added", "removed", "changes"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every(key => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                });
                const $ao7 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"added\" | \"removed\" | \"selected\")",
                    value: input.method
                })) && ("number" === typeof input.count && (0 <= input.count || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number (@minimum 0)",
                    value: input.count
                })) || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number",
                    value: input.count
                })) && ("number" === typeof input.added && (0 <= input.added || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number (@minimum 0)",
                    value: input.added
                })) || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number",
                    value: input.added
                })) && ("number" === typeof input.removed && (0 <= input.removed || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number (@minimum 0)",
                    value: input.removed
                })) || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number",
                    value: input.removed
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["method", "count", "added", "removed"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao8 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"added\" | \"removed\" | \"selected\")",
                    value: input.method
                })) && (undefined === input.view || "string" === typeof input.view || $guard(_exceptionable, {
                    path: _path + ".view",
                    expected: "(string | undefined)",
                    value: input.view
                })) && ("number" === typeof input.count && (0 <= input.count || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number (@minimum 0)",
                    value: input.count
                })) || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number",
                    value: input.count
                })) && ("number" === typeof input.added && (0 <= input.added || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number (@minimum 0)",
                    value: input.added
                })) || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number",
                    value: input.added
                })) && ("number" === typeof input.removed && (0 <= input.removed || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number (@minimum 0)",
                    value: input.removed
                })) || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number",
                    value: input.removed
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["method", "view", "count", "added", "removed"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao9 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "string",
                    value: input.method
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["method"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "TagChangeData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "TagChangeData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const TagChangeDataTypiaAssertStringify = (input: TagChangeData): string => {
    return ((input: any): string => { const assert = (input: any): TagChangeData => {
        const __is = (input: any): input is TagChangeData => {
            const $join = (typia.createAssertStringify as any).join;
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => (undefined === input.initial || "object" === typeof input.initial && null !== input.initial && false === Array.isArray(input.initial) && $io1(input.initial)) && (undefined === input.rename || "object" === typeof input.rename && null !== input.rename && $io2(input.rename)) && (undefined === input.upsize || "object" === typeof input.upsize && null !== input.upsize && $io3(input.upsize)) && (undefined === input.arithmetics || "object" === typeof input.arithmetics && null !== input.arithmetics && $io4(input.arithmetics)) && (undefined === input.metadata || "object" === typeof input.metadata && null !== input.metadata && $io5(input.metadata)) && (undefined === input.samples || "object" === typeof input.samples && null !== input.samples && $io7(input.samples)) && (undefined === input.scatterplot || "object" === typeof input.scatterplot && null !== input.scatterplot && $io8(input.scatterplot)) && (undefined === input.sampler || "object" === typeof input.sampler && null !== input.sampler && $io9(input.sampler)) && Object.keys(input).every(key => {
                if (["initial", "rename", "upsize", "arithmetics", "metadata", "samples", "scatterplot", "sampler"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            const $io1 = (input: any): boolean => undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_runId", "string", "", input.runId);
            const $io2 = (input: any): boolean => "string" === typeof input.from && "string" === typeof input.to;
            const $io3 = (input: any): boolean => (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_runId", "string", "", input.runId)) && ("number" === typeof input.from && !Number.isNaN(input.from)) && ("number" === typeof input.to && !Number.isNaN(input.to));
            const $io4 = (input: any): boolean => "string" === typeof input.operation && "string" === typeof input.tag1 && "string" === typeof input.tag2;
            const $io5 = (input: any): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && ("number" === typeof input.count && !Number.isNaN(input.count) && 0 <= input.count) && ("number" === typeof input.added && !Number.isNaN(input.added) && 0 <= input.added) && ("number" === typeof input.removed && !Number.isNaN(input.removed) && 0 <= input.removed) && ("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) && $io6(input.changes));
            const $io6 = (input: any): boolean => Object.keys(input).every(key => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            const $io7 = (input: any): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && ("number" === typeof input.count && !Number.isNaN(input.count) && 0 <= input.count) && ("number" === typeof input.added && !Number.isNaN(input.added) && 0 <= input.added) && ("number" === typeof input.removed && !Number.isNaN(input.removed) && 0 <= input.removed);
            const $io8 = (input: any): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && (undefined === input.view || "string" === typeof input.view) && ("number" === typeof input.count && !Number.isNaN(input.count) && 0 <= input.count) && ("number" === typeof input.added && !Number.isNaN(input.added) && 0 <= input.added) && ("number" === typeof input.removed && !Number.isNaN(input.removed) && 0 <= input.removed);
            const $io9 = (input: any): boolean => "string" === typeof input.method;
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is TagChangeData => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $join = (typia.createAssertStringify as any).join;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.initial || ("object" === typeof input.initial && null !== input.initial && false === Array.isArray(input.initial) || $guard(_exceptionable, {
                    path: _path + ".initial",
                    expected: "(TagChangeDataInitial | undefined)",
                    value: input.initial
                })) && $ao1(input.initial, _path + ".initial", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".initial",
                    expected: "(TagChangeDataInitial | undefined)",
                    value: input.initial
                })) && (undefined === input.rename || ("object" === typeof input.rename && null !== input.rename || $guard(_exceptionable, {
                    path: _path + ".rename",
                    expected: "(TagChangeDataRename | undefined)",
                    value: input.rename
                })) && $ao2(input.rename, _path + ".rename", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".rename",
                    expected: "(TagChangeDataRename | undefined)",
                    value: input.rename
                })) && (undefined === input.upsize || ("object" === typeof input.upsize && null !== input.upsize || $guard(_exceptionable, {
                    path: _path + ".upsize",
                    expected: "(TagChangeDataUpsize | undefined)",
                    value: input.upsize
                })) && $ao3(input.upsize, _path + ".upsize", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".upsize",
                    expected: "(TagChangeDataUpsize | undefined)",
                    value: input.upsize
                })) && (undefined === input.arithmetics || ("object" === typeof input.arithmetics && null !== input.arithmetics || $guard(_exceptionable, {
                    path: _path + ".arithmetics",
                    expected: "(TagChangeDataArithmetics | undefined)",
                    value: input.arithmetics
                })) && $ao4(input.arithmetics, _path + ".arithmetics", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".arithmetics",
                    expected: "(TagChangeDataArithmetics | undefined)",
                    value: input.arithmetics
                })) && (undefined === input.metadata || ("object" === typeof input.metadata && null !== input.metadata || $guard(_exceptionable, {
                    path: _path + ".metadata",
                    expected: "(TagChangeDataMetadata | undefined)",
                    value: input.metadata
                })) && $ao5(input.metadata, _path + ".metadata", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".metadata",
                    expected: "(TagChangeDataMetadata | undefined)",
                    value: input.metadata
                })) && (undefined === input.samples || ("object" === typeof input.samples && null !== input.samples || $guard(_exceptionable, {
                    path: _path + ".samples",
                    expected: "(TagChangeDataSamples | undefined)",
                    value: input.samples
                })) && $ao7(input.samples, _path + ".samples", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".samples",
                    expected: "(TagChangeDataSamples | undefined)",
                    value: input.samples
                })) && (undefined === input.scatterplot || ("object" === typeof input.scatterplot && null !== input.scatterplot || $guard(_exceptionable, {
                    path: _path + ".scatterplot",
                    expected: "(TagChangeDataScatterplot | undefined)",
                    value: input.scatterplot
                })) && $ao8(input.scatterplot, _path + ".scatterplot", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".scatterplot",
                    expected: "(TagChangeDataScatterplot | undefined)",
                    value: input.scatterplot
                })) && (undefined === input.sampler || ("object" === typeof input.sampler && null !== input.sampler || $guard(_exceptionable, {
                    path: _path + ".sampler",
                    expected: "(TagChangeDataSampler | undefined)",
                    value: input.sampler
                })) && $ao9(input.sampler, _path + ".sampler", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".sampler",
                    expected: "(TagChangeDataSampler | undefined)",
                    value: input.sampler
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["initial", "rename", "upsize", "arithmetics", "metadata", "samples", "scatterplot", "sampler"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                });
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.from || $guard(_exceptionable, {
                    path: _path + ".from",
                    expected: "string",
                    value: input.from
                })) && ("string" === typeof input.to || $guard(_exceptionable, {
                    path: _path + ".to",
                    expected: "string",
                    value: input.to
                }));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                })) && ("number" === typeof input.from && !Number.isNaN(input.from) || $guard(_exceptionable, {
                    path: _path + ".from",
                    expected: "number",
                    value: input.from
                })) && ("number" === typeof input.to && !Number.isNaN(input.to) || $guard(_exceptionable, {
                    path: _path + ".to",
                    expected: "number",
                    value: input.to
                }));
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.operation || $guard(_exceptionable, {
                    path: _path + ".operation",
                    expected: "string",
                    value: input.operation
                })) && ("string" === typeof input.tag1 || $guard(_exceptionable, {
                    path: _path + ".tag1",
                    expected: "string",
                    value: input.tag1
                })) && ("string" === typeof input.tag2 || $guard(_exceptionable, {
                    path: _path + ".tag2",
                    expected: "string",
                    value: input.tag2
                }));
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"added\" | \"removed\" | \"selected\")",
                    value: input.method
                })) && ("number" === typeof input.count && !Number.isNaN(input.count) && (0 <= input.count || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number (@minimum 0)",
                    value: input.count
                })) || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number",
                    value: input.count
                })) && ("number" === typeof input.added && !Number.isNaN(input.added) && (0 <= input.added || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number (@minimum 0)",
                    value: input.added
                })) || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number",
                    value: input.added
                })) && ("number" === typeof input.removed && !Number.isNaN(input.removed) && (0 <= input.removed || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number (@minimum 0)",
                    value: input.removed
                })) || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number",
                    value: input.removed
                })) && (("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "__type",
                    value: input.changes
                })) && $ao6(input.changes, _path + ".changes", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "__type",
                    value: input.changes
                }));
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every(key => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                });
                const $ao7 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"added\" | \"removed\" | \"selected\")",
                    value: input.method
                })) && ("number" === typeof input.count && !Number.isNaN(input.count) && (0 <= input.count || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number (@minimum 0)",
                    value: input.count
                })) || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number",
                    value: input.count
                })) && ("number" === typeof input.added && !Number.isNaN(input.added) && (0 <= input.added || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number (@minimum 0)",
                    value: input.added
                })) || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number",
                    value: input.added
                })) && ("number" === typeof input.removed && !Number.isNaN(input.removed) && (0 <= input.removed || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number (@minimum 0)",
                    value: input.removed
                })) || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number",
                    value: input.removed
                }));
                const $ao8 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"added\" | \"removed\" | \"selected\")",
                    value: input.method
                })) && (undefined === input.view || "string" === typeof input.view || $guard(_exceptionable, {
                    path: _path + ".view",
                    expected: "(string | undefined)",
                    value: input.view
                })) && ("number" === typeof input.count && !Number.isNaN(input.count) && (0 <= input.count || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number (@minimum 0)",
                    value: input.count
                })) || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number",
                    value: input.count
                })) && ("number" === typeof input.added && !Number.isNaN(input.added) && (0 <= input.added || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number (@minimum 0)",
                    value: input.added
                })) || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number",
                    value: input.added
                })) && ("number" === typeof input.removed && !Number.isNaN(input.removed) && (0 <= input.removed || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number (@minimum 0)",
                    value: input.removed
                })) || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number",
                    value: input.removed
                }));
                const $ao9 = (input: any, _path: string, _exceptionable: boolean = true): boolean => "string" === typeof input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "string",
                    value: input.method
                });
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "TagChangeData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "TagChangeData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: TagChangeData): string => {
        const $io1 = (input: any): boolean => undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_runId", "string", "", input.runId);
        const $io2 = (input: any): boolean => "string" === typeof input.from && "string" === typeof input.to;
        const $io3 = (input: any): boolean => (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_runId", "string", "", input.runId)) && "number" === typeof input.from && "number" === typeof input.to;
        const $io4 = (input: any): boolean => "string" === typeof input.operation && "string" === typeof input.tag1 && "string" === typeof input.tag2;
        const $io5 = (input: any): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && ("number" === typeof input.count && 0 <= input.count) && ("number" === typeof input.added && 0 <= input.added) && ("number" === typeof input.removed && 0 <= input.removed) && ("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) && $io6(input.changes));
        const $io6 = (input: any): boolean => Object.keys(input).every(key => {
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $io7 = (input: any): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && ("number" === typeof input.count && 0 <= input.count) && ("number" === typeof input.added && 0 <= input.added) && ("number" === typeof input.removed && 0 <= input.removed);
        const $io8 = (input: any): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && (undefined === input.view || "string" === typeof input.view) && ("number" === typeof input.count && 0 <= input.count) && ("number" === typeof input.added && 0 <= input.added) && ("number" === typeof input.removed && 0 <= input.removed);
        const $io9 = (input: any): boolean => "string" === typeof input.method;
        const $string = (typia.createAssertStringify as any).string;
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $throws = (typia.createAssertStringify as any).throws;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${$tail(`${undefined === input.initial ? "" : `"initial":${undefined !== input.initial ? $so1(input.initial) : undefined},`}${undefined === input.rename ? "" : `"rename":${undefined !== input.rename ? `{"from":${$string((input.rename as any).from)},"to":${$string((input.rename as any).to)}}` : undefined},`}${undefined === input.upsize ? "" : `"upsize":${undefined !== input.upsize ? $so3(input.upsize) : undefined},`}${undefined === input.arithmetics ? "" : `"arithmetics":${undefined !== input.arithmetics ? `{"operation":${$string((input.arithmetics as any).operation)},"tag1":${$string((input.arithmetics as any).tag1)},"tag2":${$string((input.arithmetics as any).tag2)}}` : undefined},`}${undefined === input.metadata ? "" : `"metadata":${undefined !== input.metadata ? $so5(input.metadata) : undefined},`}${undefined === input.samples ? "" : `"samples":${undefined !== input.samples ? $so7(input.samples) : undefined},`}${undefined === input.scatterplot ? "" : `"scatterplot":${undefined !== input.scatterplot ? $so8(input.scatterplot) : undefined},`}${undefined === input.sampler ? "" : `"sampler":${undefined !== input.sampler ? `{"method":${$string((input.sampler as any).method)}}` : undefined},`}${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["initial", "rename", "upsize", "arithmetics", "metadata", "samples", "scatterplot", "sampler"].some(regular => regular === key))
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter(str => "" !== str).join(",")}`)}}`;
        const $so1 = (input: any): any => `{${$tail(`${undefined === input.runId ? "" : `"runId":${undefined !== input.runId ? $string(input.runId) : undefined}`}`)}}`;
        const $so3 = (input: any): any => `{${undefined === input.runId ? "" : `"runId":${undefined !== input.runId ? $string(input.runId) : undefined},`}"from":${input.from},"to":${input.to}}`;
        const $so5 = (input: any): any => `{"method":${(() => {
            if ("string" === typeof input.method)
                return $string(input.method);
            if ("string" === typeof input.method)
                return "\"" + input.method + "\"";
            $throws({
                expected: "(\"added\" | \"removed\" | \"selected\")",
                value: input.method
            });
        })()},"count":${input.count},"added":${input.added},"removed":${input.removed},"changes":${$so6(input.changes)}}`;
        const $so6 = (input: any): any => `{${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter(str => "" !== str).join(",")}}`;
        const $so7 = (input: any): any => `{"method":${(() => {
            if ("string" === typeof input.method)
                return $string(input.method);
            if ("string" === typeof input.method)
                return "\"" + input.method + "\"";
            $throws({
                expected: "(\"added\" | \"removed\" | \"selected\")",
                value: input.method
            });
        })()},"count":${input.count},"added":${input.added},"removed":${input.removed}}`;
        const $so8 = (input: any): any => `{${undefined === input.view ? "" : `"view":${undefined !== input.view ? $string(input.view) : undefined},`}"method":${(() => {
            if ("string" === typeof input.method)
                return $string(input.method);
            if ("string" === typeof input.method)
                return "\"" + input.method + "\"";
            $throws({
                expected: "(\"added\" | \"removed\" | \"selected\")",
                value: input.method
            });
        })()},"count":${input.count},"added":${input.added},"removed":${input.removed}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const TagChangeDataValidatorShape = {
    'initial': TagChangeDataInitialValidator.optional().default(undefined),
    'rename': TagChangeDataRenameValidator.optional().default(undefined),
    'upsize': TagChangeDataUpsizeValidator.optional().default(undefined),
    'arithmetics': TagChangeDataArithmeticsValidator.optional().default(undefined),
    'metadata': TagChangeDataMetadataValidator.optional().default(undefined),
    'samples': TagChangeDataSamplesValidator.optional().default(undefined),
    'scatterplot': TagChangeDataScatterplotValidator.optional().default(undefined),
    'sampler': TagChangeDataSamplerValidator.optional().default(undefined),
};
export const TagChangeDataValidator = yup.mixed().transform((val: any) => { return val instanceof Object && !Array.isArray(val) ? val : {}; });
export const TagChangeDataStringifyShapeProperties: ObjectSchema['properties'] = {
    'initial': TagChangeDataInitialStringifyShape,
    'rename': TagChangeDataRenameStringifyShape,
    'upsize': TagChangeDataUpsizeStringifyShape,
    'arithmetics': TagChangeDataArithmeticsStringifyShape,
    'metadata': TagChangeDataMetadataStringifyShape,
    'samples': TagChangeDataSamplesStringifyShape,
    'scatterplot': TagChangeDataScatterplotStringifyShape,
    'sampler': TagChangeDataSamplerStringifyShape,
};
export const TagChangeDataStringifyShape: ObjectSchema = {
    title: 'TagChangeData Stringifier',
    type: 'object',
    properties: {
        ...TagChangeDataStringifyShapeProperties
    },
    required: [],
    additionalProperties: true
};
export const TagChangeDataStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(TagChangeDataStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const TagChangeDataStringify: (data: TagChangeData) => string = TagChangeDataStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function TagChangeDataFromJSON(json: any): TagChangeData {
    return TagChangeDataFromJSONTyped(json, false);
}
export function TagChangeDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagChangeData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        ...json,
        'initial': !exists(json, 'initial') ? undefined : TagChangeDataInitialFromJSON(json['initial']),
        'rename': !exists(json, 'rename') ? undefined : TagChangeDataRenameFromJSON(json['rename']),
        'upsize': !exists(json, 'upsize') ? undefined : TagChangeDataUpsizeFromJSON(json['upsize']),
        'arithmetics': !exists(json, 'arithmetics') ? undefined : TagChangeDataArithmeticsFromJSON(json['arithmetics']),
        'metadata': !exists(json, 'metadata') ? undefined : TagChangeDataMetadataFromJSON(json['metadata']),
        'samples': !exists(json, 'samples') ? undefined : TagChangeDataSamplesFromJSON(json['samples']),
        'scatterplot': !exists(json, 'scatterplot') ? undefined : TagChangeDataScatterplotFromJSON(json['scatterplot']),
        'sampler': !exists(json, 'sampler') ? undefined : TagChangeDataSamplerFromJSON(json['sampler']),
    };
}
export function TagChangeDataToJSON(value?: TagChangeData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...value,
        'initial': TagChangeDataInitialToJSON(value.initial),
        'rename': TagChangeDataRenameToJSON(value.rename),
        'upsize': TagChangeDataUpsizeToJSON(value.upsize),
        'arithmetics': TagChangeDataArithmeticsToJSON(value.arithmetics),
        'metadata': TagChangeDataMetadataToJSON(value.metadata),
        'samples': TagChangeDataSamplesToJSON(value.samples),
        'scatterplot': TagChangeDataScatterplotToJSON(value.scatterplot),
        'sampler': TagChangeDataSamplerToJSON(value.sampler),
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DockerWorkerAuthorizationRequest
 *
 */
// create custom validators for the regexes
export interface DockerWorkerAuthorizationRequest {
    /**
     *
     * #type string
     * #memberof DockerWorkerAuthorizationRequest
        
        
         */
    hashedTaskDescription: string;
}
/**
 * Check if a given object implements the DockerWorkerAuthorizationRequest interface.
 */
export function instanceOfDockerWorkerAuthorizationRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "hashedTaskDescription" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
export const DockerWorkerAuthorizationRequestTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerWorkerAuthorizationRequest => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerWorkerAuthorizationRequest => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.hashedTaskDescription && (1 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["hashedTaskDescription"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerAuthorizationRequest => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.hashedTaskDescription || $guard(_exceptionable, {
                    path: _path + ".hashedTaskDescription",
                    expected: "string",
                    value: input.hashedTaskDescription
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["hashedTaskDescription"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerAuthorizationRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerAuthorizationRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerWorkerAuthorizationRequestTypiaAssertStringify = (input: DockerWorkerAuthorizationRequest): string => {
    return ((input: any): string => { const assert = (input: any): DockerWorkerAuthorizationRequest => {
        const __is = (input: any): input is DockerWorkerAuthorizationRequest => {
            return "object" === typeof input && null !== input && "string" === typeof (input as any).hashedTaskDescription;
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerAuthorizationRequest => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => "string" === typeof input.hashedTaskDescription || $guard(_exceptionable, {
                    path: _path + ".hashedTaskDescription",
                    expected: "string",
                    value: input.hashedTaskDescription
                });
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerAuthorizationRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerAuthorizationRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerWorkerAuthorizationRequest): string => {
        const $string = (typia.createAssertStringify as any).string;
        return `{"hashedTaskDescription":${$string((input as any).hashedTaskDescription)}}`;
    }; return stringify(assert(input)); })(input);
};
export const DockerWorkerAuthorizationRequestValidatorShape = {
    'hashedTaskDescription': yup.string().defined(),
};
export const DockerWorkerAuthorizationRequestValidator = yup.object().shape(DockerWorkerAuthorizationRequestValidatorShape);
export const DockerWorkerAuthorizationRequestStringifyShapeProperties: ObjectSchema['properties'] = {
    'hashedTaskDescription': {
        'type': 'string',
    },
};
export const DockerWorkerAuthorizationRequestStringifyShape: ObjectSchema = {
    title: 'DockerWorkerAuthorizationRequest Stringifier',
    type: 'object',
    properties: {
        ...DockerWorkerAuthorizationRequestStringifyShapeProperties
    },
    required: [
        'hashedTaskDescription',
    ],
};
export const DockerWorkerAuthorizationRequestStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerWorkerAuthorizationRequestStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerWorkerAuthorizationRequestStringify: (data: DockerWorkerAuthorizationRequest) => string = DockerWorkerAuthorizationRequestStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerWorkerAuthorizationRequestFromJSON(json: any): DockerWorkerAuthorizationRequest {
    return DockerWorkerAuthorizationRequestFromJSONTyped(json, false);
}
export function DockerWorkerAuthorizationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerWorkerAuthorizationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'hashedTaskDescription': json['hashedTaskDescription'],
    };
}
export function DockerWorkerAuthorizationRequestToJSON(value?: DockerWorkerAuthorizationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'hashedTaskDescription': value.hashedTaskDescription,
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { PredictionTaskSchemaBase } from './PredictionTaskSchemaBase';
import { PredictionTaskSchemaBaseFromJSON, PredictionTaskSchemaBaseFromJSONTyped, PredictionTaskSchemaBaseToJSON, } from './PredictionTaskSchemaBase';
import type { PredictionTaskSchemaCategoryKeypoints } from './PredictionTaskSchemaCategoryKeypoints';
import { PredictionTaskSchemaCategoryKeypointsFromJSON, PredictionTaskSchemaCategoryKeypointsFromJSONTyped, PredictionTaskSchemaCategoryKeypointsToJSON, } from './PredictionTaskSchemaCategoryKeypoints';
/*
causes circular dependendies
// .concat(PredictionTaskSchemaBaseValidator)

*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface PredictionTaskSchemaKeypoint
 *
 */
// create custom validators for the regexes
export interface PredictionTaskSchemaKeypoint extends PredictionTaskSchemaBase {
    /**
     * An array of the categories that exist for this prediction task. The id needs to be unique
     * #type Array<PredictionTaskSchemaCategoryKeypoints>
     * #memberof PredictionTaskSchemaKeypoint
        
        
         */
    categories: Array<PredictionTaskSchemaCategoryKeypoints>;
}
/**
 * Check if a given object implements the PredictionTaskSchemaKeypoint interface.
 */
export function instanceOfPredictionTaskSchemaKeypoint(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "categories" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { PredictionTaskSchemaBaseValidator, PredictionTaskSchemaBaseStringifyShape, PredictionTaskSchemaBaseStringifyShapeProperties, } from './PredictionTaskSchemaBase';
import { PredictionTaskSchemaCategoryKeypointsValidator, PredictionTaskSchemaCategoryKeypointsStringifyShape, PredictionTaskSchemaCategoryKeypointsStringifyShapeProperties, } from './PredictionTaskSchemaCategoryKeypoints';
export const PredictionTaskSchemaKeypointTypiaAssertEquals = (input: any) => {
    return ((input: any): PredictionTaskSchemaKeypoint => {
        const __is = (input: any, _exceptionable: boolean = true): input is PredictionTaskSchemaKeypoint => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => Array.isArray(input.categories) && input.categories.every((elem: any, _index1: number) => "object" === typeof elem && null !== elem && $io1(elem, true && _exceptionable)) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name)) && "string" === typeof input.type && (3 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["categories", "name", "type"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.id && 0 <= input.id && ("string" === typeof input.name && 1 <= input.name.length) && (undefined === input.keypointNames || Array.isArray(input.keypointNames) && input.keypointNames.every((elem: any, _index2: number) => "string" === typeof elem)) && (undefined === input.keypointSkeleton || Array.isArray(input.keypointSkeleton) && input.keypointSkeleton.every((elem: any, _index3: number) => "object" === typeof elem && null !== elem && $io2(elem, true && _exceptionable))) && (2 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["id", "name", "keypointNames", "keypointSkeleton"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value;
                return false;
            });
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is PredictionTaskSchemaKeypoint => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategoryKeypoints>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index1 + "]",
                    expected: "PredictionTaskSchemaCategoryKeypoints",
                    value: elem
                })) && $ao1(elem, _path + ".categories[" + _index1 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index1 + "]",
                    expected: "PredictionTaskSchemaCategoryKeypoints",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategoryKeypoints>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("string" === typeof input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "string",
                    value: input.type
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["categories", "name", "type"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.id && (0 <= input.id || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number (@minimum 0)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number",
                    value: input.id
                })) && ("string" === typeof input.name && (1 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 1)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (undefined === input.keypointNames || (Array.isArray(input.keypointNames) || $guard(_exceptionable, {
                    path: _path + ".keypointNames",
                    expected: "(Array<string> | undefined)",
                    value: input.keypointNames
                })) && input.keypointNames.every((elem: any, _index2: number) => "string" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".keypointNames[" + _index2 + "]",
                    expected: "string",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".keypointNames",
                    expected: "(Array<string> | undefined)",
                    value: input.keypointNames
                })) && (undefined === input.keypointSkeleton || (Array.isArray(input.keypointSkeleton) || $guard(_exceptionable, {
                    path: _path + ".keypointSkeleton",
                    expected: "(Array<KeypointSkeletonConnection> | undefined)",
                    value: input.keypointSkeleton
                })) && input.keypointSkeleton.every((elem: any, _index3: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".keypointSkeleton[" + _index3 + "]",
                    expected: "KeypointSkeletonConnection",
                    value: elem
                })) && $ao2(elem, _path + ".keypointSkeleton[" + _index3 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".keypointSkeleton[" + _index3 + "]",
                    expected: "KeypointSkeletonConnection",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".keypointSkeleton",
                    expected: "(Array<KeypointSkeletonConnection> | undefined)",
                    value: input.keypointSkeleton
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["id", "name", "keypointNames", "keypointSkeleton"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "PredictionTaskSchemaKeypoint",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "PredictionTaskSchemaKeypoint",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const PredictionTaskSchemaKeypointTypiaAssertStringify = (input: PredictionTaskSchemaKeypoint): string => {
    return ((input: any): string => { const assert = (input: any): PredictionTaskSchemaKeypoint => {
        const __is = (input: any): input is PredictionTaskSchemaKeypoint => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io1(elem)) && ("string" === typeof input.name && $is_custom("pattern_name", "string", "", input.name)) && "string" === typeof input.type;
            const $io1 = (input: any): boolean => "number" === typeof input.id && !Number.isNaN(input.id) && 0 <= input.id && ("string" === typeof input.name && 1 <= input.name.length) && (undefined === input.keypointNames || Array.isArray(input.keypointNames) && input.keypointNames.every((elem: any) => "string" === typeof elem)) && (undefined === input.keypointSkeleton || Array.isArray(input.keypointSkeleton) && input.keypointSkeleton.every((elem: any) => "object" === typeof elem && null !== elem && $io2(elem)));
            const $io2 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value && !Number.isNaN(value);
                return true;
            });
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is PredictionTaskSchemaKeypoint => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategoryKeypoints>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index1 + "]",
                    expected: "PredictionTaskSchemaCategoryKeypoints",
                    value: elem
                })) && $ao1(elem, _path + ".categories[" + _index1 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index1 + "]",
                    expected: "PredictionTaskSchemaCategoryKeypoints",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategoryKeypoints>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("string" === typeof input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "string",
                    value: input.type
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.id && !Number.isNaN(input.id) && (0 <= input.id || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number (@minimum 0)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number",
                    value: input.id
                })) && ("string" === typeof input.name && (1 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 1)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (undefined === input.keypointNames || (Array.isArray(input.keypointNames) || $guard(_exceptionable, {
                    path: _path + ".keypointNames",
                    expected: "(Array<string> | undefined)",
                    value: input.keypointNames
                })) && input.keypointNames.every((elem: any, _index2: number) => "string" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".keypointNames[" + _index2 + "]",
                    expected: "string",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".keypointNames",
                    expected: "(Array<string> | undefined)",
                    value: input.keypointNames
                })) && (undefined === input.keypointSkeleton || (Array.isArray(input.keypointSkeleton) || $guard(_exceptionable, {
                    path: _path + ".keypointSkeleton",
                    expected: "(Array<KeypointSkeletonConnection> | undefined)",
                    value: input.keypointSkeleton
                })) && input.keypointSkeleton.every((elem: any, _index3: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".keypointSkeleton[" + _index3 + "]",
                    expected: "KeypointSkeletonConnection",
                    value: elem
                })) && $ao2(elem, _path + ".keypointSkeleton[" + _index3 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".keypointSkeleton[" + _index3 + "]",
                    expected: "KeypointSkeletonConnection",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".keypointSkeleton",
                    expected: "(Array<KeypointSkeletonConnection> | undefined)",
                    value: input.keypointSkeleton
                }));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value && !Number.isNaN(value) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return true;
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "PredictionTaskSchemaKeypoint",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "PredictionTaskSchemaKeypoint",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: PredictionTaskSchemaKeypoint): string => {
        const $io1 = (input: any): boolean => "number" === typeof input.id && 0 <= input.id && ("string" === typeof input.name && 1 <= input.name.length) && (undefined === input.keypointNames || Array.isArray(input.keypointNames) && input.keypointNames.every((elem: any) => "string" === typeof elem)) && (undefined === input.keypointSkeleton || Array.isArray(input.keypointSkeleton) && input.keypointSkeleton.every((elem: any) => "object" === typeof elem && null !== elem && $io2(elem)));
        const $io2 = (input: any): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
            if (["length"].some(prop => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                return "number" === typeof value;
            return true;
        });
        const $string = (typia.createAssertStringify as any).string;
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{"categories":${`[${input.categories.map((elem: any) => $so1(elem)).join(",")}]`},"name":${$string(input.name)},"type":${$string(input.type)}}`;
        const $so1 = (input: any): any => `{${undefined === input.keypointNames ? "" : `"keypointNames":${undefined !== input.keypointNames ? `[${input.keypointNames.map((elem: any) => $string(elem)).join(",")}]` : undefined},`}${undefined === input.keypointSkeleton ? "" : `"keypointSkeleton":${undefined !== input.keypointSkeleton ? `[${input.keypointSkeleton.map((elem: any) => $so2(elem)).join(",")}]` : undefined},`}"id":${input.id},"name":${$string(input.name)}}`;
        const $so2 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some(regular => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${value}`; return ""; }).filter(str => "" !== str).join(",")}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const PredictionTaskSchemaKeypointValidatorShape = {
    'categories': yup.array().of(PredictionTaskSchemaCategoryKeypointsValidator).defined(),
};
export const PredictionTaskSchemaKeypointValidator = yup.object().shape(PredictionTaskSchemaKeypointValidatorShape).concat(PredictionTaskSchemaBaseValidator);
export const PredictionTaskSchemaKeypointStringifyShapeProperties: ObjectSchema['properties'] = {
    'categories': {
        type: 'array',
        items: {
            PredictionTaskSchemaCategoryKeypointsStringifyShape
        }
    },
};
export const PredictionTaskSchemaKeypointStringifyShape: ObjectSchema = {
    title: 'PredictionTaskSchemaKeypoint Stringifier',
    type: 'object',
    properties: {
        ...PredictionTaskSchemaBaseStringifyShapeProperties,
        ...PredictionTaskSchemaKeypointStringifyShapeProperties
    },
    required: [
        ...(PredictionTaskSchemaBaseStringifyShape.required ? PredictionTaskSchemaBaseStringifyShape.required : []),
        'categories',
    ],
};
export const PredictionTaskSchemaKeypointStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(PredictionTaskSchemaKeypointStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const PredictionTaskSchemaKeypointStringify: (data: PredictionTaskSchemaKeypoint) => string = PredictionTaskSchemaKeypointStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function PredictionTaskSchemaKeypointFromJSON(json: any): PredictionTaskSchemaKeypoint {
    return PredictionTaskSchemaKeypointFromJSONTyped(json, false);
}
export function PredictionTaskSchemaKeypointFromJSONTyped(json: any, ignoreDiscriminator: boolean): PredictionTaskSchemaKeypoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
    if (!ignoreDiscriminator) {
    }
*/
    return {
        ...PredictionTaskSchemaBaseFromJSONTyped(json, ignoreDiscriminator),
        'categories': ((json['categories'] as Array<any> || []).map(PredictionTaskSchemaCategoryKeypointsFromJSON)),
    };
}
export function PredictionTaskSchemaKeypointToJSON(value?: PredictionTaskSchemaKeypoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...PredictionTaskSchemaBaseToJSON(value),
        'categories': ((value.categories as Array<any> || []).map(PredictionTaskSchemaCategoryKeypointsToJSON)),
    };
}

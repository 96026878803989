/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { DockerWorkerConfigV0Data } from './DockerWorkerConfigV0Data';
import { DockerWorkerConfigV0DataFromJSON, DockerWorkerConfigV0DataFromJSONTyped, DockerWorkerConfigV0DataToJSON, } from './DockerWorkerConfigV0Data';
import type { DockerWorkerConfigV2Data } from './DockerWorkerConfigV2Data';
import { DockerWorkerConfigV2DataFromJSON, DockerWorkerConfigV2DataFromJSONTyped, DockerWorkerConfigV2DataToJSON, } from './DockerWorkerConfigV2Data';
import type { DockerWorkerConfigV3Data } from './DockerWorkerConfigV3Data';
import { DockerWorkerConfigV3DataFromJSON, DockerWorkerConfigV3DataFromJSONTyped, DockerWorkerConfigV3DataToJSON, } from './DockerWorkerConfigV3Data';
import type { DockerWorkerConfigV4Data } from './DockerWorkerConfigV4Data';
import { DockerWorkerConfigV4DataFromJSON, DockerWorkerConfigV4DataFromJSONTyped, DockerWorkerConfigV4DataToJSON, } from './DockerWorkerConfigV4Data';
/*
causes circular dependendies
import {
     DockerWorkerConfigV0DataFromJSONTyped,
} from './DockerWorkerConfigV0Data';
import {
     DockerWorkerConfigV2DataFromJSONTyped,
} from './DockerWorkerConfigV2Data';
import {
     DockerWorkerConfigV3DataFromJSONTyped,
} from './DockerWorkerConfigV3Data';
import {
     DockerWorkerConfigV4DataFromJSONTyped
} from './DockerWorkerConfigV4Data';


*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DockerWorkerConfigVXDataBase
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_id")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
export interface DockerWorkerConfigVXDataBase {
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof DockerWorkerConfigVXDataBase
        
        
    * @pattern_id
         */
    id: string;
    /**
     * The version of the config. Either V3, V4, etc. Used as the discriminator for
     * #type string
     * #memberof DockerWorkerConfigVXDataBase
        
        
         */
    version: string;
    /**
     * unix timestamp in milliseconds
     * #type number
     * #memberof DockerWorkerConfigVXDataBase
        
    * @minimum 0
            
         */
    createdAt: number;
}
/**
 * Check if a given object implements the DockerWorkerConfigVXDataBase interface.
 */
export function instanceOfDockerWorkerConfigVXDataBase(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "version" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { DockerWorkerConfigV0DataValidator, DockerWorkerConfigV0DataStringifyShape, DockerWorkerConfigV0DataStringifyShapeProperties, } from './DockerWorkerConfigV0Data';
import { DockerWorkerConfigV2DataValidator, DockerWorkerConfigV2DataStringifyShape, DockerWorkerConfigV2DataStringifyShapeProperties, } from './DockerWorkerConfigV2Data';
import { DockerWorkerConfigV3DataValidator, DockerWorkerConfigV3DataStringifyShape, DockerWorkerConfigV3DataStringifyShapeProperties, } from './DockerWorkerConfigV3Data';
import { DockerWorkerConfigV4DataValidator, DockerWorkerConfigV4DataStringifyShape, DockerWorkerConfigV4DataStringifyShapeProperties, } from './DockerWorkerConfigV4Data';
export const DockerWorkerConfigVXDataBaseTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerWorkerConfigVXDataBase => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerWorkerConfigVXDataBase => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.id && $is_custom("pattern_id", "string", "", input.id) && "string" === typeof input.version && ("number" === typeof input.createdAt && 0 <= input.createdAt) && (3 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["id", "version", "createdAt"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigVXDataBase => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id && ($is_custom("pattern_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && ("string" === typeof input.version || $guard(_exceptionable, {
                    path: _path + ".version",
                    expected: "string",
                    value: input.version
                })) && ("number" === typeof input.createdAt && (0 <= input.createdAt || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number (@minimum 0)",
                    value: input.createdAt
                })) || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number",
                    value: input.createdAt
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["id", "version", "createdAt"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigVXDataBase",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigVXDataBase",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerWorkerConfigVXDataBaseTypiaAssertStringify = (input: DockerWorkerConfigVXDataBase): string => {
    return ((input: any): string => { const assert = (input: any): DockerWorkerConfigVXDataBase => {
        const __is = (input: any): input is DockerWorkerConfigVXDataBase => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            return "object" === typeof input && null !== input && ("string" === typeof (input as any).id && $is_custom("pattern_id", "string", "", (input as any).id) && "string" === typeof (input as any).version && ("number" === typeof (input as any).createdAt && !Number.isNaN((input as any).createdAt) && 0 <= (input as any).createdAt));
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigVXDataBase => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id && ($is_custom("pattern_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && ("string" === typeof input.version || $guard(_exceptionable, {
                    path: _path + ".version",
                    expected: "string",
                    value: input.version
                })) && ("number" === typeof input.createdAt && !Number.isNaN(input.createdAt) && (0 <= input.createdAt || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number (@minimum 0)",
                    value: input.createdAt
                })) || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number",
                    value: input.createdAt
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigVXDataBase",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigVXDataBase",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerWorkerConfigVXDataBase): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        return `{"id":${$string((input as any).id)},"version":${$string((input as any).version)},"createdAt":${(input as any).createdAt}}`;
    }; return stringify(assert(input)); })(input);
};
export const DockerWorkerConfigVXDataBaseValidatorShape = {
    'id': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'version': yup.string().defined(),
    'createdAt': yup.number().min(0).defined(),
};
export const DockerWorkerConfigVXDataBaseValidator = yup.object().shape(DockerWorkerConfigVXDataBaseValidatorShape);
export const DockerWorkerConfigVXDataBaseStringifyShapeProperties: ObjectSchema['properties'] = {
    'id': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    },
    'version': {
        'type': 'string',
    },
    'createdAt': {
        'type': 'number',
        'minimum': 0,
    },
};
export const DockerWorkerConfigVXDataBaseStringifyShape: ObjectSchema = {
    title: 'DockerWorkerConfigVXDataBase Stringifier',
    type: 'object',
    properties: {
        ...DockerWorkerConfigVXDataBaseStringifyShapeProperties
    },
    required: [
        'id',
        'version',
        'createdAt',
    ],
};
export const DockerWorkerConfigVXDataBaseStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerWorkerConfigVXDataBaseStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerWorkerConfigVXDataBaseStringify: (data: DockerWorkerConfigVXDataBase) => string = DockerWorkerConfigVXDataBaseStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerWorkerConfigVXDataBaseFromJSON(json: any): DockerWorkerConfigVXDataBase {
    return DockerWorkerConfigVXDataBaseFromJSONTyped(json, false);
}
export function DockerWorkerConfigVXDataBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerWorkerConfigVXDataBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
    if (!ignoreDiscriminator) {
        if (json['version'] === 'DockerWorkerConfigV0Data') {
            return DockerWorkerConfigV0DataFromJSONTyped(json, true);
        }
        if (json['version'] === 'DockerWorkerConfigV2Data') {
            return DockerWorkerConfigV2DataFromJSONTyped(json, true);
        }
        if (json['version'] === 'DockerWorkerConfigV3Data') {
            return DockerWorkerConfigV3DataFromJSONTyped(json, true);
        }
        if (json['version'] === 'DockerWorkerConfigV4Data') {
            return DockerWorkerConfigV4DataFromJSONTyped(json, true);
        }
    }
*/
    return {
        'id': json['id'],
        'version': json['version'],
        'createdAt': json['createdAt'],
    };
}
export function DockerWorkerConfigVXDataBaseToJSON(value?: DockerWorkerConfigVXDataBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'version': value.version,
        'createdAt': value.createdAt,
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { Creator } from './Creator';
import { CreatorFromJSON, CreatorFromJSONTyped, CreatorToJSON, } from './Creator';
import type { SharedAccessType } from './SharedAccessType';
import { SharedAccessTypeFromJSON, SharedAccessTypeFromJSONTyped, SharedAccessTypeToJSON, } from './SharedAccessType';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface SharedAccessConfigCreateRequest
 *
 */
// create custom validators for the regexes
export interface SharedAccessConfigCreateRequest {
    /**
     *
     * #type SharedAccessType
     * #memberof SharedAccessConfigCreateRequest
        
        
         */
    accessType: SharedAccessType;
    /**
     * List of users with access to the dataset.
     * #type Array<string>
     * #memberof SharedAccessConfigCreateRequest
        
        
         */
    users?: Array<string>;
    /**
     * List of teams with access to the dataset.
     * #type Array<string>
     * #memberof SharedAccessConfigCreateRequest
        
        
         */
    teams?: Array<string>;
    /**
     *
     * #type Creator
     * #memberof SharedAccessConfigCreateRequest
        
        
         */
    creator?: Creator;
}
/**
 * Check if a given object implements the SharedAccessConfigCreateRequest interface.
 */
export function instanceOfSharedAccessConfigCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accessType" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { CreatorValidator, CreatorStringifyShape, CreatorStringifyShapeProperties, } from './Creator';
import { SharedAccessTypeValidator, SharedAccessTypeStringifyShape, SharedAccessTypeStringifyShapeProperties, } from './SharedAccessType';
export const SharedAccessConfigCreateRequestTypiaAssertEquals = (input: any) => {
    return ((input: any): SharedAccessConfigCreateRequest => {
        const __is = (input: any, _exceptionable: boolean = true): input is SharedAccessConfigCreateRequest => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "WRITE" === input.accessType && (undefined === input.users || Array.isArray(input.users) && input.users.every((elem: any, _index1: number) => "string" === typeof elem)) && (undefined === input.teams || Array.isArray(input.teams) && input.teams.every((elem: any, _index2: number) => "string" === typeof elem)) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator) && (1 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["accessType", "users", "teams", "creator"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is SharedAccessConfigCreateRequest => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("WRITE" === input.accessType || $guard(_exceptionable, {
                    path: _path + ".accessType",
                    expected: "\"WRITE\"",
                    value: input.accessType
                })) && (undefined === input.users || (Array.isArray(input.users) || $guard(_exceptionable, {
                    path: _path + ".users",
                    expected: "(Array<string> | undefined)",
                    value: input.users
                })) && input.users.every((elem: any, _index1: number) => "string" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".users[" + _index1 + "]",
                    expected: "string",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".users",
                    expected: "(Array<string> | undefined)",
                    value: input.users
                })) && (undefined === input.teams || (Array.isArray(input.teams) || $guard(_exceptionable, {
                    path: _path + ".teams",
                    expected: "(Array<string> | undefined)",
                    value: input.teams
                })) && input.teams.every((elem: any, _index2: number) => "string" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".teams[" + _index2 + "]",
                    expected: "string",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".teams",
                    expected: "(Array<string> | undefined)",
                    value: input.teams
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["accessType", "users", "teams", "creator"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SharedAccessConfigCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SharedAccessConfigCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const SharedAccessConfigCreateRequestTypiaAssertStringify = (input: SharedAccessConfigCreateRequest): string => {
    return ((input: any): string => { const assert = (input: any): SharedAccessConfigCreateRequest => {
        const __is = (input: any): input is SharedAccessConfigCreateRequest => {
            const $io0 = (input: any): boolean => "WRITE" === input.accessType && (undefined === input.users || Array.isArray(input.users) && input.users.every((elem: any) => "string" === typeof elem)) && (undefined === input.teams || Array.isArray(input.teams) && input.teams.every((elem: any) => "string" === typeof elem)) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is SharedAccessConfigCreateRequest => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("WRITE" === input.accessType || $guard(_exceptionable, {
                    path: _path + ".accessType",
                    expected: "\"WRITE\"",
                    value: input.accessType
                })) && (undefined === input.users || (Array.isArray(input.users) || $guard(_exceptionable, {
                    path: _path + ".users",
                    expected: "(Array<string> | undefined)",
                    value: input.users
                })) && input.users.every((elem: any, _index1: number) => "string" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".users[" + _index1 + "]",
                    expected: "string",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".users",
                    expected: "(Array<string> | undefined)",
                    value: input.users
                })) && (undefined === input.teams || (Array.isArray(input.teams) || $guard(_exceptionable, {
                    path: _path + ".teams",
                    expected: "(Array<string> | undefined)",
                    value: input.teams
                })) && input.teams.every((elem: any, _index2: number) => "string" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".teams[" + _index2 + "]",
                    expected: "string",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".teams",
                    expected: "(Array<string> | undefined)",
                    value: input.teams
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SharedAccessConfigCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SharedAccessConfigCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: SharedAccessConfigCreateRequest): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $so0 = (input: any): any => `{${undefined === input.users ? "" : `"users":${undefined !== input.users ? `[${input.users.map((elem: any) => $string(elem)).join(",")}]` : undefined},`}${undefined === input.teams ? "" : `"teams":${undefined !== input.teams ? `[${input.teams.map((elem: any) => $string(elem)).join(",")}]` : undefined},`}${undefined === input.creator ? "" : `"creator":${undefined !== input.creator ? (() => {
            if ("string" === typeof input.creator)
                return $string(input.creator);
            if ("string" === typeof input.creator)
                return "\"" + input.creator + "\"";
            $throws({
                expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                value: input.creator
            });
        })() : undefined},`}"accessType":${(() => {
            if ("string" === typeof input.accessType)
                return $string(input.accessType);
            if ("string" === typeof input.accessType)
                return "\"" + input.accessType + "\"";
            $throws({
                expected: "\"WRITE\"",
                value: input.accessType
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const SharedAccessConfigCreateRequestValidatorShape = {
    'accessType': SharedAccessTypeValidator.defined(),
    'users': yup.array().of(yup.string()),
    'teams': yup.array().of(yup.string()),
    'creator': CreatorValidator.optional().default(undefined),
};
export const SharedAccessConfigCreateRequestValidator = yup.object().shape(SharedAccessConfigCreateRequestValidatorShape);
export const SharedAccessConfigCreateRequestStringifyShapeProperties: ObjectSchema['properties'] = {
    'accessType': SharedAccessTypeStringifyShape,
    'users': {
        type: 'array',
        items: {
            type: 'string'
        }
    },
    'teams': {
        type: 'array',
        items: {
            type: 'string'
        }
    },
    'creator': CreatorStringifyShape,
};
export const SharedAccessConfigCreateRequestStringifyShape: ObjectSchema = {
    title: 'SharedAccessConfigCreateRequest Stringifier',
    type: 'object',
    properties: {
        ...SharedAccessConfigCreateRequestStringifyShapeProperties
    },
    required: [
        'accessType',
    ],
};
export const SharedAccessConfigCreateRequestStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(SharedAccessConfigCreateRequestStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const SharedAccessConfigCreateRequestStringify: (data: SharedAccessConfigCreateRequest) => string = SharedAccessConfigCreateRequestStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function SharedAccessConfigCreateRequestFromJSON(json: any): SharedAccessConfigCreateRequest {
    return SharedAccessConfigCreateRequestFromJSONTyped(json, false);
}
export function SharedAccessConfigCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SharedAccessConfigCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'accessType': SharedAccessTypeFromJSON(json['accessType']),
        'users': !exists(json, 'users') ? undefined : json['users'],
        'teams': !exists(json, 'teams') ? undefined : json['teams'],
        'creator': !exists(json, 'creator') ? undefined : CreatorFromJSON(json['creator']),
    };
}
export function SharedAccessConfigCreateRequestToJSON(value?: SharedAccessConfigCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'accessType': SharedAccessTypeToJSON(value.accessType),
        'users': value.users,
        'teams': value.teams,
        'creator': CreatorToJSON(value.creator),
    };
}

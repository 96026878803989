/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { DockerWorkerConfigV3DatasourceInputExpiration } from './DockerWorkerConfigV3DatasourceInputExpiration';
import { DockerWorkerConfigV3DatasourceInputExpirationFromJSON, DockerWorkerConfigV3DatasourceInputExpirationFromJSONTyped, DockerWorkerConfigV3DatasourceInputExpirationToJSON, } from './DockerWorkerConfigV3DatasourceInputExpiration';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DockerWorkerConfigV3DockerDatasource
 *
 */
// create custom validators for the regexes
export interface DockerWorkerConfigV3DockerDatasource {
    /**
     *
     * #type boolean
     * #memberof DockerWorkerConfigV3DockerDatasource
        
        
         */
    bypassVerify?: boolean;
    /**
     *
     * #type boolean
     * #memberof DockerWorkerConfigV3DockerDatasource
        
        
         */
    enableDatapoolUpdate?: boolean;
    /**
     *
     * #type boolean
     * #memberof DockerWorkerConfigV3DockerDatasource
        
        
         */
    processAll?: boolean;
    /**
     *
     * #type DockerWorkerConfigV3DatasourceInputExpiration
     * #memberof DockerWorkerConfigV3DockerDatasource
        
        
         */
    inputExpiration?: DockerWorkerConfigV3DatasourceInputExpiration;
}
/**
 * Check if a given object implements the DockerWorkerConfigV3DockerDatasource interface.
 */
export function instanceOfDockerWorkerConfigV3DockerDatasource(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { DockerWorkerConfigV3DatasourceInputExpirationValidator, DockerWorkerConfigV3DatasourceInputExpirationStringifyShape, DockerWorkerConfigV3DatasourceInputExpirationStringifyShapeProperties, } from './DockerWorkerConfigV3DatasourceInputExpiration';
export const DockerWorkerConfigV3DockerDatasourceTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerWorkerConfigV3DockerDatasource => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerWorkerConfigV3DockerDatasource => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.bypassVerify || "boolean" === typeof input.bypassVerify) && (undefined === input.enableDatapoolUpdate || "boolean" === typeof input.enableDatapoolUpdate) && (undefined === input.processAll || "boolean" === typeof input.processAll) && (undefined === input.inputExpiration || "object" === typeof input.inputExpiration && null !== input.inputExpiration && $io1(input.inputExpiration, true && _exceptionable)) && (0 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["bypassVerify", "enableDatapoolUpdate", "processAll", "inputExpiration"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.minDaysToExpiration && 0 <= input.minDaysToExpiration && ("SKIP" === input.handlingStrategy || "ABORT" === input.handlingStrategy) && (2 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["minDaysToExpiration", "handlingStrategy"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigV3DockerDatasource => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.bypassVerify || "boolean" === typeof input.bypassVerify || $guard(_exceptionable, {
                    path: _path + ".bypassVerify",
                    expected: "(boolean | undefined)",
                    value: input.bypassVerify
                })) && (undefined === input.enableDatapoolUpdate || "boolean" === typeof input.enableDatapoolUpdate || $guard(_exceptionable, {
                    path: _path + ".enableDatapoolUpdate",
                    expected: "(boolean | undefined)",
                    value: input.enableDatapoolUpdate
                })) && (undefined === input.processAll || "boolean" === typeof input.processAll || $guard(_exceptionable, {
                    path: _path + ".processAll",
                    expected: "(boolean | undefined)",
                    value: input.processAll
                })) && (undefined === input.inputExpiration || ("object" === typeof input.inputExpiration && null !== input.inputExpiration || $guard(_exceptionable, {
                    path: _path + ".inputExpiration",
                    expected: "(DockerWorkerConfigV3DatasourceInputExpiration | undefined)",
                    value: input.inputExpiration
                })) && $ao1(input.inputExpiration, _path + ".inputExpiration", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".inputExpiration",
                    expected: "(DockerWorkerConfigV3DatasourceInputExpiration | undefined)",
                    value: input.inputExpiration
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["bypassVerify", "enableDatapoolUpdate", "processAll", "inputExpiration"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.minDaysToExpiration && (0 <= input.minDaysToExpiration || $guard(_exceptionable, {
                    path: _path + ".minDaysToExpiration",
                    expected: "number (@minimum 0)",
                    value: input.minDaysToExpiration
                })) || $guard(_exceptionable, {
                    path: _path + ".minDaysToExpiration",
                    expected: "number",
                    value: input.minDaysToExpiration
                })) && ("SKIP" === input.handlingStrategy || "ABORT" === input.handlingStrategy || $guard(_exceptionable, {
                    path: _path + ".handlingStrategy",
                    expected: "(\"ABORT\" | \"SKIP\")",
                    value: input.handlingStrategy
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["minDaysToExpiration", "handlingStrategy"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV3DockerDatasource",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV3DockerDatasource",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerWorkerConfigV3DockerDatasourceTypiaAssertStringify = (input: DockerWorkerConfigV3DockerDatasource): string => {
    return ((input: any): string => { const assert = (input: any): DockerWorkerConfigV3DockerDatasource => {
        const __is = (input: any): input is DockerWorkerConfigV3DockerDatasource => {
            const $io0 = (input: any): boolean => (undefined === input.bypassVerify || "boolean" === typeof input.bypassVerify) && (undefined === input.enableDatapoolUpdate || "boolean" === typeof input.enableDatapoolUpdate) && (undefined === input.processAll || "boolean" === typeof input.processAll) && (undefined === input.inputExpiration || "object" === typeof input.inputExpiration && null !== input.inputExpiration && $io1(input.inputExpiration));
            const $io1 = (input: any): boolean => "number" === typeof input.minDaysToExpiration && !Number.isNaN(input.minDaysToExpiration) && 0 <= input.minDaysToExpiration && ("SKIP" === input.handlingStrategy || "ABORT" === input.handlingStrategy);
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigV3DockerDatasource => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.bypassVerify || "boolean" === typeof input.bypassVerify || $guard(_exceptionable, {
                    path: _path + ".bypassVerify",
                    expected: "(boolean | undefined)",
                    value: input.bypassVerify
                })) && (undefined === input.enableDatapoolUpdate || "boolean" === typeof input.enableDatapoolUpdate || $guard(_exceptionable, {
                    path: _path + ".enableDatapoolUpdate",
                    expected: "(boolean | undefined)",
                    value: input.enableDatapoolUpdate
                })) && (undefined === input.processAll || "boolean" === typeof input.processAll || $guard(_exceptionable, {
                    path: _path + ".processAll",
                    expected: "(boolean | undefined)",
                    value: input.processAll
                })) && (undefined === input.inputExpiration || ("object" === typeof input.inputExpiration && null !== input.inputExpiration || $guard(_exceptionable, {
                    path: _path + ".inputExpiration",
                    expected: "(DockerWorkerConfigV3DatasourceInputExpiration | undefined)",
                    value: input.inputExpiration
                })) && $ao1(input.inputExpiration, _path + ".inputExpiration", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".inputExpiration",
                    expected: "(DockerWorkerConfigV3DatasourceInputExpiration | undefined)",
                    value: input.inputExpiration
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.minDaysToExpiration && !Number.isNaN(input.minDaysToExpiration) && (0 <= input.minDaysToExpiration || $guard(_exceptionable, {
                    path: _path + ".minDaysToExpiration",
                    expected: "number (@minimum 0)",
                    value: input.minDaysToExpiration
                })) || $guard(_exceptionable, {
                    path: _path + ".minDaysToExpiration",
                    expected: "number",
                    value: input.minDaysToExpiration
                })) && ("SKIP" === input.handlingStrategy || "ABORT" === input.handlingStrategy || $guard(_exceptionable, {
                    path: _path + ".handlingStrategy",
                    expected: "(\"ABORT\" | \"SKIP\")",
                    value: input.handlingStrategy
                }));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV3DockerDatasource",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV3DockerDatasource",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerWorkerConfigV3DockerDatasource): string => {
        const $io1 = (input: any): boolean => "number" === typeof input.minDaysToExpiration && 0 <= input.minDaysToExpiration && ("SKIP" === input.handlingStrategy || "ABORT" === input.handlingStrategy);
        const $tail = (typia.createAssertStringify as any).tail;
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $so0 = (input: any): any => `{${$tail(`${undefined === input.bypassVerify ? "" : `"bypassVerify":${undefined !== input.bypassVerify ? input.bypassVerify : undefined},`}${undefined === input.enableDatapoolUpdate ? "" : `"enableDatapoolUpdate":${undefined !== input.enableDatapoolUpdate ? input.enableDatapoolUpdate : undefined},`}${undefined === input.processAll ? "" : `"processAll":${undefined !== input.processAll ? input.processAll : undefined},`}${undefined === input.inputExpiration ? "" : `"inputExpiration":${undefined !== input.inputExpiration ? $so1(input.inputExpiration) : undefined}`}`)}}`;
        const $so1 = (input: any): any => `{"minDaysToExpiration":${input.minDaysToExpiration},"handlingStrategy":${(() => {
            if ("string" === typeof input.handlingStrategy)
                return $string(input.handlingStrategy);
            if ("string" === typeof input.handlingStrategy)
                return "\"" + input.handlingStrategy + "\"";
            $throws({
                expected: "(\"ABORT\" | \"SKIP\")",
                value: input.handlingStrategy
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DockerWorkerConfigV3DockerDatasourceValidatorShape = {
    'bypassVerify': yup.boolean(),
    'enableDatapoolUpdate': yup.boolean(),
    'processAll': yup.boolean(),
    'inputExpiration': DockerWorkerConfigV3DatasourceInputExpirationValidator.optional().default(undefined),
};
export const DockerWorkerConfigV3DockerDatasourceValidator = yup.object().shape(DockerWorkerConfigV3DockerDatasourceValidatorShape);
export const DockerWorkerConfigV3DockerDatasourceStringifyShapeProperties: ObjectSchema['properties'] = {
    'bypassVerify': {
        'type': 'boolean',
    },
    'enableDatapoolUpdate': {
        'type': 'boolean',
    },
    'processAll': {
        'type': 'boolean',
    },
    'inputExpiration': DockerWorkerConfigV3DatasourceInputExpirationStringifyShape,
};
export const DockerWorkerConfigV3DockerDatasourceStringifyShape: ObjectSchema = {
    title: 'DockerWorkerConfigV3DockerDatasource Stringifier',
    type: 'object',
    properties: {
        ...DockerWorkerConfigV3DockerDatasourceStringifyShapeProperties
    },
    required: [],
};
export const DockerWorkerConfigV3DockerDatasourceStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerWorkerConfigV3DockerDatasourceStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerWorkerConfigV3DockerDatasourceStringify: (data: DockerWorkerConfigV3DockerDatasource) => string = DockerWorkerConfigV3DockerDatasourceStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerWorkerConfigV3DockerDatasourceFromJSON(json: any): DockerWorkerConfigV3DockerDatasource {
    return DockerWorkerConfigV3DockerDatasourceFromJSONTyped(json, false);
}
export function DockerWorkerConfigV3DockerDatasourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerWorkerConfigV3DockerDatasource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'bypassVerify': !exists(json, 'bypassVerify') ? undefined : json['bypassVerify'],
        'enableDatapoolUpdate': !exists(json, 'enableDatapoolUpdate') ? undefined : json['enableDatapoolUpdate'],
        'processAll': !exists(json, 'processAll') ? undefined : json['processAll'],
        'inputExpiration': !exists(json, 'inputExpiration') ? undefined : DockerWorkerConfigV3DatasourceInputExpirationFromJSON(json['inputExpiration']),
    };
}
export function DockerWorkerConfigV3DockerDatasourceToJSON(value?: DockerWorkerConfigV3DockerDatasource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'bypassVerify': value.bypassVerify,
        'enableDatapoolUpdate': value.enableDatapoolUpdate,
        'processAll': value.processAll,
        'inputExpiration': DockerWorkerConfigV3DatasourceInputExpirationToJSON(value.inputExpiration),
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { DatasourceRawSamplesPredictionsDataRow } from './DatasourceRawSamplesPredictionsDataRow';
import { DatasourceRawSamplesPredictionsDataRowFromJSON, DatasourceRawSamplesPredictionsDataRowFromJSONTyped, DatasourceRawSamplesPredictionsDataRowToJSON, } from './DatasourceRawSamplesPredictionsDataRow';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DatasourceRawSamplesPredictionsData
 *
 */
// create custom validators for the regexes
export interface DatasourceRawSamplesPredictionsData {
    /**
     * Set to `false` if end of list is reached. Otherwise `true`.
     * #type boolean
     * #memberof DatasourceRawSamplesPredictionsData
        
        
         */
    hasMore: boolean;
    /**
     * A cursor that indicates the current position in the list. Must be passed to future requests to continue reading from the same list.
     * #type string
     * #memberof DatasourceRawSamplesPredictionsData
        
        
         */
    cursor: string;
    /**
     * Array containing the raw samples prediction objects
     * #type Array<DatasourceRawSamplesPredictionsDataRow>
     * #memberof DatasourceRawSamplesPredictionsData
        
        
         */
    data: Array<DatasourceRawSamplesPredictionsDataRow>;
}
/**
 * Check if a given object implements the DatasourceRawSamplesPredictionsData interface.
 */
export function instanceOfDatasourceRawSamplesPredictionsData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "hasMore" in value;
    isInstance = isInstance && "cursor" in value;
    isInstance = isInstance && "data" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { DatasourceRawSamplesPredictionsDataRowValidator, DatasourceRawSamplesPredictionsDataRowStringifyShape, DatasourceRawSamplesPredictionsDataRowStringifyShapeProperties, } from './DatasourceRawSamplesPredictionsDataRow';
export const DatasourceRawSamplesPredictionsDataTypiaAssertEquals = (input: any) => {
    return ((input: any): DatasourceRawSamplesPredictionsData => {
        const __is = (input: any, _exceptionable: boolean = true): input is DatasourceRawSamplesPredictionsData => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "boolean" === typeof input.hasMore && "string" === typeof input.cursor && (Array.isArray(input.data) && input.data.every((elem: any, _index1: number) => "object" === typeof elem && null !== elem && $io1(elem, true && _exceptionable))) && (3 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["hasMore", "cursor", "data"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.fileName && "string" === typeof input.readUrl && (2 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["fileName", "readUrl"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DatasourceRawSamplesPredictionsData => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("boolean" === typeof input.hasMore || $guard(_exceptionable, {
                    path: _path + ".hasMore",
                    expected: "boolean",
                    value: input.hasMore
                })) && ("string" === typeof input.cursor || $guard(_exceptionable, {
                    path: _path + ".cursor",
                    expected: "string",
                    value: input.cursor
                })) && ((Array.isArray(input.data) || $guard(_exceptionable, {
                    path: _path + ".data",
                    expected: "Array<DatasourceRawSamplesPredictionsDataRow>",
                    value: input.data
                })) && input.data.every((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".data[" + _index1 + "]",
                    expected: "DatasourceRawSamplesPredictionsDataRow",
                    value: elem
                })) && $ao1(elem, _path + ".data[" + _index1 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".data[" + _index1 + "]",
                    expected: "DatasourceRawSamplesPredictionsDataRow",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".data",
                    expected: "Array<DatasourceRawSamplesPredictionsDataRow>",
                    value: input.data
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["hasMore", "cursor", "data"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.fileName || $guard(_exceptionable, {
                    path: _path + ".fileName",
                    expected: "string",
                    value: input.fileName
                })) && ("string" === typeof input.readUrl || $guard(_exceptionable, {
                    path: _path + ".readUrl",
                    expected: "string",
                    value: input.readUrl
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["fileName", "readUrl"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceRawSamplesPredictionsData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceRawSamplesPredictionsData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DatasourceRawSamplesPredictionsDataTypiaAssertStringify = (input: DatasourceRawSamplesPredictionsData): string => {
    return ((input: any): string => { const assert = (input: any): DatasourceRawSamplesPredictionsData => {
        const __is = (input: any): input is DatasourceRawSamplesPredictionsData => {
            const $io0 = (input: any): boolean => "boolean" === typeof input.hasMore && "string" === typeof input.cursor && (Array.isArray(input.data) && input.data.every((elem: any) => "object" === typeof elem && null !== elem && $io1(elem)));
            const $io1 = (input: any): boolean => "string" === typeof input.fileName && "string" === typeof input.readUrl;
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DatasourceRawSamplesPredictionsData => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("boolean" === typeof input.hasMore || $guard(_exceptionable, {
                    path: _path + ".hasMore",
                    expected: "boolean",
                    value: input.hasMore
                })) && ("string" === typeof input.cursor || $guard(_exceptionable, {
                    path: _path + ".cursor",
                    expected: "string",
                    value: input.cursor
                })) && ((Array.isArray(input.data) || $guard(_exceptionable, {
                    path: _path + ".data",
                    expected: "Array<DatasourceRawSamplesPredictionsDataRow>",
                    value: input.data
                })) && input.data.every((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".data[" + _index1 + "]",
                    expected: "DatasourceRawSamplesPredictionsDataRow",
                    value: elem
                })) && $ao1(elem, _path + ".data[" + _index1 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".data[" + _index1 + "]",
                    expected: "DatasourceRawSamplesPredictionsDataRow",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".data",
                    expected: "Array<DatasourceRawSamplesPredictionsDataRow>",
                    value: input.data
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.fileName || $guard(_exceptionable, {
                    path: _path + ".fileName",
                    expected: "string",
                    value: input.fileName
                })) && ("string" === typeof input.readUrl || $guard(_exceptionable, {
                    path: _path + ".readUrl",
                    expected: "string",
                    value: input.readUrl
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceRawSamplesPredictionsData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceRawSamplesPredictionsData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DatasourceRawSamplesPredictionsData): string => {
        const $io1 = (input: any): boolean => "string" === typeof input.fileName && "string" === typeof input.readUrl;
        const $string = (typia.createAssertStringify as any).string;
        const $so0 = (input: any): any => `{"hasMore":${input.hasMore},"cursor":${$string(input.cursor)},"data":${`[${input.data.map((elem: any) => `{"fileName":${$string((elem as any).fileName)},"readUrl":${$string((elem as any).readUrl)}}`).join(",")}]`}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DatasourceRawSamplesPredictionsDataValidatorShape = {
    'hasMore': yup.boolean().defined(),
    'cursor': yup.string().defined(),
    'data': yup.array().of(DatasourceRawSamplesPredictionsDataRowValidator).defined(),
};
export const DatasourceRawSamplesPredictionsDataValidator = yup.object().shape(DatasourceRawSamplesPredictionsDataValidatorShape);
export const DatasourceRawSamplesPredictionsDataStringifyShapeProperties: ObjectSchema['properties'] = {
    'hasMore': {
        'type': 'boolean',
    },
    'cursor': {
        'type': 'string',
    },
    'data': {
        type: 'array',
        items: {
            DatasourceRawSamplesPredictionsDataRowStringifyShape
        }
    },
};
export const DatasourceRawSamplesPredictionsDataStringifyShape: ObjectSchema = {
    title: 'DatasourceRawSamplesPredictionsData Stringifier',
    type: 'object',
    properties: {
        ...DatasourceRawSamplesPredictionsDataStringifyShapeProperties
    },
    required: [
        'hasMore',
        'cursor',
        'data',
    ],
};
export const DatasourceRawSamplesPredictionsDataStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DatasourceRawSamplesPredictionsDataStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DatasourceRawSamplesPredictionsDataStringify: (data: DatasourceRawSamplesPredictionsData) => string = DatasourceRawSamplesPredictionsDataStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DatasourceRawSamplesPredictionsDataFromJSON(json: any): DatasourceRawSamplesPredictionsData {
    return DatasourceRawSamplesPredictionsDataFromJSONTyped(json, false);
}
export function DatasourceRawSamplesPredictionsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatasourceRawSamplesPredictionsData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'hasMore': json['hasMore'],
        'cursor': json['cursor'],
        'data': ((json['data'] as Array<any> || []).map(DatasourceRawSamplesPredictionsDataRowFromJSON)),
    };
}
export function DatasourceRawSamplesPredictionsDataToJSON(value?: DatasourceRawSamplesPredictionsData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'hasMore': value.hasMore,
        'cursor': value.cursor,
        'data': ((value.data as Array<any> || []).map(DatasourceRawSamplesPredictionsDataRowToJSON)),
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { PredictionSingletonClassification } from './PredictionSingletonClassification';
import { PredictionSingletonClassificationFromJSON, PredictionSingletonClassificationFromJSONTyped, PredictionSingletonClassificationToJSON, } from './PredictionSingletonClassification';
import type { PredictionSingletonInstanceSegmentation } from './PredictionSingletonInstanceSegmentation';
import { PredictionSingletonInstanceSegmentationFromJSON, PredictionSingletonInstanceSegmentationFromJSONTyped, PredictionSingletonInstanceSegmentationToJSON, } from './PredictionSingletonInstanceSegmentation';
import type { PredictionSingletonKeypointDetection } from './PredictionSingletonKeypointDetection';
import { PredictionSingletonKeypointDetectionFromJSON, PredictionSingletonKeypointDetectionFromJSONTyped, PredictionSingletonKeypointDetectionToJSON, } from './PredictionSingletonKeypointDetection';
import type { PredictionSingletonObjectDetection } from './PredictionSingletonObjectDetection';
import { PredictionSingletonObjectDetectionFromJSON, PredictionSingletonObjectDetectionFromJSONTyped, PredictionSingletonObjectDetectionToJSON, } from './PredictionSingletonObjectDetection';
import type { PredictionSingletonSemanticSegmentation } from './PredictionSingletonSemanticSegmentation';
import { PredictionSingletonSemanticSegmentationFromJSON, PredictionSingletonSemanticSegmentationFromJSONTyped, PredictionSingletonSemanticSegmentationToJSON, } from './PredictionSingletonSemanticSegmentation';
/*
causes circular dependendies
import {
     PredictionSingletonClassificationFromJSONTyped,
} from './PredictionSingletonClassification';
import {
     PredictionSingletonInstanceSegmentationFromJSONTyped,
} from './PredictionSingletonInstanceSegmentation';
import {
     PredictionSingletonKeypointDetectionFromJSONTyped,
} from './PredictionSingletonKeypointDetection';
import {
     PredictionSingletonObjectDetectionFromJSONTyped,
} from './PredictionSingletonObjectDetection';
import {
     PredictionSingletonSemanticSegmentationFromJSONTyped
} from './PredictionSingletonSemanticSegmentation';


*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface PredictionSingletonBase
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_taskName")("string")(() => {
    const regex = new RegExp(/^[a-zA-Z0-9_+=,.@:\/-]*$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_cropDatasetId")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_cropSampleId")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
export interface PredictionSingletonBase {
    /**
     *
     * #type string
     * #memberof PredictionSingletonBase
        
        
         */
    type: string;
    /**
     * Since we sometimes stitch together SelectionInputTask+ActiveLearningScoreType, they need to follow the same specs of ActiveLearningScoreType. However, this can be an empty string due to internal logic (no minLength). Also v2config.filespecs.ts has this pattern for predictionTaskJSONSchema as well.
     * #type string
     * #memberof PredictionSingletonBase
        
        
    * @pattern_taskName
         */
    taskName: string;
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof PredictionSingletonBase
        
        
    * @pattern_cropDatasetId
         */
    cropDatasetId?: string;
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof PredictionSingletonBase
        
        
    * @pattern_cropSampleId
         */
    cropSampleId?: string;
    /**
     * The id of the category. Needs to be a positive integer but can be any integer (gaps are allowed, does not need to be sequential)
     * #type number
     * #memberof PredictionSingletonBase
        
    * @minimum 0
            
         */
    categoryId: number;
    /**
     * the score for the prediction task which yielded this crop
     * #type number
     * #memberof PredictionSingletonBase
        
    * @minimum 0
        * @maximum 1
        
         */
    score: number;
}
/**
 * Check if a given object implements the PredictionSingletonBase interface.
 */
export function instanceOfPredictionSingletonBase(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "taskName" in value;
    isInstance = isInstance && "categoryId" in value;
    isInstance = isInstance && "score" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { PredictionSingletonClassificationValidator, PredictionSingletonClassificationStringifyShape, PredictionSingletonClassificationStringifyShapeProperties, } from './PredictionSingletonClassification';
import { PredictionSingletonInstanceSegmentationValidator, PredictionSingletonInstanceSegmentationStringifyShape, PredictionSingletonInstanceSegmentationStringifyShapeProperties, } from './PredictionSingletonInstanceSegmentation';
import { PredictionSingletonKeypointDetectionValidator, PredictionSingletonKeypointDetectionStringifyShape, PredictionSingletonKeypointDetectionStringifyShapeProperties, } from './PredictionSingletonKeypointDetection';
import { PredictionSingletonObjectDetectionValidator, PredictionSingletonObjectDetectionStringifyShape, PredictionSingletonObjectDetectionStringifyShapeProperties, } from './PredictionSingletonObjectDetection';
import { PredictionSingletonSemanticSegmentationValidator, PredictionSingletonSemanticSegmentationStringifyShape, PredictionSingletonSemanticSegmentationStringifyShapeProperties, } from './PredictionSingletonSemanticSegmentation';
export const PredictionSingletonBaseTypiaAssertEquals = (input: any) => {
    return ((input: any): PredictionSingletonBase => {
        const __is = (input: any, _exceptionable: boolean = true): input is PredictionSingletonBase => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.type && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (4 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["type", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is PredictionSingletonBase => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "string",
                    value: input.type
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["type", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "PredictionSingletonBase",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "PredictionSingletonBase",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const PredictionSingletonBaseTypiaAssertStringify = (input: PredictionSingletonBase): string => {
    return ((input: any): string => { const assert = (input: any): PredictionSingletonBase => {
        const __is = (input: any): input is PredictionSingletonBase => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "string" === typeof input.type && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is PredictionSingletonBase => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "string",
                    value: input.type
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "PredictionSingletonBase",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "PredictionSingletonBase",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: PredictionSingletonBase): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${$string(input.type)},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const PredictionSingletonBaseValidatorShape = {
    'type': yup.string().defined(),
    'taskName': yup.string().matches(/^[a-zA-Z0-9_+=,.@:\/-]*$/).defined(),
    'cropDatasetId': yup.string().matches(/^[a-f0-9]{24}$/),
    'cropSampleId': yup.string().matches(/^[a-f0-9]{24}$/),
    'categoryId': yup.number().min(0).defined(),
    'score': yup.number().min(0).max(1).defined(),
};
export const PredictionSingletonBaseValidator = yup.object().shape(PredictionSingletonBaseValidatorShape);
export const PredictionSingletonBaseStringifyShapeProperties: ObjectSchema['properties'] = {
    'type': {
        'type': 'string',
    },
    'taskName': {
        'type': 'string',
        'pattern': "/^[a-zA-Z0-9_+&#x3D;,.@:\/-]*$/".slice(1, -1),
    },
    'cropDatasetId': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    },
    'cropSampleId': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    },
    'categoryId': {
        'type': 'number',
        'minimum': 0,
    },
    'score': {
        'type': 'number',
        'minimum': 0,
        'maximum': 1,
    },
};
export const PredictionSingletonBaseStringifyShape: ObjectSchema = {
    title: 'PredictionSingletonBase Stringifier',
    type: 'object',
    properties: {
        ...PredictionSingletonBaseStringifyShapeProperties
    },
    required: [
        'type',
        'taskName',
        'categoryId',
        'score',
    ],
};
export const PredictionSingletonBaseStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(PredictionSingletonBaseStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const PredictionSingletonBaseStringify: (data: PredictionSingletonBase) => string = PredictionSingletonBaseStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function PredictionSingletonBaseFromJSON(json: any): PredictionSingletonBase {
    return PredictionSingletonBaseFromJSONTyped(json, false);
}
export function PredictionSingletonBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PredictionSingletonBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
    if (!ignoreDiscriminator) {
        if (json['type'] === 'PredictionSingletonClassification') {
            return PredictionSingletonClassificationFromJSONTyped(json, true);
        }
        if (json['type'] === 'PredictionSingletonInstanceSegmentation') {
            return PredictionSingletonInstanceSegmentationFromJSONTyped(json, true);
        }
        if (json['type'] === 'PredictionSingletonKeypointDetection') {
            return PredictionSingletonKeypointDetectionFromJSONTyped(json, true);
        }
        if (json['type'] === 'PredictionSingletonObjectDetection') {
            return PredictionSingletonObjectDetectionFromJSONTyped(json, true);
        }
        if (json['type'] === 'PredictionSingletonSemanticSegmentation') {
            return PredictionSingletonSemanticSegmentationFromJSONTyped(json, true);
        }
    }
*/
    return {
        'type': json['type'],
        'taskName': json['taskName'],
        'cropDatasetId': !exists(json, 'cropDatasetId') ? undefined : json['cropDatasetId'],
        'cropSampleId': !exists(json, 'cropSampleId') ? undefined : json['cropSampleId'],
        'categoryId': json['categoryId'],
        'score': json['score'],
    };
}
export function PredictionSingletonBaseToJSON(value?: PredictionSingletonBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': value.type,
        'taskName': value.taskName,
        'cropDatasetId': value.cropDatasetId,
        'cropSampleId': value.cropSampleId,
        'categoryId': value.categoryId,
        'score': value.score,
    };
}

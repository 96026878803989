/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { DockerWorkerState } from './DockerWorkerState';
import { DockerWorkerStateFromJSON, DockerWorkerStateFromJSONTyped, DockerWorkerStateToJSON, } from './DockerWorkerState';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface UpdateDockerWorkerRegistryEntryRequest
 *
 */
// create custom validators for the regexes
export interface UpdateDockerWorkerRegistryEntryRequest {
    /**
     *
     * #type DockerWorkerState
     * #memberof UpdateDockerWorkerRegistryEntryRequest
        
        
         */
    state: DockerWorkerState;
    /**
     *
     * #type string
     * #memberof UpdateDockerWorkerRegistryEntryRequest
        
        
         */
    dockerVersion?: string;
}
/**
 * Check if a given object implements the UpdateDockerWorkerRegistryEntryRequest interface.
 */
export function instanceOfUpdateDockerWorkerRegistryEntryRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "state" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { DockerWorkerStateValidator, DockerWorkerStateStringifyShape, DockerWorkerStateStringifyShapeProperties, } from './DockerWorkerState';
export const UpdateDockerWorkerRegistryEntryRequestTypiaAssertEquals = (input: any) => {
    return ((input: any): UpdateDockerWorkerRegistryEntryRequest => {
        const __is = (input: any, _exceptionable: boolean = true): input is UpdateDockerWorkerRegistryEntryRequest => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => ("OFFLINE" === input.state || "CRASHED" === input.state || "IDLE" === input.state || "BUSY" === input.state) && (undefined === input.dockerVersion || "string" === typeof input.dockerVersion) && (1 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["state", "dockerVersion"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is UpdateDockerWorkerRegistryEntryRequest => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("OFFLINE" === input.state || "CRASHED" === input.state || "IDLE" === input.state || "BUSY" === input.state || $guard(_exceptionable, {
                    path: _path + ".state",
                    expected: "(\"BUSY\" | \"CRASHED\" | \"IDLE\" | \"OFFLINE\")",
                    value: input.state
                })) && (undefined === input.dockerVersion || "string" === typeof input.dockerVersion || $guard(_exceptionable, {
                    path: _path + ".dockerVersion",
                    expected: "(string | undefined)",
                    value: input.dockerVersion
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["state", "dockerVersion"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "UpdateDockerWorkerRegistryEntryRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "UpdateDockerWorkerRegistryEntryRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const UpdateDockerWorkerRegistryEntryRequestTypiaAssertStringify = (input: UpdateDockerWorkerRegistryEntryRequest): string => {
    return ((input: any): string => { const assert = (input: any): UpdateDockerWorkerRegistryEntryRequest => {
        const __is = (input: any): input is UpdateDockerWorkerRegistryEntryRequest => {
            const $io0 = (input: any): boolean => ("OFFLINE" === input.state || "CRASHED" === input.state || "IDLE" === input.state || "BUSY" === input.state) && (undefined === input.dockerVersion || "string" === typeof input.dockerVersion);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is UpdateDockerWorkerRegistryEntryRequest => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("OFFLINE" === input.state || "CRASHED" === input.state || "IDLE" === input.state || "BUSY" === input.state || $guard(_exceptionable, {
                    path: _path + ".state",
                    expected: "(\"BUSY\" | \"CRASHED\" | \"IDLE\" | \"OFFLINE\")",
                    value: input.state
                })) && (undefined === input.dockerVersion || "string" === typeof input.dockerVersion || $guard(_exceptionable, {
                    path: _path + ".dockerVersion",
                    expected: "(string | undefined)",
                    value: input.dockerVersion
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "UpdateDockerWorkerRegistryEntryRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "UpdateDockerWorkerRegistryEntryRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: UpdateDockerWorkerRegistryEntryRequest): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $so0 = (input: any): any => `{${undefined === input.dockerVersion ? "" : `"dockerVersion":${undefined !== input.dockerVersion ? $string(input.dockerVersion) : undefined},`}"state":${(() => {
            if ("string" === typeof input.state)
                return $string(input.state);
            if ("string" === typeof input.state)
                return "\"" + input.state + "\"";
            $throws({
                expected: "(\"BUSY\" | \"CRASHED\" | \"IDLE\" | \"OFFLINE\")",
                value: input.state
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const UpdateDockerWorkerRegistryEntryRequestValidatorShape = {
    'state': DockerWorkerStateValidator.defined(),
    'dockerVersion': yup.string(),
};
export const UpdateDockerWorkerRegistryEntryRequestValidator = yup.object().shape(UpdateDockerWorkerRegistryEntryRequestValidatorShape);
export const UpdateDockerWorkerRegistryEntryRequestStringifyShapeProperties: ObjectSchema['properties'] = {
    'state': DockerWorkerStateStringifyShape,
    'dockerVersion': {
        'type': 'string',
    },
};
export const UpdateDockerWorkerRegistryEntryRequestStringifyShape: ObjectSchema = {
    title: 'UpdateDockerWorkerRegistryEntryRequest Stringifier',
    type: 'object',
    properties: {
        ...UpdateDockerWorkerRegistryEntryRequestStringifyShapeProperties
    },
    required: [
        'state',
    ],
};
export const UpdateDockerWorkerRegistryEntryRequestStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(UpdateDockerWorkerRegistryEntryRequestStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const UpdateDockerWorkerRegistryEntryRequestStringify: (data: UpdateDockerWorkerRegistryEntryRequest) => string = UpdateDockerWorkerRegistryEntryRequestStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function UpdateDockerWorkerRegistryEntryRequestFromJSON(json: any): UpdateDockerWorkerRegistryEntryRequest {
    return UpdateDockerWorkerRegistryEntryRequestFromJSONTyped(json, false);
}
export function UpdateDockerWorkerRegistryEntryRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDockerWorkerRegistryEntryRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'state': DockerWorkerStateFromJSON(json['state']),
        'dockerVersion': !exists(json, 'dockerVersion') ? undefined : json['dockerVersion'],
    };
}
export function UpdateDockerWorkerRegistryEntryRequestToJSON(value?: UpdateDockerWorkerRegistryEntryRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'state': DockerWorkerStateToJSON(value.state),
        'dockerVersion': value.dockerVersion,
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { DimensionalityReductionMethod } from './DimensionalityReductionMethod';
import { DimensionalityReductionMethodFromJSON, DimensionalityReductionMethodFromJSONTyped, DimensionalityReductionMethodToJSON, } from './DimensionalityReductionMethod';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface Trigger2dEmbeddingJobRequest
 *
 */
// create custom validators for the regexes
export interface Trigger2dEmbeddingJobRequest {
    /**
     *
     * #type DimensionalityReductionMethod
     * #memberof Trigger2dEmbeddingJobRequest
        
        
         */
    dimensionalityReductionMethod: DimensionalityReductionMethod;
}
/**
 * Check if a given object implements the Trigger2dEmbeddingJobRequest interface.
 */
export function instanceOfTrigger2dEmbeddingJobRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dimensionalityReductionMethod" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { DimensionalityReductionMethodValidator, DimensionalityReductionMethodStringifyShape, DimensionalityReductionMethodStringifyShapeProperties, } from './DimensionalityReductionMethod';
export const Trigger2dEmbeddingJobRequestTypiaAssertEquals = (input: any) => {
    return ((input: any): Trigger2dEmbeddingJobRequest => {
        const __is = (input: any, _exceptionable: boolean = true): input is Trigger2dEmbeddingJobRequest => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => ("PCA" === input.dimensionalityReductionMethod || "TSNE" === input.dimensionalityReductionMethod || "UMAP" === input.dimensionalityReductionMethod) && (1 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["dimensionalityReductionMethod"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is Trigger2dEmbeddingJobRequest => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PCA" === input.dimensionalityReductionMethod || "TSNE" === input.dimensionalityReductionMethod || "UMAP" === input.dimensionalityReductionMethod || $guard(_exceptionable, {
                    path: _path + ".dimensionalityReductionMethod",
                    expected: "(\"PCA\" | \"TSNE\" | \"UMAP\")",
                    value: input.dimensionalityReductionMethod
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["dimensionalityReductionMethod"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "Trigger2dEmbeddingJobRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "Trigger2dEmbeddingJobRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const Trigger2dEmbeddingJobRequestTypiaAssertStringify = (input: Trigger2dEmbeddingJobRequest): string => {
    return ((input: any): string => { const assert = (input: any): Trigger2dEmbeddingJobRequest => {
        const __is = (input: any): input is Trigger2dEmbeddingJobRequest => {
            const $io0 = (input: any): boolean => "PCA" === input.dimensionalityReductionMethod || "TSNE" === input.dimensionalityReductionMethod || "UMAP" === input.dimensionalityReductionMethod;
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is Trigger2dEmbeddingJobRequest => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => "PCA" === input.dimensionalityReductionMethod || "TSNE" === input.dimensionalityReductionMethod || "UMAP" === input.dimensionalityReductionMethod || $guard(_exceptionable, {
                    path: _path + ".dimensionalityReductionMethod",
                    expected: "(\"PCA\" | \"TSNE\" | \"UMAP\")",
                    value: input.dimensionalityReductionMethod
                });
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "Trigger2dEmbeddingJobRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "Trigger2dEmbeddingJobRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: Trigger2dEmbeddingJobRequest): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $so0 = (input: any): any => `{"dimensionalityReductionMethod":${(() => {
            if ("string" === typeof input.dimensionalityReductionMethod)
                return $string(input.dimensionalityReductionMethod);
            if ("string" === typeof input.dimensionalityReductionMethod)
                return "\"" + input.dimensionalityReductionMethod + "\"";
            $throws({
                expected: "(\"PCA\" | \"TSNE\" | \"UMAP\")",
                value: input.dimensionalityReductionMethod
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const Trigger2dEmbeddingJobRequestValidatorShape = {
    'dimensionalityReductionMethod': DimensionalityReductionMethodValidator.defined(),
};
export const Trigger2dEmbeddingJobRequestValidator = yup.object().shape(Trigger2dEmbeddingJobRequestValidatorShape);
export const Trigger2dEmbeddingJobRequestStringifyShapeProperties: ObjectSchema['properties'] = {
    'dimensionalityReductionMethod': DimensionalityReductionMethodStringifyShape,
};
export const Trigger2dEmbeddingJobRequestStringifyShape: ObjectSchema = {
    title: 'Trigger2dEmbeddingJobRequest Stringifier',
    type: 'object',
    properties: {
        ...Trigger2dEmbeddingJobRequestStringifyShapeProperties
    },
    required: [
        'dimensionalityReductionMethod',
    ],
};
export const Trigger2dEmbeddingJobRequestStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(Trigger2dEmbeddingJobRequestStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const Trigger2dEmbeddingJobRequestStringify: (data: Trigger2dEmbeddingJobRequest) => string = Trigger2dEmbeddingJobRequestStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function Trigger2dEmbeddingJobRequestFromJSON(json: any): Trigger2dEmbeddingJobRequest {
    return Trigger2dEmbeddingJobRequestFromJSONTyped(json, false);
}
export function Trigger2dEmbeddingJobRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): Trigger2dEmbeddingJobRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'dimensionalityReductionMethod': DimensionalityReductionMethodFromJSON(json['dimensionalityReductionMethod']),
    };
}
export function Trigger2dEmbeddingJobRequestToJSON(value?: Trigger2dEmbeddingJobRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dimensionalityReductionMethod': DimensionalityReductionMethodToJSON(value.dimensionalityReductionMethod),
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { TagChangeDataOperationMethod } from './TagChangeDataOperationMethod';
import { TagChangeDataOperationMethodFromJSON, TagChangeDataOperationMethodFromJSONTyped, TagChangeDataOperationMethodToJSON, } from './TagChangeDataOperationMethod';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface TagChangeDataMetadata
 *
 */
// create custom validators for the regexes
export interface TagChangeDataMetadata {
    /**
     *
     * #type TagChangeDataOperationMethod
     * #memberof TagChangeDataMetadata
        
        
         */
    method: TagChangeDataOperationMethod;
    /**
     *
     * #type number
     * #memberof TagChangeDataMetadata
        
    * @minimum 0
            
         */
    count: number;
    /**
     *
     * #type number
     * #memberof TagChangeDataMetadata
        
    * @minimum 0
            
         */
    added: number;
    /**
     *
     * #type number
     * #memberof TagChangeDataMetadata
        
    * @minimum 0
            
         */
    removed: number;
    /**
     *
     * #type { [key: string]: any | undefined; }
     * #memberof TagChangeDataMetadata
        
        
         */
    changes: {
        [key: string]: any | undefined;
    };
}
/**
 * Check if a given object implements the TagChangeDataMetadata interface.
 */
export function instanceOfTagChangeDataMetadata(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "method" in value;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "added" in value;
    isInstance = isInstance && "removed" in value;
    isInstance = isInstance && "changes" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { TagChangeDataOperationMethodValidator, TagChangeDataOperationMethodStringifyShape, TagChangeDataOperationMethodStringifyShapeProperties, } from './TagChangeDataOperationMethod';
export const TagChangeDataMetadataTypiaAssertEquals = (input: any) => {
    return ((input: any): TagChangeDataMetadata => {
        const __is = (input: any, _exceptionable: boolean = true): input is TagChangeDataMetadata => {
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && ("number" === typeof input.count && 0 <= input.count) && ("number" === typeof input.added && 0 <= input.added) && ("number" === typeof input.removed && 0 <= input.removed) && ("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) && $io1(input.changes, true && _exceptionable)) && (5 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["method", "count", "added", "removed", "changes"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => Object.keys(input).every(key => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is TagChangeDataMetadata => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"added\" | \"removed\" | \"selected\")",
                    value: input.method
                })) && ("number" === typeof input.count && (0 <= input.count || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number (@minimum 0)",
                    value: input.count
                })) || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number",
                    value: input.count
                })) && ("number" === typeof input.added && (0 <= input.added || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number (@minimum 0)",
                    value: input.added
                })) || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number",
                    value: input.added
                })) && ("number" === typeof input.removed && (0 <= input.removed || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number (@minimum 0)",
                    value: input.removed
                })) || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number",
                    value: input.removed
                })) && (("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "__type",
                    value: input.changes
                })) && $ao1(input.changes, _path + ".changes", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "__type",
                    value: input.changes
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["method", "count", "added", "removed", "changes"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every(key => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                });
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "TagChangeDataMetadata",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "TagChangeDataMetadata",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const TagChangeDataMetadataTypiaAssertStringify = (input: TagChangeDataMetadata): string => {
    return ((input: any): string => { const assert = (input: any): TagChangeDataMetadata => {
        const __is = (input: any): input is TagChangeDataMetadata => {
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method) && ("number" === typeof input.count && !Number.isNaN(input.count) && 0 <= input.count) && ("number" === typeof input.added && !Number.isNaN(input.added) && 0 <= input.added) && ("number" === typeof input.removed && !Number.isNaN(input.removed) && 0 <= input.removed) && ("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) && $io1(input.changes));
            const $io1 = (input: any): boolean => Object.keys(input).every(key => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is TagChangeDataMetadata => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("selected" === input.method || "added" === input.method || "removed" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"added\" | \"removed\" | \"selected\")",
                    value: input.method
                })) && ("number" === typeof input.count && !Number.isNaN(input.count) && (0 <= input.count || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number (@minimum 0)",
                    value: input.count
                })) || $guard(_exceptionable, {
                    path: _path + ".count",
                    expected: "number",
                    value: input.count
                })) && ("number" === typeof input.added && !Number.isNaN(input.added) && (0 <= input.added || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number (@minimum 0)",
                    value: input.added
                })) || $guard(_exceptionable, {
                    path: _path + ".added",
                    expected: "number",
                    value: input.added
                })) && ("number" === typeof input.removed && !Number.isNaN(input.removed) && (0 <= input.removed || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number (@minimum 0)",
                    value: input.removed
                })) || $guard(_exceptionable, {
                    path: _path + ".removed",
                    expected: "number",
                    value: input.removed
                })) && (("object" === typeof input.changes && null !== input.changes && false === Array.isArray(input.changes) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "__type",
                    value: input.changes
                })) && $ao1(input.changes, _path + ".changes", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".changes",
                    expected: "__type",
                    value: input.changes
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every(key => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                });
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "TagChangeDataMetadata",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "TagChangeDataMetadata",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: TagChangeDataMetadata): string => {
        const $io1 = (input: any): boolean => Object.keys(input).every(key => {
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $join = (typia.createAssertStringify as any).join;
        const $so0 = (input: any): any => `{"method":${(() => {
            if ("string" === typeof input.method)
                return $string(input.method);
            if ("string" === typeof input.method)
                return "\"" + input.method + "\"";
            $throws({
                expected: "(\"added\" | \"removed\" | \"selected\")",
                value: input.method
            });
        })()},"count":${input.count},"added":${input.added},"removed":${input.removed},"changes":${$so1(input.changes)}}`;
        const $so1 = (input: any): any => `{${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter(str => "" !== str).join(",")}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const TagChangeDataMetadataValidatorShape = {
    'method': TagChangeDataOperationMethodValidator.defined(),
    'count': yup.number().min(0).defined(),
    'added': yup.number().min(0).defined(),
    'removed': yup.number().min(0).defined(),
    'changes': yup.mixed().transform((val: any) => { return val instanceof Object && !Array.isArray(val) ? val : {}; }).defined(),
};
export const TagChangeDataMetadataValidator = yup.object().shape(TagChangeDataMetadataValidatorShape);
export const TagChangeDataMetadataStringifyShapeProperties: ObjectSchema['properties'] = {
    'method': TagChangeDataOperationMethodStringifyShape,
    'count': {
        'type': 'number',
        'minimum': 0,
    },
    'added': {
        'type': 'number',
        'minimum': 0,
    },
    'removed': {
        'type': 'number',
        'minimum': 0,
    },
    'changes': {
        type: 'object',
        additionalProperties: true
    },
};
export const TagChangeDataMetadataStringifyShape: ObjectSchema = {
    title: 'TagChangeDataMetadata Stringifier',
    type: 'object',
    properties: {
        ...TagChangeDataMetadataStringifyShapeProperties
    },
    required: [
        'method',
        'count',
        'added',
        'removed',
        'changes',
    ],
};
export const TagChangeDataMetadataStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(TagChangeDataMetadataStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const TagChangeDataMetadataStringify: (data: TagChangeDataMetadata) => string = TagChangeDataMetadataStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function TagChangeDataMetadataFromJSON(json: any): TagChangeDataMetadata {
    return TagChangeDataMetadataFromJSONTyped(json, false);
}
export function TagChangeDataMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagChangeDataMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'method': TagChangeDataOperationMethodFromJSON(json['method']),
        'count': json['count'],
        'added': json['added'],
        'removed': json['removed'],
        'changes': json['changes'],
    };
}
export function TagChangeDataMetadataToJSON(value?: TagChangeDataMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'method': TagChangeDataOperationMethodToJSON(value.method),
        'count': value.count,
        'added': value.added,
        'removed': value.removed,
        'changes': value.changes,
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { LightlyModelV2 } from './LightlyModelV2';
import { LightlyModelV2FromJSON, LightlyModelV2FromJSONTyped, LightlyModelV2ToJSON, } from './LightlyModelV2';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DockerWorkerConfigV2LightlyModel
 *
 */
// create custom validators for the regexes
export interface DockerWorkerConfigV2LightlyModel {
    /**
     *
     * #type LightlyModelV2
     * #memberof DockerWorkerConfigV2LightlyModel
        
        
         */
    name?: LightlyModelV2;
    /**
     *
     * #type number
     * #memberof DockerWorkerConfigV2LightlyModel
        * @type int
    * @minimum 1
            
         */
    outDim?: number;
    /**
     *
     * #type number
     * #memberof DockerWorkerConfigV2LightlyModel
        * @type int
    * @minimum 1
            
         */
    numFtrs?: number;
    /**
     *
     * #type number
     * #memberof DockerWorkerConfigV2LightlyModel
        * @type int
    * @minimum 1
            
         */
    width?: number;
}
/**
 * Check if a given object implements the DockerWorkerConfigV2LightlyModel interface.
 */
export function instanceOfDockerWorkerConfigV2LightlyModel(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { LightlyModelV2Validator, LightlyModelV2StringifyShape, LightlyModelV2StringifyShapeProperties, } from './LightlyModelV2';
export const DockerWorkerConfigV2LightlyModelTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerWorkerConfigV2LightlyModel => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerWorkerConfigV2LightlyModel => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.name || "resnet-18" === input.name || "resnet-34" === input.name || "resnet-50" === input.name || "resnet-101" === input.name || "resnet-152" === input.name) && (undefined === input.outDim || "number" === typeof input.outDim && Math.floor(input.outDim) === input.outDim && (-2147483648 <= input.outDim && input.outDim <= 2147483647) && 1 <= input.outDim) && (undefined === input.numFtrs || "number" === typeof input.numFtrs && Math.floor(input.numFtrs) === input.numFtrs && (-2147483648 <= input.numFtrs && input.numFtrs <= 2147483647) && 1 <= input.numFtrs) && (undefined === input.width || "number" === typeof input.width && Math.floor(input.width) === input.width && (-2147483648 <= input.width && input.width <= 2147483647) && 1 <= input.width) && (0 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["name", "outDim", "numFtrs", "width"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigV2LightlyModel => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.name || "resnet-18" === input.name || "resnet-34" === input.name || "resnet-50" === input.name || "resnet-101" === input.name || "resnet-152" === input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "(\"resnet-101\" | \"resnet-152\" | \"resnet-18\" | \"resnet-34\" | \"resnet-50\" | undefined)",
                    value: input.name
                })) && (undefined === input.outDim || "number" === typeof input.outDim && (Math.floor(input.outDim) === input.outDim || $guard(_exceptionable, {
                    path: _path + ".outDim",
                    expected: "number (@type int)",
                    value: input.outDim
                })) && (-2147483648 <= input.outDim && input.outDim <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".outDim",
                    expected: "number (@type int)",
                    value: input.outDim
                })) && (1 <= input.outDim || $guard(_exceptionable, {
                    path: _path + ".outDim",
                    expected: "number (@minimum 1)",
                    value: input.outDim
                })) || $guard(_exceptionable, {
                    path: _path + ".outDim",
                    expected: "(number | undefined)",
                    value: input.outDim
                })) && (undefined === input.numFtrs || "number" === typeof input.numFtrs && (Math.floor(input.numFtrs) === input.numFtrs || $guard(_exceptionable, {
                    path: _path + ".numFtrs",
                    expected: "number (@type int)",
                    value: input.numFtrs
                })) && (-2147483648 <= input.numFtrs && input.numFtrs <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".numFtrs",
                    expected: "number (@type int)",
                    value: input.numFtrs
                })) && (1 <= input.numFtrs || $guard(_exceptionable, {
                    path: _path + ".numFtrs",
                    expected: "number (@minimum 1)",
                    value: input.numFtrs
                })) || $guard(_exceptionable, {
                    path: _path + ".numFtrs",
                    expected: "(number | undefined)",
                    value: input.numFtrs
                })) && (undefined === input.width || "number" === typeof input.width && (Math.floor(input.width) === input.width || $guard(_exceptionable, {
                    path: _path + ".width",
                    expected: "number (@type int)",
                    value: input.width
                })) && (-2147483648 <= input.width && input.width <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".width",
                    expected: "number (@type int)",
                    value: input.width
                })) && (1 <= input.width || $guard(_exceptionable, {
                    path: _path + ".width",
                    expected: "number (@minimum 1)",
                    value: input.width
                })) || $guard(_exceptionable, {
                    path: _path + ".width",
                    expected: "(number | undefined)",
                    value: input.width
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["name", "outDim", "numFtrs", "width"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV2LightlyModel",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV2LightlyModel",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerWorkerConfigV2LightlyModelTypiaAssertStringify = (input: DockerWorkerConfigV2LightlyModel): string => {
    return ((input: any): string => { const assert = (input: any): DockerWorkerConfigV2LightlyModel => {
        const __is = (input: any): input is DockerWorkerConfigV2LightlyModel => {
            const $io0 = (input: any): boolean => (undefined === input.name || "resnet-18" === input.name || "resnet-34" === input.name || "resnet-50" === input.name || "resnet-101" === input.name || "resnet-152" === input.name) && (undefined === input.outDim || "number" === typeof input.outDim && !Number.isNaN(input.outDim) && Math.floor(input.outDim) === input.outDim && (-2147483648 <= input.outDim && input.outDim <= 2147483647) && 1 <= input.outDim) && (undefined === input.numFtrs || "number" === typeof input.numFtrs && !Number.isNaN(input.numFtrs) && Math.floor(input.numFtrs) === input.numFtrs && (-2147483648 <= input.numFtrs && input.numFtrs <= 2147483647) && 1 <= input.numFtrs) && (undefined === input.width || "number" === typeof input.width && !Number.isNaN(input.width) && Math.floor(input.width) === input.width && (-2147483648 <= input.width && input.width <= 2147483647) && 1 <= input.width);
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigV2LightlyModel => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.name || "resnet-18" === input.name || "resnet-34" === input.name || "resnet-50" === input.name || "resnet-101" === input.name || "resnet-152" === input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "(\"resnet-101\" | \"resnet-152\" | \"resnet-18\" | \"resnet-34\" | \"resnet-50\" | undefined)",
                    value: input.name
                })) && (undefined === input.outDim || "number" === typeof input.outDim && !Number.isNaN(input.outDim) && (Math.floor(input.outDim) === input.outDim || $guard(_exceptionable, {
                    path: _path + ".outDim",
                    expected: "number (@type int)",
                    value: input.outDim
                })) && (-2147483648 <= input.outDim && input.outDim <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".outDim",
                    expected: "number (@type int)",
                    value: input.outDim
                })) && (1 <= input.outDim || $guard(_exceptionable, {
                    path: _path + ".outDim",
                    expected: "number (@minimum 1)",
                    value: input.outDim
                })) || $guard(_exceptionable, {
                    path: _path + ".outDim",
                    expected: "(number | undefined)",
                    value: input.outDim
                })) && (undefined === input.numFtrs || "number" === typeof input.numFtrs && !Number.isNaN(input.numFtrs) && (Math.floor(input.numFtrs) === input.numFtrs || $guard(_exceptionable, {
                    path: _path + ".numFtrs",
                    expected: "number (@type int)",
                    value: input.numFtrs
                })) && (-2147483648 <= input.numFtrs && input.numFtrs <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".numFtrs",
                    expected: "number (@type int)",
                    value: input.numFtrs
                })) && (1 <= input.numFtrs || $guard(_exceptionable, {
                    path: _path + ".numFtrs",
                    expected: "number (@minimum 1)",
                    value: input.numFtrs
                })) || $guard(_exceptionable, {
                    path: _path + ".numFtrs",
                    expected: "(number | undefined)",
                    value: input.numFtrs
                })) && (undefined === input.width || "number" === typeof input.width && !Number.isNaN(input.width) && (Math.floor(input.width) === input.width || $guard(_exceptionable, {
                    path: _path + ".width",
                    expected: "number (@type int)",
                    value: input.width
                })) && (-2147483648 <= input.width && input.width <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".width",
                    expected: "number (@type int)",
                    value: input.width
                })) && (1 <= input.width || $guard(_exceptionable, {
                    path: _path + ".width",
                    expected: "number (@minimum 1)",
                    value: input.width
                })) || $guard(_exceptionable, {
                    path: _path + ".width",
                    expected: "(number | undefined)",
                    value: input.width
                }));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV2LightlyModel",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV2LightlyModel",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerWorkerConfigV2LightlyModel): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $tail = (typia.createAssertStringify as any).tail;
        const $so0 = (input: any): any => `{${$tail(`${undefined === input.name ? "" : `"name":${undefined !== input.name ? (() => {
            if ("string" === typeof input.name)
                return $string(input.name);
            if ("string" === typeof input.name)
                return "\"" + input.name + "\"";
            $throws({
                expected: "(\"resnet-101\" | \"resnet-152\" | \"resnet-18\" | \"resnet-34\" | \"resnet-50\" | undefined)",
                value: input.name
            });
        })() : undefined},`}${undefined === input.outDim ? "" : `"outDim":${undefined !== input.outDim ? input.outDim : undefined},`}${undefined === input.numFtrs ? "" : `"numFtrs":${undefined !== input.numFtrs ? input.numFtrs : undefined},`}${undefined === input.width ? "" : `"width":${undefined !== input.width ? input.width : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DockerWorkerConfigV2LightlyModelValidatorShape = {
    'name': LightlyModelV2Validator.optional().default(undefined),
    'outDim': yup.number().integer().min(1),
    'numFtrs': yup.number().integer().min(1),
    'width': yup.number().integer().min(1),
};
export const DockerWorkerConfigV2LightlyModelValidator = yup.object().shape(DockerWorkerConfigV2LightlyModelValidatorShape);
export const DockerWorkerConfigV2LightlyModelStringifyShapeProperties: ObjectSchema['properties'] = {
    'name': LightlyModelV2StringifyShape,
    'outDim': {
        'type': 'integer',
        'minimum': 1,
    },
    'numFtrs': {
        'type': 'integer',
        'minimum': 1,
    },
    'width': {
        'type': 'integer',
        'minimum': 1,
    },
};
export const DockerWorkerConfigV2LightlyModelStringifyShape: ObjectSchema = {
    title: 'DockerWorkerConfigV2LightlyModel Stringifier',
    type: 'object',
    properties: {
        ...DockerWorkerConfigV2LightlyModelStringifyShapeProperties
    },
    required: [],
};
export const DockerWorkerConfigV2LightlyModelStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerWorkerConfigV2LightlyModelStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerWorkerConfigV2LightlyModelStringify: (data: DockerWorkerConfigV2LightlyModel) => string = DockerWorkerConfigV2LightlyModelStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerWorkerConfigV2LightlyModelFromJSON(json: any): DockerWorkerConfigV2LightlyModel {
    return DockerWorkerConfigV2LightlyModelFromJSONTyped(json, false);
}
export function DockerWorkerConfigV2LightlyModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerWorkerConfigV2LightlyModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'name': !exists(json, 'name') ? undefined : LightlyModelV2FromJSON(json['name']),
        'outDim': !exists(json, 'outDim') ? undefined : json['outDim'],
        'numFtrs': !exists(json, 'numFtrs') ? undefined : json['numFtrs'],
        'width': !exists(json, 'width') ? undefined : json['width'],
    };
}
export function DockerWorkerConfigV2LightlyModelToJSON(value?: DockerWorkerConfigV2LightlyModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': LightlyModelV2ToJSON(value.name),
        'outDim': value.outDim,
        'numFtrs': value.numFtrs,
        'width': value.width,
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface VideoFrameData
 *
 */
// create custom validators for the regexes
export interface VideoFrameData {
    /**
     * Name of the source video.
     * #type string
     * #memberof VideoFrameData
        
        
         */
    sourceVideo?: string;
    /**
     * Index of the frame in the source video.
     * #type number
     * #memberof VideoFrameData
        
        
         */
    sourceVideoFrameIndex?: number;
    /**
     * Timestamp of the frame in the source video.
     * #type number
     * #memberof VideoFrameData
        
        
         */
    sourceVideoFrameTimestamp?: number;
}
/**
 * Check if a given object implements the VideoFrameData interface.
 */
export function instanceOfVideoFrameData(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
export const VideoFrameDataTypiaAssertEquals = (input: any) => {
    return ((input: any): VideoFrameData => {
        const __is = (input: any, _exceptionable: boolean = true): input is VideoFrameData => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.sourceVideo || "string" === typeof input.sourceVideo) && (undefined === input.sourceVideoFrameIndex || "number" === typeof input.sourceVideoFrameIndex) && (undefined === input.sourceVideoFrameTimestamp || "number" === typeof input.sourceVideoFrameTimestamp) && (0 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["sourceVideo", "sourceVideoFrameIndex", "sourceVideoFrameTimestamp"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is VideoFrameData => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.sourceVideo || "string" === typeof input.sourceVideo || $guard(_exceptionable, {
                    path: _path + ".sourceVideo",
                    expected: "(string | undefined)",
                    value: input.sourceVideo
                })) && (undefined === input.sourceVideoFrameIndex || "number" === typeof input.sourceVideoFrameIndex || $guard(_exceptionable, {
                    path: _path + ".sourceVideoFrameIndex",
                    expected: "(number | undefined)",
                    value: input.sourceVideoFrameIndex
                })) && (undefined === input.sourceVideoFrameTimestamp || "number" === typeof input.sourceVideoFrameTimestamp || $guard(_exceptionable, {
                    path: _path + ".sourceVideoFrameTimestamp",
                    expected: "(number | undefined)",
                    value: input.sourceVideoFrameTimestamp
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["sourceVideo", "sourceVideoFrameIndex", "sourceVideoFrameTimestamp"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "VideoFrameData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "VideoFrameData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const VideoFrameDataTypiaAssertStringify = (input: VideoFrameData): string => {
    return ((input: any): string => { const assert = (input: any): VideoFrameData => {
        const __is = (input: any): input is VideoFrameData => {
            const $io0 = (input: any): boolean => (undefined === input.sourceVideo || "string" === typeof input.sourceVideo) && (undefined === input.sourceVideoFrameIndex || "number" === typeof input.sourceVideoFrameIndex && !Number.isNaN(input.sourceVideoFrameIndex)) && (undefined === input.sourceVideoFrameTimestamp || "number" === typeof input.sourceVideoFrameTimestamp && !Number.isNaN(input.sourceVideoFrameTimestamp));
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is VideoFrameData => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.sourceVideo || "string" === typeof input.sourceVideo || $guard(_exceptionable, {
                    path: _path + ".sourceVideo",
                    expected: "(string | undefined)",
                    value: input.sourceVideo
                })) && (undefined === input.sourceVideoFrameIndex || "number" === typeof input.sourceVideoFrameIndex && !Number.isNaN(input.sourceVideoFrameIndex) || $guard(_exceptionable, {
                    path: _path + ".sourceVideoFrameIndex",
                    expected: "(number | undefined)",
                    value: input.sourceVideoFrameIndex
                })) && (undefined === input.sourceVideoFrameTimestamp || "number" === typeof input.sourceVideoFrameTimestamp && !Number.isNaN(input.sourceVideoFrameTimestamp) || $guard(_exceptionable, {
                    path: _path + ".sourceVideoFrameTimestamp",
                    expected: "(number | undefined)",
                    value: input.sourceVideoFrameTimestamp
                }));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "VideoFrameData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "VideoFrameData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: VideoFrameData): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $tail = (typia.createAssertStringify as any).tail;
        const $so0 = (input: any): any => `{${$tail(`${undefined === input.sourceVideo ? "" : `"sourceVideo":${undefined !== input.sourceVideo ? $string(input.sourceVideo) : undefined},`}${undefined === input.sourceVideoFrameIndex ? "" : `"sourceVideoFrameIndex":${undefined !== input.sourceVideoFrameIndex ? input.sourceVideoFrameIndex : undefined},`}${undefined === input.sourceVideoFrameTimestamp ? "" : `"sourceVideoFrameTimestamp":${undefined !== input.sourceVideoFrameTimestamp ? input.sourceVideoFrameTimestamp : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const VideoFrameDataValidatorShape = {
    'sourceVideo': yup.string(),
    'sourceVideoFrameIndex': yup.number(),
    'sourceVideoFrameTimestamp': yup.number(),
};
export const VideoFrameDataValidator = yup.object().shape(VideoFrameDataValidatorShape);
export const VideoFrameDataStringifyShapeProperties: ObjectSchema['properties'] = {
    'sourceVideo': {
        'type': 'string',
    },
    'sourceVideoFrameIndex': {
        'type': 'number',
    },
    'sourceVideoFrameTimestamp': {
        'type': 'number',
    },
};
export const VideoFrameDataStringifyShape: ObjectSchema = {
    title: 'VideoFrameData Stringifier',
    type: 'object',
    properties: {
        ...VideoFrameDataStringifyShapeProperties
    },
    required: [],
};
export const VideoFrameDataStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(VideoFrameDataStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const VideoFrameDataStringify: (data: VideoFrameData) => string = VideoFrameDataStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function VideoFrameDataFromJSON(json: any): VideoFrameData {
    return VideoFrameDataFromJSONTyped(json, false);
}
export function VideoFrameDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): VideoFrameData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'sourceVideo': !exists(json, 'sourceVideo') ? undefined : json['sourceVideo'],
        'sourceVideoFrameIndex': !exists(json, 'sourceVideoFrameIndex') ? undefined : json['sourceVideoFrameIndex'],
        'sourceVideoFrameTimestamp': !exists(json, 'sourceVideoFrameTimestamp') ? undefined : json['sourceVideoFrameTimestamp'],
    };
}
export function VideoFrameDataToJSON(value?: VideoFrameData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'sourceVideo': value.sourceVideo,
        'sourceVideoFrameIndex': value.sourceVideoFrameIndex,
        'sourceVideoFrameTimestamp': value.sourceVideoFrameTimestamp,
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DatasourceConfigVerifyDataErrors
 *
 */
// create custom validators for the regexes
export interface DatasourceConfigVerifyDataErrors {
    /**
     *
     * #type string
     * #memberof DatasourceConfigVerifyDataErrors
        
        
         */
    canRead?: string;
    /**
     *
     * #type string
     * #memberof DatasourceConfigVerifyDataErrors
        
        
         */
    canWrite?: string;
    /**
     *
     * #type string
     * #memberof DatasourceConfigVerifyDataErrors
        
        
         */
    canList?: string;
    /**
     *
     * #type string
     * #memberof DatasourceConfigVerifyDataErrors
        
        
         */
    canOverwrite?: string;
}
/**
 * Check if a given object implements the DatasourceConfigVerifyDataErrors interface.
 */
export function instanceOfDatasourceConfigVerifyDataErrors(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
export const DatasourceConfigVerifyDataErrorsTypiaAssertEquals = (input: any) => {
    return ((input: any): DatasourceConfigVerifyDataErrors => {
        const __is = (input: any, _exceptionable: boolean = true): input is DatasourceConfigVerifyDataErrors => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.canRead || "string" === typeof input.canRead) && (undefined === input.canWrite || "string" === typeof input.canWrite) && (undefined === input.canList || "string" === typeof input.canList) && (undefined === input.canOverwrite || "string" === typeof input.canOverwrite) && (0 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["canRead", "canWrite", "canList", "canOverwrite"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DatasourceConfigVerifyDataErrors => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.canRead || "string" === typeof input.canRead || $guard(_exceptionable, {
                    path: _path + ".canRead",
                    expected: "(string | undefined)",
                    value: input.canRead
                })) && (undefined === input.canWrite || "string" === typeof input.canWrite || $guard(_exceptionable, {
                    path: _path + ".canWrite",
                    expected: "(string | undefined)",
                    value: input.canWrite
                })) && (undefined === input.canList || "string" === typeof input.canList || $guard(_exceptionable, {
                    path: _path + ".canList",
                    expected: "(string | undefined)",
                    value: input.canList
                })) && (undefined === input.canOverwrite || "string" === typeof input.canOverwrite || $guard(_exceptionable, {
                    path: _path + ".canOverwrite",
                    expected: "(string | undefined)",
                    value: input.canOverwrite
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["canRead", "canWrite", "canList", "canOverwrite"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceConfigVerifyDataErrors",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceConfigVerifyDataErrors",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DatasourceConfigVerifyDataErrorsTypiaAssertStringify = (input: DatasourceConfigVerifyDataErrors): string => {
    return ((input: any): string => { const assert = (input: any): DatasourceConfigVerifyDataErrors => {
        const __is = (input: any): input is DatasourceConfigVerifyDataErrors => {
            const $io0 = (input: any): boolean => (undefined === input.canRead || "string" === typeof input.canRead) && (undefined === input.canWrite || "string" === typeof input.canWrite) && (undefined === input.canList || "string" === typeof input.canList) && (undefined === input.canOverwrite || "string" === typeof input.canOverwrite);
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DatasourceConfigVerifyDataErrors => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.canRead || "string" === typeof input.canRead || $guard(_exceptionable, {
                    path: _path + ".canRead",
                    expected: "(string | undefined)",
                    value: input.canRead
                })) && (undefined === input.canWrite || "string" === typeof input.canWrite || $guard(_exceptionable, {
                    path: _path + ".canWrite",
                    expected: "(string | undefined)",
                    value: input.canWrite
                })) && (undefined === input.canList || "string" === typeof input.canList || $guard(_exceptionable, {
                    path: _path + ".canList",
                    expected: "(string | undefined)",
                    value: input.canList
                })) && (undefined === input.canOverwrite || "string" === typeof input.canOverwrite || $guard(_exceptionable, {
                    path: _path + ".canOverwrite",
                    expected: "(string | undefined)",
                    value: input.canOverwrite
                }));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceConfigVerifyDataErrors",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceConfigVerifyDataErrors",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DatasourceConfigVerifyDataErrors): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $tail = (typia.createAssertStringify as any).tail;
        const $so0 = (input: any): any => `{${$tail(`${undefined === input.canRead ? "" : `"canRead":${undefined !== input.canRead ? $string(input.canRead) : undefined},`}${undefined === input.canWrite ? "" : `"canWrite":${undefined !== input.canWrite ? $string(input.canWrite) : undefined},`}${undefined === input.canList ? "" : `"canList":${undefined !== input.canList ? $string(input.canList) : undefined},`}${undefined === input.canOverwrite ? "" : `"canOverwrite":${undefined !== input.canOverwrite ? $string(input.canOverwrite) : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DatasourceConfigVerifyDataErrorsValidatorShape = {
    'canRead': yup.string(),
    'canWrite': yup.string(),
    'canList': yup.string(),
    'canOverwrite': yup.string(),
};
export const DatasourceConfigVerifyDataErrorsValidator = yup.object().shape(DatasourceConfigVerifyDataErrorsValidatorShape);
export const DatasourceConfigVerifyDataErrorsStringifyShapeProperties: ObjectSchema['properties'] = {
    'canRead': {
        'type': 'string',
    },
    'canWrite': {
        'type': 'string',
    },
    'canList': {
        'type': 'string',
    },
    'canOverwrite': {
        'type': 'string',
    },
};
export const DatasourceConfigVerifyDataErrorsStringifyShape: ObjectSchema = {
    title: 'DatasourceConfigVerifyDataErrors Stringifier',
    type: 'object',
    properties: {
        ...DatasourceConfigVerifyDataErrorsStringifyShapeProperties
    },
    required: [],
};
export const DatasourceConfigVerifyDataErrorsStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DatasourceConfigVerifyDataErrorsStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DatasourceConfigVerifyDataErrorsStringify: (data: DatasourceConfigVerifyDataErrors) => string = DatasourceConfigVerifyDataErrorsStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DatasourceConfigVerifyDataErrorsFromJSON(json: any): DatasourceConfigVerifyDataErrors {
    return DatasourceConfigVerifyDataErrorsFromJSONTyped(json, false);
}
export function DatasourceConfigVerifyDataErrorsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatasourceConfigVerifyDataErrors {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'canRead': !exists(json, 'canRead') ? undefined : json['canRead'],
        'canWrite': !exists(json, 'canWrite') ? undefined : json['canWrite'],
        'canList': !exists(json, 'canList') ? undefined : json['canList'],
        'canOverwrite': !exists(json, 'canOverwrite') ? undefined : json['canOverwrite'],
    };
}
export function DatasourceConfigVerifyDataErrorsToJSON(value?: DatasourceConfigVerifyDataErrors | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'canRead': value.canRead,
        'canWrite': value.canWrite,
        'canList': value.canList,
        'canOverwrite': value.canOverwrite,
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { DockerRunArtifactStorageLocation } from './DockerRunArtifactStorageLocation';
import { DockerRunArtifactStorageLocationFromJSON, DockerRunArtifactStorageLocationFromJSONTyped, DockerRunArtifactStorageLocationToJSON, } from './DockerRunArtifactStorageLocation';
import type { DockerRunArtifactType } from './DockerRunArtifactType';
import { DockerRunArtifactTypeFromJSON, DockerRunArtifactTypeFromJSONTyped, DockerRunArtifactTypeToJSON, } from './DockerRunArtifactType';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DockerRunArtifactData
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_id")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
export interface DockerRunArtifactData {
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof DockerRunArtifactData
        
        
    * @pattern_id
         */
    id: string;
    /**
     *
     * #type DockerRunArtifactType
     * #memberof DockerRunArtifactData
        
        
         */
    type: DockerRunArtifactType;
    /**
     *
     * #type string
     * #memberof DockerRunArtifactData
        
        
         */
    fileName: string;
    /**
     *
     * #type DockerRunArtifactStorageLocation
     * #memberof DockerRunArtifactData
        
        
         */
    storageLocation?: DockerRunArtifactStorageLocation;
    /**
     * unix timestamp in milliseconds
     * #type number
     * #memberof DockerRunArtifactData
        
    * @minimum 0
            
         */
    createdAt?: number;
}
/**
 * Check if a given object implements the DockerRunArtifactData interface.
 */
export function instanceOfDockerRunArtifactData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "fileName" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { DockerRunArtifactStorageLocationValidator, DockerRunArtifactStorageLocationStringifyShape, DockerRunArtifactStorageLocationStringifyShapeProperties, } from './DockerRunArtifactStorageLocation';
import { DockerRunArtifactTypeValidator, DockerRunArtifactTypeStringifyShape, DockerRunArtifactTypeStringifyShapeProperties, } from './DockerRunArtifactType';
export const DockerRunArtifactDataTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerRunArtifactData => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerRunArtifactData => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.id && $is_custom("pattern_id", "string", "", input.id) && ("LOG" === input.type || "MEMLOG" === input.type || "CHECKPOINT" === input.type || "REPORT_PDF" === input.type || "REPORT_JSON" === input.type || "REPORT_V2_JSON" === input.type || "CORRUPTNESS_CHECK_INFORMATION" === input.type || "SEQUENCE_INFORMATION" === input.type || "RELEVANT_FILENAMES" === input.type) && "string" === typeof input.fileName && (undefined === input.storageLocation || "LIGHTLY" === input.storageLocation || "DATASOURCE" === input.storageLocation) && (undefined === input.createdAt || "number" === typeof input.createdAt && 0 <= input.createdAt) && (3 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["id", "type", "fileName", "storageLocation", "createdAt"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerRunArtifactData => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id && ($is_custom("pattern_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && ("LOG" === input.type || "MEMLOG" === input.type || "CHECKPOINT" === input.type || "REPORT_PDF" === input.type || "REPORT_JSON" === input.type || "REPORT_V2_JSON" === input.type || "CORRUPTNESS_CHECK_INFORMATION" === input.type || "SEQUENCE_INFORMATION" === input.type || "RELEVANT_FILENAMES" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "(\"CHECKPOINT\" | \"CORRUPTNESS_CHECK_INFORMATION\" | \"LOG\" | \"MEMLOG\" | \"RELEVANT_FILENAMES\" | \"REPORT_JSON\" | \"REPORT_PDF\" | \"REPORT_V2_JSON\" | \"SEQUENCE_INFORMATION\")",
                    value: input.type
                })) && ("string" === typeof input.fileName || $guard(_exceptionable, {
                    path: _path + ".fileName",
                    expected: "string",
                    value: input.fileName
                })) && (undefined === input.storageLocation || "LIGHTLY" === input.storageLocation || "DATASOURCE" === input.storageLocation || $guard(_exceptionable, {
                    path: _path + ".storageLocation",
                    expected: "(\"DATASOURCE\" | \"LIGHTLY\" | undefined)",
                    value: input.storageLocation
                })) && (undefined === input.createdAt || "number" === typeof input.createdAt && (0 <= input.createdAt || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number (@minimum 0)",
                    value: input.createdAt
                })) || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "(number | undefined)",
                    value: input.createdAt
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["id", "type", "fileName", "storageLocation", "createdAt"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerRunArtifactData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerRunArtifactData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerRunArtifactDataTypiaAssertStringify = (input: DockerRunArtifactData): string => {
    return ((input: any): string => { const assert = (input: any): DockerRunArtifactData => {
        const __is = (input: any): input is DockerRunArtifactData => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "string" === typeof input.id && $is_custom("pattern_id", "string", "", input.id) && ("LOG" === input.type || "MEMLOG" === input.type || "CHECKPOINT" === input.type || "REPORT_PDF" === input.type || "REPORT_JSON" === input.type || "REPORT_V2_JSON" === input.type || "CORRUPTNESS_CHECK_INFORMATION" === input.type || "SEQUENCE_INFORMATION" === input.type || "RELEVANT_FILENAMES" === input.type) && "string" === typeof input.fileName && (undefined === input.storageLocation || "LIGHTLY" === input.storageLocation || "DATASOURCE" === input.storageLocation) && (undefined === input.createdAt || "number" === typeof input.createdAt && !Number.isNaN(input.createdAt) && 0 <= input.createdAt);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerRunArtifactData => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id && ($is_custom("pattern_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && ("LOG" === input.type || "MEMLOG" === input.type || "CHECKPOINT" === input.type || "REPORT_PDF" === input.type || "REPORT_JSON" === input.type || "REPORT_V2_JSON" === input.type || "CORRUPTNESS_CHECK_INFORMATION" === input.type || "SEQUENCE_INFORMATION" === input.type || "RELEVANT_FILENAMES" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "(\"CHECKPOINT\" | \"CORRUPTNESS_CHECK_INFORMATION\" | \"LOG\" | \"MEMLOG\" | \"RELEVANT_FILENAMES\" | \"REPORT_JSON\" | \"REPORT_PDF\" | \"REPORT_V2_JSON\" | \"SEQUENCE_INFORMATION\")",
                    value: input.type
                })) && ("string" === typeof input.fileName || $guard(_exceptionable, {
                    path: _path + ".fileName",
                    expected: "string",
                    value: input.fileName
                })) && (undefined === input.storageLocation || "LIGHTLY" === input.storageLocation || "DATASOURCE" === input.storageLocation || $guard(_exceptionable, {
                    path: _path + ".storageLocation",
                    expected: "(\"DATASOURCE\" | \"LIGHTLY\" | undefined)",
                    value: input.storageLocation
                })) && (undefined === input.createdAt || "number" === typeof input.createdAt && !Number.isNaN(input.createdAt) && (0 <= input.createdAt || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number (@minimum 0)",
                    value: input.createdAt
                })) || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "(number | undefined)",
                    value: input.createdAt
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerRunArtifactData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerRunArtifactData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerRunArtifactData): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.storageLocation ? "" : `"storageLocation":${undefined !== input.storageLocation ? (() => {
            if ("string" === typeof input.storageLocation)
                return $string(input.storageLocation);
            if ("string" === typeof input.storageLocation)
                return "\"" + input.storageLocation + "\"";
            $throws({
                expected: "(\"DATASOURCE\" | \"LIGHTLY\" | undefined)",
                value: input.storageLocation
            });
        })() : undefined},`}${undefined === input.createdAt ? "" : `"createdAt":${undefined !== input.createdAt ? input.createdAt : undefined},`}"id":${$string(input.id)},"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "(\"CHECKPOINT\" | \"CORRUPTNESS_CHECK_INFORMATION\" | \"LOG\" | \"MEMLOG\" | \"RELEVANT_FILENAMES\" | \"REPORT_JSON\" | \"REPORT_PDF\" | \"REPORT_V2_JSON\" | \"SEQUENCE_INFORMATION\")",
                value: input.type
            });
        })()},"fileName":${$string(input.fileName)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DockerRunArtifactDataValidatorShape = {
    'id': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'type': DockerRunArtifactTypeValidator.defined(),
    'fileName': yup.string().defined(),
    'storageLocation': DockerRunArtifactStorageLocationValidator.optional().default(undefined),
    'createdAt': yup.number().min(0),
};
export const DockerRunArtifactDataValidator = yup.object().shape(DockerRunArtifactDataValidatorShape);
export const DockerRunArtifactDataStringifyShapeProperties: ObjectSchema['properties'] = {
    'id': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    },
    'type': DockerRunArtifactTypeStringifyShape,
    'fileName': {
        'type': 'string',
    },
    'storageLocation': DockerRunArtifactStorageLocationStringifyShape,
    'createdAt': {
        'type': 'number',
        'minimum': 0,
    },
};
export const DockerRunArtifactDataStringifyShape: ObjectSchema = {
    title: 'DockerRunArtifactData Stringifier',
    type: 'object',
    properties: {
        ...DockerRunArtifactDataStringifyShapeProperties
    },
    required: [
        'id',
        'type',
        'fileName',
    ],
};
export const DockerRunArtifactDataStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerRunArtifactDataStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerRunArtifactDataStringify: (data: DockerRunArtifactData) => string = DockerRunArtifactDataStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerRunArtifactDataFromJSON(json: any): DockerRunArtifactData {
    return DockerRunArtifactDataFromJSONTyped(json, false);
}
export function DockerRunArtifactDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerRunArtifactData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'id': json['id'],
        'type': DockerRunArtifactTypeFromJSON(json['type']),
        'fileName': json['fileName'],
        'storageLocation': !exists(json, 'storageLocation') ? undefined : DockerRunArtifactStorageLocationFromJSON(json['storageLocation']),
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
    };
}
export function DockerRunArtifactDataToJSON(value?: DockerRunArtifactData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'type': DockerRunArtifactTypeToJSON(value.type),
        'fileName': value.fileName,
        'storageLocation': DockerRunArtifactStorageLocationToJSON(value.storageLocation),
        'createdAt': value.createdAt,
    };
}

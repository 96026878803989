/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { ExpiryHandlingStrategyV3 } from './ExpiryHandlingStrategyV3';
import { ExpiryHandlingStrategyV3FromJSON, ExpiryHandlingStrategyV3FromJSONTyped, ExpiryHandlingStrategyV3ToJSON, } from './ExpiryHandlingStrategyV3';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 * Images that expire in less than the specified number of days are handled specially.
 * Given the handling strategy, these images are either skipped
 * or the worker breaks if encountering any of them.
 * @export
 * @interface DockerWorkerConfigV3DatasourceInputExpiration
 *
 */
// create custom validators for the regexes
export interface DockerWorkerConfigV3DatasourceInputExpiration {
    /**
     *
     * #type number
     * #memberof DockerWorkerConfigV3DatasourceInputExpiration
        
    * @minimum 0
            
         */
    minDaysToExpiration: number;
    /**
     *
     * #type ExpiryHandlingStrategyV3
     * #memberof DockerWorkerConfigV3DatasourceInputExpiration
        
        
         */
    handlingStrategy: ExpiryHandlingStrategyV3;
}
/**
 * Check if a given object implements the DockerWorkerConfigV3DatasourceInputExpiration interface.
 */
export function instanceOfDockerWorkerConfigV3DatasourceInputExpiration(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "minDaysToExpiration" in value;
    isInstance = isInstance && "handlingStrategy" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { ExpiryHandlingStrategyV3Validator, ExpiryHandlingStrategyV3StringifyShape, ExpiryHandlingStrategyV3StringifyShapeProperties, } from './ExpiryHandlingStrategyV3';
export const DockerWorkerConfigV3DatasourceInputExpirationTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerWorkerConfigV3DatasourceInputExpiration => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerWorkerConfigV3DatasourceInputExpiration => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.minDaysToExpiration && 0 <= input.minDaysToExpiration && ("SKIP" === input.handlingStrategy || "ABORT" === input.handlingStrategy) && (2 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["minDaysToExpiration", "handlingStrategy"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigV3DatasourceInputExpiration => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.minDaysToExpiration && (0 <= input.minDaysToExpiration || $guard(_exceptionable, {
                    path: _path + ".minDaysToExpiration",
                    expected: "number (@minimum 0)",
                    value: input.minDaysToExpiration
                })) || $guard(_exceptionable, {
                    path: _path + ".minDaysToExpiration",
                    expected: "number",
                    value: input.minDaysToExpiration
                })) && ("SKIP" === input.handlingStrategy || "ABORT" === input.handlingStrategy || $guard(_exceptionable, {
                    path: _path + ".handlingStrategy",
                    expected: "(\"ABORT\" | \"SKIP\")",
                    value: input.handlingStrategy
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["minDaysToExpiration", "handlingStrategy"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV3DatasourceInputExpiration",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV3DatasourceInputExpiration",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerWorkerConfigV3DatasourceInputExpirationTypiaAssertStringify = (input: DockerWorkerConfigV3DatasourceInputExpiration): string => {
    return ((input: any): string => { const assert = (input: any): DockerWorkerConfigV3DatasourceInputExpiration => {
        const __is = (input: any): input is DockerWorkerConfigV3DatasourceInputExpiration => {
            const $io0 = (input: any): boolean => "number" === typeof input.minDaysToExpiration && !Number.isNaN(input.minDaysToExpiration) && 0 <= input.minDaysToExpiration && ("SKIP" === input.handlingStrategy || "ABORT" === input.handlingStrategy);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigV3DatasourceInputExpiration => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.minDaysToExpiration && !Number.isNaN(input.minDaysToExpiration) && (0 <= input.minDaysToExpiration || $guard(_exceptionable, {
                    path: _path + ".minDaysToExpiration",
                    expected: "number (@minimum 0)",
                    value: input.minDaysToExpiration
                })) || $guard(_exceptionable, {
                    path: _path + ".minDaysToExpiration",
                    expected: "number",
                    value: input.minDaysToExpiration
                })) && ("SKIP" === input.handlingStrategy || "ABORT" === input.handlingStrategy || $guard(_exceptionable, {
                    path: _path + ".handlingStrategy",
                    expected: "(\"ABORT\" | \"SKIP\")",
                    value: input.handlingStrategy
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV3DatasourceInputExpiration",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV3DatasourceInputExpiration",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerWorkerConfigV3DatasourceInputExpiration): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $so0 = (input: any): any => `{"minDaysToExpiration":${input.minDaysToExpiration},"handlingStrategy":${(() => {
            if ("string" === typeof input.handlingStrategy)
                return $string(input.handlingStrategy);
            if ("string" === typeof input.handlingStrategy)
                return "\"" + input.handlingStrategy + "\"";
            $throws({
                expected: "(\"ABORT\" | \"SKIP\")",
                value: input.handlingStrategy
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DockerWorkerConfigV3DatasourceInputExpirationValidatorShape = {
    'minDaysToExpiration': yup.number().min(0).defined(),
    'handlingStrategy': ExpiryHandlingStrategyV3Validator.defined(),
};
export const DockerWorkerConfigV3DatasourceInputExpirationValidator = yup.object().shape(DockerWorkerConfigV3DatasourceInputExpirationValidatorShape);
export const DockerWorkerConfigV3DatasourceInputExpirationStringifyShapeProperties: ObjectSchema['properties'] = {
    'minDaysToExpiration': {
        'type': 'number',
        'minimum': 0,
    },
    'handlingStrategy': ExpiryHandlingStrategyV3StringifyShape,
};
export const DockerWorkerConfigV3DatasourceInputExpirationStringifyShape: ObjectSchema = {
    title: 'DockerWorkerConfigV3DatasourceInputExpiration Stringifier',
    type: 'object',
    properties: {
        ...DockerWorkerConfigV3DatasourceInputExpirationStringifyShapeProperties
    },
    required: [
        'minDaysToExpiration',
        'handlingStrategy',
    ],
};
export const DockerWorkerConfigV3DatasourceInputExpirationStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerWorkerConfigV3DatasourceInputExpirationStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerWorkerConfigV3DatasourceInputExpirationStringify: (data: DockerWorkerConfigV3DatasourceInputExpiration) => string = DockerWorkerConfigV3DatasourceInputExpirationStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerWorkerConfigV3DatasourceInputExpirationFromJSON(json: any): DockerWorkerConfigV3DatasourceInputExpiration {
    return DockerWorkerConfigV3DatasourceInputExpirationFromJSONTyped(json, false);
}
export function DockerWorkerConfigV3DatasourceInputExpirationFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerWorkerConfigV3DatasourceInputExpiration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'minDaysToExpiration': json['minDaysToExpiration'],
        'handlingStrategy': ExpiryHandlingStrategyV3FromJSON(json['handlingStrategy']),
    };
}
export function DockerWorkerConfigV3DatasourceInputExpirationToJSON(value?: DockerWorkerConfigV3DatasourceInputExpiration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'minDaysToExpiration': value.minDaysToExpiration,
        'handlingStrategy': ExpiryHandlingStrategyV3ToJSON(value.handlingStrategy),
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface TagChangeDataUpsize
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_runId")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
export interface TagChangeDataUpsize {
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof TagChangeDataUpsize
        
        
    * @pattern_runId
         */
    runId?: string;
    /**
     *
     * #type number
     * #memberof TagChangeDataUpsize
        
        
         */
    from: number;
    /**
     *
     * #type number
     * #memberof TagChangeDataUpsize
        
        
         */
    to: number;
}
/**
 * Check if a given object implements the TagChangeDataUpsize interface.
 */
export function instanceOfTagChangeDataUpsize(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "to" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
export const TagChangeDataUpsizeTypiaAssertEquals = (input: any) => {
    return ((input: any): TagChangeDataUpsize => {
        const __is = (input: any, _exceptionable: boolean = true): input is TagChangeDataUpsize => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_runId", "string", "", input.runId)) && "number" === typeof input.from && "number" === typeof input.to && (2 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["runId", "from", "to"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is TagChangeDataUpsize => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                })) && ("number" === typeof input.from || $guard(_exceptionable, {
                    path: _path + ".from",
                    expected: "number",
                    value: input.from
                })) && ("number" === typeof input.to || $guard(_exceptionable, {
                    path: _path + ".to",
                    expected: "number",
                    value: input.to
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["runId", "from", "to"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "TagChangeDataUpsize",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "TagChangeDataUpsize",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const TagChangeDataUpsizeTypiaAssertStringify = (input: TagChangeDataUpsize): string => {
    return ((input: any): string => { const assert = (input: any): TagChangeDataUpsize => {
        const __is = (input: any): input is TagChangeDataUpsize => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_runId", "string", "", input.runId)) && ("number" === typeof input.from && !Number.isNaN(input.from)) && ("number" === typeof input.to && !Number.isNaN(input.to));
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is TagChangeDataUpsize => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                })) && ("number" === typeof input.from && !Number.isNaN(input.from) || $guard(_exceptionable, {
                    path: _path + ".from",
                    expected: "number",
                    value: input.from
                })) && ("number" === typeof input.to && !Number.isNaN(input.to) || $guard(_exceptionable, {
                    path: _path + ".to",
                    expected: "number",
                    value: input.to
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "TagChangeDataUpsize",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "TagChangeDataUpsize",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: TagChangeDataUpsize): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.runId ? "" : `"runId":${undefined !== input.runId ? $string(input.runId) : undefined},`}"from":${input.from},"to":${input.to}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const TagChangeDataUpsizeValidatorShape = {
    'runId': yup.string().matches(/^[a-f0-9]{24}$/),
    'from': yup.number().defined(),
    'to': yup.number().defined(),
};
export const TagChangeDataUpsizeValidator = yup.object().shape(TagChangeDataUpsizeValidatorShape);
export const TagChangeDataUpsizeStringifyShapeProperties: ObjectSchema['properties'] = {
    'runId': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    },
    'from': {
        'type': 'number',
    },
    'to': {
        'type': 'number',
    },
};
export const TagChangeDataUpsizeStringifyShape: ObjectSchema = {
    title: 'TagChangeDataUpsize Stringifier',
    type: 'object',
    properties: {
        ...TagChangeDataUpsizeStringifyShapeProperties
    },
    required: [
        'from',
        'to',
    ],
};
export const TagChangeDataUpsizeStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(TagChangeDataUpsizeStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const TagChangeDataUpsizeStringify: (data: TagChangeDataUpsize) => string = TagChangeDataUpsizeStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function TagChangeDataUpsizeFromJSON(json: any): TagChangeDataUpsize {
    return TagChangeDataUpsizeFromJSONTyped(json, false);
}
export function TagChangeDataUpsizeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagChangeDataUpsize {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'runId': !exists(json, 'runId') ? undefined : json['runId'],
        'from': json['from'],
        'to': json['to'],
    };
}
export function TagChangeDataUpsizeToJSON(value?: TagChangeDataUpsize | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'runId': value.runId,
        'from': value.from,
        'to': value.to,
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 * If specified, it tries to select samples such that their sum of inputs is >= min_sum and <= max_sum. Only compatible with the WEIGHTS strategy.
 * @export
 * @interface SelectionConfigV3EntryStrategyAllOfTargetRange
 *
 */
// create custom validators for the regexes
export interface SelectionConfigV3EntryStrategyAllOfTargetRange {
    /**
     * Target minimum sum of inputs.
     * #type number
     * #memberof SelectionConfigV3EntryStrategyAllOfTargetRange
        
    * @minimum 0.0
            
         */
    minSum?: number;
    /**
     * Target maximum sum of inputs.  Must be >= min_sum.
     * #type number
     * #memberof SelectionConfigV3EntryStrategyAllOfTargetRange
        
    * @minimum 0.0
            
         */
    maxSum?: number;
}
/**
 * Check if a given object implements the SelectionConfigV3EntryStrategyAllOfTargetRange interface.
 */
export function instanceOfSelectionConfigV3EntryStrategyAllOfTargetRange(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
export const SelectionConfigV3EntryStrategyAllOfTargetRangeTypiaAssertEquals = (input: any) => {
    return ((input: any): SelectionConfigV3EntryStrategyAllOfTargetRange => {
        const __is = (input: any, _exceptionable: boolean = true): input is SelectionConfigV3EntryStrategyAllOfTargetRange => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.minSum || "number" === typeof input.minSum && 0 <= input.minSum) && (undefined === input.maxSum || "number" === typeof input.maxSum && 0 <= input.maxSum) && (0 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["minSum", "maxSum"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is SelectionConfigV3EntryStrategyAllOfTargetRange => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.minSum || "number" === typeof input.minSum && (0 <= input.minSum || $guard(_exceptionable, {
                    path: _path + ".minSum",
                    expected: "number (@minimum 0)",
                    value: input.minSum
                })) || $guard(_exceptionable, {
                    path: _path + ".minSum",
                    expected: "(number | undefined)",
                    value: input.minSum
                })) && (undefined === input.maxSum || "number" === typeof input.maxSum && (0 <= input.maxSum || $guard(_exceptionable, {
                    path: _path + ".maxSum",
                    expected: "number (@minimum 0)",
                    value: input.maxSum
                })) || $guard(_exceptionable, {
                    path: _path + ".maxSum",
                    expected: "(number | undefined)",
                    value: input.maxSum
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["minSum", "maxSum"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "SelectionConfigV3EntryStrategyAllOfTargetRange",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SelectionConfigV3EntryStrategyAllOfTargetRange",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const SelectionConfigV3EntryStrategyAllOfTargetRangeTypiaAssertStringify = (input: SelectionConfigV3EntryStrategyAllOfTargetRange): string => {
    return ((input: any): string => { const assert = (input: any): SelectionConfigV3EntryStrategyAllOfTargetRange => {
        const __is = (input: any): input is SelectionConfigV3EntryStrategyAllOfTargetRange => {
            const $io0 = (input: any): boolean => (undefined === input.minSum || "number" === typeof input.minSum && !Number.isNaN(input.minSum) && 0 <= input.minSum) && (undefined === input.maxSum || "number" === typeof input.maxSum && !Number.isNaN(input.maxSum) && 0 <= input.maxSum);
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is SelectionConfigV3EntryStrategyAllOfTargetRange => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.minSum || "number" === typeof input.minSum && !Number.isNaN(input.minSum) && (0 <= input.minSum || $guard(_exceptionable, {
                    path: _path + ".minSum",
                    expected: "number (@minimum 0)",
                    value: input.minSum
                })) || $guard(_exceptionable, {
                    path: _path + ".minSum",
                    expected: "(number | undefined)",
                    value: input.minSum
                })) && (undefined === input.maxSum || "number" === typeof input.maxSum && !Number.isNaN(input.maxSum) && (0 <= input.maxSum || $guard(_exceptionable, {
                    path: _path + ".maxSum",
                    expected: "number (@minimum 0)",
                    value: input.maxSum
                })) || $guard(_exceptionable, {
                    path: _path + ".maxSum",
                    expected: "(number | undefined)",
                    value: input.maxSum
                }));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "SelectionConfigV3EntryStrategyAllOfTargetRange",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SelectionConfigV3EntryStrategyAllOfTargetRange",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: SelectionConfigV3EntryStrategyAllOfTargetRange): string => {
        const $tail = (typia.createAssertStringify as any).tail;
        const $so0 = (input: any): any => `{${$tail(`${undefined === input.minSum ? "" : `"minSum":${undefined !== input.minSum ? input.minSum : undefined},`}${undefined === input.maxSum ? "" : `"maxSum":${undefined !== input.maxSum ? input.maxSum : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const SelectionConfigV3EntryStrategyAllOfTargetRangeValidatorShape = {
    'minSum': yup.number().min(0.0),
    'maxSum': yup.number().min(0.0),
};
export const SelectionConfigV3EntryStrategyAllOfTargetRangeValidator = yup.object().shape(SelectionConfigV3EntryStrategyAllOfTargetRangeValidatorShape);
export const SelectionConfigV3EntryStrategyAllOfTargetRangeStringifyShapeProperties: ObjectSchema['properties'] = {
    'minSum': {
        'type': 'number',
        'minimum': 0.0,
    },
    'maxSum': {
        'type': 'number',
        'minimum': 0.0,
    },
};
export const SelectionConfigV3EntryStrategyAllOfTargetRangeStringifyShape: ObjectSchema = {
    title: 'SelectionConfigV3EntryStrategyAllOfTargetRange Stringifier',
    type: 'object',
    properties: {
        ...SelectionConfigV3EntryStrategyAllOfTargetRangeStringifyShapeProperties
    },
    required: [],
};
export const SelectionConfigV3EntryStrategyAllOfTargetRangeStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(SelectionConfigV3EntryStrategyAllOfTargetRangeStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const SelectionConfigV3EntryStrategyAllOfTargetRangeStringify: (data: SelectionConfigV3EntryStrategyAllOfTargetRange) => string = SelectionConfigV3EntryStrategyAllOfTargetRangeStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function SelectionConfigV3EntryStrategyAllOfTargetRangeFromJSON(json: any): SelectionConfigV3EntryStrategyAllOfTargetRange {
    return SelectionConfigV3EntryStrategyAllOfTargetRangeFromJSONTyped(json, false);
}
export function SelectionConfigV3EntryStrategyAllOfTargetRangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectionConfigV3EntryStrategyAllOfTargetRange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'minSum': !exists(json, 'minSum') ? undefined : json['minSum'],
        'maxSum': !exists(json, 'maxSum') ? undefined : json['maxSum'],
    };
}
export function SelectionConfigV3EntryStrategyAllOfTargetRangeToJSON(value?: SelectionConfigV3EntryStrategyAllOfTargetRange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'minSum': value.minSum,
        'maxSum': value.maxSum,
    };
}

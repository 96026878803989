/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { ConfigurationEntry } from './ConfigurationEntry';
import { ConfigurationEntryFromJSON, ConfigurationEntryFromJSONTyped, ConfigurationEntryToJSON, } from './ConfigurationEntry';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface ConfigurationSetRequest
 *
 */
// create custom validators for the regexes
export interface ConfigurationSetRequest {
    /**
     *
     * #type string
     * #memberof ConfigurationSetRequest
        
        
         */
    name: string;
    /**
     *
     * #type Array<ConfigurationEntry>
     * #memberof ConfigurationSetRequest
        
        
         */
    configs: Array<ConfigurationEntry>;
}
/**
 * Check if a given object implements the ConfigurationSetRequest interface.
 */
export function instanceOfConfigurationSetRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "configs" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { ConfigurationEntryValidator, ConfigurationEntryStringifyShape, ConfigurationEntryStringifyShapeProperties, } from './ConfigurationEntry';
export const ConfigurationSetRequestTypiaAssertEquals = (input: any) => {
    return ((input: any): ConfigurationSetRequest => {
        const __is = (input: any, _exceptionable: boolean = true): input is ConfigurationSetRequest => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.name && (Array.isArray(input.configs) && input.configs.every((elem: any, _index1: number) => "object" === typeof elem && null !== elem && $io1(elem, true && _exceptionable))) && (2 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["name", "configs"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.name && 1 <= input.name.length && ("string" === typeof input.path && 1 <= input.path.length) && true && ("NUMERIC_INT" === input.valueDataType || "NUMERIC_FLOAT" === input.valueDataType || "CATEGORICAL_STRING" === input.valueDataType || "CATEGORICAL_INT" === input.valueDataType || "CATEGORICAL_BOOLEAN" === input.valueDataType || "CATEGORICAL_DATETIME" === input.valueDataType || "CATEGORICAL_TIMESTAMP" === input.valueDataType || "OTHER_STRING" === input.valueDataType) && (4 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["name", "path", "defaultValue", "valueDataType"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is ConfigurationSetRequest => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ((Array.isArray(input.configs) || $guard(_exceptionable, {
                    path: _path + ".configs",
                    expected: "Array<ConfigurationEntry>",
                    value: input.configs
                })) && input.configs.every((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".configs[" + _index1 + "]",
                    expected: "ConfigurationEntry",
                    value: elem
                })) && $ao1(elem, _path + ".configs[" + _index1 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".configs[" + _index1 + "]",
                    expected: "ConfigurationEntry",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".configs",
                    expected: "Array<ConfigurationEntry>",
                    value: input.configs
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["name", "configs"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name && (1 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 1)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("string" === typeof input.path && (1 <= input.path.length || $guard(_exceptionable, {
                    path: _path + ".path",
                    expected: "string (@minLength 1)",
                    value: input.path
                })) || $guard(_exceptionable, {
                    path: _path + ".path",
                    expected: "string",
                    value: input.path
                })) && true && ("NUMERIC_INT" === input.valueDataType || "NUMERIC_FLOAT" === input.valueDataType || "CATEGORICAL_STRING" === input.valueDataType || "CATEGORICAL_INT" === input.valueDataType || "CATEGORICAL_BOOLEAN" === input.valueDataType || "CATEGORICAL_DATETIME" === input.valueDataType || "CATEGORICAL_TIMESTAMP" === input.valueDataType || "OTHER_STRING" === input.valueDataType || $guard(_exceptionable, {
                    path: _path + ".valueDataType",
                    expected: "(\"CATEGORICAL_BOOLEAN\" | \"CATEGORICAL_DATETIME\" | \"CATEGORICAL_INT\" | \"CATEGORICAL_STRING\" | \"CATEGORICAL_TIMESTAMP\" | \"NUMERIC_FLOAT\" | \"NUMERIC_INT\" | \"OTHER_STRING\")",
                    value: input.valueDataType
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["name", "path", "defaultValue", "valueDataType"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "ConfigurationSetRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "ConfigurationSetRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const ConfigurationSetRequestTypiaAssertStringify = (input: ConfigurationSetRequest): string => {
    return ((input: any): string => { const assert = (input: any): ConfigurationSetRequest => {
        const __is = (input: any): input is ConfigurationSetRequest => {
            const $io0 = (input: any): boolean => "string" === typeof input.name && (Array.isArray(input.configs) && input.configs.every((elem: any) => "object" === typeof elem && null !== elem && $io1(elem)));
            const $io1 = (input: any): boolean => "string" === typeof input.name && 1 <= input.name.length && ("string" === typeof input.path && 1 <= input.path.length) && true && ("NUMERIC_INT" === input.valueDataType || "NUMERIC_FLOAT" === input.valueDataType || "CATEGORICAL_STRING" === input.valueDataType || "CATEGORICAL_INT" === input.valueDataType || "CATEGORICAL_BOOLEAN" === input.valueDataType || "CATEGORICAL_DATETIME" === input.valueDataType || "CATEGORICAL_TIMESTAMP" === input.valueDataType || "OTHER_STRING" === input.valueDataType);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is ConfigurationSetRequest => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ((Array.isArray(input.configs) || $guard(_exceptionable, {
                    path: _path + ".configs",
                    expected: "Array<ConfigurationEntry>",
                    value: input.configs
                })) && input.configs.every((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".configs[" + _index1 + "]",
                    expected: "ConfigurationEntry",
                    value: elem
                })) && $ao1(elem, _path + ".configs[" + _index1 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".configs[" + _index1 + "]",
                    expected: "ConfigurationEntry",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".configs",
                    expected: "Array<ConfigurationEntry>",
                    value: input.configs
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name && (1 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 1)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("string" === typeof input.path && (1 <= input.path.length || $guard(_exceptionable, {
                    path: _path + ".path",
                    expected: "string (@minLength 1)",
                    value: input.path
                })) || $guard(_exceptionable, {
                    path: _path + ".path",
                    expected: "string",
                    value: input.path
                })) && true && ("NUMERIC_INT" === input.valueDataType || "NUMERIC_FLOAT" === input.valueDataType || "CATEGORICAL_STRING" === input.valueDataType || "CATEGORICAL_INT" === input.valueDataType || "CATEGORICAL_BOOLEAN" === input.valueDataType || "CATEGORICAL_DATETIME" === input.valueDataType || "CATEGORICAL_TIMESTAMP" === input.valueDataType || "OTHER_STRING" === input.valueDataType || $guard(_exceptionable, {
                    path: _path + ".valueDataType",
                    expected: "(\"CATEGORICAL_BOOLEAN\" | \"CATEGORICAL_DATETIME\" | \"CATEGORICAL_INT\" | \"CATEGORICAL_STRING\" | \"CATEGORICAL_TIMESTAMP\" | \"NUMERIC_FLOAT\" | \"NUMERIC_INT\" | \"OTHER_STRING\")",
                    value: input.valueDataType
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "ConfigurationSetRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "ConfigurationSetRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: ConfigurationSetRequest): string => {
        const $io1 = (input: any): boolean => "string" === typeof input.name && 1 <= input.name.length && ("string" === typeof input.path && 1 <= input.path.length) && true && ("NUMERIC_INT" === input.valueDataType || "NUMERIC_FLOAT" === input.valueDataType || "CATEGORICAL_STRING" === input.valueDataType || "CATEGORICAL_INT" === input.valueDataType || "CATEGORICAL_BOOLEAN" === input.valueDataType || "CATEGORICAL_DATETIME" === input.valueDataType || "CATEGORICAL_TIMESTAMP" === input.valueDataType || "OTHER_STRING" === input.valueDataType);
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $so0 = (input: any): any => `{"name":${$string(input.name)},"configs":${`[${input.configs.map((elem: any) => $so1(elem)).join(",")}]`}}`;
        const $so1 = (input: any): any => `{${undefined === input.defaultValue || "function" === typeof input.defaultValue ? "" : `"defaultValue":${undefined !== input.defaultValue ? JSON.stringify(input.defaultValue) : undefined},`}"name":${$string(input.name)},"path":${$string(input.path)},"valueDataType":${(() => {
            if ("string" === typeof input.valueDataType)
                return $string(input.valueDataType);
            if ("string" === typeof input.valueDataType)
                return "\"" + input.valueDataType + "\"";
            $throws({
                expected: "(\"CATEGORICAL_BOOLEAN\" | \"CATEGORICAL_DATETIME\" | \"CATEGORICAL_INT\" | \"CATEGORICAL_STRING\" | \"CATEGORICAL_TIMESTAMP\" | \"NUMERIC_FLOAT\" | \"NUMERIC_INT\" | \"OTHER_STRING\")",
                value: input.valueDataType
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const ConfigurationSetRequestValidatorShape = {
    'name': yup.string().defined(),
    'configs': yup.array().of(ConfigurationEntryValidator).defined(),
};
export const ConfigurationSetRequestValidator = yup.object().shape(ConfigurationSetRequestValidatorShape);
export const ConfigurationSetRequestStringifyShapeProperties: ObjectSchema['properties'] = {
    'name': {
        'type': 'string',
    },
    'configs': {
        type: 'array',
        items: {
            ConfigurationEntryStringifyShape
        }
    },
};
export const ConfigurationSetRequestStringifyShape: ObjectSchema = {
    title: 'ConfigurationSetRequest Stringifier',
    type: 'object',
    properties: {
        ...ConfigurationSetRequestStringifyShapeProperties
    },
    required: [
        'name',
        'configs',
    ],
};
export const ConfigurationSetRequestStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(ConfigurationSetRequestStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const ConfigurationSetRequestStringify: (data: ConfigurationSetRequest) => string = ConfigurationSetRequestStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function ConfigurationSetRequestFromJSON(json: any): ConfigurationSetRequest {
    return ConfigurationSetRequestFromJSONTyped(json, false);
}
export function ConfigurationSetRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigurationSetRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'name': json['name'],
        'configs': ((json['configs'] as Array<any> || []).map(ConfigurationEntryFromJSON)),
    };
}
export function ConfigurationSetRequestToJSON(value?: ConfigurationSetRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'configs': ((value.configs as Array<any> || []).map(ConfigurationEntryToJSON)),
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DockerWorkerConfigV3LightlyOptimizer
 *
 */
// create custom validators for the regexes
export interface DockerWorkerConfigV3LightlyOptimizer {
    /**
     *
     * #type number
     * #memberof DockerWorkerConfigV3LightlyOptimizer
        
    * @minimum 0.0
            
         */
    lr?: number;
    /**
     *
     * #type number
     * #memberof DockerWorkerConfigV3LightlyOptimizer
        
    * @minimum 0.0
        * @maximum 1.0
        
         */
    weightDecay?: number;
}
/**
 * Check if a given object implements the DockerWorkerConfigV3LightlyOptimizer interface.
 */
export function instanceOfDockerWorkerConfigV3LightlyOptimizer(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
export const DockerWorkerConfigV3LightlyOptimizerTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerWorkerConfigV3LightlyOptimizer => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerWorkerConfigV3LightlyOptimizer => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.lr || "number" === typeof input.lr && 0 <= input.lr) && (undefined === input.weightDecay || "number" === typeof input.weightDecay && 0 <= input.weightDecay && 1 >= input.weightDecay) && (0 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["lr", "weightDecay"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigV3LightlyOptimizer => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.lr || "number" === typeof input.lr && (0 <= input.lr || $guard(_exceptionable, {
                    path: _path + ".lr",
                    expected: "number (@minimum 0)",
                    value: input.lr
                })) || $guard(_exceptionable, {
                    path: _path + ".lr",
                    expected: "(number | undefined)",
                    value: input.lr
                })) && (undefined === input.weightDecay || "number" === typeof input.weightDecay && (0 <= input.weightDecay || $guard(_exceptionable, {
                    path: _path + ".weightDecay",
                    expected: "number (@minimum 0)",
                    value: input.weightDecay
                })) && (1 >= input.weightDecay || $guard(_exceptionable, {
                    path: _path + ".weightDecay",
                    expected: "number (@maximum 1)",
                    value: input.weightDecay
                })) || $guard(_exceptionable, {
                    path: _path + ".weightDecay",
                    expected: "(number | undefined)",
                    value: input.weightDecay
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["lr", "weightDecay"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV3LightlyOptimizer",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV3LightlyOptimizer",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerWorkerConfigV3LightlyOptimizerTypiaAssertStringify = (input: DockerWorkerConfigV3LightlyOptimizer): string => {
    return ((input: any): string => { const assert = (input: any): DockerWorkerConfigV3LightlyOptimizer => {
        const __is = (input: any): input is DockerWorkerConfigV3LightlyOptimizer => {
            const $io0 = (input: any): boolean => (undefined === input.lr || "number" === typeof input.lr && !Number.isNaN(input.lr) && 0 <= input.lr) && (undefined === input.weightDecay || "number" === typeof input.weightDecay && 0 <= input.weightDecay && 1 >= input.weightDecay);
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigV3LightlyOptimizer => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.lr || "number" === typeof input.lr && !Number.isNaN(input.lr) && (0 <= input.lr || $guard(_exceptionable, {
                    path: _path + ".lr",
                    expected: "number (@minimum 0)",
                    value: input.lr
                })) || $guard(_exceptionable, {
                    path: _path + ".lr",
                    expected: "(number | undefined)",
                    value: input.lr
                })) && (undefined === input.weightDecay || "number" === typeof input.weightDecay && (0 <= input.weightDecay || $guard(_exceptionable, {
                    path: _path + ".weightDecay",
                    expected: "number (@minimum 0)",
                    value: input.weightDecay
                })) && (1 >= input.weightDecay || $guard(_exceptionable, {
                    path: _path + ".weightDecay",
                    expected: "number (@maximum 1)",
                    value: input.weightDecay
                })) || $guard(_exceptionable, {
                    path: _path + ".weightDecay",
                    expected: "(number | undefined)",
                    value: input.weightDecay
                }));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV3LightlyOptimizer",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigV3LightlyOptimizer",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerWorkerConfigV3LightlyOptimizer): string => {
        const $tail = (typia.createAssertStringify as any).tail;
        const $so0 = (input: any): any => `{${$tail(`${undefined === input.lr ? "" : `"lr":${undefined !== input.lr ? input.lr : undefined},`}${undefined === input.weightDecay ? "" : `"weightDecay":${undefined !== input.weightDecay ? input.weightDecay : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DockerWorkerConfigV3LightlyOptimizerValidatorShape = {
    'lr': yup.number().min(0.0),
    'weightDecay': yup.number().min(0.0).max(1.0),
};
export const DockerWorkerConfigV3LightlyOptimizerValidator = yup.object().shape(DockerWorkerConfigV3LightlyOptimizerValidatorShape);
export const DockerWorkerConfigV3LightlyOptimizerStringifyShapeProperties: ObjectSchema['properties'] = {
    'lr': {
        'type': 'number',
        'minimum': 0.0,
    },
    'weightDecay': {
        'type': 'number',
        'minimum': 0.0,
        'maximum': 1.0,
    },
};
export const DockerWorkerConfigV3LightlyOptimizerStringifyShape: ObjectSchema = {
    title: 'DockerWorkerConfigV3LightlyOptimizer Stringifier',
    type: 'object',
    properties: {
        ...DockerWorkerConfigV3LightlyOptimizerStringifyShapeProperties
    },
    required: [],
};
export const DockerWorkerConfigV3LightlyOptimizerStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerWorkerConfigV3LightlyOptimizerStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerWorkerConfigV3LightlyOptimizerStringify: (data: DockerWorkerConfigV3LightlyOptimizer) => string = DockerWorkerConfigV3LightlyOptimizerStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerWorkerConfigV3LightlyOptimizerFromJSON(json: any): DockerWorkerConfigV3LightlyOptimizer {
    return DockerWorkerConfigV3LightlyOptimizerFromJSONTyped(json, false);
}
export function DockerWorkerConfigV3LightlyOptimizerFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerWorkerConfigV3LightlyOptimizer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'lr': !exists(json, 'lr') ? undefined : json['lr'],
        'weightDecay': !exists(json, 'weightDecay') ? undefined : json['weightDecay'],
    };
}
export function DockerWorkerConfigV3LightlyOptimizerToJSON(value?: DockerWorkerConfigV3LightlyOptimizer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'lr': value.lr,
        'weightDecay': value.weightDecay,
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { TagArithmeticsOperation } from './TagArithmeticsOperation';
import { TagArithmeticsOperationFromJSON, TagArithmeticsOperationFromJSONTyped, TagArithmeticsOperationToJSON, } from './TagArithmeticsOperation';
import type { TagCreator } from './TagCreator';
import { TagCreatorFromJSON, TagCreatorFromJSONTyped, TagCreatorToJSON, } from './TagCreator';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface TagArithmeticsRequest
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_tagId1")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_tagId2")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_newTagName")("string")(() => {
    const regex = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9 .:;=@_-]+$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_runId")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
export interface TagArithmeticsRequest {
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof TagArithmeticsRequest
        
        
    * @pattern_tagId1
         */
    tagId1: string;
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof TagArithmeticsRequest
        
        
    * @pattern_tagId2
         */
    tagId2: string;
    /**
     *
     * #type TagArithmeticsOperation
     * #memberof TagArithmeticsRequest
        
        
         */
    operation: TagArithmeticsOperation;
    /**
     * The name of the tag
     * #type string
     * #memberof TagArithmeticsRequest
        
        
    * @pattern_newTagName
    * @minLength 3
         */
    newTagName?: string;
    /**
     *
     * #type TagCreator
     * #memberof TagArithmeticsRequest
        
        
         */
    creator?: TagCreator;
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof TagArithmeticsRequest
        
        
    * @pattern_runId
         */
    runId?: string;
}
/**
 * Check if a given object implements the TagArithmeticsRequest interface.
 */
export function instanceOfTagArithmeticsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tagId1" in value;
    isInstance = isInstance && "tagId2" in value;
    isInstance = isInstance && "operation" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { TagArithmeticsOperationValidator, TagArithmeticsOperationStringifyShape, TagArithmeticsOperationStringifyShapeProperties, } from './TagArithmeticsOperation';
import { TagCreatorValidator, TagCreatorStringifyShape, TagCreatorStringifyShapeProperties, } from './TagCreator';
export const TagArithmeticsRequestTypiaAssertEquals = (input: any) => {
    return ((input: any): TagArithmeticsRequest => {
        const __is = (input: any, _exceptionable: boolean = true): input is TagArithmeticsRequest => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.tagId1 && $is_custom("pattern_tagId1", "string", "", input.tagId1) && ("string" === typeof input.tagId2 && $is_custom("pattern_tagId2", "string", "", input.tagId2)) && ("UNION" === input.operation || "INTERSECTION" === input.operation || "DIFFERENCE" === input.operation) && (undefined === input.newTagName || "string" === typeof input.newTagName && 3 <= input.newTagName.length && $is_custom("pattern_newTagName", "string", "", input.newTagName)) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator) && (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_runId", "string", "", input.runId)) && (3 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["tagId1", "tagId2", "operation", "newTagName", "creator", "runId"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is TagArithmeticsRequest => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.tagId1 && ($is_custom("pattern_tagId1", "string", "", input.tagId1) || $guard(_exceptionable, {
                    path: _path + ".tagId1",
                    expected: "string (@pattern_tagId1)",
                    value: input.tagId1
                })) || $guard(_exceptionable, {
                    path: _path + ".tagId1",
                    expected: "string",
                    value: input.tagId1
                })) && ("string" === typeof input.tagId2 && ($is_custom("pattern_tagId2", "string", "", input.tagId2) || $guard(_exceptionable, {
                    path: _path + ".tagId2",
                    expected: "string (@pattern_tagId2)",
                    value: input.tagId2
                })) || $guard(_exceptionable, {
                    path: _path + ".tagId2",
                    expected: "string",
                    value: input.tagId2
                })) && ("UNION" === input.operation || "INTERSECTION" === input.operation || "DIFFERENCE" === input.operation || $guard(_exceptionable, {
                    path: _path + ".operation",
                    expected: "(\"DIFFERENCE\" | \"INTERSECTION\" | \"UNION\")",
                    value: input.operation
                })) && (undefined === input.newTagName || "string" === typeof input.newTagName && (3 <= input.newTagName.length || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "string (@minLength 3)",
                    value: input.newTagName
                })) && ($is_custom("pattern_newTagName", "string", "", input.newTagName) || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "string (@pattern_newTagName)",
                    value: input.newTagName
                })) || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "(string | undefined)",
                    value: input.newTagName
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                })) && (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["tagId1", "tagId2", "operation", "newTagName", "creator", "runId"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "TagArithmeticsRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "TagArithmeticsRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const TagArithmeticsRequestTypiaAssertStringify = (input: TagArithmeticsRequest): string => {
    return ((input: any): string => { const assert = (input: any): TagArithmeticsRequest => {
        const __is = (input: any): input is TagArithmeticsRequest => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "string" === typeof input.tagId1 && $is_custom("pattern_tagId1", "string", "", input.tagId1) && ("string" === typeof input.tagId2 && $is_custom("pattern_tagId2", "string", "", input.tagId2)) && ("UNION" === input.operation || "INTERSECTION" === input.operation || "DIFFERENCE" === input.operation) && (undefined === input.newTagName || "string" === typeof input.newTagName && 3 <= input.newTagName.length && $is_custom("pattern_newTagName", "string", "", input.newTagName)) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator) && (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_runId", "string", "", input.runId));
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is TagArithmeticsRequest => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.tagId1 && ($is_custom("pattern_tagId1", "string", "", input.tagId1) || $guard(_exceptionable, {
                    path: _path + ".tagId1",
                    expected: "string (@pattern_tagId1)",
                    value: input.tagId1
                })) || $guard(_exceptionable, {
                    path: _path + ".tagId1",
                    expected: "string",
                    value: input.tagId1
                })) && ("string" === typeof input.tagId2 && ($is_custom("pattern_tagId2", "string", "", input.tagId2) || $guard(_exceptionable, {
                    path: _path + ".tagId2",
                    expected: "string (@pattern_tagId2)",
                    value: input.tagId2
                })) || $guard(_exceptionable, {
                    path: _path + ".tagId2",
                    expected: "string",
                    value: input.tagId2
                })) && ("UNION" === input.operation || "INTERSECTION" === input.operation || "DIFFERENCE" === input.operation || $guard(_exceptionable, {
                    path: _path + ".operation",
                    expected: "(\"DIFFERENCE\" | \"INTERSECTION\" | \"UNION\")",
                    value: input.operation
                })) && (undefined === input.newTagName || "string" === typeof input.newTagName && (3 <= input.newTagName.length || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "string (@minLength 3)",
                    value: input.newTagName
                })) && ($is_custom("pattern_newTagName", "string", "", input.newTagName) || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "string (@pattern_newTagName)",
                    value: input.newTagName
                })) || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "(string | undefined)",
                    value: input.newTagName
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                })) && (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "TagArithmeticsRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "TagArithmeticsRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: TagArithmeticsRequest): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.newTagName ? "" : `"newTagName":${undefined !== input.newTagName ? $string(input.newTagName) : undefined},`}${undefined === input.creator ? "" : `"creator":${undefined !== input.creator ? (() => {
            if ("string" === typeof input.creator)
                return $string(input.creator);
            if ("string" === typeof input.creator)
                return "\"" + input.creator + "\"";
            $throws({
                expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                value: input.creator
            });
        })() : undefined},`}${undefined === input.runId ? "" : `"runId":${undefined !== input.runId ? $string(input.runId) : undefined},`}"tagId1":${$string(input.tagId1)},"tagId2":${$string(input.tagId2)},"operation":${(() => {
            if ("string" === typeof input.operation)
                return $string(input.operation);
            if ("string" === typeof input.operation)
                return "\"" + input.operation + "\"";
            $throws({
                expected: "(\"DIFFERENCE\" | \"INTERSECTION\" | \"UNION\")",
                value: input.operation
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const TagArithmeticsRequestValidatorShape = {
    'tagId1': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId2': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'operation': TagArithmeticsOperationValidator.defined(),
    'newTagName': yup.string().matches(/^[a-zA-Z0-9][a-zA-Z0-9 .:;=@_-]+$/).min(3),
    'creator': TagCreatorValidator.optional().default(undefined),
    'runId': yup.string().matches(/^[a-f0-9]{24}$/),
};
export const TagArithmeticsRequestValidator = yup.object().shape(TagArithmeticsRequestValidatorShape);
export const TagArithmeticsRequestStringifyShapeProperties: ObjectSchema['properties'] = {
    'tagId1': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    },
    'tagId2': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    },
    'operation': TagArithmeticsOperationStringifyShape,
    'newTagName': {
        'type': 'string',
        'pattern': "/^[a-zA-Z0-9][a-zA-Z0-9 .:;&#x3D;@_-]+$/".slice(1, -1),
        'minLength': 3,
    },
    'creator': TagCreatorStringifyShape,
    'runId': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    },
};
export const TagArithmeticsRequestStringifyShape: ObjectSchema = {
    title: 'TagArithmeticsRequest Stringifier',
    type: 'object',
    properties: {
        ...TagArithmeticsRequestStringifyShapeProperties
    },
    required: [
        'tagId1',
        'tagId2',
        'operation',
    ],
};
export const TagArithmeticsRequestStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(TagArithmeticsRequestStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const TagArithmeticsRequestStringify: (data: TagArithmeticsRequest) => string = TagArithmeticsRequestStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function TagArithmeticsRequestFromJSON(json: any): TagArithmeticsRequest {
    return TagArithmeticsRequestFromJSONTyped(json, false);
}
export function TagArithmeticsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagArithmeticsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'tagId1': json['tagId1'],
        'tagId2': json['tagId2'],
        'operation': TagArithmeticsOperationFromJSON(json['operation']),
        'newTagName': !exists(json, 'newTagName') ? undefined : json['newTagName'],
        'creator': !exists(json, 'creator') ? undefined : TagCreatorFromJSON(json['creator']),
        'runId': !exists(json, 'runId') ? undefined : json['runId'],
    };
}
export function TagArithmeticsRequestToJSON(value?: TagArithmeticsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tagId1': value.tagId1,
        'tagId2': value.tagId2,
        'operation': TagArithmeticsOperationToJSON(value.operation),
        'newTagName': value.newTagName,
        'creator': TagCreatorToJSON(value.creator),
        'runId': value.runId,
    };
}

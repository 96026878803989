/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DelegatedAccessExternalIdsInner
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_externalId")("string")(() => {
    const regex = new RegExp(/^[a-zA-Z0-9_+=,.@:\/-]+$/);
    return (value: string) => !!regex.exec(value);
});
export interface DelegatedAccessExternalIdsInner {
    /**
     * The external ID specified when creating the role.
     * More information can be found here:
     * - https://docs.aws.amazon.com/IAM/latest/UserGuide/confused-deputy.html
     * - https://docs.aws.amazon.com/IAM/latest/UserGuide/reference_policies_iam-condition-keys.html#ck_externalid
     * #type string
     * #memberof DelegatedAccessExternalIdsInner
        
        
    * @pattern_externalId
    * @minLength 10
         */
    externalId: string;
    /**
     *
     * #type string
     * #memberof DelegatedAccessExternalIdsInner
        
        
         */
    userId?: string;
    /**
     *
     * #type string
     * #memberof DelegatedAccessExternalIdsInner
        
        
         */
    teamId?: string;
}
/**
 * Check if a given object implements the DelegatedAccessExternalIdsInner interface.
 */
export function instanceOfDelegatedAccessExternalIdsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "externalId" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
export const DelegatedAccessExternalIdsInnerTypiaAssertEquals = (input: any) => {
    return ((input: any): DelegatedAccessExternalIdsInner => {
        const __is = (input: any, _exceptionable: boolean = true): input is DelegatedAccessExternalIdsInner => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.externalId && 10 <= input.externalId.length && $is_custom("pattern_externalId", "string", "", input.externalId) && (undefined === input.userId || "string" === typeof input.userId) && (undefined === input.teamId || "string" === typeof input.teamId) && (1 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["externalId", "userId", "teamId"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DelegatedAccessExternalIdsInner => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.externalId && (10 <= input.externalId.length || $guard(_exceptionable, {
                    path: _path + ".externalId",
                    expected: "string (@minLength 10)",
                    value: input.externalId
                })) && ($is_custom("pattern_externalId", "string", "", input.externalId) || $guard(_exceptionable, {
                    path: _path + ".externalId",
                    expected: "string (@pattern_externalId)",
                    value: input.externalId
                })) || $guard(_exceptionable, {
                    path: _path + ".externalId",
                    expected: "string",
                    value: input.externalId
                })) && (undefined === input.userId || "string" === typeof input.userId || $guard(_exceptionable, {
                    path: _path + ".userId",
                    expected: "(string | undefined)",
                    value: input.userId
                })) && (undefined === input.teamId || "string" === typeof input.teamId || $guard(_exceptionable, {
                    path: _path + ".teamId",
                    expected: "(string | undefined)",
                    value: input.teamId
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["externalId", "userId", "teamId"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DelegatedAccessExternalIdsInner",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DelegatedAccessExternalIdsInner",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DelegatedAccessExternalIdsInnerTypiaAssertStringify = (input: DelegatedAccessExternalIdsInner): string => {
    return ((input: any): string => { const assert = (input: any): DelegatedAccessExternalIdsInner => {
        const __is = (input: any): input is DelegatedAccessExternalIdsInner => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "string" === typeof input.externalId && 10 <= input.externalId.length && $is_custom("pattern_externalId", "string", "", input.externalId) && (undefined === input.userId || "string" === typeof input.userId) && (undefined === input.teamId || "string" === typeof input.teamId);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DelegatedAccessExternalIdsInner => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.externalId && (10 <= input.externalId.length || $guard(_exceptionable, {
                    path: _path + ".externalId",
                    expected: "string (@minLength 10)",
                    value: input.externalId
                })) && ($is_custom("pattern_externalId", "string", "", input.externalId) || $guard(_exceptionable, {
                    path: _path + ".externalId",
                    expected: "string (@pattern_externalId)",
                    value: input.externalId
                })) || $guard(_exceptionable, {
                    path: _path + ".externalId",
                    expected: "string",
                    value: input.externalId
                })) && (undefined === input.userId || "string" === typeof input.userId || $guard(_exceptionable, {
                    path: _path + ".userId",
                    expected: "(string | undefined)",
                    value: input.userId
                })) && (undefined === input.teamId || "string" === typeof input.teamId || $guard(_exceptionable, {
                    path: _path + ".teamId",
                    expected: "(string | undefined)",
                    value: input.teamId
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DelegatedAccessExternalIdsInner",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DelegatedAccessExternalIdsInner",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DelegatedAccessExternalIdsInner): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.userId ? "" : `"userId":${undefined !== input.userId ? $string(input.userId) : undefined},`}${undefined === input.teamId ? "" : `"teamId":${undefined !== input.teamId ? $string(input.teamId) : undefined},`}"externalId":${$string(input.externalId)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DelegatedAccessExternalIdsInnerValidatorShape = {
    'externalId': yup.string().matches(/^[a-zA-Z0-9_+=,.@:\/-]+$/).min(10).defined(),
    'userId': yup.string(),
    'teamId': yup.string(),
};
export const DelegatedAccessExternalIdsInnerValidator = yup.object().shape(DelegatedAccessExternalIdsInnerValidatorShape);
export const DelegatedAccessExternalIdsInnerStringifyShapeProperties: ObjectSchema['properties'] = {
    'externalId': {
        'type': 'string',
        'pattern': "/^[a-zA-Z0-9_+&#x3D;,.@:\/-]+$/".slice(1, -1),
        'minLength': 10,
    },
    'userId': {
        'type': 'string',
    },
    'teamId': {
        'type': 'string',
    },
};
export const DelegatedAccessExternalIdsInnerStringifyShape: ObjectSchema = {
    title: 'DelegatedAccessExternalIdsInner Stringifier',
    type: 'object',
    properties: {
        ...DelegatedAccessExternalIdsInnerStringifyShapeProperties
    },
    required: [
        'externalId',
    ],
};
export const DelegatedAccessExternalIdsInnerStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DelegatedAccessExternalIdsInnerStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DelegatedAccessExternalIdsInnerStringify: (data: DelegatedAccessExternalIdsInner) => string = DelegatedAccessExternalIdsInnerStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DelegatedAccessExternalIdsInnerFromJSON(json: any): DelegatedAccessExternalIdsInner {
    return DelegatedAccessExternalIdsInnerFromJSONTyped(json, false);
}
export function DelegatedAccessExternalIdsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): DelegatedAccessExternalIdsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'externalId': json['externalId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'teamId': !exists(json, 'teamId') ? undefined : json['teamId'],
    };
}
export function DelegatedAccessExternalIdsInnerToJSON(value?: DelegatedAccessExternalIdsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'externalId': value.externalId,
        'userId': value.userId,
        'teamId': value.teamId,
    };
}

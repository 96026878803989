/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DatasetEmbeddingData
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_id")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
export interface DatasetEmbeddingData {
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof DatasetEmbeddingData
        
        
    * @pattern_id
         */
    id: string;
    /**
     * name of the embedding chosen by the user calling writeCSVUrl
     * #type string
     * #memberof DatasetEmbeddingData
        
        
         */
    name: string;
    /**
     * indicator whether embeddings have already been processed by a background worker
     * #type boolean
     * #memberof DatasetEmbeddingData
        
        
         */
    isProcessed: boolean;
    /**
     * unix timestamp in milliseconds
     * #type number
     * #memberof DatasetEmbeddingData
        
    * @minimum 0
            
         */
    createdAt: number;
    /**
     * flag set by the background worker if the embedding is 2d
     * #type boolean
     * #memberof DatasetEmbeddingData
        
        
         */
    is2d?: boolean;
}
/**
 * Check if a given object implements the DatasetEmbeddingData interface.
 */
export function instanceOfDatasetEmbeddingData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "isProcessed" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
export const DatasetEmbeddingDataTypiaAssertEquals = (input: any) => {
    return ((input: any): DatasetEmbeddingData => {
        const __is = (input: any, _exceptionable: boolean = true): input is DatasetEmbeddingData => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.id && $is_custom("pattern_id", "string", "", input.id) && "string" === typeof input.name && "boolean" === typeof input.isProcessed && ("number" === typeof input.createdAt && 0 <= input.createdAt) && (undefined === input.is2d || "boolean" === typeof input.is2d) && (4 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["id", "name", "isProcessed", "createdAt", "is2d"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DatasetEmbeddingData => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id && ($is_custom("pattern_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && ("string" === typeof input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("boolean" === typeof input.isProcessed || $guard(_exceptionable, {
                    path: _path + ".isProcessed",
                    expected: "boolean",
                    value: input.isProcessed
                })) && ("number" === typeof input.createdAt && (0 <= input.createdAt || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number (@minimum 0)",
                    value: input.createdAt
                })) || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number",
                    value: input.createdAt
                })) && (undefined === input.is2d || "boolean" === typeof input.is2d || $guard(_exceptionable, {
                    path: _path + ".is2d",
                    expected: "(boolean | undefined)",
                    value: input.is2d
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["id", "name", "isProcessed", "createdAt", "is2d"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DatasetEmbeddingData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DatasetEmbeddingData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DatasetEmbeddingDataTypiaAssertStringify = (input: DatasetEmbeddingData): string => {
    return ((input: any): string => { const assert = (input: any): DatasetEmbeddingData => {
        const __is = (input: any): input is DatasetEmbeddingData => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "string" === typeof input.id && $is_custom("pattern_id", "string", "", input.id) && "string" === typeof input.name && "boolean" === typeof input.isProcessed && ("number" === typeof input.createdAt && !Number.isNaN(input.createdAt) && 0 <= input.createdAt) && (undefined === input.is2d || "boolean" === typeof input.is2d);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DatasetEmbeddingData => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id && ($is_custom("pattern_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && ("string" === typeof input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("boolean" === typeof input.isProcessed || $guard(_exceptionable, {
                    path: _path + ".isProcessed",
                    expected: "boolean",
                    value: input.isProcessed
                })) && ("number" === typeof input.createdAt && !Number.isNaN(input.createdAt) && (0 <= input.createdAt || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number (@minimum 0)",
                    value: input.createdAt
                })) || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number",
                    value: input.createdAt
                })) && (undefined === input.is2d || "boolean" === typeof input.is2d || $guard(_exceptionable, {
                    path: _path + ".is2d",
                    expected: "(boolean | undefined)",
                    value: input.is2d
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DatasetEmbeddingData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DatasetEmbeddingData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DatasetEmbeddingData): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.is2d ? "" : `"is2d":${undefined !== input.is2d ? input.is2d : undefined},`}"id":${$string(input.id)},"name":${$string(input.name)},"isProcessed":${input.isProcessed},"createdAt":${input.createdAt}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DatasetEmbeddingDataValidatorShape = {
    'id': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'name': yup.string().defined(),
    'isProcessed': yup.boolean().defined(),
    'createdAt': yup.number().min(0).defined(),
    'is2d': yup.boolean(),
};
export const DatasetEmbeddingDataValidator = yup.object().shape(DatasetEmbeddingDataValidatorShape);
export const DatasetEmbeddingDataStringifyShapeProperties: ObjectSchema['properties'] = {
    'id': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    },
    'name': {
        'type': 'string',
    },
    'isProcessed': {
        'type': 'boolean',
    },
    'createdAt': {
        'type': 'number',
        'minimum': 0,
    },
    'is2d': {
        'type': 'boolean',
    },
};
export const DatasetEmbeddingDataStringifyShape: ObjectSchema = {
    title: 'DatasetEmbeddingData Stringifier',
    type: 'object',
    properties: {
        ...DatasetEmbeddingDataStringifyShapeProperties
    },
    required: [
        'id',
        'name',
        'isProcessed',
        'createdAt',
    ],
};
export const DatasetEmbeddingDataStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DatasetEmbeddingDataStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DatasetEmbeddingDataStringify: (data: DatasetEmbeddingData) => string = DatasetEmbeddingDataStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DatasetEmbeddingDataFromJSON(json: any): DatasetEmbeddingData {
    return DatasetEmbeddingDataFromJSONTyped(json, false);
}
export function DatasetEmbeddingDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatasetEmbeddingData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'id': json['id'],
        'name': json['name'],
        'isProcessed': json['isProcessed'],
        'createdAt': json['createdAt'],
        'is2d': !exists(json, 'is2d') ? undefined : json['is2d'],
    };
}
export function DatasetEmbeddingDataToJSON(value?: DatasetEmbeddingData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'isProcessed': value.isProcessed,
        'createdAt': value.createdAt,
        'is2d': value.is2d,
    };
}

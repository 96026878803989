/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { SelectionStrategyThresholdOperation } from './SelectionStrategyThresholdOperation';
import { SelectionStrategyThresholdOperationFromJSON, SelectionStrategyThresholdOperationFromJSONTyped, SelectionStrategyThresholdOperationToJSON, } from './SelectionStrategyThresholdOperation';
import type { SelectionStrategyType } from './SelectionStrategyType';
import { SelectionStrategyTypeFromJSON, SelectionStrategyTypeFromJSONTyped, SelectionStrategyTypeToJSON, } from './SelectionStrategyType';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface SelectionConfigEntryStrategy
 *
 */
// create custom validators for the regexes
export interface SelectionConfigEntryStrategy {
    /**
     *
     * #type SelectionStrategyType
     * #memberof SelectionConfigEntryStrategy
        
        
         */
    type: SelectionStrategyType;
    /**
     *
     * #type number
     * #memberof SelectionConfigEntryStrategy
        
        
         */
    stopping_condition_minimum_distance?: number;
    /**
     *
     * #type number
     * #memberof SelectionConfigEntryStrategy
        
        
         */
    threshold?: number;
    /**
     *
     * #type SelectionStrategyThresholdOperation
     * #memberof SelectionConfigEntryStrategy
        
        
         */
    operation?: SelectionStrategyThresholdOperation;
    /**
     *
     * #type object
     * #memberof SelectionConfigEntryStrategy
        
        
         */
    target?: object;
}
/**
 * Check if a given object implements the SelectionConfigEntryStrategy interface.
 */
export function instanceOfSelectionConfigEntryStrategy(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { SelectionStrategyThresholdOperationValidator, SelectionStrategyThresholdOperationStringifyShape, SelectionStrategyThresholdOperationStringifyShapeProperties, } from './SelectionStrategyThresholdOperation';
import { SelectionStrategyTypeValidator, SelectionStrategyTypeStringifyShape, SelectionStrategyTypeStringifyShapeProperties, } from './SelectionStrategyType';
export const SelectionConfigEntryStrategyTypiaAssertEquals = (input: any) => {
    return ((input: any): SelectionConfigEntryStrategy => {
        const __is = (input: any, _exceptionable: boolean = true): input is SelectionConfigEntryStrategy => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => ("DIVERSITY" === input.type || "WEIGHTS" === input.type || "THRESHOLD" === input.type || "BALANCE" === input.type || "SIMILARITY" === input.type) && (undefined === input.stopping_condition_minimum_distance || "number" === typeof input.stopping_condition_minimum_distance) && (undefined === input.threshold || "number" === typeof input.threshold) && (undefined === input.operation || "SMALLER" === input.operation || "SMALLER_EQUAL" === input.operation || "BIGGER" === input.operation || "BIGGER_EQUAL" === input.operation) && (undefined === input.target || "object" === typeof input.target && null !== input.target && false === Array.isArray(input.target) && $io1(input.target, true && _exceptionable)) && (1 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["type", "stopping_condition_minimum_distance", "threshold", "operation", "target"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => 0 === Object.keys(input).length || Object.keys(input).every(key => {
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            });
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is SelectionConfigEntryStrategy => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("DIVERSITY" === input.type || "WEIGHTS" === input.type || "THRESHOLD" === input.type || "BALANCE" === input.type || "SIMILARITY" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "(\"BALANCE\" | \"DIVERSITY\" | \"SIMILARITY\" | \"THRESHOLD\" | \"WEIGHTS\")",
                    value: input.type
                })) && (undefined === input.stopping_condition_minimum_distance || "number" === typeof input.stopping_condition_minimum_distance || $guard(_exceptionable, {
                    path: _path + ".stopping_condition_minimum_distance",
                    expected: "(number | undefined)",
                    value: input.stopping_condition_minimum_distance
                })) && (undefined === input.threshold || "number" === typeof input.threshold || $guard(_exceptionable, {
                    path: _path + ".threshold",
                    expected: "(number | undefined)",
                    value: input.threshold
                })) && (undefined === input.operation || "SMALLER" === input.operation || "SMALLER_EQUAL" === input.operation || "BIGGER" === input.operation || "BIGGER_EQUAL" === input.operation || $guard(_exceptionable, {
                    path: _path + ".operation",
                    expected: "(\"BIGGER\" | \"BIGGER_EQUAL\" | \"SMALLER\" | \"SMALLER_EQUAL\" | undefined)",
                    value: input.operation
                })) && (undefined === input.target || ("object" === typeof input.target && null !== input.target && false === Array.isArray(input.target) || $guard(_exceptionable, {
                    path: _path + ".target",
                    expected: "(object | undefined)",
                    value: input.target
                })) && $ao1(input.target, _path + ".target", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".target",
                    expected: "(object | undefined)",
                    value: input.target
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["type", "stopping_condition_minimum_distance", "threshold", "operation", "target"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => 0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SelectionConfigEntryStrategy",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SelectionConfigEntryStrategy",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const SelectionConfigEntryStrategyTypiaAssertStringify = (input: SelectionConfigEntryStrategy): string => {
    return ((input: any): string => { const assert = (input: any): SelectionConfigEntryStrategy => {
        const __is = (input: any): input is SelectionConfigEntryStrategy => {
            const $io0 = (input: any): boolean => ("DIVERSITY" === input.type || "WEIGHTS" === input.type || "THRESHOLD" === input.type || "BALANCE" === input.type || "SIMILARITY" === input.type) && (undefined === input.stopping_condition_minimum_distance || "number" === typeof input.stopping_condition_minimum_distance && !Number.isNaN(input.stopping_condition_minimum_distance)) && (undefined === input.threshold || "number" === typeof input.threshold && !Number.isNaN(input.threshold)) && (undefined === input.operation || "SMALLER" === input.operation || "SMALLER_EQUAL" === input.operation || "BIGGER" === input.operation || "BIGGER_EQUAL" === input.operation) && (undefined === input.target || "object" === typeof input.target && null !== input.target && false === Array.isArray(input.target) && $io1(input.target));
            const $io1 = (input: any): boolean => true;
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is SelectionConfigEntryStrategy => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("DIVERSITY" === input.type || "WEIGHTS" === input.type || "THRESHOLD" === input.type || "BALANCE" === input.type || "SIMILARITY" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "(\"BALANCE\" | \"DIVERSITY\" | \"SIMILARITY\" | \"THRESHOLD\" | \"WEIGHTS\")",
                    value: input.type
                })) && (undefined === input.stopping_condition_minimum_distance || "number" === typeof input.stopping_condition_minimum_distance && !Number.isNaN(input.stopping_condition_minimum_distance) || $guard(_exceptionable, {
                    path: _path + ".stopping_condition_minimum_distance",
                    expected: "(number | undefined)",
                    value: input.stopping_condition_minimum_distance
                })) && (undefined === input.threshold || "number" === typeof input.threshold && !Number.isNaN(input.threshold) || $guard(_exceptionable, {
                    path: _path + ".threshold",
                    expected: "(number | undefined)",
                    value: input.threshold
                })) && (undefined === input.operation || "SMALLER" === input.operation || "SMALLER_EQUAL" === input.operation || "BIGGER" === input.operation || "BIGGER_EQUAL" === input.operation || $guard(_exceptionable, {
                    path: _path + ".operation",
                    expected: "(\"BIGGER\" | \"BIGGER_EQUAL\" | \"SMALLER\" | \"SMALLER_EQUAL\" | undefined)",
                    value: input.operation
                })) && (undefined === input.target || ("object" === typeof input.target && null !== input.target && false === Array.isArray(input.target) || $guard(_exceptionable, {
                    path: _path + ".target",
                    expected: "(object | undefined)",
                    value: input.target
                })) && $ao1(input.target, _path + ".target", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".target",
                    expected: "(object | undefined)",
                    value: input.target
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => true;
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SelectionConfigEntryStrategy",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SelectionConfigEntryStrategy",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: SelectionConfigEntryStrategy): string => {
        const $io1 = (input: any): boolean => true;
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $so0 = (input: any): any => `{${undefined === input.stopping_condition_minimum_distance ? "" : `"stopping_condition_minimum_distance":${undefined !== input.stopping_condition_minimum_distance ? input.stopping_condition_minimum_distance : undefined},`}${undefined === input.threshold ? "" : `"threshold":${undefined !== input.threshold ? input.threshold : undefined},`}${undefined === input.operation ? "" : `"operation":${undefined !== input.operation ? (() => {
            if ("string" === typeof input.operation)
                return $string(input.operation);
            if ("string" === typeof input.operation)
                return "\"" + input.operation + "\"";
            $throws({
                expected: "(\"BIGGER\" | \"BIGGER_EQUAL\" | \"SMALLER\" | \"SMALLER_EQUAL\" | undefined)",
                value: input.operation
            });
        })() : undefined},`}${undefined === input.target ? "" : `"target":${undefined !== input.target ? "{}" : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "(\"BALANCE\" | \"DIVERSITY\" | \"SIMILARITY\" | \"THRESHOLD\" | \"WEIGHTS\")",
                value: input.type
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const SelectionConfigEntryStrategyValidatorShape = {
    'type': SelectionStrategyTypeValidator.defined(),
    'stopping_condition_minimum_distance': yup.number(),
    'threshold': yup.number(),
    'operation': SelectionStrategyThresholdOperationValidator.optional().default(undefined),
    'target': yup.mixed().transform((val: any) => { return val instanceof Object && !Array.isArray(val) ? val : {}; }),
};
export const SelectionConfigEntryStrategyValidator = yup.object().shape(SelectionConfigEntryStrategyValidatorShape);
export const SelectionConfigEntryStrategyStringifyShapeProperties: ObjectSchema['properties'] = {
    'type': SelectionStrategyTypeStringifyShape,
    'stopping_condition_minimum_distance': {
        'type': 'number',
    },
    'threshold': {
        'type': 'number',
    },
    'operation': SelectionStrategyThresholdOperationStringifyShape,
    'target': {
        type: 'object',
        additionalProperties: true
    },
};
export const SelectionConfigEntryStrategyStringifyShape: ObjectSchema = {
    title: 'SelectionConfigEntryStrategy Stringifier',
    type: 'object',
    properties: {
        ...SelectionConfigEntryStrategyStringifyShapeProperties
    },
    required: [
        'type',
    ],
};
export const SelectionConfigEntryStrategyStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(SelectionConfigEntryStrategyStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const SelectionConfigEntryStrategyStringify: (data: SelectionConfigEntryStrategy) => string = SelectionConfigEntryStrategyStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function SelectionConfigEntryStrategyFromJSON(json: any): SelectionConfigEntryStrategy {
    return SelectionConfigEntryStrategyFromJSONTyped(json, false);
}
export function SelectionConfigEntryStrategyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectionConfigEntryStrategy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'type': SelectionStrategyTypeFromJSON(json['type']),
        'stopping_condition_minimum_distance': !exists(json, 'stopping_condition_minimum_distance') ? undefined : json['stopping_condition_minimum_distance'],
        'threshold': !exists(json, 'threshold') ? undefined : json['threshold'],
        'operation': !exists(json, 'operation') ? undefined : SelectionStrategyThresholdOperationFromJSON(json['operation']),
        'target': !exists(json, 'target') ? undefined : json['target'],
    };
}
export function SelectionConfigEntryStrategyToJSON(value?: SelectionConfigEntryStrategy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': SelectionStrategyTypeToJSON(value.type),
        'stopping_condition_minimum_distance': value.stopping_condition_minimum_distance,
        'threshold': value.threshold,
        'operation': SelectionStrategyThresholdOperationToJSON(value.operation),
        'target': value.target,
    };
}

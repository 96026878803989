/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { PredictionSingleton } from './PredictionSingleton';
import { PredictionSingletonFromJSON, PredictionSingletonFromJSONTyped, PredictionSingletonToJSON, } from './PredictionSingleton';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 * List of prediction singletons ordered by the predictionIndex of when the singleton was found
 * @export
 * @interface PredictionSingletons
 *
 */
// create custom validators for the regexes
export interface PredictionSingletons extends Array<PredictionSingleton> {
}
/**
 * Check if a given object implements the PredictionSingletons interface.
 */
export function instanceOfPredictionSingletons(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { PredictionSingletonValidator, PredictionSingletonStringifyShape, PredictionSingletonStringifyShapeProperties, } from './PredictionSingleton';
export const PredictionSingletonsTypiaAssertEquals = (input: any) => {
    return ((input: any): PredictionSingletons => {
        const __is = (input: any, _exceptionable: boolean = true): input is PredictionSingletons => {
            const $join = (typia.createAssertEquals as any).join;
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "object" === typeof value && null !== value && $iu0(value, true && _exceptionable);
                return false;
            });
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "CLASSIFICATION" === input.type && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (4 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["type", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value;
                return false;
            });
            const $io3 = (input: any, _exceptionable: boolean = true): boolean => "INSTANCE_SEGMENTATION" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io4(input.segmentation, true && _exceptionable)) && ("object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox, true && _exceptionable)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (6 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["type", "segmentation", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io4 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value;
                return false;
            });
            const $io5 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value;
                return false;
            });
            const $io6 = (input: any, _exceptionable: boolean = true): boolean => "KEYPOINT_DETECTION" === input.type && (Array.isArray(input.keypoints) && input.keypoints.every((elem: any, _index1: number) => "number" === typeof elem)) && (undefined === input.bbox || "object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox, true && _exceptionable)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (5 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["type", "keypoints", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io7 = (input: any, _exceptionable: boolean = true): boolean => "OBJECT_DETECTION" === input.type && ("object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox, true && _exceptionable)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (5 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["type", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io8 = (input: any, _exceptionable: boolean = true): boolean => "PredictionSingletonClassification" === input.type && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (4 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["type", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io9 = (input: any, _exceptionable: boolean = true): boolean => "PredictionSingletonInstanceSegmentation" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io4(input.segmentation, true && _exceptionable)) && ("object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox, true && _exceptionable)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (6 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["type", "segmentation", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io10 = (input: any, _exceptionable: boolean = true): boolean => "PredictionSingletonKeypointDetection" === input.type && (Array.isArray(input.keypoints) && input.keypoints.every((elem: any, _index2: number) => "number" === typeof elem)) && (undefined === input.bbox || "object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox, true && _exceptionable)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (5 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["type", "keypoints", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io11 = (input: any, _exceptionable: boolean = true): boolean => "PredictionSingletonObjectDetection" === input.type && ("object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox, true && _exceptionable)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (5 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["type", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io12 = (input: any, _exceptionable: boolean = true): boolean => "PredictionSingletonSemanticSegmentation" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io4(input.segmentation, true && _exceptionable)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (5 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["type", "segmentation", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io13 = (input: any, _exceptionable: boolean = true): boolean => "SEMANTIC_SEGMENTATION" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io4(input.segmentation, true && _exceptionable)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (5 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["type", "segmentation", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $iu0 = (input: any, _exceptionable: boolean = true): any => (() => {
                if ("CLASSIFICATION" === input.type)
                    return $io1(input, true && _exceptionable);
                if ("INSTANCE_SEGMENTATION" === input.type)
                    return $io3(input, true && _exceptionable);
                if ("KEYPOINT_DETECTION" === input.type)
                    return $io6(input, true && _exceptionable);
                if ("OBJECT_DETECTION" === input.type)
                    return $io7(input, true && _exceptionable);
                if ("PredictionSingletonClassification" === input.type)
                    return $io8(input, true && _exceptionable);
                if ("PredictionSingletonInstanceSegmentation" === input.type)
                    return $io9(input, true && _exceptionable);
                if ("PredictionSingletonKeypointDetection" === input.type)
                    return $io10(input, true && _exceptionable);
                if ("PredictionSingletonObjectDetection" === input.type)
                    return $io11(input, true && _exceptionable);
                if ("SEMANTIC_SEGMENTATION" === input.type)
                    return $io13(input, true && _exceptionable);
                if ("PredictionSingletonSemanticSegmentation" === input.type)
                    return $io12(input, true && _exceptionable);
                return false;
            })();
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is PredictionSingletons => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return ("object" === typeof value && null !== value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "({ type: \"CLASSIFICATION\"; } & PredictionSingletonClassification | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionSingletonInstanceSegmentation | { type: \"KEYPOINT_DETECTION\"; } & PredictionSingletonKeypointDetection | { type: \"OBJECT_DETECTION\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonClassification\"; } & PredictionSingletonClassification | { type: \"PredictionSingletonInstanceSegmentation\"; } & PredictionSingletonInstanceSegmentation | { type: \"PredictionSingletonKeypointDetection\"; } & PredictionSingletonKeypointDetection | { type: \"PredictionSingletonObjectDetection\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonSemanticSegmentation\"; } & PredictionSingletonSemanticSegmentation | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionSingletonSemanticSegmentation)",
                            value: value
                        })) && $au0(value, _path + $join(key), true && _exceptionable) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "({ type: \"CLASSIFICATION\"; } & PredictionSingletonClassification | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionSingletonInstanceSegmentation | { type: \"KEYPOINT_DETECTION\"; } & PredictionSingletonKeypointDetection | { type: \"OBJECT_DETECTION\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonClassification\"; } & PredictionSingletonClassification | { type: \"PredictionSingletonInstanceSegmentation\"; } & PredictionSingletonInstanceSegmentation | { type: \"PredictionSingletonKeypointDetection\"; } & PredictionSingletonKeypointDetection | { type: \"PredictionSingletonObjectDetection\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonSemanticSegmentation\"; } & PredictionSingletonSemanticSegmentation | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionSingletonSemanticSegmentation)",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("CLASSIFICATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"CLASSIFICATION\"",
                    value: input.type
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["type", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("INSTANCE_SEGMENTATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"INSTANCE_SEGMENTATION\"",
                    value: input.type
                })) && (("object" === typeof input.segmentation && null !== input.segmentation || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && $ao4(input.segmentation, _path + ".segmentation", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && (("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && $ao5(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (6 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["type", "segmentation", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("KEYPOINT_DETECTION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"KEYPOINT_DETECTION\"",
                    value: input.type
                })) && ((Array.isArray(input.keypoints) || $guard(_exceptionable, {
                    path: _path + ".keypoints",
                    expected: "Array<number>",
                    value: input.keypoints
                })) && input.keypoints.every((elem: any, _index1: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".keypoints[" + _index1 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".keypoints",
                    expected: "Array<number>",
                    value: input.keypoints
                })) && (undefined === input.bbox || ("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "(BoundingBox | undefined)",
                    value: input.bbox
                })) && $ao5(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "(BoundingBox | undefined)",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["type", "keypoints", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao7 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("OBJECT_DETECTION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"OBJECT_DETECTION\"",
                    value: input.type
                })) && (("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && $ao5(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["type", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao8 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonClassification" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonClassification\"",
                    value: input.type
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["type", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao9 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonInstanceSegmentation" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonInstanceSegmentation\"",
                    value: input.type
                })) && (("object" === typeof input.segmentation && null !== input.segmentation || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && $ao4(input.segmentation, _path + ".segmentation", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && (("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && $ao5(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (6 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["type", "segmentation", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao10 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonKeypointDetection" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonKeypointDetection\"",
                    value: input.type
                })) && ((Array.isArray(input.keypoints) || $guard(_exceptionable, {
                    path: _path + ".keypoints",
                    expected: "Array<number>",
                    value: input.keypoints
                })) && input.keypoints.every((elem: any, _index2: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".keypoints[" + _index2 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".keypoints",
                    expected: "Array<number>",
                    value: input.keypoints
                })) && (undefined === input.bbox || ("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "(BoundingBox | undefined)",
                    value: input.bbox
                })) && $ao5(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "(BoundingBox | undefined)",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["type", "keypoints", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao11 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonObjectDetection" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonObjectDetection\"",
                    value: input.type
                })) && (("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && $ao5(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["type", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao12 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonSemanticSegmentation" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonSemanticSegmentation\"",
                    value: input.type
                })) && (("object" === typeof input.segmentation && null !== input.segmentation || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && $ao4(input.segmentation, _path + ".segmentation", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["type", "segmentation", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao13 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("SEMANTIC_SEGMENTATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"SEMANTIC_SEGMENTATION\"",
                    value: input.type
                })) && (("object" === typeof input.segmentation && null !== input.segmentation || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && $ao4(input.segmentation, _path + ".segmentation", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["type", "segmentation", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $au0 = (input: any, _path: string, _exceptionable: boolean = true): any => (() => {
                    if ("CLASSIFICATION" === input.type)
                        return $ao1(input, _path, true && _exceptionable);
                    if ("INSTANCE_SEGMENTATION" === input.type)
                        return $ao3(input, _path, true && _exceptionable);
                    if ("KEYPOINT_DETECTION" === input.type)
                        return $ao6(input, _path, true && _exceptionable);
                    if ("OBJECT_DETECTION" === input.type)
                        return $ao7(input, _path, true && _exceptionable);
                    if ("PredictionSingletonClassification" === input.type)
                        return $ao8(input, _path, true && _exceptionable);
                    if ("PredictionSingletonInstanceSegmentation" === input.type)
                        return $ao9(input, _path, true && _exceptionable);
                    if ("PredictionSingletonKeypointDetection" === input.type)
                        return $ao10(input, _path, true && _exceptionable);
                    if ("PredictionSingletonObjectDetection" === input.type)
                        return $ao11(input, _path, true && _exceptionable);
                    if ("SEMANTIC_SEGMENTATION" === input.type)
                        return $ao13(input, _path, true && _exceptionable);
                    if ("PredictionSingletonSemanticSegmentation" === input.type)
                        return $ao12(input, _path, true && _exceptionable);
                    return $guard(_exceptionable, {
                        path: _path,
                        expected: "({ type: \"CLASSIFICATION\"; } & PredictionSingletonClassification | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionSingletonInstanceSegmentation | { type: \"KEYPOINT_DETECTION\"; } & PredictionSingletonKeypointDetection | { type: \"OBJECT_DETECTION\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonClassification\"; } & PredictionSingletonClassification | { type: \"PredictionSingletonInstanceSegmentation\"; } & PredictionSingletonInstanceSegmentation | { type: \"PredictionSingletonKeypointDetection\"; } & PredictionSingletonKeypointDetection | { type: \"PredictionSingletonObjectDetection\"; } & PredictionSingletonObjectDetection | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionSingletonSemanticSegmentation | { type: \"PredictionSingletonSemanticSegmentation\"; } & PredictionSingletonSemanticSegmentation)",
                        value: input
                    });
                })();
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "PredictionSingletons",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "PredictionSingletons",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const PredictionSingletonsTypiaAssertStringify = (input: PredictionSingletons): string => {
    return ((input: any): string => { const assert = (input: any): PredictionSingletons => {
        const __is = (input: any): input is PredictionSingletons => {
            const $join = (typia.createAssertStringify as any).join;
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "object" === typeof value && null !== value && $iu0(value);
                return true;
            });
            const $io1 = (input: any): boolean => "CLASSIFICATION" === input.type && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io2 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value && !Number.isNaN(value);
                return true;
            });
            const $io3 = (input: any): boolean => "INSTANCE_SEGMENTATION" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io4(input.segmentation)) && ("object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io4 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value && !Number.isNaN(value);
                return true;
            });
            const $io5 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value && !Number.isNaN(value);
                return true;
            });
            const $io6 = (input: any): boolean => "KEYPOINT_DETECTION" === input.type && (Array.isArray(input.keypoints) && input.keypoints.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.bbox || "object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io7 = (input: any): boolean => "OBJECT_DETECTION" === input.type && ("object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io8 = (input: any): boolean => "PredictionSingletonClassification" === input.type && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io9 = (input: any): boolean => "PredictionSingletonInstanceSegmentation" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io4(input.segmentation)) && ("object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io10 = (input: any): boolean => "PredictionSingletonKeypointDetection" === input.type && (Array.isArray(input.keypoints) && input.keypoints.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.bbox || "object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io11 = (input: any): boolean => "PredictionSingletonObjectDetection" === input.type && ("object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io12 = (input: any): boolean => "PredictionSingletonSemanticSegmentation" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io4(input.segmentation)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io13 = (input: any): boolean => "SEMANTIC_SEGMENTATION" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io4(input.segmentation)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $iu0 = (input: any): any => (() => {
                if ("CLASSIFICATION" === input.type)
                    return $io1(input);
                if ("INSTANCE_SEGMENTATION" === input.type)
                    return $io3(input);
                if ("KEYPOINT_DETECTION" === input.type)
                    return $io6(input);
                if ("OBJECT_DETECTION" === input.type)
                    return $io7(input);
                if ("PredictionSingletonClassification" === input.type)
                    return $io8(input);
                if ("PredictionSingletonInstanceSegmentation" === input.type)
                    return $io9(input);
                if ("PredictionSingletonKeypointDetection" === input.type)
                    return $io10(input);
                if ("PredictionSingletonObjectDetection" === input.type)
                    return $io11(input);
                if ("SEMANTIC_SEGMENTATION" === input.type)
                    return $io13(input);
                if ("PredictionSingletonSemanticSegmentation" === input.type)
                    return $io12(input);
                return false;
            })();
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is PredictionSingletons => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $join = (typia.createAssertStringify as any).join;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return ("object" === typeof value && null !== value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "({ type: \"CLASSIFICATION\"; } & PredictionSingletonClassification | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionSingletonInstanceSegmentation | { type: \"KEYPOINT_DETECTION\"; } & PredictionSingletonKeypointDetection | { type: \"OBJECT_DETECTION\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonClassification\"; } & PredictionSingletonClassification | { type: \"PredictionSingletonInstanceSegmentation\"; } & PredictionSingletonInstanceSegmentation | { type: \"PredictionSingletonKeypointDetection\"; } & PredictionSingletonKeypointDetection | { type: \"PredictionSingletonObjectDetection\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonSemanticSegmentation\"; } & PredictionSingletonSemanticSegmentation | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionSingletonSemanticSegmentation)",
                            value: value
                        })) && $au0(value, _path + $join(key), true && _exceptionable) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "({ type: \"CLASSIFICATION\"; } & PredictionSingletonClassification | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionSingletonInstanceSegmentation | { type: \"KEYPOINT_DETECTION\"; } & PredictionSingletonKeypointDetection | { type: \"OBJECT_DETECTION\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonClassification\"; } & PredictionSingletonClassification | { type: \"PredictionSingletonInstanceSegmentation\"; } & PredictionSingletonInstanceSegmentation | { type: \"PredictionSingletonKeypointDetection\"; } & PredictionSingletonKeypointDetection | { type: \"PredictionSingletonObjectDetection\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonSemanticSegmentation\"; } & PredictionSingletonSemanticSegmentation | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionSingletonSemanticSegmentation)",
                            value: value
                        });
                    return true;
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("CLASSIFICATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"CLASSIFICATION\"",
                    value: input.type
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value && !Number.isNaN(value) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return true;
                }));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("INSTANCE_SEGMENTATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"INSTANCE_SEGMENTATION\"",
                    value: input.type
                })) && (("object" === typeof input.segmentation && null !== input.segmentation || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && $ao4(input.segmentation, _path + ".segmentation", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && (("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && $ao5(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value && !Number.isNaN(value) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return true;
                }));
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value && !Number.isNaN(value) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return true;
                }));
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("KEYPOINT_DETECTION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"KEYPOINT_DETECTION\"",
                    value: input.type
                })) && ((Array.isArray(input.keypoints) || $guard(_exceptionable, {
                    path: _path + ".keypoints",
                    expected: "Array<number>",
                    value: input.keypoints
                })) && input.keypoints.every((elem: any, _index1: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".keypoints[" + _index1 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".keypoints",
                    expected: "Array<number>",
                    value: input.keypoints
                })) && (undefined === input.bbox || ("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "(BoundingBox | undefined)",
                    value: input.bbox
                })) && $ao5(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "(BoundingBox | undefined)",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao7 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("OBJECT_DETECTION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"OBJECT_DETECTION\"",
                    value: input.type
                })) && (("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && $ao5(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao8 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonClassification" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonClassification\"",
                    value: input.type
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao9 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonInstanceSegmentation" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonInstanceSegmentation\"",
                    value: input.type
                })) && (("object" === typeof input.segmentation && null !== input.segmentation || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && $ao4(input.segmentation, _path + ".segmentation", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && (("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && $ao5(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao10 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonKeypointDetection" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonKeypointDetection\"",
                    value: input.type
                })) && ((Array.isArray(input.keypoints) || $guard(_exceptionable, {
                    path: _path + ".keypoints",
                    expected: "Array<number>",
                    value: input.keypoints
                })) && input.keypoints.every((elem: any, _index2: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".keypoints[" + _index2 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".keypoints",
                    expected: "Array<number>",
                    value: input.keypoints
                })) && (undefined === input.bbox || ("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "(BoundingBox | undefined)",
                    value: input.bbox
                })) && $ao5(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "(BoundingBox | undefined)",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao11 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonObjectDetection" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonObjectDetection\"",
                    value: input.type
                })) && (("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && $ao5(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao12 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonSemanticSegmentation" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonSemanticSegmentation\"",
                    value: input.type
                })) && (("object" === typeof input.segmentation && null !== input.segmentation || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && $ao4(input.segmentation, _path + ".segmentation", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao13 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("SEMANTIC_SEGMENTATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"SEMANTIC_SEGMENTATION\"",
                    value: input.type
                })) && (("object" === typeof input.segmentation && null !== input.segmentation || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && $ao4(input.segmentation, _path + ".segmentation", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $au0 = (input: any, _path: string, _exceptionable: boolean = true): any => (() => {
                    if ("CLASSIFICATION" === input.type)
                        return $ao1(input, _path, true && _exceptionable);
                    if ("INSTANCE_SEGMENTATION" === input.type)
                        return $ao3(input, _path, true && _exceptionable);
                    if ("KEYPOINT_DETECTION" === input.type)
                        return $ao6(input, _path, true && _exceptionable);
                    if ("OBJECT_DETECTION" === input.type)
                        return $ao7(input, _path, true && _exceptionable);
                    if ("PredictionSingletonClassification" === input.type)
                        return $ao8(input, _path, true && _exceptionable);
                    if ("PredictionSingletonInstanceSegmentation" === input.type)
                        return $ao9(input, _path, true && _exceptionable);
                    if ("PredictionSingletonKeypointDetection" === input.type)
                        return $ao10(input, _path, true && _exceptionable);
                    if ("PredictionSingletonObjectDetection" === input.type)
                        return $ao11(input, _path, true && _exceptionable);
                    if ("SEMANTIC_SEGMENTATION" === input.type)
                        return $ao13(input, _path, true && _exceptionable);
                    if ("PredictionSingletonSemanticSegmentation" === input.type)
                        return $ao12(input, _path, true && _exceptionable);
                    return $guard(_exceptionable, {
                        path: _path,
                        expected: "({ type: \"CLASSIFICATION\"; } & PredictionSingletonClassification | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionSingletonInstanceSegmentation | { type: \"KEYPOINT_DETECTION\"; } & PredictionSingletonKeypointDetection | { type: \"OBJECT_DETECTION\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonClassification\"; } & PredictionSingletonClassification | { type: \"PredictionSingletonInstanceSegmentation\"; } & PredictionSingletonInstanceSegmentation | { type: \"PredictionSingletonKeypointDetection\"; } & PredictionSingletonKeypointDetection | { type: \"PredictionSingletonObjectDetection\"; } & PredictionSingletonObjectDetection | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionSingletonSemanticSegmentation | { type: \"PredictionSingletonSemanticSegmentation\"; } & PredictionSingletonSemanticSegmentation)",
                        value: input
                    });
                })();
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "PredictionSingletons",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "PredictionSingletons",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: PredictionSingletons): string => {
        const $io1 = (input: any): boolean => "CLASSIFICATION" === input.type && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io2 = (input: any): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
            if (["length"].some(prop => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                return "number" === typeof value;
            return true;
        });
        const $io3 = (input: any): boolean => "INSTANCE_SEGMENTATION" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io4(input.segmentation)) && ("object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io4 = (input: any): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
            if (["length"].some(prop => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                return "number" === typeof value;
            return true;
        });
        const $io5 = (input: any): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
            if (["length"].some(prop => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                return "number" === typeof value;
            return true;
        });
        const $io6 = (input: any): boolean => "KEYPOINT_DETECTION" === input.type && (Array.isArray(input.keypoints) && input.keypoints.every((elem: any) => "number" === typeof elem)) && (undefined === input.bbox || "object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io7 = (input: any): boolean => "OBJECT_DETECTION" === input.type && ("object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io8 = (input: any): boolean => "PredictionSingletonClassification" === input.type && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io9 = (input: any): boolean => "PredictionSingletonInstanceSegmentation" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io4(input.segmentation)) && ("object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io10 = (input: any): boolean => "PredictionSingletonKeypointDetection" === input.type && (Array.isArray(input.keypoints) && input.keypoints.every((elem: any) => "number" === typeof elem)) && (undefined === input.bbox || "object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io11 = (input: any): boolean => "PredictionSingletonObjectDetection" === input.type && ("object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io12 = (input: any): boolean => "PredictionSingletonSemanticSegmentation" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io4(input.segmentation)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io13 = (input: any): boolean => "SEMANTIC_SEGMENTATION" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io4(input.segmentation)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $iu0 = (input: any): any => (() => {
            if ("CLASSIFICATION" === input.type)
                return $io1(input);
            if ("INSTANCE_SEGMENTATION" === input.type)
                return $io3(input);
            if ("KEYPOINT_DETECTION" === input.type)
                return $io6(input);
            if ("OBJECT_DETECTION" === input.type)
                return $io7(input);
            if ("PredictionSingletonClassification" === input.type)
                return $io8(input);
            if ("PredictionSingletonInstanceSegmentation" === input.type)
                return $io9(input);
            if ("PredictionSingletonKeypointDetection" === input.type)
                return $io10(input);
            if ("PredictionSingletonObjectDetection" === input.type)
                return $io11(input);
            if ("SEMANTIC_SEGMENTATION" === input.type)
                return $io13(input);
            if ("PredictionSingletonSemanticSegmentation" === input.type)
                return $io12(input);
            return false;
        })();
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some(regular => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${$su0(value)}`; return ""; }).filter(str => "" !== str).join(",")}`)}}`;
        const $so1 = (input: any): any => `{${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so2(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"CLASSIFICATION\"",
                value: input.type
            });
        })()},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so2 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some(regular => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${value}`; return ""; }).filter(str => "" !== str).join(",")}`)}}`;
        const $so3 = (input: any): any => `{${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so2(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"INSTANCE_SEGMENTATION\"",
                value: input.type
            });
        })()},"segmentation":${$so4(input.segmentation)},"bbox":${$so5(input.bbox)},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so4 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some(regular => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${value}`; return ""; }).filter(str => "" !== str).join(",")}`)}}`;
        const $so5 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some(regular => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${value}`; return ""; }).filter(str => "" !== str).join(",")}`)}}`;
        const $so6 = (input: any): any => `{${undefined === input.bbox ? "" : `"bbox":${undefined !== input.bbox ? $so5(input.bbox) : undefined},`}${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so2(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"KEYPOINT_DETECTION\"",
                value: input.type
            });
        })()},"keypoints":${`[${input.keypoints.map((elem: any) => elem).join(",")}]`},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so7 = (input: any): any => `{${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so2(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"OBJECT_DETECTION\"",
                value: input.type
            });
        })()},"bbox":${$so5(input.bbox)},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so8 = (input: any): any => `{${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so2(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"PredictionSingletonClassification\"",
                value: input.type
            });
        })()},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so9 = (input: any): any => `{${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so2(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"PredictionSingletonInstanceSegmentation\"",
                value: input.type
            });
        })()},"segmentation":${$so4(input.segmentation)},"bbox":${$so5(input.bbox)},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so10 = (input: any): any => `{${undefined === input.bbox ? "" : `"bbox":${undefined !== input.bbox ? $so5(input.bbox) : undefined},`}${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so2(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"PredictionSingletonKeypointDetection\"",
                value: input.type
            });
        })()},"keypoints":${`[${input.keypoints.map((elem: any) => elem).join(",")}]`},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so11 = (input: any): any => `{${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so2(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"PredictionSingletonObjectDetection\"",
                value: input.type
            });
        })()},"bbox":${$so5(input.bbox)},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so12 = (input: any): any => `{${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so2(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"PredictionSingletonSemanticSegmentation\"",
                value: input.type
            });
        })()},"segmentation":${$so4(input.segmentation)},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so13 = (input: any): any => `{${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so2(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"SEMANTIC_SEGMENTATION\"",
                value: input.type
            });
        })()},"segmentation":${$so4(input.segmentation)},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $su0 = (input: any): any => (() => {
            if ("CLASSIFICATION" === input.type)
                return $so1(input);
            if ("INSTANCE_SEGMENTATION" === input.type)
                return $so3(input);
            if ("KEYPOINT_DETECTION" === input.type)
                return $so6(input);
            if ("OBJECT_DETECTION" === input.type)
                return $so7(input);
            if ("PredictionSingletonClassification" === input.type)
                return $so8(input);
            if ("PredictionSingletonInstanceSegmentation" === input.type)
                return $so9(input);
            if ("PredictionSingletonKeypointDetection" === input.type)
                return $so10(input);
            if ("PredictionSingletonObjectDetection" === input.type)
                return $so11(input);
            if ("SEMANTIC_SEGMENTATION" === input.type)
                return $so13(input);
            if ("PredictionSingletonSemanticSegmentation" === input.type)
                return $so12(input);
            $throws({
                expected: "({ type: \"CLASSIFICATION\"; } & PredictionSingletonClassification | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionSingletonInstanceSegmentation | { type: \"KEYPOINT_DETECTION\"; } & PredictionSingletonKeypointDetection | { type: \"OBJECT_DETECTION\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonClassification\"; } & PredictionSingletonClassification | { type: \"PredictionSingletonInstanceSegmentation\"; } & PredictionSingletonInstanceSegmentation | { type: \"PredictionSingletonKeypointDetection\"; } & PredictionSingletonKeypointDetection | { type: \"PredictionSingletonObjectDetection\"; } & PredictionSingletonObjectDetection | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionSingletonSemanticSegmentation | { type: \"PredictionSingletonSemanticSegmentation\"; } & PredictionSingletonSemanticSegmentation)",
                value: input
            });
        })();
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const PredictionSingletonsValidatorShape = {};
export const PredictionSingletonsValidator = yup.array().of(PredictionSingletonValidator);
export const PredictionSingletonsStringifyShapeProperties: ObjectSchema['properties'] = {};
export const PredictionSingletonsStringifyShape: ArraySchema = {
    title: 'PredictionSingletons Stringifier',
    type: 'array',
    items: PredictionSingletonStringifyShape
};
export const PredictionSingletonsStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(PredictionSingletonsStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const PredictionSingletonsStringify: (data: PredictionSingletons) => string = PredictionSingletonsStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function PredictionSingletonsFromJSON(json: any): PredictionSingletons {
    return PredictionSingletonsFromJSONTyped(json, false);
}
export function PredictionSingletonsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PredictionSingletons {
    return json;
}
export function PredictionSingletonsToJSON(value?: PredictionSingletons | null): any {
    return value;
}

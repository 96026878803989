/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { SamplingConfig } from './SamplingConfig';
import { SamplingConfigFromJSON, SamplingConfigFromJSONTyped, SamplingConfigToJSON, } from './SamplingConfig';
import type { SamplingMethod } from './SamplingMethod';
import { SamplingMethodFromJSON, SamplingMethodFromJSONTyped, SamplingMethodToJSON, } from './SamplingMethod';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DockerTaskDescription
 *
 */
// create custom validators for the regexes
export interface DockerTaskDescription {
    /**
     *
     * #type string
     * #memberof DockerTaskDescription
        
        
         */
    embeddingsFilename: string;
    /**
     *
     * #type string
     * #memberof DockerTaskDescription
        
        
         */
    embeddingsHash: string;
    /**
     *
     * #type SamplingMethod
     * #memberof DockerTaskDescription
        
        
         */
    method: SamplingMethod;
    /**
     *
     * #type string
     * #memberof DockerTaskDescription
        
        
         */
    existingSelectionColumnName: string;
    /**
     *
     * #type string
     * #memberof DockerTaskDescription
        
        
         */
    activeLearningScoresColumnName: string;
    /**
     *
     * #type string
     * #memberof DockerTaskDescription
        
        
         */
    maskedOutColumnName: string;
    /**
     *
     * #type SamplingConfig
     * #memberof DockerTaskDescription
        
        
         */
    samplingConfig: SamplingConfig;
    /**
     * the number of samples in the current embeddings file
     * #type number
     * #memberof DockerTaskDescription
        
    * @minimum 0
            
         */
    nData: number;
}
/**
 * Check if a given object implements the DockerTaskDescription interface.
 */
export function instanceOfDockerTaskDescription(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "embeddingsFilename" in value;
    isInstance = isInstance && "embeddingsHash" in value;
    isInstance = isInstance && "method" in value;
    isInstance = isInstance && "existingSelectionColumnName" in value;
    isInstance = isInstance && "activeLearningScoresColumnName" in value;
    isInstance = isInstance && "maskedOutColumnName" in value;
    isInstance = isInstance && "samplingConfig" in value;
    isInstance = isInstance && "nData" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { SamplingConfigValidator, SamplingConfigStringifyShape, SamplingConfigStringifyShapeProperties, } from './SamplingConfig';
import { SamplingMethodValidator, SamplingMethodStringifyShape, SamplingMethodStringifyShapeProperties, } from './SamplingMethod';
export const DockerTaskDescriptionTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerTaskDescription => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerTaskDescription => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.embeddingsFilename && "string" === typeof input.embeddingsHash && ("ACTIVE_LEARNING" === input.method || "CORAL" === input.method || "CORESET" === input.method || "RANDOM" === input.method) && "string" === typeof input.existingSelectionColumnName && "string" === typeof input.activeLearningScoresColumnName && "string" === typeof input.maskedOutColumnName && ("object" === typeof input.samplingConfig && null !== input.samplingConfig && false === Array.isArray(input.samplingConfig) && $io1(input.samplingConfig, true && _exceptionable)) && ("number" === typeof input.nData && 0 <= input.nData) && (8 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["embeddingsFilename", "embeddingsHash", "method", "existingSelectionColumnName", "activeLearningScoresColumnName", "maskedOutColumnName", "samplingConfig", "nData"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.stoppingCondition || "object" === typeof input.stoppingCondition && null !== input.stoppingCondition && false === Array.isArray(input.stoppingCondition) && $io2(input.stoppingCondition, true && _exceptionable)) && (0 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["stoppingCondition"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.nSamples || "number" === typeof input.nSamples) && (undefined === input.minDistance || "number" === typeof input.minDistance) && (0 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["nSamples", "minDistance"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerTaskDescription => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.embeddingsFilename || $guard(_exceptionable, {
                    path: _path + ".embeddingsFilename",
                    expected: "string",
                    value: input.embeddingsFilename
                })) && ("string" === typeof input.embeddingsHash || $guard(_exceptionable, {
                    path: _path + ".embeddingsHash",
                    expected: "string",
                    value: input.embeddingsHash
                })) && ("ACTIVE_LEARNING" === input.method || "CORAL" === input.method || "CORESET" === input.method || "RANDOM" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"ACTIVE_LEARNING\" | \"CORAL\" | \"CORESET\" | \"RANDOM\")",
                    value: input.method
                })) && ("string" === typeof input.existingSelectionColumnName || $guard(_exceptionable, {
                    path: _path + ".existingSelectionColumnName",
                    expected: "string",
                    value: input.existingSelectionColumnName
                })) && ("string" === typeof input.activeLearningScoresColumnName || $guard(_exceptionable, {
                    path: _path + ".activeLearningScoresColumnName",
                    expected: "string",
                    value: input.activeLearningScoresColumnName
                })) && ("string" === typeof input.maskedOutColumnName || $guard(_exceptionable, {
                    path: _path + ".maskedOutColumnName",
                    expected: "string",
                    value: input.maskedOutColumnName
                })) && (("object" === typeof input.samplingConfig && null !== input.samplingConfig && false === Array.isArray(input.samplingConfig) || $guard(_exceptionable, {
                    path: _path + ".samplingConfig",
                    expected: "SamplingConfig",
                    value: input.samplingConfig
                })) && $ao1(input.samplingConfig, _path + ".samplingConfig", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".samplingConfig",
                    expected: "SamplingConfig",
                    value: input.samplingConfig
                })) && ("number" === typeof input.nData && (0 <= input.nData || $guard(_exceptionable, {
                    path: _path + ".nData",
                    expected: "number (@minimum 0)",
                    value: input.nData
                })) || $guard(_exceptionable, {
                    path: _path + ".nData",
                    expected: "number",
                    value: input.nData
                })) && (8 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["embeddingsFilename", "embeddingsHash", "method", "existingSelectionColumnName", "activeLearningScoresColumnName", "maskedOutColumnName", "samplingConfig", "nData"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.stoppingCondition || ("object" === typeof input.stoppingCondition && null !== input.stoppingCondition && false === Array.isArray(input.stoppingCondition) || $guard(_exceptionable, {
                    path: _path + ".stoppingCondition",
                    expected: "(SamplingConfigStoppingCondition | undefined)",
                    value: input.stoppingCondition
                })) && $ao2(input.stoppingCondition, _path + ".stoppingCondition", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".stoppingCondition",
                    expected: "(SamplingConfigStoppingCondition | undefined)",
                    value: input.stoppingCondition
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["stoppingCondition"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.nSamples || "number" === typeof input.nSamples || $guard(_exceptionable, {
                    path: _path + ".nSamples",
                    expected: "(number | undefined)",
                    value: input.nSamples
                })) && (undefined === input.minDistance || "number" === typeof input.minDistance || $guard(_exceptionable, {
                    path: _path + ".minDistance",
                    expected: "(number | undefined)",
                    value: input.minDistance
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["nSamples", "minDistance"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerTaskDescription",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerTaskDescription",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerTaskDescriptionTypiaAssertStringify = (input: DockerTaskDescription): string => {
    return ((input: any): string => { const assert = (input: any): DockerTaskDescription => {
        const __is = (input: any): input is DockerTaskDescription => {
            const $io0 = (input: any): boolean => "string" === typeof input.embeddingsFilename && "string" === typeof input.embeddingsHash && ("ACTIVE_LEARNING" === input.method || "CORAL" === input.method || "CORESET" === input.method || "RANDOM" === input.method) && "string" === typeof input.existingSelectionColumnName && "string" === typeof input.activeLearningScoresColumnName && "string" === typeof input.maskedOutColumnName && ("object" === typeof input.samplingConfig && null !== input.samplingConfig && false === Array.isArray(input.samplingConfig) && $io1(input.samplingConfig)) && ("number" === typeof input.nData && !Number.isNaN(input.nData) && 0 <= input.nData);
            const $io1 = (input: any): boolean => undefined === input.stoppingCondition || "object" === typeof input.stoppingCondition && null !== input.stoppingCondition && false === Array.isArray(input.stoppingCondition) && $io2(input.stoppingCondition);
            const $io2 = (input: any): boolean => (undefined === input.nSamples || "number" === typeof input.nSamples && !Number.isNaN(input.nSamples)) && (undefined === input.minDistance || "number" === typeof input.minDistance && !Number.isNaN(input.minDistance));
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerTaskDescription => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.embeddingsFilename || $guard(_exceptionable, {
                    path: _path + ".embeddingsFilename",
                    expected: "string",
                    value: input.embeddingsFilename
                })) && ("string" === typeof input.embeddingsHash || $guard(_exceptionable, {
                    path: _path + ".embeddingsHash",
                    expected: "string",
                    value: input.embeddingsHash
                })) && ("ACTIVE_LEARNING" === input.method || "CORAL" === input.method || "CORESET" === input.method || "RANDOM" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"ACTIVE_LEARNING\" | \"CORAL\" | \"CORESET\" | \"RANDOM\")",
                    value: input.method
                })) && ("string" === typeof input.existingSelectionColumnName || $guard(_exceptionable, {
                    path: _path + ".existingSelectionColumnName",
                    expected: "string",
                    value: input.existingSelectionColumnName
                })) && ("string" === typeof input.activeLearningScoresColumnName || $guard(_exceptionable, {
                    path: _path + ".activeLearningScoresColumnName",
                    expected: "string",
                    value: input.activeLearningScoresColumnName
                })) && ("string" === typeof input.maskedOutColumnName || $guard(_exceptionable, {
                    path: _path + ".maskedOutColumnName",
                    expected: "string",
                    value: input.maskedOutColumnName
                })) && (("object" === typeof input.samplingConfig && null !== input.samplingConfig && false === Array.isArray(input.samplingConfig) || $guard(_exceptionable, {
                    path: _path + ".samplingConfig",
                    expected: "SamplingConfig",
                    value: input.samplingConfig
                })) && $ao1(input.samplingConfig, _path + ".samplingConfig", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".samplingConfig",
                    expected: "SamplingConfig",
                    value: input.samplingConfig
                })) && ("number" === typeof input.nData && !Number.isNaN(input.nData) && (0 <= input.nData || $guard(_exceptionable, {
                    path: _path + ".nData",
                    expected: "number (@minimum 0)",
                    value: input.nData
                })) || $guard(_exceptionable, {
                    path: _path + ".nData",
                    expected: "number",
                    value: input.nData
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => undefined === input.stoppingCondition || ("object" === typeof input.stoppingCondition && null !== input.stoppingCondition && false === Array.isArray(input.stoppingCondition) || $guard(_exceptionable, {
                    path: _path + ".stoppingCondition",
                    expected: "(SamplingConfigStoppingCondition | undefined)",
                    value: input.stoppingCondition
                })) && $ao2(input.stoppingCondition, _path + ".stoppingCondition", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".stoppingCondition",
                    expected: "(SamplingConfigStoppingCondition | undefined)",
                    value: input.stoppingCondition
                });
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.nSamples || "number" === typeof input.nSamples && !Number.isNaN(input.nSamples) || $guard(_exceptionable, {
                    path: _path + ".nSamples",
                    expected: "(number | undefined)",
                    value: input.nSamples
                })) && (undefined === input.minDistance || "number" === typeof input.minDistance && !Number.isNaN(input.minDistance) || $guard(_exceptionable, {
                    path: _path + ".minDistance",
                    expected: "(number | undefined)",
                    value: input.minDistance
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerTaskDescription",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerTaskDescription",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerTaskDescription): string => {
        const $io1 = (input: any): boolean => undefined === input.stoppingCondition || "object" === typeof input.stoppingCondition && null !== input.stoppingCondition && false === Array.isArray(input.stoppingCondition) && $io2(input.stoppingCondition);
        const $io2 = (input: any): boolean => (undefined === input.nSamples || "number" === typeof input.nSamples) && (undefined === input.minDistance || "number" === typeof input.minDistance);
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $tail = (typia.createAssertStringify as any).tail;
        const $so0 = (input: any): any => `{"embeddingsFilename":${$string(input.embeddingsFilename)},"embeddingsHash":${$string(input.embeddingsHash)},"method":${(() => {
            if ("string" === typeof input.method)
                return $string(input.method);
            if ("string" === typeof input.method)
                return "\"" + input.method + "\"";
            $throws({
                expected: "(\"ACTIVE_LEARNING\" | \"CORAL\" | \"CORESET\" | \"RANDOM\")",
                value: input.method
            });
        })()},"existingSelectionColumnName":${$string(input.existingSelectionColumnName)},"activeLearningScoresColumnName":${$string(input.activeLearningScoresColumnName)},"maskedOutColumnName":${$string(input.maskedOutColumnName)},"samplingConfig":${$so1(input.samplingConfig)},"nData":${input.nData}}`;
        const $so1 = (input: any): any => `{${$tail(`${undefined === input.stoppingCondition ? "" : `"stoppingCondition":${undefined !== input.stoppingCondition ? $so2(input.stoppingCondition) : undefined}`}`)}}`;
        const $so2 = (input: any): any => `{${$tail(`${undefined === input.nSamples ? "" : `"nSamples":${undefined !== input.nSamples ? input.nSamples : undefined},`}${undefined === input.minDistance ? "" : `"minDistance":${undefined !== input.minDistance ? input.minDistance : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DockerTaskDescriptionValidatorShape = {
    'embeddingsFilename': yup.string().defined(),
    'embeddingsHash': yup.string().defined(),
    'method': SamplingMethodValidator.defined(),
    'existingSelectionColumnName': yup.string().defined(),
    'activeLearningScoresColumnName': yup.string().defined(),
    'maskedOutColumnName': yup.string().defined(),
    'samplingConfig': SamplingConfigValidator.defined(),
    'nData': yup.number().min(0).defined(),
};
export const DockerTaskDescriptionValidator = yup.object().shape(DockerTaskDescriptionValidatorShape);
export const DockerTaskDescriptionStringifyShapeProperties: ObjectSchema['properties'] = {
    'embeddingsFilename': {
        'type': 'string',
    },
    'embeddingsHash': {
        'type': 'string',
    },
    'method': SamplingMethodStringifyShape,
    'existingSelectionColumnName': {
        'type': 'string',
    },
    'activeLearningScoresColumnName': {
        'type': 'string',
    },
    'maskedOutColumnName': {
        'type': 'string',
    },
    'samplingConfig': SamplingConfigStringifyShape,
    'nData': {
        'type': 'number',
        'minimum': 0,
    },
};
export const DockerTaskDescriptionStringifyShape: ObjectSchema = {
    title: 'DockerTaskDescription Stringifier',
    type: 'object',
    properties: {
        ...DockerTaskDescriptionStringifyShapeProperties
    },
    required: [
        'embeddingsFilename',
        'embeddingsHash',
        'method',
        'existingSelectionColumnName',
        'activeLearningScoresColumnName',
        'maskedOutColumnName',
        'samplingConfig',
        'nData',
    ],
};
export const DockerTaskDescriptionStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerTaskDescriptionStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerTaskDescriptionStringify: (data: DockerTaskDescription) => string = DockerTaskDescriptionStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerTaskDescriptionFromJSON(json: any): DockerTaskDescription {
    return DockerTaskDescriptionFromJSONTyped(json, false);
}
export function DockerTaskDescriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerTaskDescription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'embeddingsFilename': json['embeddingsFilename'],
        'embeddingsHash': json['embeddingsHash'],
        'method': SamplingMethodFromJSON(json['method']),
        'existingSelectionColumnName': json['existingSelectionColumnName'],
        'activeLearningScoresColumnName': json['activeLearningScoresColumnName'],
        'maskedOutColumnName': json['maskedOutColumnName'],
        'samplingConfig': SamplingConfigFromJSON(json['samplingConfig']),
        'nData': json['nData'],
    };
}
export function DockerTaskDescriptionToJSON(value?: DockerTaskDescription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'embeddingsFilename': value.embeddingsFilename,
        'embeddingsHash': value.embeddingsHash,
        'method': SamplingMethodToJSON(value.method),
        'existingSelectionColumnName': value.existingSelectionColumnName,
        'activeLearningScoresColumnName': value.activeLearningScoresColumnName,
        'maskedOutColumnName': value.maskedOutColumnName,
        'samplingConfig': SamplingConfigToJSON(value.samplingConfig),
        'nData': value.nData,
    };
}

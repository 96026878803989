/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { SamaTask } from './SamaTask';
import { SamaTaskFromJSON, SamaTaskFromJSONTyped, SamaTaskToJSON, } from './SamaTask';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface SamaTasks
 *
 */
// create custom validators for the regexes
export interface SamaTasks extends Array<SamaTask> {
}
/**
 * Check if a given object implements the SamaTasks interface.
 */
export function instanceOfSamaTasks(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { SamaTaskValidator, SamaTaskStringifyShape, SamaTaskStringifyShapeProperties, } from './SamaTask';
export const SamaTasksTypiaAssertEquals = (input: any) => {
    return ((input: any): SamaTasks => {
        const __is = (input: any, _exceptionable: boolean = true): input is SamaTasks => {
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "object" === typeof value && null !== value && $io1(value, true && _exceptionable);
                return false;
            });
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.priority || "number" === typeof input.priority && Math.floor(input.priority) === input.priority && (-2147483648 <= input.priority && input.priority <= 2147483647)) && (undefined === input.reserve_for || "number" === typeof input.reserve_for && Math.floor(input.reserve_for) === input.reserve_for && (-2147483648 <= input.reserve_for && input.reserve_for <= 2147483647)) && ("object" === typeof input.data && null !== input.data && $io2(input.data, true && _exceptionable)) && (1 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["priority", "reserve_for", "data"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.id && Math.floor(input.id) === input.id && (-2147483648 <= input.id && input.id <= 2147483647) && "string" === typeof input.url && (undefined === input.image || "string" === typeof input.image) && (undefined === input.lightlyFileName || "string" === typeof input.lightlyFileName) && (undefined === input.lightlyMetaInfo || "string" === typeof input.lightlyMetaInfo) && (2 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["id", "url", "image", "lightlyFileName", "lightlyMetaInfo"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is SamaTasks => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return ("object" === typeof value && null !== value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "SamaTask",
                            value: value
                        })) && $ao1(value, _path + $join(key), true && _exceptionable) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "SamaTask",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.priority || "number" === typeof input.priority && (Math.floor(input.priority) === input.priority || $guard(_exceptionable, {
                    path: _path + ".priority",
                    expected: "number (@type int)",
                    value: input.priority
                })) && (-2147483648 <= input.priority && input.priority <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".priority",
                    expected: "number (@type int)",
                    value: input.priority
                })) || $guard(_exceptionable, {
                    path: _path + ".priority",
                    expected: "(number | undefined)",
                    value: input.priority
                })) && (undefined === input.reserve_for || "number" === typeof input.reserve_for && (Math.floor(input.reserve_for) === input.reserve_for || $guard(_exceptionable, {
                    path: _path + ".reserve_for",
                    expected: "number (@type int)",
                    value: input.reserve_for
                })) && (-2147483648 <= input.reserve_for && input.reserve_for <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".reserve_for",
                    expected: "number (@type int)",
                    value: input.reserve_for
                })) || $guard(_exceptionable, {
                    path: _path + ".reserve_for",
                    expected: "(number | undefined)",
                    value: input.reserve_for
                })) && (("object" === typeof input.data && null !== input.data || $guard(_exceptionable, {
                    path: _path + ".data",
                    expected: "SamaTaskData",
                    value: input.data
                })) && $ao2(input.data, _path + ".data", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".data",
                    expected: "SamaTaskData",
                    value: input.data
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["priority", "reserve_for", "data"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.id && (Math.floor(input.id) === input.id || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number (@type int)",
                    value: input.id
                })) && (-2147483648 <= input.id && input.id <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number (@type int)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number",
                    value: input.id
                })) && ("string" === typeof input.url || $guard(_exceptionable, {
                    path: _path + ".url",
                    expected: "string",
                    value: input.url
                })) && (undefined === input.image || "string" === typeof input.image || $guard(_exceptionable, {
                    path: _path + ".image",
                    expected: "(string | undefined)",
                    value: input.image
                })) && (undefined === input.lightlyFileName || "string" === typeof input.lightlyFileName || $guard(_exceptionable, {
                    path: _path + ".lightlyFileName",
                    expected: "(string | undefined)",
                    value: input.lightlyFileName
                })) && (undefined === input.lightlyMetaInfo || "string" === typeof input.lightlyMetaInfo || $guard(_exceptionable, {
                    path: _path + ".lightlyMetaInfo",
                    expected: "(string | undefined)",
                    value: input.lightlyMetaInfo
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["id", "url", "image", "lightlyFileName", "lightlyMetaInfo"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SamaTasks",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SamaTasks",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const SamaTasksTypiaAssertStringify = (input: SamaTasks): string => {
    return ((input: any): string => { const assert = (input: any): SamaTasks => {
        const __is = (input: any): input is SamaTasks => {
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "object" === typeof value && null !== value && $io1(value);
                return true;
            });
            const $io1 = (input: any): boolean => (undefined === input.priority || "number" === typeof input.priority && !Number.isNaN(input.priority) && Math.floor(input.priority) === input.priority && (-2147483648 <= input.priority && input.priority <= 2147483647)) && (undefined === input.reserve_for || "number" === typeof input.reserve_for && !Number.isNaN(input.reserve_for) && Math.floor(input.reserve_for) === input.reserve_for && (-2147483648 <= input.reserve_for && input.reserve_for <= 2147483647)) && ("object" === typeof input.data && null !== input.data && $io2(input.data));
            const $io2 = (input: any): boolean => "number" === typeof input.id && !Number.isNaN(input.id) && Math.floor(input.id) === input.id && (-2147483648 <= input.id && input.id <= 2147483647) && "string" === typeof input.url && (undefined === input.image || "string" === typeof input.image) && (undefined === input.lightlyFileName || "string" === typeof input.lightlyFileName) && (undefined === input.lightlyMetaInfo || "string" === typeof input.lightlyMetaInfo);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is SamaTasks => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return ("object" === typeof value && null !== value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "SamaTask",
                            value: value
                        })) && $ao1(value, _path + $join(key), true && _exceptionable) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "SamaTask",
                            value: value
                        });
                    return true;
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.priority || "number" === typeof input.priority && !Number.isNaN(input.priority) && (Math.floor(input.priority) === input.priority || $guard(_exceptionable, {
                    path: _path + ".priority",
                    expected: "number (@type int)",
                    value: input.priority
                })) && (-2147483648 <= input.priority && input.priority <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".priority",
                    expected: "number (@type int)",
                    value: input.priority
                })) || $guard(_exceptionable, {
                    path: _path + ".priority",
                    expected: "(number | undefined)",
                    value: input.priority
                })) && (undefined === input.reserve_for || "number" === typeof input.reserve_for && !Number.isNaN(input.reserve_for) && (Math.floor(input.reserve_for) === input.reserve_for || $guard(_exceptionable, {
                    path: _path + ".reserve_for",
                    expected: "number (@type int)",
                    value: input.reserve_for
                })) && (-2147483648 <= input.reserve_for && input.reserve_for <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".reserve_for",
                    expected: "number (@type int)",
                    value: input.reserve_for
                })) || $guard(_exceptionable, {
                    path: _path + ".reserve_for",
                    expected: "(number | undefined)",
                    value: input.reserve_for
                })) && (("object" === typeof input.data && null !== input.data || $guard(_exceptionable, {
                    path: _path + ".data",
                    expected: "SamaTaskData",
                    value: input.data
                })) && $ao2(input.data, _path + ".data", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".data",
                    expected: "SamaTaskData",
                    value: input.data
                }));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.id && !Number.isNaN(input.id) && (Math.floor(input.id) === input.id || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number (@type int)",
                    value: input.id
                })) && (-2147483648 <= input.id && input.id <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number (@type int)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number",
                    value: input.id
                })) && ("string" === typeof input.url || $guard(_exceptionable, {
                    path: _path + ".url",
                    expected: "string",
                    value: input.url
                })) && (undefined === input.image || "string" === typeof input.image || $guard(_exceptionable, {
                    path: _path + ".image",
                    expected: "(string | undefined)",
                    value: input.image
                })) && (undefined === input.lightlyFileName || "string" === typeof input.lightlyFileName || $guard(_exceptionable, {
                    path: _path + ".lightlyFileName",
                    expected: "(string | undefined)",
                    value: input.lightlyFileName
                })) && (undefined === input.lightlyMetaInfo || "string" === typeof input.lightlyMetaInfo || $guard(_exceptionable, {
                    path: _path + ".lightlyMetaInfo",
                    expected: "(string | undefined)",
                    value: input.lightlyMetaInfo
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SamaTasks",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SamaTasks",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: SamaTasks): string => {
        const $io1 = (input: any): boolean => (undefined === input.priority || "number" === typeof input.priority && Math.floor(input.priority) === input.priority && (-2147483648 <= input.priority && input.priority <= 2147483647)) && (undefined === input.reserve_for || "number" === typeof input.reserve_for && Math.floor(input.reserve_for) === input.reserve_for && (-2147483648 <= input.reserve_for && input.reserve_for <= 2147483647)) && ("object" === typeof input.data && null !== input.data && $io2(input.data));
        const $io2 = (input: any): boolean => "number" === typeof input.id && Math.floor(input.id) === input.id && (-2147483648 <= input.id && input.id <= 2147483647) && "string" === typeof input.url && (undefined === input.image || "string" === typeof input.image) && (undefined === input.lightlyFileName || "string" === typeof input.lightlyFileName) && (undefined === input.lightlyMetaInfo || "string" === typeof input.lightlyMetaInfo);
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $string = (typia.createAssertStringify as any).string;
        const $so0 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some(regular => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${$so1(value)}`; return ""; }).filter(str => "" !== str).join(",")}`)}}`;
        const $so1 = (input: any): any => `{${undefined === input.priority ? "" : `"priority":${undefined !== input.priority ? input.priority : undefined},`}${undefined === input.reserve_for ? "" : `"reserve_for":${undefined !== input.reserve_for ? input.reserve_for : undefined},`}"data":${$so2(input.data)}}`;
        const $so2 = (input: any): any => `{${undefined === input.image ? "" : `"image":${undefined !== input.image ? $string(input.image) : undefined},`}${undefined === input.lightlyFileName ? "" : `"lightlyFileName":${undefined !== input.lightlyFileName ? $string(input.lightlyFileName) : undefined},`}${undefined === input.lightlyMetaInfo ? "" : `"lightlyMetaInfo":${undefined !== input.lightlyMetaInfo ? $string(input.lightlyMetaInfo) : undefined},`}"id":${input.id},"url":${$string(input.url)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const SamaTasksValidatorShape = {};
export const SamaTasksValidator = yup.array().of(SamaTaskValidator);
export const SamaTasksStringifyShapeProperties: ObjectSchema['properties'] = {};
export const SamaTasksStringifyShape: ArraySchema = {
    title: 'SamaTasks Stringifier',
    type: 'array',
    items: SamaTaskStringifyShape
};
export const SamaTasksStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(SamaTasksStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const SamaTasksStringify: (data: SamaTasks) => string = SamaTasksStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function SamaTasksFromJSON(json: any): SamaTasks {
    return SamaTasksFromJSONTyped(json, false);
}
export function SamaTasksFromJSONTyped(json: any, ignoreDiscriminator: boolean): SamaTasks {
    return json;
}
export function SamaTasksToJSON(value?: SamaTasks | null): any {
    return value;
}

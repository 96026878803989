/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { JobStatusUploadMethod } from './JobStatusUploadMethod';
import { JobStatusUploadMethodFromJSON, JobStatusUploadMethodFromJSONTyped, JobStatusUploadMethodToJSON, } from './JobStatusUploadMethod';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface JobStatusMeta
 *
 */
// create custom validators for the regexes
export interface JobStatusMeta {
    /**
     *
     * #type number
     * #memberof JobStatusMeta
        * @type int
        
         */
    total: number;
    /**
     *
     * #type number
     * #memberof JobStatusMeta
        * @type int
        
         */
    processed: number;
    /**
     *
     * #type JobStatusUploadMethod
     * #memberof JobStatusMeta
        
        
         */
    uploadMethod?: JobStatusUploadMethod;
    /**
     * Flag which indicates whether the job was registered or not.
     * #type boolean
     * #memberof JobStatusMeta
        
        
         */
    isRegistered?: boolean;
}
/**
 * Check if a given object implements the JobStatusMeta interface.
 */
export function instanceOfJobStatusMeta(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "processed" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { JobStatusUploadMethodValidator, JobStatusUploadMethodStringifyShape, JobStatusUploadMethodStringifyShapeProperties, } from './JobStatusUploadMethod';
export const JobStatusMetaTypiaAssertEquals = (input: any) => {
    return ((input: any): JobStatusMeta => {
        const __is = (input: any, _exceptionable: boolean = true): input is JobStatusMeta => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.total && Math.floor(input.total) === input.total && (-2147483648 <= input.total && input.total <= 2147483647) && ("number" === typeof input.processed && Math.floor(input.processed) === input.processed && (-2147483648 <= input.processed && input.processed <= 2147483647)) && (undefined === input.uploadMethod || "USER_WEBAPP" === input.uploadMethod || "USER_PIP" === input.uploadMethod || "INTERNAL" === input.uploadMethod) && (undefined === input.isRegistered || "boolean" === typeof input.isRegistered) && (2 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["total", "processed", "uploadMethod", "isRegistered"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is JobStatusMeta => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.total && (Math.floor(input.total) === input.total || $guard(_exceptionable, {
                    path: _path + ".total",
                    expected: "number (@type int)",
                    value: input.total
                })) && (-2147483648 <= input.total && input.total <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".total",
                    expected: "number (@type int)",
                    value: input.total
                })) || $guard(_exceptionable, {
                    path: _path + ".total",
                    expected: "number",
                    value: input.total
                })) && ("number" === typeof input.processed && (Math.floor(input.processed) === input.processed || $guard(_exceptionable, {
                    path: _path + ".processed",
                    expected: "number (@type int)",
                    value: input.processed
                })) && (-2147483648 <= input.processed && input.processed <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".processed",
                    expected: "number (@type int)",
                    value: input.processed
                })) || $guard(_exceptionable, {
                    path: _path + ".processed",
                    expected: "number",
                    value: input.processed
                })) && (undefined === input.uploadMethod || "USER_WEBAPP" === input.uploadMethod || "USER_PIP" === input.uploadMethod || "INTERNAL" === input.uploadMethod || $guard(_exceptionable, {
                    path: _path + ".uploadMethod",
                    expected: "(\"INTERNAL\" | \"USER_PIP\" | \"USER_WEBAPP\" | undefined)",
                    value: input.uploadMethod
                })) && (undefined === input.isRegistered || "boolean" === typeof input.isRegistered || $guard(_exceptionable, {
                    path: _path + ".isRegistered",
                    expected: "(boolean | undefined)",
                    value: input.isRegistered
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["total", "processed", "uploadMethod", "isRegistered"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "JobStatusMeta",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "JobStatusMeta",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const JobStatusMetaTypiaAssertStringify = (input: JobStatusMeta): string => {
    return ((input: any): string => { const assert = (input: any): JobStatusMeta => {
        const __is = (input: any): input is JobStatusMeta => {
            const $io0 = (input: any): boolean => "number" === typeof input.total && !Number.isNaN(input.total) && Math.floor(input.total) === input.total && (-2147483648 <= input.total && input.total <= 2147483647) && ("number" === typeof input.processed && !Number.isNaN(input.processed) && Math.floor(input.processed) === input.processed && (-2147483648 <= input.processed && input.processed <= 2147483647)) && (undefined === input.uploadMethod || "USER_WEBAPP" === input.uploadMethod || "USER_PIP" === input.uploadMethod || "INTERNAL" === input.uploadMethod) && (undefined === input.isRegistered || "boolean" === typeof input.isRegistered);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is JobStatusMeta => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.total && !Number.isNaN(input.total) && (Math.floor(input.total) === input.total || $guard(_exceptionable, {
                    path: _path + ".total",
                    expected: "number (@type int)",
                    value: input.total
                })) && (-2147483648 <= input.total && input.total <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".total",
                    expected: "number (@type int)",
                    value: input.total
                })) || $guard(_exceptionable, {
                    path: _path + ".total",
                    expected: "number",
                    value: input.total
                })) && ("number" === typeof input.processed && !Number.isNaN(input.processed) && (Math.floor(input.processed) === input.processed || $guard(_exceptionable, {
                    path: _path + ".processed",
                    expected: "number (@type int)",
                    value: input.processed
                })) && (-2147483648 <= input.processed && input.processed <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".processed",
                    expected: "number (@type int)",
                    value: input.processed
                })) || $guard(_exceptionable, {
                    path: _path + ".processed",
                    expected: "number",
                    value: input.processed
                })) && (undefined === input.uploadMethod || "USER_WEBAPP" === input.uploadMethod || "USER_PIP" === input.uploadMethod || "INTERNAL" === input.uploadMethod || $guard(_exceptionable, {
                    path: _path + ".uploadMethod",
                    expected: "(\"INTERNAL\" | \"USER_PIP\" | \"USER_WEBAPP\" | undefined)",
                    value: input.uploadMethod
                })) && (undefined === input.isRegistered || "boolean" === typeof input.isRegistered || $guard(_exceptionable, {
                    path: _path + ".isRegistered",
                    expected: "(boolean | undefined)",
                    value: input.isRegistered
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "JobStatusMeta",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "JobStatusMeta",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: JobStatusMeta): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $so0 = (input: any): any => `{${undefined === input.uploadMethod ? "" : `"uploadMethod":${undefined !== input.uploadMethod ? (() => {
            if ("string" === typeof input.uploadMethod)
                return $string(input.uploadMethod);
            if ("string" === typeof input.uploadMethod)
                return "\"" + input.uploadMethod + "\"";
            $throws({
                expected: "(\"INTERNAL\" | \"USER_PIP\" | \"USER_WEBAPP\" | undefined)",
                value: input.uploadMethod
            });
        })() : undefined},`}${undefined === input.isRegistered ? "" : `"isRegistered":${undefined !== input.isRegistered ? input.isRegistered : undefined},`}"total":${input.total},"processed":${input.processed}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const JobStatusMetaValidatorShape = {
    'total': yup.number().integer().defined(),
    'processed': yup.number().integer().defined(),
    'uploadMethod': JobStatusUploadMethodValidator.optional().default(undefined),
    'isRegistered': yup.boolean(),
};
export const JobStatusMetaValidator = yup.object().shape(JobStatusMetaValidatorShape);
export const JobStatusMetaStringifyShapeProperties: ObjectSchema['properties'] = {
    'total': {
        'type': 'integer',
    },
    'processed': {
        'type': 'integer',
    },
    'uploadMethod': JobStatusUploadMethodStringifyShape,
    'isRegistered': {
        'type': 'boolean',
    },
};
export const JobStatusMetaStringifyShape: ObjectSchema = {
    title: 'JobStatusMeta Stringifier',
    type: 'object',
    properties: {
        ...JobStatusMetaStringifyShapeProperties
    },
    required: [
        'total',
        'processed',
    ],
};
export const JobStatusMetaStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(JobStatusMetaStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const JobStatusMetaStringify: (data: JobStatusMeta) => string = JobStatusMetaStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function JobStatusMetaFromJSON(json: any): JobStatusMeta {
    return JobStatusMetaFromJSONTyped(json, false);
}
export function JobStatusMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobStatusMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'total': json['total'],
        'processed': json['processed'],
        'uploadMethod': !exists(json, 'uploadMethod') ? undefined : JobStatusUploadMethodFromJSON(json['uploadMethod']),
        'isRegistered': !exists(json, 'isRegistered') ? undefined : json['isRegistered'],
    };
}
export function JobStatusMetaToJSON(value?: JobStatusMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'total': value.total,
        'processed': value.processed,
        'uploadMethod': JobStatusUploadMethodToJSON(value.uploadMethod),
        'isRegistered': value.isRegistered,
    };
}

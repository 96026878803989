/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DockerLicenseInformation
 *
 */
// create custom validators for the regexes
export interface DockerLicenseInformation {
    /**
     *
     * #type string
     * #memberof DockerLicenseInformation
        
        
         */
    licenseType: string;
    /**
     * unix timestamp in milliseconds
     * #type number
     * #memberof DockerLicenseInformation
        
    * @minimum 0
            
         */
    licenseExpirationDate: number;
    /**
     *
     * #type boolean
     * #memberof DockerLicenseInformation
        
        
         */
    licenseIsValid: boolean;
}
/**
 * Check if a given object implements the DockerLicenseInformation interface.
 */
export function instanceOfDockerLicenseInformation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "licenseType" in value;
    isInstance = isInstance && "licenseExpirationDate" in value;
    isInstance = isInstance && "licenseIsValid" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
export const DockerLicenseInformationTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerLicenseInformation => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerLicenseInformation => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.licenseType && ("number" === typeof input.licenseExpirationDate && 0 <= input.licenseExpirationDate) && "boolean" === typeof input.licenseIsValid && (3 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["licenseType", "licenseExpirationDate", "licenseIsValid"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerLicenseInformation => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.licenseType || $guard(_exceptionable, {
                    path: _path + ".licenseType",
                    expected: "string",
                    value: input.licenseType
                })) && ("number" === typeof input.licenseExpirationDate && (0 <= input.licenseExpirationDate || $guard(_exceptionable, {
                    path: _path + ".licenseExpirationDate",
                    expected: "number (@minimum 0)",
                    value: input.licenseExpirationDate
                })) || $guard(_exceptionable, {
                    path: _path + ".licenseExpirationDate",
                    expected: "number",
                    value: input.licenseExpirationDate
                })) && ("boolean" === typeof input.licenseIsValid || $guard(_exceptionable, {
                    path: _path + ".licenseIsValid",
                    expected: "boolean",
                    value: input.licenseIsValid
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["licenseType", "licenseExpirationDate", "licenseIsValid"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerLicenseInformation",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerLicenseInformation",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerLicenseInformationTypiaAssertStringify = (input: DockerLicenseInformation): string => {
    return ((input: any): string => { const assert = (input: any): DockerLicenseInformation => {
        const __is = (input: any): input is DockerLicenseInformation => {
            return "object" === typeof input && null !== input && ("string" === typeof (input as any).licenseType && ("number" === typeof (input as any).licenseExpirationDate && !Number.isNaN((input as any).licenseExpirationDate) && 0 <= (input as any).licenseExpirationDate) && "boolean" === typeof (input as any).licenseIsValid);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerLicenseInformation => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.licenseType || $guard(_exceptionable, {
                    path: _path + ".licenseType",
                    expected: "string",
                    value: input.licenseType
                })) && ("number" === typeof input.licenseExpirationDate && !Number.isNaN(input.licenseExpirationDate) && (0 <= input.licenseExpirationDate || $guard(_exceptionable, {
                    path: _path + ".licenseExpirationDate",
                    expected: "number (@minimum 0)",
                    value: input.licenseExpirationDate
                })) || $guard(_exceptionable, {
                    path: _path + ".licenseExpirationDate",
                    expected: "number",
                    value: input.licenseExpirationDate
                })) && ("boolean" === typeof input.licenseIsValid || $guard(_exceptionable, {
                    path: _path + ".licenseIsValid",
                    expected: "boolean",
                    value: input.licenseIsValid
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerLicenseInformation",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerLicenseInformation",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerLicenseInformation): string => {
        const $string = (typia.createAssertStringify as any).string;
        return `{"licenseType":${$string((input as any).licenseType)},"licenseExpirationDate":${(input as any).licenseExpirationDate},"licenseIsValid":${(input as any).licenseIsValid}}`;
    }; return stringify(assert(input)); })(input);
};
export const DockerLicenseInformationValidatorShape = {
    'licenseType': yup.string().defined(),
    'licenseExpirationDate': yup.number().min(0).defined(),
    'licenseIsValid': yup.boolean().defined(),
};
export const DockerLicenseInformationValidator = yup.object().shape(DockerLicenseInformationValidatorShape);
export const DockerLicenseInformationStringifyShapeProperties: ObjectSchema['properties'] = {
    'licenseType': {
        'type': 'string',
    },
    'licenseExpirationDate': {
        'type': 'number',
        'minimum': 0,
    },
    'licenseIsValid': {
        'type': 'boolean',
    },
};
export const DockerLicenseInformationStringifyShape: ObjectSchema = {
    title: 'DockerLicenseInformation Stringifier',
    type: 'object',
    properties: {
        ...DockerLicenseInformationStringifyShapeProperties
    },
    required: [
        'licenseType',
        'licenseExpirationDate',
        'licenseIsValid',
    ],
};
export const DockerLicenseInformationStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerLicenseInformationStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerLicenseInformationStringify: (data: DockerLicenseInformation) => string = DockerLicenseInformationStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerLicenseInformationFromJSON(json: any): DockerLicenseInformation {
    return DockerLicenseInformationFromJSONTyped(json, false);
}
export function DockerLicenseInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerLicenseInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'licenseType': json['licenseType'],
        'licenseExpirationDate': json['licenseExpirationDate'],
        'licenseIsValid': json['licenseIsValid'],
    };
}
export function DockerLicenseInformationToJSON(value?: DockerLicenseInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'licenseType': value.licenseType,
        'licenseExpirationDate': value.licenseExpirationDate,
        'licenseIsValid': value.licenseIsValid,
    };
}

/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface LabelBoxV4DataRow
 *
 */
// create custom validators for the regexes
export interface LabelBoxV4DataRow {
    /**
     * A URL which allows anyone in possession of said URL for the time specified by the expiresIn query param to access the resource
     * #type string
     * #memberof LabelBoxV4DataRow
        
        
         */
    row_data: string;
    /**
     * The task_id for importing into LabelBox.
     * #type string
     * #memberof LabelBoxV4DataRow
        
        
         */
    global_key?: string;
    /**
     * LabelBox media type, e.g. IMAGE
     * #type string
     * #memberof LabelBoxV4DataRow
        
        
         */
    media_type?: string;
}
/**
 * Check if a given object implements the LabelBoxV4DataRow interface.
 */
export function instanceOfLabelBoxV4DataRow(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "row_data" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
export const LabelBoxV4DataRowTypiaAssertEquals = (input: any) => {
    return ((input: any): LabelBoxV4DataRow => {
        const __is = (input: any, _exceptionable: boolean = true): input is LabelBoxV4DataRow => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.row_data && (undefined === input.global_key || "string" === typeof input.global_key) && (undefined === input.media_type || "string" === typeof input.media_type) && (1 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["row_data", "global_key", "media_type"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is LabelBoxV4DataRow => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.row_data || $guard(_exceptionable, {
                    path: _path + ".row_data",
                    expected: "string",
                    value: input.row_data
                })) && (undefined === input.global_key || "string" === typeof input.global_key || $guard(_exceptionable, {
                    path: _path + ".global_key",
                    expected: "(string | undefined)",
                    value: input.global_key
                })) && (undefined === input.media_type || "string" === typeof input.media_type || $guard(_exceptionable, {
                    path: _path + ".media_type",
                    expected: "(string | undefined)",
                    value: input.media_type
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["row_data", "global_key", "media_type"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "LabelBoxV4DataRow",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "LabelBoxV4DataRow",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const LabelBoxV4DataRowTypiaAssertStringify = (input: LabelBoxV4DataRow): string => {
    return ((input: any): string => { const assert = (input: any): LabelBoxV4DataRow => {
        const __is = (input: any): input is LabelBoxV4DataRow => {
            const $io0 = (input: any): boolean => "string" === typeof input.row_data && (undefined === input.global_key || "string" === typeof input.global_key) && (undefined === input.media_type || "string" === typeof input.media_type);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is LabelBoxV4DataRow => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.row_data || $guard(_exceptionable, {
                    path: _path + ".row_data",
                    expected: "string",
                    value: input.row_data
                })) && (undefined === input.global_key || "string" === typeof input.global_key || $guard(_exceptionable, {
                    path: _path + ".global_key",
                    expected: "(string | undefined)",
                    value: input.global_key
                })) && (undefined === input.media_type || "string" === typeof input.media_type || $guard(_exceptionable, {
                    path: _path + ".media_type",
                    expected: "(string | undefined)",
                    value: input.media_type
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "LabelBoxV4DataRow",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "LabelBoxV4DataRow",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: LabelBoxV4DataRow): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $so0 = (input: any): any => `{${undefined === input.global_key ? "" : `"global_key":${undefined !== input.global_key ? $string(input.global_key) : undefined},`}${undefined === input.media_type ? "" : `"media_type":${undefined !== input.media_type ? $string(input.media_type) : undefined},`}"row_data":${$string(input.row_data)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const LabelBoxV4DataRowValidatorShape = {
    'row_data': yup.string().defined(),
    'global_key': yup.string(),
    'media_type': yup.string(),
};
export const LabelBoxV4DataRowValidator = yup.object().shape(LabelBoxV4DataRowValidatorShape);
export const LabelBoxV4DataRowStringifyShapeProperties: ObjectSchema['properties'] = {
    'row_data': {
        'type': 'string',
    },
    'global_key': {
        'type': 'string',
    },
    'media_type': {
        'type': 'string',
    },
};
export const LabelBoxV4DataRowStringifyShape: ObjectSchema = {
    title: 'LabelBoxV4DataRow Stringifier',
    type: 'object',
    properties: {
        ...LabelBoxV4DataRowStringifyShapeProperties
    },
    required: [
        'row_data',
    ],
};
export const LabelBoxV4DataRowStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(LabelBoxV4DataRowStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const LabelBoxV4DataRowStringify: (data: LabelBoxV4DataRow) => string = LabelBoxV4DataRowStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function LabelBoxV4DataRowFromJSON(json: any): LabelBoxV4DataRow {
    return LabelBoxV4DataRowFromJSONTyped(json, false);
}
export function LabelBoxV4DataRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): LabelBoxV4DataRow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'row_data': json['row_data'],
        'global_key': !exists(json, 'global_key') ? undefined : json['global_key'],
        'media_type': !exists(json, 'media_type') ? undefined : json['media_type'],
    };
}
export function LabelBoxV4DataRowToJSON(value?: LabelBoxV4DataRow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'row_data': value.row_data,
        'global_key': value.global_key,
        'media_type': value.media_type,
    };
}

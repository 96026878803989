/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DockerUserStats
 *
 */
// create custom validators for the regexes
export interface DockerUserStats {
    /**
     *
     * #type string
     * #memberof DockerUserStats
        
        
         */
    runId: string;
    /**
     *
     * #type string
     * #memberof DockerUserStats
        
        
         */
    action: string;
    /**
     *
     * #type object
     * #memberof DockerUserStats
        
        
         */
    data: object;
    /**
     * unix timestamp in milliseconds
     * #type number
     * #memberof DockerUserStats
        
    * @minimum 0
            
         */
    timestamp: number;
    /**
     *
     * #type string
     * #memberof DockerUserStats
        
        
         */
    pipVersion: string;
    /**
     *
     * #type string
     * #memberof DockerUserStats
        
        
         */
    dockerVersion: string;
}
/**
 * Check if a given object implements the DockerUserStats interface.
 */
export function instanceOfDockerUserStats(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "runId" in value;
    isInstance = isInstance && "action" in value;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "timestamp" in value;
    isInstance = isInstance && "pipVersion" in value;
    isInstance = isInstance && "dockerVersion" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
export const DockerUserStatsTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerUserStats => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerUserStats => {
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.runId && "string" === typeof input.action && ("object" === typeof input.data && null !== input.data && false === Array.isArray(input.data) && $io1(input.data, true && _exceptionable)) && ("number" === typeof input.timestamp && 0 <= input.timestamp) && "string" === typeof input.pipVersion && "string" === typeof input.dockerVersion && (6 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["runId", "action", "data", "timestamp", "pipVersion", "dockerVersion"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => 0 === Object.keys(input).length || Object.keys(input).every(key => {
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            });
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerUserStats => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.runId || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string",
                    value: input.runId
                })) && ("string" === typeof input.action || $guard(_exceptionable, {
                    path: _path + ".action",
                    expected: "string",
                    value: input.action
                })) && (("object" === typeof input.data && null !== input.data && false === Array.isArray(input.data) || $guard(_exceptionable, {
                    path: _path + ".data",
                    expected: "object",
                    value: input.data
                })) && $ao1(input.data, _path + ".data", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".data",
                    expected: "object",
                    value: input.data
                })) && ("number" === typeof input.timestamp && (0 <= input.timestamp || $guard(_exceptionable, {
                    path: _path + ".timestamp",
                    expected: "number (@minimum 0)",
                    value: input.timestamp
                })) || $guard(_exceptionable, {
                    path: _path + ".timestamp",
                    expected: "number",
                    value: input.timestamp
                })) && ("string" === typeof input.pipVersion || $guard(_exceptionable, {
                    path: _path + ".pipVersion",
                    expected: "string",
                    value: input.pipVersion
                })) && ("string" === typeof input.dockerVersion || $guard(_exceptionable, {
                    path: _path + ".dockerVersion",
                    expected: "string",
                    value: input.dockerVersion
                })) && (6 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["runId", "action", "data", "timestamp", "pipVersion", "dockerVersion"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => 0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerUserStats",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerUserStats",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerUserStatsTypiaAssertStringify = (input: DockerUserStats): string => {
    return ((input: any): string => { const assert = (input: any): DockerUserStats => {
        const __is = (input: any): input is DockerUserStats => {
            return "object" === typeof input && null !== input && ("string" === typeof (input as any).runId && "string" === typeof (input as any).action && ("object" === typeof (input as any).data && null !== (input as any).data && true) && ("number" === typeof (input as any).timestamp && !Number.isNaN((input as any).timestamp) && 0 <= (input as any).timestamp) && "string" === typeof (input as any).pipVersion && "string" === typeof (input as any).dockerVersion);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerUserStats => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.runId || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string",
                    value: input.runId
                })) && ("string" === typeof input.action || $guard(_exceptionable, {
                    path: _path + ".action",
                    expected: "string",
                    value: input.action
                })) && (("object" === typeof input.data && null !== input.data && false === Array.isArray(input.data) || $guard(_exceptionable, {
                    path: _path + ".data",
                    expected: "object",
                    value: input.data
                })) && $ao1(input.data, _path + ".data", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".data",
                    expected: "object",
                    value: input.data
                })) && ("number" === typeof input.timestamp && !Number.isNaN(input.timestamp) && (0 <= input.timestamp || $guard(_exceptionable, {
                    path: _path + ".timestamp",
                    expected: "number (@minimum 0)",
                    value: input.timestamp
                })) || $guard(_exceptionable, {
                    path: _path + ".timestamp",
                    expected: "number",
                    value: input.timestamp
                })) && ("string" === typeof input.pipVersion || $guard(_exceptionable, {
                    path: _path + ".pipVersion",
                    expected: "string",
                    value: input.pipVersion
                })) && ("string" === typeof input.dockerVersion || $guard(_exceptionable, {
                    path: _path + ".dockerVersion",
                    expected: "string",
                    value: input.dockerVersion
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => true;
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerUserStats",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerUserStats",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerUserStats): string => {
        const $io1 = (input: any): boolean => true;
        const $string = (typia.createAssertStringify as any).string;
        return `{"runId":${$string((input as any).runId)},"action":${$string((input as any).action)},"data":{},"timestamp":${(input as any).timestamp},"pipVersion":${$string((input as any).pipVersion)},"dockerVersion":${$string((input as any).dockerVersion)}}`;
    }; return stringify(assert(input)); })(input);
};
export const DockerUserStatsValidatorShape = {
    'runId': yup.string().defined(),
    'action': yup.string().defined(),
    'data': yup.mixed().transform((val: any) => { return val instanceof Object && !Array.isArray(val) ? val : {}; }).defined(),
    'timestamp': yup.number().min(0).defined(),
    'pipVersion': yup.string().defined(),
    'dockerVersion': yup.string().defined(),
};
export const DockerUserStatsValidator = yup.object().shape(DockerUserStatsValidatorShape);
export const DockerUserStatsStringifyShapeProperties: ObjectSchema['properties'] = {
    'runId': {
        'type': 'string',
    },
    'action': {
        'type': 'string',
    },
    'data': {
        type: 'object',
        additionalProperties: true
    },
    'timestamp': {
        'type': 'number',
        'minimum': 0,
    },
    'pipVersion': {
        'type': 'string',
    },
    'dockerVersion': {
        'type': 'string',
    },
};
export const DockerUserStatsStringifyShape: ObjectSchema = {
    title: 'DockerUserStats Stringifier',
    type: 'object',
    properties: {
        ...DockerUserStatsStringifyShapeProperties
    },
    required: [
        'runId',
        'action',
        'data',
        'timestamp',
        'pipVersion',
        'dockerVersion',
    ],
};
export const DockerUserStatsStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerUserStatsStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerUserStatsStringify: (data: DockerUserStats) => string = DockerUserStatsStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerUserStatsFromJSON(json: any): DockerUserStats {
    return DockerUserStatsFromJSONTyped(json, false);
}
export function DockerUserStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerUserStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'runId': json['runId'],
        'action': json['action'],
        'data': json['data'],
        'timestamp': json['timestamp'],
        'pipVersion': json['pipVersion'],
        'dockerVersion': json['dockerVersion'],
    };
}
export function DockerUserStatsToJSON(value?: DockerUserStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'runId': value.runId,
        'action': value.action,
        'data': value.data,
        'timestamp': value.timestamp,
        'pipVersion': value.pipVersion,
        'dockerVersion': value.dockerVersion,
    };
}

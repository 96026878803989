/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { LabelBoxDataRow } from './LabelBoxDataRow';
import { LabelBoxDataRowFromJSON, LabelBoxDataRowFromJSONTyped, LabelBoxDataRowToJSON, } from './LabelBoxDataRow';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface LabelBoxDataRows
 *
 */
// create custom validators for the regexes
export interface LabelBoxDataRows extends Array<LabelBoxDataRow> {
}
/**
 * Check if a given object implements the LabelBoxDataRows interface.
 */
export function instanceOfLabelBoxDataRows(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { LabelBoxDataRowValidator, LabelBoxDataRowStringifyShape, LabelBoxDataRowStringifyShapeProperties, } from './LabelBoxDataRow';
export const LabelBoxDataRowsTypiaAssertEquals = (input: any) => {
    return ((input: any): LabelBoxDataRows => {
        const __is = (input: any, _exceptionable: boolean = true): input is LabelBoxDataRows => {
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "object" === typeof value && null !== value && $io1(value, true && _exceptionable);
                return false;
            });
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.externalId && "string" === typeof input.imageUrl && (2 === Object.keys(input).length || Object.keys(input).every(key => {
                if (["externalId", "imageUrl"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is LabelBoxDataRows => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return ("object" === typeof value && null !== value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "LabelBoxDataRow",
                            value: value
                        })) && $ao1(value, _path + $join(key), true && _exceptionable) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "LabelBoxDataRow",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.externalId || $guard(_exceptionable, {
                    path: _path + ".externalId",
                    expected: "string",
                    value: input.externalId
                })) && ("string" === typeof input.imageUrl || $guard(_exceptionable, {
                    path: _path + ".imageUrl",
                    expected: "string",
                    value: input.imageUrl
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every(key => {
                    if (["externalId", "imageUrl"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "LabelBoxDataRows",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "LabelBoxDataRows",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const LabelBoxDataRowsTypiaAssertStringify = (input: LabelBoxDataRows): string => {
    return ((input: any): string => { const assert = (input: any): LabelBoxDataRows => {
        const __is = (input: any): input is LabelBoxDataRows => {
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every(key => {
                if (["length"].some(prop => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "object" === typeof value && null !== value && ("string" === typeof (value as any).externalId && "string" === typeof (value as any).imageUrl);
                return true;
            });
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is LabelBoxDataRows => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every(key => {
                    if (["length"].some(prop => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return ("object" === typeof value && null !== value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "LabelBoxDataRow",
                            value: value
                        })) && $ao1(value, _path + $join(key), true && _exceptionable) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "LabelBoxDataRow",
                            value: value
                        });
                    return true;
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.externalId || $guard(_exceptionable, {
                    path: _path + ".externalId",
                    expected: "string",
                    value: input.externalId
                })) && ("string" === typeof input.imageUrl || $guard(_exceptionable, {
                    path: _path + ".imageUrl",
                    expected: "string",
                    value: input.imageUrl
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "LabelBoxDataRows",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "LabelBoxDataRows",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: LabelBoxDataRows): string => {
        const $io1 = (input: any): boolean => "string" === typeof input.externalId && "string" === typeof input.imageUrl;
        const $join = (typia.createAssertStringify as any).join;
        const $string = (typia.createAssertStringify as any).string;
        const $tail = (typia.createAssertStringify as any).tail;
        const $so0 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some(regular => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${`{"externalId":${$string((value as any).externalId)},"imageUrl":${$string((value as any).imageUrl)}}`}`; return ""; }).filter(str => "" !== str).join(",")}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const LabelBoxDataRowsValidatorShape = {};
export const LabelBoxDataRowsValidator = yup.array().of(LabelBoxDataRowValidator);
export const LabelBoxDataRowsStringifyShapeProperties: ObjectSchema['properties'] = {};
export const LabelBoxDataRowsStringifyShape: ArraySchema = {
    title: 'LabelBoxDataRows Stringifier',
    type: 'array',
    items: LabelBoxDataRowStringifyShape
};
export const LabelBoxDataRowsStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(LabelBoxDataRowsStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const LabelBoxDataRowsStringify: (data: LabelBoxDataRows) => string = LabelBoxDataRowsStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function LabelBoxDataRowsFromJSON(json: any): LabelBoxDataRows {
    return LabelBoxDataRowsFromJSONTyped(json, false);
}
export function LabelBoxDataRowsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LabelBoxDataRows {
    return json;
}
export function LabelBoxDataRowsToJSON(value?: LabelBoxDataRows | null): any {
    return value;
}
